var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex",class:_vm.checkIfAnyIconPresent() ? '' : 'ml-auto'},[(_vm.shouldShowStockTooltip(_vm.item))?_c('v-tooltip',{attrs:{"bottom":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1 mt-n1",attrs:{"small":"","color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,595115738)},[_c('span',[_vm._v(_vm._s(_vm.$t("billings.text_messages.article_does_not_have_stock")))])]):_vm._e(),(_vm.item.supplier && _vm.item.supplier.length > 0)?[(_vm.showSupplierInfo(_vm.item))?_c('div',{staticClass:"cursor-pointer d-flex",class:_vm.getArticleClass(_vm.item),staticStyle:{"max-width":"100px","border":"0px"},attrs:{"dense":"","return-object":"","readonly":"","flat":"","solo":"","hide-details":"auto"},on:{"click":function($event){return _vm.emitReceptionDetail(_vm.item)}}},[(_vm.isPendingSupplierOrder(_vm.item))?_c('v-icon',{style:({ color: _vm.getSupplierOrderIconColor(_vm.item) }),attrs:{"slot":"prepend","small":""},slot:"prepend"},[_vm._v(" mdi-timer-sand ")]):_vm._e(),(_vm.isSupplierOrderCompleted(_vm.item))?_c('v-icon',{staticClass:"mr-1",attrs:{"slot":"prepend","small":"","color":"green"},slot:"prepend"},[_vm._v(" mdi-check ")]):_vm._e(),(_vm.isSupplierOrderAlert(_vm.item))?_c('v-icon',{staticClass:"mr-1 mt-n1",attrs:{"slot":"prepend","small":"","color":"red"},slot:"prepend"},[_vm._v(" mdi-alert-circle-outline ")]):_vm._e(),_c('span',{style:({
                    color: _vm.shouldShowStockTooltip(_vm.item)
                        ? 'red'
                        : _vm.isPendingSupplierOrder(_vm.item)
                        ? _vm.getSupplierOrderIconColor(_vm.item)
                        : _vm.isSupplierOrderCompleted(_vm.item)
                        ? 'green'
                        : _vm.isSupplierOrderAlert(_vm.item)
                        ? 'red'
                        : '',
                })},[(_vm.item.parent_supplier_id)?[_vm._v(" "+_vm._s(_vm.getSupplierById(_vm.item.parent_supplier_id))+" ")]:(_vm.item.supplier.length > 0)?[_vm._v(" "+_vm._s(_vm.item.supplier[0].identifier)+" ")]:_vm._e()],2)],1):(_vm.isStockManaged(_vm.item))?_c('div',{staticClass:"d-flex",staticStyle:{"max-width":"100px","border":"0px","float":"right"},style:({ color: !_vm.shouldShowStockTooltip(_vm.item) ? 'green' : '' })},[(!_vm.shouldShowStockTooltip(_vm.item))?_c('v-icon',{staticClass:"mr-1",attrs:{"slot":"prepend","small":"","color":"green"},slot:"prepend"},[_vm._v(" mdi-check ")]):_vm._e(),_vm._v(" INT ")],1):_vm._e()]:[(_vm.item && _vm.item.supplier_id)?[_vm._v(" "+_vm._s(_vm.getSupplierById(_vm.item.supplier_id))+" ")]:(_vm.isStockManaged(_vm.item))?_c('div',{staticClass:"d-flex",staticStyle:{"max-width":"100px","border":"0px","float":"right"},style:({ color: !_vm.shouldShowStockTooltip(_vm.item) ? 'green' : '' })},[(!_vm.shouldShowStockTooltip(_vm.item))?_c('v-icon',{staticClass:"mr-1",attrs:{"slot":"prepend","small":"","color":"green"},slot:"prepend"},[_vm._v(" mdi-check ")]):_vm._e(),_vm._v(" INT ")],1):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }