var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[(_vm.confirmationDialog)?_c('confirmation-model',{attrs:{"showDialog":_vm.confirmationDialog,"openedForOperation":_vm.confirmationDialogOperation,"text":_vm.confirmationDialogConfirmationText,"trueText":_vm.confirmationDialogTrueText,"falseText":_vm.confirmationDialogFalseText},on:{"update:showDialog":function($event){_vm.confirmationDialog=$event},"update:show-dialog":function($event){_vm.confirmationDialog=$event},"update:openedForOperation":function($event){_vm.confirmationDialogOperation=$event},"update:opened-for-operation":function($event){_vm.confirmationDialogOperation=$event},"update:text":function($event){_vm.confirmationDialogConfirmationText=$event},"update:trueText":function($event){_vm.confirmationDialogTrueText=$event},"update:true-text":function($event){_vm.confirmationDialogTrueText=$event},"update:falseText":function($event){_vm.confirmationDialogFalseText=$event},"update:false-text":function($event){_vm.confirmationDialogFalseText=$event},"buttonClicked":_vm.confirmationButtonClicked}}):_vm._e(),_c('v-form',{ref:"form",staticClass:"main_form",attrs:{"id":"supplier-form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('v-btn',{staticClass:"d-none",attrs:{"type":"submit","id":"supplier-client"}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"name":"name","label":_vm.$t('supplier.name'),"required":"","error-messages":_vm.$store.state.validation && !_vm.$v.name.required
                ? [this.$t('supplier.name_required')]
                : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'alphanumeric', 50)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('supplier.contact'),"required":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'alphanumeric', 50)}},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('supplier.identifier'),"required":"","error-messages":_vm.$store.state.validation ?  _vm.returnErrorMessage(
              [!_vm.$v.identifier.required,this.$t('supplier.identifier_required')],
              [!_vm.$v.identifier.maxLength,this.$t('supplier.max_3_character_allowed')]
            ) : [],"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"keydown":function($event){return _vm.handleInput($event, 'alphanumeric', 50)}},model:{value:(_vm.identifier),callback:function ($$v) {_vm.identifier=$$v},expression:"identifier"}})],1),_c('v-col',[_c('v-autocomplete',{staticClass:"to-upper to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.suppliers,"item-text":"name","item-value":"id","label":_vm.$t('supplier.parent_supplier_id'),"hide-details":"auto","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"return-object":""},on:{"keydown":[function($event){return _vm.handleInput($event, 'justalphanumeric', 25)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}]},model:{value:(_vm.parent_supplier),callback:function ($$v) {_vm.parent_supplier=$$v},expression:"parent_supplier"}})],1),_c('v-col',[_c('v-autocomplete',{staticClass:"to-upper to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.supplierStatuses,"item-text":"text","item-value":"id","label":_vm.$t('supplier.status'),"error-messages":_vm.$store.state.validation && !_vm.$v.supplierStatus.required
                ? [this.$t('supplier.supplier_status_required')]
                : [],"hide-details":"auto","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"return-object":""},on:{"keydown":[function($event){return _vm.handleInput($event, 'justalphanumeric', 25)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}]},model:{value:(_vm.supplierStatus),callback:function ($$v) {_vm.supplierStatus=$$v},expression:"supplierStatus"}})],1),_c('v-col',[_c('v-autocomplete',{staticClass:"to-upper to-upper",attrs:{"autocomplete":_vm.autocompleteValueLocal,"items":_vm.supplierTypes,"item-text":"text","item-value":"id","label":_vm.$t('supplier.type'),"error-messages":_vm.$store.state.validation && !_vm.$v.supplierType.required
                ? [this.$t('supplier.supplier_type_required')]
                : [],"hide-details":"auto","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"return-object":""},on:{"keydown":[function($event){return _vm.handleInput($event, 'justalphanumeric', 25)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();}]},model:{value:(_vm.supplierType),callback:function ($$v) {_vm.supplierType=$$v},expression:"supplierType"}})],1),_c('v-col',[_c('v-select',{staticClass:"mr-0",attrs:{"items":_vm.familyArticles,"item-value":"id","item-text":"name","label":_vm.$t('supplier.family_article'),"error-messages":_vm.$store.state.validation && !_vm.$v.familyArticle.required
                ? [this.$t('supplier.family_article_required')]
                : [],"return-object":"","hide-details":"auto"},on:{"keydown":function($event){return _vm.handleInput($event, 'justalphanumeric', 25)}},model:{value:(_vm.familyArticle),callback:function ($$v) {_vm.familyArticle=$$v},expression:"familyArticle"}})],1)],1)],1)],1),_c('v-btn',{staticClass:"d-none",attrs:{"id":"submit-supplier"},on:{"click":function($event){return _vm.onSubmit()}}}),_c('v-btn',{staticClass:"d-none",attrs:{"id":"delete-supplier"},on:{"click":function($event){return _vm.onDelete()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }