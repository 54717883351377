<template>
  <v-dialog :value="value" max-width="800" style="height: 60vh" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">{{translate('cash_register')}}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <thead>
              <tr>
                <th>{{ translate('monetary_euro') }}</th>
                <th>{{ translate('count') }}</th>
                <th>{{ translate('total_euro') }}</th>
              </tr>
              </thead>
              <tbody v-if="cashRegister">
              <tr v-for="item in ['in_cash']" :key="item">
                <td>{{ translate(item) }}</td>
                <td>
                  <v-text-field
                      :disabled="cashRegisterDateNotToday"
                      v-model="cashRegister[item]"
                      dense
                      type="text"
                      style="width: 60px"
                      @keydown="(e) => handleInput(e, 'float')"
                      @mouseenter="(event) =>  event.target.select()"
                      @click="(event) =>  event.target.select()"
                  />
                </td>
                <td> {{ cashRegister[item] }} </td>
              </tr>

              <tr v-for="detail in cashRegisterDetails" :key="detail.id">
                <td>{{ detail.monetary_figure.name }}</td>
                <td>
                  <v-text-field
                      :disabled="cashRegisterDateNotToday"
                      v-model="detail.quantity"
                      dense
                      type="text"
                      style="width: 60px"
                      @keydown="(e) => handleInput(e, 'int')"
                      @mouseenter="(event) =>  event.target.select()"
                      @click="(event) =>  event.target.select()"
                  />
                </td>
                <td>{{ parseFloat(detail.quantity * detail.monetary_figure.value).toFixed(2) }}</td>
              </tr>
              <tr v-for="item in ['out_cash']" :key="item">
                <td>{{ translate(item) }}</td>
                <td>
                  <v-text-field
                      :disabled="cashRegisterDateNotToday"
                      v-model="cashRegister[item]"
                      dense
                      type="text"
                      style="width: 60px"
                      @keydown="(e) => handleInput(e, 'float')"
                      @mouseenter="(event) =>  event.target.select()"
                      @click="(event) =>  event.target.select()"
                  />
                </td>
                <td> {{ cashRegister[item] }} </td>
              </tr>
              <tr style="font-weight: bolder; font-size: 30px">
                <td></td>
              <td>{{ translate('total') }}</td>
                <td>{{ grandTotal }}</td>
              </tr>
              </tbody>

              <tbody v-if="!cashRegister && monetary_figures">
              <tr v-for="item in ['in_cash']" :key="item">
                <td>{{ translate(item) }}</td>
                <td>
                  <v-text-field
                      :disabled="cashRegisterDateNotToday"
                      v-model="form[item]"
                      dense
                      type="text"
                      style="width: 60px"
                      @keydown="(e) => handleInput(e, 'float')"
                      @mouseenter="(event) =>  event.target.select()"
                      @click="(event) =>  event.target.select()"
                  />
                </td>
                <td> {{ form[item] }} </td>
              </tr>
              <tr v-for="figure in monetary_figures" :key="figure.id">
                <td>{{ figure.name }}</td>
                <td>
                  <v-text-field
                      :disabled="cashRegisterDateNotToday"
                      v-model="figure.quantity"
                      dense
                      type="text"
                      style="width: 60px"
                      @keydown="(e) => handleInput(e, 'int')"
                      @mouseenter="(event) =>  event.target.select()"
                      @click="(event) =>  event.target.select()"
                  />
                </td>
                <td>{{ parseFloat(figure.quantity * figure.value).toFixed(2) }}</td>
              </tr>
              <tr style="font-weight: bolder; font-size: 30px">
                <td></td>
                <td>{{ translate('total') }}</td>
                <td>{{ grandTotalNew }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span @click="close"> <CloseButton :disabled="loading"/> </span>
        <span class="mx-2" @click="createCashRegister" v-if="isCreate"><SaveButton :loading="loading" :disabled="loading"  /></span>
        <span class="mx-2" @click="updateCashRegister" v-else><SaveButton :loading="loading" :disabled="loading" /></span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import _axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";
import moment from "moment";
import SaveButton from "@/components/Buttons/SaveButton.vue";
export default {
  name: 'CashRegisterModal',
  components: {SaveButton, CloseButton},
  emits: ['input', 'cash'],
  data(){
    return {
      monetary_figures: [],
      hoveredCol: null,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      timerId : null,
      form: {
        in_cash: 0,
        out_cash: 0,
      }
    }
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    cashRegister: {
      type: Object,
      default: null
    }
  },
  mounted(){
    this.getMonetaryFigures()
  },
  watch: {
    value(val){
      if(!val){
        this.resetForm()
      }
    },
  },
  activated() {
    this.getMonetaryFigures()
  },
  computed: {
    loading(){
      return this.$store.state.isLoading
    },
    todaysDate(){
      return moment().format('YYYY-MM-DD')
    },
    cashRegisterDateNotToday(){
      //Temporarily return false to allow editing of cash register
      return false

      // if(!this.cashRegister){
      //   return false
      // }
      //
      // return moment(this.cashRegister?.creation_date_time).format('YYYY-MM-DD') !== this.todaysDate
    },
    cashRegisterDetails(){
      if(this.isCreate){
        return this.monetary_figures.map(mf => {
          return {
            monetary_figure_id: mf.id,
            quantity: 0
          }
        })
      }
      return this.cashRegister?.details || []
    },
    isCreate(){
      return !this.cashRegister
    },
    grandTotal(){
      if(!this.cashRegister){
        return 0
      }

      return parseFloat(
          this.cashRegisterDetails.reduce((acc, detail) => acc + (detail.quantity * detail.monetary_figure.value), 0)
          + (parseFloat(this.cashRegister.in_cash || 0)) - (parseFloat(this.cashRegister.out_cash || 0))
      ).toFixed(2)
    },
    grandTotalNew(){
      return parseFloat(
          this.monetary_figures.reduce((acc, detail) => acc + (detail.quantity * detail.value), 0)
          + (parseFloat(this.form.in_cash || 0)) - (parseFloat(this.form.out_cash || 0))
      ).toFixed(2)
    }
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    updateCashRegisterDetail(detail){
      if(this.timerId){
        clearTimeout(this.timerId)
      }
      this.timerId = setTimeout(() => {
        return new Promise(resolve => {
          return _axios.put(API_BASE_URL + '/company_cash_register/details/' + detail.id, {quantity: detail.quantity})
              .then(({data}) => {
                // let index = this.cashRegister.details.findIndex(d => d.id === detail.id)
                // this.cashRegister.details[index] = data
                this.$emit('cash', {
                  cash_register_id: this.cashRegister.id,
                  cash: data
                })
                resolve(data)
              })
        })
      }, 1000)
    },
    createCashRegister(){
      // if all values are 0, stop
      if(this.monetary_figures.every(mf => mf.quantity === 0) && this.form.in_cash === 0){
        this.$toast.error(this.translate('no_data_provided'));
        return
      }
      return new Promise(resolve => {
        this.$store.state.isLoading = true
        return _axios.post(API_BASE_URL + '/company_cash_register', {
          in_cash: this.form.in_cash || 0,
          out_cash: this.form.out_cash || 0,
          creation_date_time: moment().format('YYYY-MM-DD HH:mm:ss'),
          details: this.monetary_figures.map(detail => {
            return {
              monetary_figure_id: detail.id,
              quantity: detail.quantity
            }
          })
        })
            .finally(() => this.$store.state.isLoading = false)
            .then(({data}) => {
              this.$emit('cash', data.data)
              this.$toast.success(this.translate('added_successfully'));
              this.close()
              this.resetForm()
              // if route is not cash register, redirect to cash register
              if(this.$route.name !== 'CashRegisterListing'){
                this.$router.push({name: 'CashRegisterListing'})
              }
              resolve(data)
            })
      })
    },
    updateCashRegister(){
      if(this.timerId){
        clearTimeout(this.timerId)
      }

        return new Promise(resolve => {
          this.$store.state.isLoading = true
          return _axios.put(API_BASE_URL + '/company_cash_register/' + this.cashRegister.id, {
            in_cash: this.cashRegister.in_cash || 0,
            out_cash: this.cashRegister.out_cash || 0,
            details: this.cashRegisterDetails.map(detail => {
              return {
                id: detail.id,
                quantity: detail.quantity
              }
            })
          })
              .finally(() => this.$store.state.isLoading = false)
              .then(({data}) => {
                this.$emit('cash',data.data)
                this.$toast.success(this.translate('updated_successfully'));
                this.close()
                this.resetForm()
                resolve(data)
              })
        })
      },
    getMonetaryFigures(){
      return new Promise(resolve => {
        return _axios.get(API_BASE_URL + '/monetary_figures', { headers: this.header})
            .then(({data}) => {
              this.monetary_figures = data.data.map( i => {
                i.quantity = 0
                return i
              })
              resolve(data)
            })
      })
    },
    resetForm(){
      this.form = {
        in_cash: 0,
        out_cash: 0,
      }

      this.monetary_figures = this.monetary_figures.map(mf => {
        mf.quantity = 0
        return mf
      })
      this.cashRegister = null
    },
    translate(key){
      return this.$t('cash_register_section.' + key)
    },
  }
}
</script>