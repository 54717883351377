var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1000"},on:{"click:outside":function($event){return _vm.closePopUpModel()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closePopUpModel()}},model:{value:(_vm.popUpModelVisible),callback:function ($$v) {_vm.popUpModelVisible=$$v},expression:"popUpModelVisible"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("receptions.reception_details"))+" - "+_vm._s(_vm.data.article_original_name.original_name))])]),_c('v-card-text',[_c('v-row',[_c('span'),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-autocomplete',{staticClass:"mr-2 to-upper",attrs:{"flat":"","items":_vm.allSuppliers,"label":_vm.$t('receptions.supplier'),"item-value":"id","item-text":"name","return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-no-data":""},on:{"change":function($event){return _vm.setReceptionStatusToStatus('successfully_ordered')}},model:{value:(_vm.data.saved_supplier_order_detail.supplier_order.supplier),callback:function ($$v) {_vm.$set(_vm.data.saved_supplier_order_detail.supplier_order, "supplier", $$v)},expression:"data.saved_supplier_order_detail.supplier_order.supplier"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{staticClass:"mr-2 to-upper",attrs:{"label":_vm.$t('receptions.ordered_quantity'),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"input":function($event){return _vm.setReceptionStatusToStatus('successfully_ordered')}},model:{value:(
                            _vm.data.saved_supplier_order_detail.ordered_quantity
                        ),callback:function ($$v) {_vm.$set(_vm.data.saved_supplier_order_detail, "ordered_quantity", $$v)},expression:"\n                            data.saved_supplier_order_detail.ordered_quantity\n                        "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{staticClass:"mr-2 to-upper",attrs:{"label":_vm.$t('receptions.confirmed_quant_label'),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"input":function($event){return _vm.setReceptionStatusToStatus('successfully_ordered')}},model:{value:(
                            _vm.data.saved_supplier_order_detail.confirmed_quantity
                        ),callback:function ($$v) {_vm.$set(_vm.data.saved_supplier_order_detail, "confirmed_quantity", $$v)},expression:"\n                            data.saved_supplier_order_detail.confirmed_quantity\n                        "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{staticClass:"mr-2 to-upper",attrs:{"label":_vm.$t('receptions.received_quant_label'),"outlined":_vm.is_boxes,"dense":_vm.is_boxes},on:{"input":function($event){return _vm.setReceptionStatusDynamically()}},model:{value:(
                            _vm.data.saved_supplier_order_detail.received_quantity
                        ),callback:function ($$v) {_vm.$set(_vm.data.saved_supplier_order_detail, "received_quantity", $$v)},expression:"\n                            data.saved_supplier_order_detail.received_quantity\n                        "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"items":_vm.rec_statuses,"label":_vm.$t('receptions.status'),"item-value":"id","item-text":"text","return-object":"","outlined":_vm.is_boxes,"dense":_vm.is_boxes,"hide-details":"","append-icon":"mdi-close"},on:{"click:append":function($event){_vm.status = null}},model:{value:(_vm.data.saved_supplier_order_detail.supplier_order.status),callback:function ($$v) {_vm.$set(_vm.data.saved_supplier_order_detail.supplier_order, "status", $$v)},expression:"data.saved_supplier_order_detail.supplier_order.status"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey-lighten-2"},on:{"click":function($event){return _vm.closePopUpModel()}}},[_vm._v(" "+_vm._s(_vm.$t("receptions.modify_article.cancel"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return ")])],1),(this.data.saved_supplier_order_detail.supplier_order.status_id != 10091005 )?_c('v-btn',{attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.updateReceptionOrder()}}},[_vm._v(" "+_vm._s(_vm.$t("receptions.modify_article.save"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-content-save ")])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }