var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-text-field',{staticClass:"ml-5 text-right",class:{ 'mr-5': _vm.marginRight },attrs:{"label":_vm.$t('stocks.client_price') + '.X'},on:{"keydown":($event) => {
        _vm.handleInput($event, 'float');
        // if keycode  is equivalent to 0, prevent default
        if ($event.keyCode == 48) {
          $event.preventDefault();
        }

        // if its value greater than 4 decimal points and another number is to be added, prevent default
        if (
          $event.target.value.split('.')[1] &&
          $event.target.value.split('.')[1].length >= 4 &&
          $event.keyCode != 8
        ) {
          $event.preventDefault();
        }
      },"focusout":($event) =>
        $event.target.value < 1
          ? (_vm.stockUnits = 1)
          : (_vm.stockUnits = $event.target.value),"focus":($event) => $event.target.select()},model:{value:(_vm.stockUnits),callback:function ($$v) {_vm.stockUnits=$$v},expression:"stockUnits"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }