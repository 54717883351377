import { render, staticRenderFns } from "./CreateEditVehicle.vue?vue&type=template&id=5c71d48c&scoped=true"
import script from "./CreateEditVehicle.vue?vue&type=script&lang=js"
export * from "./CreateEditVehicle.vue?vue&type=script&lang=js"
import style0 from "./CreateEditVehicle.vue?vue&type=style&index=0&id=5c71d48c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c71d48c",
  null
  
)

export default component.exports