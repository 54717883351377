<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="formattedItems"
        :options="{
            itemsPerPage: perPage,
            itemsPerPageOptions: perPageOptions,
            page: page,

        }"
        :height="$store.state.defaultTableHeight"
        fixed-header
        :footer-props="{
            'items-per-page-options': perPageOptions,
          }"
        :server-items-length="totalItems"
        @update:page="getClocking"
        @update:items-per-page="(itemsPerPage) =>{
        perPage = itemsPerPage
        getClocking()
      }"
    >
      <!--      Override textboox header to be textbox as well-->
      <template #header.checkbox="{  }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-checkbox
                hide-details
                :input-attrs="attrs"
                :input-on="on"
                @click="toggleCheckAll()"
            ></v-checkbox>
          </template>
          <span>{{ translate('select_all') }}</span>
        </v-tooltip>
      </template>
      <template #body>
        <tbody>
        <tr v-for="item in formattedItems" :key="item.id" :style="{
          background: computeRowBgColor(item)
        }">
          <td>
            <v-checkbox
                class="my-auto"
                v-model="selected[item.id]"
                hide-details
            ></v-checkbox>
          </td>
          <td>{{ item.date }}</td>
          <td>{{ item.name }}</td>
          <td
              :style="{
            background: computeWorkBgColor(item),
            textAlign: computeColSpan(item) === 4 ? 'center' : 'left'
          }" :colspan="computeColSpan(item)">{{
              item.clocking_type
            }}
          </td>
          <td :style="{background: computeWorkBgColor(item)}" v-if="computeColSpan(item) === 1">{{ item.check_in }}</td>
          <td :style="{background: computeWorkBgColor(item)}" v-if="computeColSpan(item) === 1">{{ item.check_out }}</td>
          <td :style="{background: computeWorkBgColor(item)}" v-if="computeColSpan(item) === 1">{{ item.difference }}</td>
        </tr>
        </tbody>
      </template>

    </v-data-table>
    <span id="delete-selected" @click="confirmDelete">
    <confirmation-model
        v-if="confirmationDialog"
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        :checkboxText.sync="confirmationDialogReCheckBoxConfirmationText"
        @buttonClicked="e => confirmationButtonClicked(e, deleteHandler)"
    />
      </span>
  </div>
</template>
<script>
import ClockingMixin from "@/mixins/clockingMixin";
import {API_BASE_URL, CLOCKING_COLORS} from "@/config";
import _axios from "@/plugins/axios";
import {DEFAULT_ITEMS_PER_PAGE_OPTIONS} from "@/config";
import moment from "moment";
import confirmationModelMixin from "@/mixins/ConfirmationModelMixin";

export default {
  name: 'ClockingTableListing',
  mixins: [ClockingMixin, confirmationModelMixin],
  props: ['filterType', 'fromDate', 'toDate', 'day', 'selectedMonth', 'selectedWeek', 'selectedEmployee', 'selectedType'],
  computed: {
    watchList() {
      return {
        selectedMonth: this.selectedMonth,
        selectedWeek: this.selectedWeek,
        day: this.day,
        selectedEmployee: this.selectedEmployee,
        selectedType: this.selectedType
      }
    },
    dateWatchList() {
      return {
        fromDate: this.fromDate,
        toDate: this.toDate
      }
    },
    formattedItems() {
      return this.clocking
          .filter(i => !i.is_placeholder)
          .map((item) => {
            return {
              checkbox: false,
              id: item.id,
              date: moment(item.check_in_time).locale(this.userLanguageId).format('ddd DD MMM'),
              name: item.employee_contract.employee.name,
              clocking_type: this.translate('types.' + item.type.key),
              check_in: item.check_in_time_only,
              check_out: item.check_out_time_only,
              type: item.type,
              check_in_time: item.check_in_time,
              difference: ['work', 'break', 'mission', 'sick'].includes(item.type.key) ? this.convertMinutesToHours(item.time_difference) : '',
            }
          })
    },
    processedTypes() {
      return this.clockingTypes
          .map(item => {
            item.text = this.translate('types.' + item.key)
            return item
          })
          .sort((a, b) => a.key.localeCompare(b.key))
    },
    itemAreSelected() {
      return Object.values(this.selected).includes(true)
    }
  },
  mounted() {
    this.getClocking()
  },
  data() {
    return {
      headers: [
        {text: this.translate('select'), value: 'checkbox', sortable: false},
        {text: this.translate('date'), value: 'date', sortable: false},
        {text: this.translate('name'), value: 'name', sortable: false},
        {text: this.translate('clocking_type'), value: 'clocking_type', sortable: false},
        {text: this.translate('check_in'), value: 'check_in', sortable: false},
        {text: this.translate('check_out'), value: 'check_out', sortable: false},
        {text: this.translate('difference'), value: 'difference', sortable: false},
      ],
      clocking: [],
      perPageOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      perPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      totalItems: 0,
      page: 1,
      selected: {},
      allChecked: false,
    }
  },
  watch: {
    watchList: {
      handler: function () {
        this.getClocking()
      },
      deep: true
    },
    dateWatchList: {
      handler: function () {
        if (this.fromDate && this.toDate) {
          this.getClocking()
        }
      },
      deep: true
    },
    filterType() {
      if (this.filterType === 'day' && this.day) {
        this.getClocking()
      }

      if (this.filterType === 'week' && this.selectedWeek) {
        this.getClocking()
      }

      if (this.filterType === 'month' && this.selectedMonth) {
        this.getClocking()
      }

      if (this.filterType === 'custom' && this.fromDate && this.toDate) {
        this.getClocking()
      }
    },
    itemAreSelected() {
      this.$store.state.multipleItemsSelected = this.itemAreSelected
    }
  },
  methods: {
    getClocking(page = 1) {
      _axios.get(this.url(page))
          .then(response => {
            this.clocking = response.data.data;
            this.totalItems = response.data.total;
            this.processSelected(this.clocking);
          })
          .catch(error => {
            console.log(error);
          });
    },
    processSelected(clocking) {
      let response = {};

      for (let i = 0; i < clocking.length; i++) {
        response[clocking[i].id] = this.selected[clocking[i].id] ?? false;
      }

      this.selected = response;
      console.clear();
    },
    url(page) {
      let url = API_BASE_URL + '/clocking?page=' + page;

      if (['custom'].includes(this.filterType) && this.isset(this.fromDate) && this.isset(this.toDate)) {
        url += '&from_date=' + this.fromDate + '&to_date=' + this.toDate;
      }

      if (this.filterType === 'month' && this.isset(this.selectedMonth)) {
        // Using moment get start date and end date of the month
        let fromDate = moment(this.selectedMonth).startOf('month').format('YYYY-MM-DD')
        let toDate = moment(this.selectedMonth).endOf('month').format('YYYY-MM-DD')

        url += '&from_date=' + fromDate + '&to_date=' + toDate;
      }

      if (this.filterType === 'week' && this.isset(this.selectedWeek)) {
        // Using moment get start date and end date of the week
        let fromDate = moment(this.selectedWeek).startOf('week').format('YYYY-MM-DD')
        let toDate = moment(this.selectedWeek).endOf('week').format('YYYY-MM-DD')

        url += '&from_date=' + fromDate + '&to_date=' + toDate;
      }

      if (this.filterType === 'day' && this.isset(this.day)) {
        url += '&day=' + this.day;
      }

      if (this.selectedEmployee) {
        url += '&employee_contract_id=' + this.selectedEmployee.active_contract.id;
      }

      if (this.selectedType) {
        url += '&clocking_type_id=' + this.selectedType;
      }

      return url;
    },
    toggleCheckAll() {
      let response = {};

      for (let i = 0; i < this.clocking.length; i++) {
        response[this.clocking[i].id] = !this.allChecked;
      }
      this.selected = response;
      this.allChecked = !this.allChecked;
    },
    deleteHandler() {
      let selected = Object.keys(this.selected).filter(key => this.selected[key] === true);
      _axios.put(API_BASE_URL + '/clocking', {payload: selected})
          .then(response => {
            this.getClocking();
            this.$toast.success(this.translate('delete_success'));
          })
    },
    confirmDelete() {
      this.popConfirmation(
          this.translate('delete_clocking_confirmation_text'),
          "deleteHandler",
          null,
          null,
          this.$t("billings.convert_to_credit_note_confirmation_reconfirm_text")
      );
    },
    computeWorkBgColor(clocking) {
      let type = clocking.type.key;
      if (['vacation', 'sick_leave', 'absent', 'public_holiday', 'work'].includes(type)) {
        return CLOCKING_COLORS[type]
      } else if (clocking.check_in_time === clocking.check_out_time || !clocking.check_out_time) {
        return CLOCKING_COLORS.default
      } else {
        return CLOCKING_COLORS.work
      }
    },
    computeRowBgColor(clocking) {
      // check if day is weekend
      return moment(clocking.check_in_time).day() === 0 || moment(clocking.check_in_time).day() === 6 ? CLOCKING_COLORS.no_work : ''
    },
    computeColSpan(clocking) {
      let whiteList = ['work', 'break', 'mission'];
      if (whiteList.includes(clocking?.type?.key)) return 1

      return 4
    },
  }
}
</script>