<template>
    <v-row>
        <v-col cols="2">
            <v-simple-table
                style="height:90vh; overflow-y: auto"
                class="items-list"
            >
                <thead>
                    <tr>
                        <th>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-close"
                                @keydown.enter="getCompanySupplierConnections()"
                                @click:append="
                                    search = null;
                                    getCompanySupplierConnections();
                                "
                            ></v-text-field>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="connection in companySupplierConnections"
                        :key="connection.id"
                        :style="{ background: computeBackground(connection) }"
                        @click="selectedConnection = connection"
                        class="cursor-pointer"
                    >
                        <td>
                            {{ connection.supplier_name }}
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-col>
        <v-col cols="10">
            <supplier-connection-form
                :connection="selectedConnection"
                @updateConnection="updateHandler"
            ></supplier-connection-form>
        </v-col>
    </v-row>
</template>
<script>
import _axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import SupplierConnectionForm from "@/components/CompanySupplierConnection/SupplierConnectionForm";

export default {
    name: "CompanySupplierConnectionListing",
    components: {
        SupplierConnectionForm,
    },
    data() {
        return {
            companySupplierConnections: [],
            selectedConnection: null,
            search: null,
        };
    },
    async mounted() {
        await this.getCompanySupplierConnections();
        this.setFirstConnectionAsSelectedOrUseNewConnectionId();
        this.$store.state.isLoading = false;
    },
    methods: {
        getCompanySupplierConnections() {
            return new Promise((resolve, reject) => {
                _axios
                    .get(this.getALlUrl())
                    .then((response) => {
                        this.companySupplierConnections = response.data.data.filter(
                            (i) => i.supplier_id !== 0
                        );
                        resolve(response.data.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        getALlUrl() {
            let url = API_BASE_URL + "/company_supplier_connections";

            if (this.search) {
                url += "?search=" + this.search;
            }

            return url;
        },
        setFirstConnectionAsSelectedOrUseNewConnectionId() {
            if (this.companySupplierConnections.length > 0) {
                const newConnectionId = this.$store.state.newConnectionId;
                this.selectedConnection = newConnectionId
                    ? this.companySupplierConnections.find(
                          (c) => c.id === newConnectionId
                      )
                    : this.companySupplierConnections[0];

                this.$store.state.newConnectionId = null;
            }
        },
        updateHandler(connection) {
            let index = this.companySupplierConnections.findIndex(
                (c) => c.id === connection.id
            );
            this.companySupplierConnections[index] = connection;
        },
        computeBackground(connection) {
            if (
                this.selectedConnection &&
                this.selectedConnection.id === connection.id
            ) {
                // mix light grey bg with lighter green for enabled and lighter red for disabled
                if (
                    connection.edi_allow_order == 0 ||
                    connection.get_allow_order == 0
                ) {
                    return "#5D5D5DFF";
                }
                return connection.is_enabled === 1 ? "#64D391FF" : "#8e7779";
            }

            if (
                connection.edi_allow_order == 0 ||
                connection.get_allow_order == 0
            ) {
                return "#A8A8A8FF";
            }

            if (connection.is_enabled === 1) {
                // green (success)
                return "#d4edda";
            }

            if (connection.is_enabled === 0) {
                // red (danger)
                return "#f8d7da";
            }

            return "";
        },
    },
};
</script>
<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.items-list::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.items-list {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
</style>
