<template>
  <div>
    <v-dialog :value="value" width="1500" :persistent="processing">
      <v-card>
        <v-card-title>
          <span class="headline mr-2">{{ $t('tyre_hotel.bulk.bulk_operation') }}</span>
          <v-spacer/>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="2"  class="mb-0 pb-0">
              <v-select
                  v-model="selectedOperationType"
                  :label="translate('operation_type')"
                  :items="bulkOperationTypes"
                  item-text="text"
                  item-value="value"
              ></v-select>
            </v-col>
            <v-col cols="12" :sm="5"  class="mb-0 pb-0">
              <v-menu
                  v-if="selectedOperationTypeIsReturnRequest"
                  v-model="dayPickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="selectedDay"
                      :label="$t('clocking_section.filter_options.select_day')"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedDay"
                    @input="dayPickerMenu = false"
                    :min="new Date().toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>

              <v-combobox
                  v-if="selectedOperationTypeIsStoreToDepot"
                  v-model="client"
                  :items="clients"
                  item-text="name"
                  :label="translate('client')"
                  class="to-upper"
                  @update:search-input="searchClients"
                  clearable
                  :loading="searchingClients"
              />
            </v-col>
            <v-col cols="12" :sm="5" class="mb-0 pb-0">
              <v-combobox
                  v-if="selectedOperationTypeIsReturnRequest"
                  v-model="depotManagerName"
                  :items="depotManagerNamePredictions"
                  :label="$t('tyre_hotel.deposit.depot_manager_name')"
                  class="to-upper"
                  @update:search-input="getManagersNamePredictions"
                  clearable
              />
              <v-select
                  v-model="template"
                  v-if="selectedOperationType === 'store_to_depot'"
                  :items="templates"
                  item-text="description"
                  item-value="id"
                  :label="translate('template')"
              >

              </v-select>
            </v-col>
            <v-col class="mt-0 pt-0" cols="12" v-if="csvHeaders">
              <p style="font-weight: bolder">{{csvHeaders}}</p>
            </v-col>

            <v-col cols="12">
              <v-textarea
                  :label="selectedOperationTypeIsStoreToDepot ? translate('paste_csv_with_headers') : translate('paste_csv')"
                  v-model="bulkData"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <span @click="close()" class="mr-3"> <CloseButton :disabled="processing"/> </span>
          <span @click="launchConfirmation()"><SaveButton :label="$t('submit')" :loading="processing"/></span>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation-model
        v-if="confirmationDialog"
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </div>
</template>
<script>
import CloseButton from "@/components/Buttons/CloseButton.vue";
import confirmationModel from "@/components/ConfirmationModel.vue";
import axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import debounce from "lodash/debounce";

export default {
  name: 'BulkOperationForm',
  components: {SaveButton, confirmationModel, CloseButton},
  emits: ['updated', 'created'],
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      dayPickerMenu: false,
      selectedDay: null,
      depotManagerName: null,
      selectedOperationType: 'return_request',
      client: null,
      clients: [],
      templates: [],
      template: null,
      templateWithDetails: null,
      depotManagerNamePredictions: [],
      bulkData: null,
      processedBulkData: [],
      confirmationDialog: false,
      confirmationDialogOperation: null,
      confirmationDialogConfirmationText: null,
      confirmationDialogTrueText: null,
      confirmationDialogFalseText: null,
      header: {Authorization: 'Bearer ' + this.$store.state.AccessToken},
      depotBulkOperation: null,
      depotBulkOperationDetailDialog: false,
      searchingClients: false,
      processing: false

    }
  },
  computed: {
    bulkOperationTypes() {
      return [
        {text: this.$t('tyre_hotel.bulk.return_request'), value: 'return_request'},
        {text: this.$t('tyre_hotel.bulk.store_to_depot'), value: 'store_to_depot'},
      ]
    },
    selectedOperationTypeIsStoreToDepot() {
      return this.selectedOperationType === 'store_to_depot';
    },
    selectedOperationTypeIsReturnRequest() {
      return this.selectedOperationType === 'return_request';
    },
    csvHeaders(){
      if(!this.selectedOperationTypeIsStoreToDepot || !this.template || !this.templateWithDetails) return null;

      let countColumns = this.templateWithDetails.details.length

      return this.translate('csv_recommended_columns', {length: countColumns}) + ' ' + this.templateWithDetails.details.map(i => i.field_name).join(', ');
    },
  },
  mounted() {
    this.getCsvMappingTemplates();
  },
  watch: {
    value() {
      if (this.value) {
        this.selectedDay = null;
        this.depotManagerName = null;

      }
    },
    template(){
      this.getTemplate();
    },
  },
  methods: {
    translate(key) {
      return this.$t('tyre_hotel.bulk.' + key)
    },
    close() {
      this.bulkData = null;
      this.$emit('input', false);
    },
    confirmationButtonClicked(value) {
      if (!value) {
        return false;
      }

      if (this.confirmationDialogOperation === 'return_request') {
        this.processBulkData()
      }
    },
    launchConfirmation() {
      if (this.selectedOperationTypeIsReturnRequest) {
        if (!this.selectedDay) {
          return this.$toast.error(this.$t('clocking_section.filter_options.select_day'));
        }
      }

      if (this.selectedOperationTypeIsStoreToDepot) {
        if (!this.client) {
          return this.$toast.error(this.$t('tyre_hotel.bulk.select_client_error'));
        }

        if (!this.template) {
          return this.$toast.error(this.translate('select_template_error'));
        }
      }

      this.confirmationDialogConfirmationText = this.$t('tyre_hotel.return_request_confirmation_text');
      this.confirmationDialogFalseText = this.$t('tyre_hotel.return_request_false_button_text');
      this.confirmationDialogTrueText = this.$t('tyre_hotel.return_request_true_button_text');
      this.confirmationDialogOperation = 'return_request';
      this.confirmationDialog = true;

    },
    generatePayload(){
      if(this.selectedOperationTypeIsReturnRequest){
        return {
          data: this.bulkData,
          date: this.selectedDay,
          depot_manager: this.depotManagerName
        }
      }else if(this.selectedOperationTypeIsStoreToDepot){
        return {
          data: this.bulkData,
          client_id: this.client.id,
          data_mapping_id: this.template,
          action: 'in'
        }
      }
    },
    processBulkData() {
        let endpoint = this.selectedOperationTypeIsStoreToDepot
      ? API_BASE_URL + '/data_mappings'
            : API_BASE_URL + '/depots/bulk_operations'
      this.processing = true;
      axios.post(endpoint, this.generatePayload(), {headers: this.header})
          .finally(() => {
            this.processing = false;
          })
          .then((data) => {
            this.processedBulkData = data.data;
            this.$emit("updated", this.processedBulkData);
            this.close();
            localStorage.setItem('bulk_operation_id', this.processedBulkData.data.id);
            this.$router.push({name: 'TyreHotelBulkOperationDetails'})
          })
          .catch((error) => {
            console.log(error)
            this.$toast.error(this.$t('unknown_error'))
          })
          .finally(() => {

          })
    },
    getManagersNamePredictions(search) {
      axios.get(API_BASE_URL + '/depots/managers?search=' + search, {headers: this.header})
          .then((data) => {
            this.depotManagerNamePredictions = data.data.data;
          })
          .catch((error) => {

          })
          .finally(() => {

          })
    },
    searchClients(search) {
      this.searchingClients = true;
      axios.get(API_BASE_URL + '/clients/search?without_relations=1&search=' + encodeURIComponent(search), {headers: this.header})
          .finally(() => {
            this.searchingClients = false;
          })
          .then((data) => {
            this.clients = data.data.data;
          })
    },
    getCsvMappingTemplates() {
      axios.get(API_BASE_URL + '/data_mappings', {headers: this.header})
          .then((data) => {
            this.templates = data.data.data;
          })
          .catch((error) => {
            console.log(error)
          })
    },
    getTemplate(){
      axios.get(API_BASE_URL + '/data_mappings/' + this.template, {headers: this.header})
          .then((data) => {
            this.templateWithDetails = data.data.data;
          })
          .catch((error) => {
            console.log(error)
          })
    }
  }
}

</script>