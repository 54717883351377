<template>
    <div v-if="readyToView">
        <h2 class="card-title mb-0 pb-4">{{ card.title }}</h2>
        <v-data-table
            fixed-header
            :footer-props="{
                'items-per-page-options': dataTableOptions,
            }"
            :headers="computedHeaders"
            :items="cardDataProcessed"
            :items-per-page="10000"
            class="elevation-0"
            style="width: 100%"
            disable
            :height="'calc(30vh)'"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td v-for="(data, index) in item" :key="index">
                        {{ data }}
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";

export default {
    props:{
        carddata: {
            type: String,
            default: "",
        },
        cardfields: {
            type: Array,
            default: Array,
        },
        card: [Object]
    },
    data() {
        return {
            readyToView: false,
            cardDataProcessed: [],
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
        }
    },
    mounted(){

            // Process Bar Data
            if(this.carddata){
                let dataRows = this.carddata.replace(/"/g, '').split("\n");
                let finalData  = [];
                let count = -1;
                let rowNumber = 1;
                dataRows.forEach((record) => {
                    count++;
                    if(count == 0){
                        // return because first row will contain headers
                        return
                    }
                    let dataInColumns = record.split(";");
                    let rowObject = {};
                    for (let index = 0; index < this.cardfields.length; index++) {
                        rowObject[index] = dataInColumns[index]
                    }
                    finalData.push(rowObject);
                    rowNumber++;
                })
                this.cardDataProcessed = finalData;
            }

            // Process All the Data related to Graph
            this.cardfields.forEach((element,index) => {
                if(element.key.includes("x_")){
                    // this.chartOptions.xaxis.categories.push(element.translation);
                    let dataToBeFilled = [];
                    this.cardDataProcessed.forEach((row) => {
                        if(!row[index]){
                            return;
                        }
                        dataToBeFilled.push(row[index]); // index is same as data to be filled
                    }) 
                    this.chartOptions.xaxis.categories = dataToBeFilled;
                }
                if(element.key.includes("y_")){
                    let dataToBeFilled = [];
                    this.cardDataProcessed.forEach((row) => {
                        if(!row[index]){
                            return;
                        }
                        dataToBeFilled.push(row[index]); // index is same as data to be filled
                    });
                }
            })
            

            // Show Graph
            this.$nextTick(() => {
                this.readyToView = true;
            })
    },
    computed:{
        computedHeaders(){
            let headers = [];
            if(this.cardfields && this.cardfields.length > 0){
                this.cardfields.forEach((field) => {
                    headers.push({
                        text: field.translation,
                        align: "start",
                        sortable: false,
                        value: field.key,
                    });
                })
            }
            return headers;
        }
    }
}
</script>