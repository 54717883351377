<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="0"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="formattedDate"
                :label="label || $t('billings.date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="disabled"
            ></v-text-field>
        </template>
        <v-date-picker
            :first-day-of-week="1"
            :weekday-format="getDay"
            v-model="date"
            @input="onDateChange"
            :disabled="disabled"
            :elevation="0"
        ></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    name: "DateInput",
    emits: ["input", "change"],
    props: {
        value: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            date: null,
            menu: false,
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.date = value;
            },
        },
    },
    computed: {
        formattedDate: {
            get() {
                if (!this.date) {
                    return null;
                }
                return this.formatDate(this.date);
            },
            set(value) {
                this.date = value;
                this.$emit("input", value);
            },
        },
    },
    methods: {
        onDateChange(date) {
            this.date = date;
            this.$emit("input", date); // Emitting v-model input
            this.$emit("change", date); // Emitting change event
            this.menu = false;
        },
    },
};
</script>
