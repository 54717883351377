<template>
    <v-dialog
        persistent
        @click:outside="falseButtonClicked()"
        @keydown.esc="falseButtonClicked()"
        v-model="internalShowDialog"
        min-width="300"
        max-width="800"
    >
        <v-card>
            <v-card-title class="text-title">
                {{ $t("billings.payment_methods.title") }}
            </v-card-title>

            <v-card-text
                class="dialog-text text-content"
                color="black"
                v-if="isContentLoaded"
            >
                <v-row>
                    <!-- Payment Method -->
                    <v-col cols="12" sm="4">
                        <v-autocomplete
                            :autocomplete="autocompleteValueLocal"
                            v-model="payed_by_id"
                            :items="paymentMethodsBasedOnResource"
                            item-value="id"
                            item-text="text"
                            return-object
                            menu-props="auto"
                            :label="$t('billings.payed_by')"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-autocomplete>
                    </v-col>
                    <!-- Payment Amount -->
                    <v-col
                        cols="12"
                        sm="4"
                        :style="{
                            display: 'flex',
                            flexDirection: 'row',
                            maxWidth: '220px',
                        }"
                    >
                        <custom-money-input
                            :label="$t('bulk_updater.amount')"
                            v-model="amount"
                            :autocomplete="autocompleteValueLocal"
                            class="pt-4 to-upper right-aligned-input shrink"
                            hide-details="auto"
                            outlined
                            dense
                            tabindex="3"
                            style="width:250px"
                            ref="tableUnitPrice"
                            v-bind:properties="{
                                prefix: currencySymbol,
                                readonly: false,
                                disabled: false,
                                outlined: false,
                                placeholder:
                                    getDecimalNumber() == 2 ? '0.00' : '0.000',
                                dense: true,
                                hideDetails: 'auto',
                            }"
                            v-bind:options="{
                                locale: userLanguageId,
                                length: 12,
                                precision: getDecimalNumber(),
                                empty: getDecimalNumber() == 2 ? 0.0 : 0.0,
                            }"
                        />
                    </v-col>
                    <!-- Payed At -->
                    <v-col
                        cols="12"
                        sm="4"
                        v-if="refreshContentOnChange"
                        :style="{
                            display: 'flex',
                            flexDirection: 'row',
                            maxWidth: '220px',
                        }"
                    >
                        <v-text-field
                            :autocomplete="autocompleteValueLocal"
                            class="to-upper right-aligned-input shrink"
                            :label="$t('billings.payed_at')"
                            v-model="payed_at"
                            type="datetime-local"
                        ></v-text-field>
                    </v-col>
                    <!-- Action Buttons -->
                    <v-col cols="12" sm="1">
                        <v-btn
                            color="success"
                            class="mt-3"
                            @click="addItem()"
                            :disabled="checkPaymentAllowed"
                        >
                            <v-icon color="white">mdi-check</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>

                <!-- Payment Listing/History -->
                <v-row>
                    <v-data-table
                        fixed-header
                        :footer-props="{
                            'items-per-page-options': dataTableOptions,
                        }"
                        :hide-default-footer="true"
                        :headers="headers"
                        :items="articlePayments"
                        :options="options"
                        :server-items-length="100"
                        :loading="isLoading"
                        :loading-text="this.$t('loading')"
                        class="elevation-0"
                        :items-per-page="100"
                        item-key="identifier"
                        height="400px"
                        style="width: 100%"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        mustSort
                    >
                        <template v-slot:item="{ item, index }">
                            <tr>
                                <td>
                                    <template
                                        v-if="
                                            !editModeItem ||
                                                (editModeItem &&
                                                    editModeItem.id != item.id)
                                        "
                                    >
                                        <span
                                            v-if="
                                                item.payed_by_id &&
                                                    typeof item.payed_by_id ==
                                                        'object'
                                            "
                                        >
                                            {{
                                                $t(
                                                    "billings.payment_methods." +
                                                        item.payed_by_id.key
                                                )
                                            }}
                                        </span>
                                        <span
                                            v-else-if="
                                                item.payed_by &&
                                                    typeof item.payed_by ==
                                                        'object'
                                            "
                                        >
                                            {{
                                                $t(
                                                    "billings.payment_methods." +
                                                        item.payed_by.key
                                                )
                                            }}
                                        </span>
                                        <span
                                            v-else-if="
                                                item.payed_by &&
                                                    typeof item.payed_by ==
                                                        'number'
                                            "
                                        >
                                            {{ item.payed_by_id }}
                                        </span>
                                    </template>
                                    <template
                                        v-if="
                                            editModeItem &&
                                                editModeItem.id == item.id
                                        "
                                    >
                                        <v-autocomplete
                                            :autocomplete="
                                                autocompleteValueLocal
                                            "
                                            v-model="item.payed_by_id"
                                            :items="payment_methods"
                                            item-value="id"
                                            item-text="text"
                                            return-object
                                            menu-props="auto"
                                            :label="$t('billings.payed_by')"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-autocomplete>
                                    </template>
                                </td>
                                <td>
                                    <template
                                        v-if="
                                            !editModeItem ||
                                                (editModeItem &&
                                                    editModeItem.id != item.id)
                                        "
                                    >
                                        <template v-if="item.amount">
                                            {{
                                                parseFloat(item.amount).toFixed(
                                                    getDecimalNumber()
                                                )
                                            }}
                                        </template>
                                        <template v-else>
                                            {{
                                                (0).toFixed(getDecimalNumber())
                                            }}
                                        </template>
                                    </template>
                                    <template
                                        v-if="
                                            editModeItem &&
                                                editModeItem.id == item.id
                                        "
                                    >
                                        <v-col
                                            cols="12"
                                            sm="4"
                                            :style="{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                maxWidth: '150px',
                                            }"
                                        >
                                            <custom-money-input
                                                :label="
                                                    $t('bulk_updater.amount')
                                                "
                                                v-model="item.amount"
                                                :autocomplete="
                                                    autocompleteValueLocal
                                                "
                                                class="to-upper right-aligned-input shrink"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                tabindex="3"
                                                style="width:250px"
                                                ref="tableUnitPrice"
                                                v-bind:properties="{
                                                    prefix: currencySymbol,
                                                    readonly: false,
                                                    disabled: false,
                                                    outlined: false,
                                                    placeholder:
                                                        getDecimalNumber() == 2
                                                            ? '0.00'
                                                            : '0.000',
                                                    dense: true,
                                                    hideDetails: 'auto',
                                                }"
                                                v-bind:options="{
                                                    locale: userLanguageId,
                                                    length: 12,
                                                    precision: getDecimalNumber(),
                                                    empty:
                                                        getDecimalNumber() == 2
                                                            ? 0.0
                                                            : 0.0,
                                                }"
                                            />
                                        </v-col>
                                    </template>
                                </td>
                                <td>
                                    <template
                                        v-if="
                                            !editModeItem ||
                                                (editModeItem &&
                                                    editModeItem.id != item.id)
                                        "
                                    >
                                        {{ item.executed_at }}
                                    </template>
                                    <template
                                        v-if="
                                            editModeItem &&
                                                editModeItem.id == item.id
                                        "
                                    >
                                        <v-text-field
                                            :autocomplete="
                                                autocompleteValueLocal
                                            "
                                            class="to-upper right-aligned-input shrink"
                                            :label="$t('billings.payed_at')"
                                            v-model="item.executed_at"
                                            @change="
                                                fixFormat(
                                                    item,
                                                    item.executed_at,
                                                    index
                                                )
                                            "
                                            type="datetime-local"
                                        ></v-text-field>
                                    </template>
                                </td>
                                <!-- Receipts -->
                                <td>
                                    <v-icon
                                        @click="openReceipt(item)"
                                        v-if="item && item.transaction"
                                        class="mr-1"
                                    >
                                        mdi mdi-file-pdf-box
                                    </v-icon>
                                </td>
                                <td>
                                    <template
                                        v-if="
                                            editModeItem &&
                                                editModeItem.id == item.id
                                        "
                                    >
                                        <v-icon
                                            v-if="item"
                                            color="blue"
                                            class="mr-1"
                                            @click="cancelEdit()"
                                        >
                                            mdi-close
                                        </v-icon>
                                        <v-icon
                                            v-if="item"
                                            color="green"
                                            class="mr-1"
                                            @click="saveItem(item, index)"
                                        >
                                            mdi-content-save
                                        </v-icon>
                                    </template>
                                    <v-icon
                                        v-if="item"
                                        color="warning"
                                        class="mr-1"
                                        @click="
                                            deletePaymentMethodAfterConfirmation(
                                                index
                                            )
                                        "
                                    >
                                        mdi-delete
                                    </v-icon>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-row>

                <!-- Payment Balance Left -->
                <v-row>
                    <v-col
                        cols="12"
                        sm="5"
                        class="mr-2 mb-3"
                        :style="{
                            display: 'flex',
                            flexDirection: 'row',
                            maxWidth: '200px',
                            color: 'black',
                        }"
                    >
                        <strong>{{ $t("billings.balance") }}</strong>
                    </v-col>
                    <v-col
                        class="mr-2 mb-3 d-flex"
                        :style="{
                            display: 'flex',
                            flexDirection: 'row',
                            maxWidth: '120px',
                            color: 'black',
                        }"
                    >
                        <v-spacer />
                        <strong align="right">
                            <span class="text-no-wrap">
                                {{ currencySymbol }}
                                {{ paymentBalance }}
                            </span>
                        </strong>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pr-2">
                <v-spacer></v-spacer>
                <v-btn color="grey lighten-2" @click="falseButtonClicked()">
                    {{ $t("attachment.close") }}
                    <v-icon dark right> mdi-close </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
        <confirmation-model
            v-if="confirmationDialog"
            :showDialog.sync="confirmationDialog"
            :openedForOperation.sync="confirmationDialogOperation"
            :text.sync="confirmationDialogConfirmationText"
            :trueText.sync="confirmationDialogTrueText"
            :falseText.sync="confirmationDialogFalseText"
            @buttonClicked="confirmationButtonClicked"
        ></confirmation-model>
    </v-dialog>
</template>

<script>
import _ from "lodash";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { validationMixin } from "vuelidate";
import splitPaymentMixin from "@/mixins/splitPaymentMixin";
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";
import DateTimePicker from "@/components/DateTimePicker";
import { mapState } from "vuex";
import moment from "moment";
import { DateTime } from "luxon";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
    name: "PaymentDialog",
    components: {
        "custom-money-input": CutsomMoneyInput,
        // "t-date-time-field": DateTimePicker,
        ConfirmationModel,
    },
    mixins: [splitPaymentMixin],
    props: {
        showDialog: { type: Boolean, default: true },
        payment_methods: {
            type: Array,
        },
        currencySymbol: {
            type: String,
        },
        articleTotal: [Number, String],
        articlePayments: [Array],
    },
    computed: {
        paymentMethodsBasedOnResource() {
            return this.filterPaymentMethods(this.payment_methods);
        },
        ...mapState(["selectedBillData"]),
        headers() {
            return [
                {
                    text: this.$t("billings.payed_by"),
                    align: "start",
                    sortable: false,
                    value: "group",
                },
                {
                    text: this.$t("billings.amount"),
                    align: "start",
                    sortable: false,
                    value: "group",
                },
                {
                    text: this.$t("billings.payed_at"),
                    align: "start",
                    sortable: false,
                    value: "title",
                },
                {
                    text: this.$t("billings.receipt"),
                    align: "start",
                    sortable: false,
                    value: "title",
                },
                {
                    text: this.$t("attachment.actions"),
                    align: "start",
                    sortable: false,
                    value: "actions",
                },
            ];
        },
        isLoading() {
            return this.$store.state.progressBarLoading;
        },
        checkPaymentAllowed() {
            if (this.amount > this.paymentBalance) {
                return true;
            }
            if (
                this.paymentBalance == this.amount &&
                this.paymentBalance != 0
            ) {
                return false;
            }
            return this.paymentBalance <= 0;
        },
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            internalShowDialog: this.showDialog,
            autocompleteValueLocal: "off",
            refreshContentOnChange: true,
            isContentLoaded: false,
            editModeItem: null,
            payed_by_id: null,
            amount: 0,
            payed_at: this.toLocalISOString(new Date()),

            sortBy: "id",
            sortDesc: false,
            options: {},
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            totalItems: 0,
            page: 1,
            lastPage: 1,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],

            confirmationDialog: false,
            confirmationDialogConfirmationText: null,
            confirmationDialogFalseText: null,
            confirmationDialogTrueText: null,
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,
            confirmationDialogData: null,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.isContentLoaded = true;
            this.amount = this.paymentBalance;
        });
    },
    methods: {
        openReceipt(item) {
            let url = item.transaction.receipt_url;
            let fileName = item.transaction.reference;
            this.setPreviewData(url, fileName, false, null, true, true);
        },
        fixFormat(item, date, index) {
            this.articlePayments[index].executed_at = moment(date)
                .tz(this.currentTimeZone)
                .format("YYYY-MM-DD HH:mm:ss");
            this.articlePayments.push({});
            this.$nextTick(() => {
                this.articlePayments.pop();
            });
        },
        getPaymentName(paymentCode) {
            let paymentMethod = null;
            this.payment_methods.forEach((element) => {
                if (element.id == paymentCode) {
                    paymentMethod = element;
                }
            });
            return paymentMethod;
        },
        editItem(item) {
            this.editModeItem = item;
        },
        cancelEdit() {
            this.editModeItem = null;
        },
        addItem() {
            if (
                this.amount &&
                this.amount != 0 &&
                this.payed_at &&
                this.payed_by_id
            ) {
                let datetimenow = DateTime.now();
                if (!this.articlePayments) {
                    this.articlePayments = [];
                }
                let dataToBeSaved = {
                    id: null,
                    order_id: this.order_id ?? null,
                    amount: this.amount ?? null,
                    currency_id: this.currency_id ?? null,
                    payed_by_id: this.payed_by_id ?? null,
                    executed_at: this.payed_at ?? datetimenow,
                    updated_at: this.payed_at ?? datetimenow,
                };
                this.saveBillPayment(
                    dataToBeSaved,
                    dataToBeSaved.payed_by_id,
                    dataToBeSaved.amount,
                    dataToBeSaved.executed_at
                )
                    .then((data) => {
                        this.articlePayments.push(data.data);
                        this.cancelEdit();
                    })
                    .finally(() => {
                        this.clearItem();
                    });
            }
        },
        clearItem() {
            this.payed_by_id = null;
            setTimeout(() => {
                this.$nextTick(() => {
                    this.amount = this.paymentBalance;
                });
            }, 50);
            this.payed_at = this.toLocalISOString(new Date());
        },
        deletePaymentMethodAfterConfirmation(index) {
            this.confirmationDialogData = index;
            this.confirmationDialogConfirmationText = this.$t(
                "attachment.delete_attachment_confirmation"
            );
            this.confirmationDialogFalseText = this.$t("attachment.cancel");
            this.confirmationDialogTrueText = this.$t("attachment.delete");
            this.confirmationDialogOperation = "delete_payment";
            this.confirmationDialog = true;
        },
        confirmationButtonClicked(buttonClicked) {
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog = false;
        },
        removeItem(index) {
            let item = this.articlePayments[index];
            if (this.articlePayments.length > 0) {
                this.articlePayments.splice(index, 1);
            }
            if (item && item.id) {
                axios
                    .delete(API_BASE_URL + "/order_payments/" + item.id, {
                        headers: {
                            Authorization:
                                "Bearer " + this.$store.state.AccessToken,
                        },
                    })
                    .then(({ data }) => {})
                    .finally(() => {
                        this.clearItem();
                    });
            }

            this.$nextTick(() => {
                this.clearItem();
                this.cancelEdit();
            });
        },
        saveItem(item, index) {
            this.saveBillPayment(
                item,
                item.payed_by_id,
                item.amount,
                item.executed_at
            ).then((data) => {
                this.articlePayments[index] = data.data;
                this.cancelEdit();
            });
        },
        falseButtonClicked(item) {
            this.$emit("update:showDialog", false);
            this.$emit("buttonClicked", false);
            this.internalShowDialog = false;
        },
    },
    watch: {
        showDialog: {
            handler: function(val) {
                this.internalShowDialog = val;
            },
        },
        confirmationDialogButtonClickedIs(newVal) {
            if (newVal == true && this.confirmationDialogOperation != null) {
                // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
                if (this.confirmationDialogOperation == "delete_payment") {
                    if (this.confirmationDialogButtonClickedIs == true) {
                        this.removeItem(this.confirmationDialogData);
                        this.cancelEdit();
                    }
                }
                this.confirmationDialogButtonClickedIs = null;
            }
        },
        articlePayments: {
            handler: function(val) {
                val.forEach((element) => {
                    if (element && element.payed_by_id) {
                        element.payed_by = this.getPaymentName(
                            element.payed_by_id
                        );
                    }
                });
            },
            immediate: true,
        },
    },
    beforeDestroy() {
        this.$emit("update:showDialog", false);
    },
};
</script>

<style scoped>
.right-aligned-input >>> input {
    text-align: right !important;
}
</style>
