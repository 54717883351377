<template>
  <v-card flat>
    <v-form
      ref="form"
      class="main_form"
      id="client-form"
      @submit.prevent="submit"
    >
      <v-btn type="submit" class="d-none" id="submit-client"></v-btn>
      <!-- ProfileContainer starts -->
      <v-row class="mt-12">
        <v-row class="ma-6">
          <!-- responsive grid starts -->
          <v-row>
            <v-col cols="12" lg="2">
              <v-row>
                <v-col lg="12" cols="auto">
                  <label class="logoImage ma-4">
                    <img
                      :src="logoImage"
                      style="width: 150px; height: 150px; border-radius: 50%; border: 3px dotted #efefef;"
                    />
                    <input
                      type="file"
                      class="d-none"
                      id="files"
                      ref="files"
                      multiple
                      v-on:change="handleFilesUpload()"
                    />
                  </label>
                </v-col>
                <v-col>
                  <v-card flat>
                    <v-card-subtitle v-if="companyDetails">
                      <strong> {{ companyDetails.name }} </strong> <br />
                      <strong> {{ $t("company_settings.vat") }} : </strong>
                      {{ vat }}
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="10">
              <v-row>
                <!-- Company Name -->
                  <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    v-model="name"
                    class="to-upper mr-2"
                    v-on:keydown="handleInput($event, 'name', 60)"
                    :label="$t('company_settings.name')"
                    :error-messages="
                      $store.state.validation && !$v.name.required
                        ? [this.$t('company_settings.text_messages.required_name')]
                        : []
                    "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                  <!-- Denomination -->
                  <v-select
                      class="to-upper mr-2"
                      v-model="denomination"
                      :items="denominations"
                      item-value="id"
                      item-text="text"
                      :label="$t('billings.denomination')"
                      v-on:keydown.enter.prevent=""
                      @change="$emit('update:comp_denomination', denomination)"
                      :error-messages="
                        $store.state.validation && !$v.denomination.required
                          ? [this.$t('billings.text_messages.denomination_required')]
                          : []
                      "
                      :outlined="is_boxes"
                      :dense="is_boxes"
                  ></v-select>
                  <!-- Registration Number -->
                  <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    v-model="registrationNumber"
                    class="to-upper mr-2"
                    v-on:keydown="handleInput($event, 'registrationNumber', 60)"
                    :label="$t('company_settings.registrationNumber')"
                    :error-messages="
                      $store.state.validation && !$v.registrationNumber.required
                        ? [
                            this.$t(
                              'company_settings.text_messages.required_registrationNumber'
                            ),
                          ]
                        : []
                    "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                  <!-- vat -->
                  <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    v-model="vat"
                    class="to-upper mr-2"
                    v-on:keydown="handleInput($event, 'name', 60)"
                    :label="$t('company_settings.vat')"
                    :error-messages="
                      $store.state.validation && !$v.vat.required
                        ? [this.$t('company_settings.text_messages.required_vat')]
                        : []
                    "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
              </v-row>
              <v-row>
                  <!-- E-Mail -->
                  <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    v-model="email"
                    class="to-upper mr-2"
                    v-on:keydown="handleInput($event, 'email', 60)"
                    :label="$t('company_settings.email')"
                    :error-messages="
                      // $store.state.validation && !$v.email.email
                      //   ? [this.$t('company_settings.text_messages.required_email')]
                      //   : []
                      []
                    "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                  <!-- Phone Number -->
                  <vue-tel-input-vuetify :disabledFetchingCountry="true" :preferredCountries="preferredCountry" :filterCountries="onlyCountries"  validCharactersOnly :label="$t('company_settings.phone')" :inputOptions="{ showDialCode: true, tabindex: 0 }" :mode="'international'" v-model="phone"></vue-tel-input-vuetify>
                  <!-- FAX -->
                  <vue-tel-input-vuetify :disabledFetchingCountry="true" :preferredCountries="preferredCountry" :filterCountries="onlyCountries"  validCharactersOnly :label="$t('company_settings.fax')" :inputOptions="{ showDialCode: true, tabindex: 0 }" :mode="'international'" v-model="fax"></vue-tel-input-vuetify>
                  <!-- GSM -->
                  <vue-tel-input-vuetify :disabledFetchingCountry="true" :preferredCountries="preferredCountry" :filterCountries="onlyCountries" validCharactersOnly :label="$t('company_settings.gsm')" :inputOptions="{ showDialCode: true, tabindex: 0 }" :mode="'international'" v-model="gsm"></vue-tel-input-vuetify>
                  <!-- WEB -->
                  <v-text-field
                    :autocomplete="autocompleteValueLocal"
                    v-model="web"
                    class="to-upper mr-2"
                    v-on:keydown="handleInput($event, 'web', 60)"
                    :label="$t('company_settings.web')"
                    :error-messages="
                      []
                    "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
              </v-row>
              <!-- <v-row -->

              <!---- Peppol Row -->
              <v-row v-if="is_peppol">
                  <v-text-field
                    :label="$t('company_settings.end_point_id')"
                    :placeholder="$t('company_settings.end_point_id')"
                    v-model="peppol_endpoint_id"
                    class="to-upper mr-2"
                    required
                    v-on:keydown.enter.prevent=""
                    v-on:keydown="$v.$touch()"
                    :error-messages="
                      $store.state.validation && !$v.peppol_endpoint_id.required
                        ? [
                            this.$t(
                              'company_settings.text_messages.peppol_endpoint_id_required'
                            ),
                          ]
                        : []
                    "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
                  <v-text-field
                    :label="$t('company_settings.scheme_id')"
                    :placeholder="$t('company_settings.scheme_id')"
                    v-model="peppol_scheme_id"
                    class="to-upper mr-2"
                    required
                    v-on:keydown.enter.prevent=""
                    v-on:keydown="$v.$touch()"
                    :error-messages="
                      $store.state.validation && !$v.peppol_scheme_id.required
                        ? [
                            this.$t(
                              'company_settings.text_messages.peppol_scheme_id_required'
                            ),
                          ]
                        : []
                    "
                    :outlined="is_boxes"
                    :dense="is_boxes"
                  ></v-text-field>
              </v-row>
              <!-- Working Points Row -->
              
              <tabs 
                class="mt-8"
                :prop_address.sync="address"
                :prop_shipping_address.sync="shipping_address"
                :prop_workpointdata.sync="working_points"
                :prop_company_properties.sync="company_properties"
              ></tabs>
            </v-col>
          </v-row>
          <!-- responsive grid ends -->
        </v-row>
      </v-row>
    </v-form>

    <v-btn
      @click="onSubmit()"
      class="d-none"
      id="submit-company-setting"
    ></v-btn>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { UPDATE_MODE } from "@/config";
import { validationMixin } from "vuelidate";
import Tabs from "@/components/CompanySettings/Tabs";
import VueTelInputVuetify from "@/components/PhoneNumberInput";
import {
  required,
  email,
  requiredIf,
  phoneNum,
  numeric,
  minLength,
  maxLength,
  alpha,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    name:{
      required
    },
    registrationNumber: {
      required
    },
    vat:{
      required
    },
    // fax:{
    //   required
    // },
    // email:{ 
    //   required, 
    //   email: (val) => email((val ?? "").toLowerCase()),
    // },
    bank_account: {
      required: required,
    },
    bank_name: {
      required: required,
    },
    swift_code: {
      required: required,
    },
    currency: {
      required,
    },
    denomination: {
      required,
    },
    // gsm:{
    //   required,
    // },
    // web: {
    //   required,
    // },
    peppol_endpoint_id: {
        required: requiredIf(function(model) {
            return this.is_peppol;
        }),
    },
    peppol_scheme_id: {
        required: requiredIf(function(model) {
            return this.is_peppol;
        }),
    },
  },
  name: "CompanySetting",
  components: {
      "tabs": Tabs,
      "vue-tel-input-vuetify":VueTelInputVuetify
  },
  data() {
    return {
      is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
      DeleteConfirm: false,
      token: this.$store.state.AccessToken,
      header: { Authorization: "Bearer " + this.$store.state.AccessToken },
      logoImage: "",
      gradient: "to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)",
      companyDetails: null,
      bank_name: null,
      bank_account: null,
      swift_code: null,
      denominations: [],
      denomination: null,
      currencies: [],
      currency: null,
      email: null,
      fax: null,
      gsm: null,
      name: null,
      phone: null,
      registrationNumber: null,
      web: null,
      autocompleteValueLocal: "off",
      is_peppol: false,
      peppol_scheme_id: null,
      peppol_endpoint_id: null,
      vat: null,


      isDebounceCancelled: false,

      address: null,
      shipping_address: null,
      working_points: [],
      company_properties: [],
      
      is_default: false,
    };
  },
  mounted() {
    this.$store.commit("setValidation", false); // enable validation
    
    this.header = { Authorization: "Bearer " + this.token };
    this.getCompanySettings();
    this.getCompanyDenominations();
    this.getCurrencies();
    this.$store.commit("setFormMode", UPDATE_MODE);
  },
  methods: {
    getCurrencies() {
        this.getDataWithCache('/currencies', 'currencies', 'currencies', true);
    },
    getCompanySettings() {
      axios
        .get(API_BASE_URL + "/company-settings", { headers: this.header })
        .then(({ data }) => {
          data = data.data;
          this.logoImage = data.logo;
          this.companyDetails = data.detail;

          this.bank_name = data.detail.bank_name;
          this.bank_account = data.detail.bank_account;
          this.swift_code = data.detail.swift_code;

          this.denomination = data.detail.denomination;
          this.currency = data.detail.currency_id;
          this.email = data.detail.email;
          this.gsm = data.detail.gsm;
          this.fax = data.detail.fax;
          this.name = data.detail.name;
          this.phone = data.detail.phone;
          this.registrationNumber = data.detail.registration_number;
          this.web = data.detail.web;
          this.is_peppol = data.detail.is_peppol;
          this.peppol_endpoint_id = data.detail.peppol_endpoint_id;
          this.peppol_scheme_id = data.detail.peppol_scheme_id;
          this.vat = data.detail.tva

          if(data.address){
            this.address = data.address.address;
          }
          if(data.shippingAddress){
            this.shipping_address = data.shippingAddress.address;
          }
          if(data.workPoints){
            this.working_points = data.workPoints;
          }
          if(data.companyProperties){
            this.company_properties = data.companyProperties;
          }
        })
        .catch(function(error) {
          console.log("an error occured " + error);
        })
        .finally(() => {
          
        });
    },
    onSubmit() {
      this.$store.commit("setValidation", true); // enable validation
      if (!this.$v.$invalid) {
        
        let formData = {
          bank_name: this.bank_name,
          bank_account: this.bank_account,
          swift_code: this.swift_code,
          denomination:
            typeof this.denomination == "object"
              ? this.denomination.id
              : this.denomination,
          currency:
            typeof this.currency == "object" ? this.currency.id : this.currency,
          email: this.email,
          gsm: this.gsm,
          fax: this.fax,
          name: this.name,
          phone: this.phone,
          registration_number: this.registrationNumber,
          web: this.web,
          peppol_scheme_id: this.peppol_scheme_id,
          peppol_endpoint_id: this.peppol_endpoint_id,
          vat: this.vat,
          billing_address: this.address,
          shipping_address: this.shipping_address,
          working_points: this.working_points,
          company_properties: this.company_properties.map((element) => {
            return {
                company_setting_key: element.company_setting_key,
                value: element.value
            }
          })
        };
        axios
          .post(API_BASE_URL + "/company-settings", formData, {
            headers: this.header,
          })
          .then((response) => {
            this.$toast.success(
              this.$t("company_settings.data_saved_successfully")
            );
            localStorage.setItem(
              "currencyId",
              typeof this.currency == "object"
                ? this.currency.id
                : this.currency
            );
          })
          .catch(() => {
            this.$toast.error(this.$t("company_settings.save_failed"));
          })
          .finally(() => {
            
          });
      } else {
        this.$toast.warning(
          this.$t("company_settings.text_messages.fill_form_correctly")
        );
      }
    },
    handleFilesUpload() {
      if (!this.$refs.files.files[0]) {
        return;
      }
      
      let formData = new FormData();
      formData.append("logofile", this.$refs.files.files[0]);
      // this.header{ 'Content-Type': 'multipart/form-data'}
      axios
        .post(API_BASE_URL + "/company-settings", formData, {
          "Content-Type": "multipart/form-data",
          headers: this.header,
        })
        .then((response) => {
          this.$toast.success(this.$t("company_settings.image_uploaded"));
          this.logoImage = response.data.data.logo;
        })
        .catch(() => {
          this.$toast.error(this.$t("company_settings.image_format_error"));
        })
        .finally(() => {
          
        });
    },
    async getCompanyDenominations() {
      await this.getCodes('company_denomination', 'company_denomination')
                .then((data) => {
                  this.denominations = data;
                });
    },
  },
  computed: {},
  watch: {
    shipping_address(val){
      console.log("updating shipping address in watch", val);
    }
  },
};
</script>

<style scoped>
.logoImage:hover {
  opacity: 0.8 !important;
  cursor: copy;
}
</style>
