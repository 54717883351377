<template>
    <div class="d-flex" :class="checkIfAnyIconPresent() ? '' : 'ml-auto'">
        <v-tooltip bottom z-index="9999" v-if="shouldShowStockTooltip(item)">
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    class="mr-1 mt-n1"
                >
                    mdi-information-outline
                </v-icon>
            </template>
            <span>{{
                $t("billings.text_messages.article_does_not_have_stock")
            }}</span>
        </v-tooltip>
        <template v-if="item.supplier && item.supplier.length > 0">
            <div
                class="cursor-pointer d-flex"
                @click="emitReceptionDetail(item)"
                v-if="showSupplierInfo(item)"
                :class="getArticleClass(item)"
                style="max-width: 100px; border:0px;"
                dense
                return-object
                readonly
                flat
                solo
                hide-details="auto"
            >
                <v-icon
                    small
                    v-if="isPendingSupplierOrder(item)"
                    slot="prepend"
                    :style="{ color: getSupplierOrderIconColor(item) }"
                >
                    mdi-timer-sand
                </v-icon>
                <v-icon
                    small
                    v-if="isSupplierOrderCompleted(item)"
                    slot="prepend"
                    color="green"
                    class="mr-1"
                >
                    mdi-check
                </v-icon>
                <v-icon
                    small
                    v-if="isSupplierOrderAlert(item)"
                    slot="prepend"
                    color="red"
                    class="mr-1 mt-n1"
                >
                    mdi-alert-circle-outline
                </v-icon>

                <span
                    :style="{
                        color: shouldShowStockTooltip(item)
                            ? 'red'
                            : isPendingSupplierOrder(item)
                            ? getSupplierOrderIconColor(item)
                            : isSupplierOrderCompleted(item)
                            ? 'green'
                            : isSupplierOrderAlert(item)
                            ? 'red'
                            : '',
                    }"
                >
                    <template v-if="item.parent_supplier_id">
                        {{ getSupplierById(item.parent_supplier_id) }}
                    </template>
                    <template v-else-if="item.supplier.length > 0">
                        {{ item.supplier[0].identifier }}
                    </template>
                </span>
            </div>

            <div
                v-else-if="isStockManaged(item)"
                style="max-width: 100px; border:0px; float:right"
                class="d-flex"
                :style="{ color: !shouldShowStockTooltip(item) ? 'green' : '' }"
            >
                <v-icon
                    small
                    v-if="!shouldShowStockTooltip(item)"
                    slot="prepend"
                    color="green"
                    class="mr-1"
                >
                    mdi-check
                </v-icon>
                INT
            </div>

        </template>

        <template v-else>
            <template v-if="item && item.supplier_id">
                {{ getSupplierById(item.supplier_id) }}
            </template>
            <div
                v-else-if="isStockManaged(item)"
                style="max-width: 100px; border:0px; float:right"
                class="d-flex"
                :style="{ color: !shouldShowStockTooltip(item) ? 'green' : '' }"
            >
                <v-icon
                    small
                    v-if="!shouldShowStockTooltip(item)"
                    slot="prepend"
                    color="green"
                    class="mr-1"
                >
                    mdi-check
                </v-icon>
                INT
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: [Object],
            required: true,
        },
    },
    methods: {
        checkIfAnyIconPresent() {
            if (
                this.shouldShowStockTooltip(this.item) ||
                this.isPendingSupplierOrder(this.item) ||
                this.isSupplierOrderCompleted(this.item) ||
                this.isSupplierOrderAlert(this.item)
            ) {
                return true;
            }
            return false;
        },
        getSupplierById(supplier_id) {
            let supplierIdentifier = null;
            if (
                supplier_id &&
                this.allSuppliers &&
                this.allSuppliers.length > 0
            ) {
                this.allSuppliers.forEach((element) => {
                    if (element.id == supplier_id) {
                        supplierIdentifier = element.identifier;
                    }
                });
            }
            return supplierIdentifier;
        },
        emitReceptionDetail(item) {
            this.$emit("update-reception-detail", item);
        },
        showSupplierInfo(item) {
            return (
                item.supplier &&
                item.supplier.length > 0 &&
                item.supplier[0] &&
                item.supplier[0].id >= 0 &&
                item.article_original_name &&
                item.article_original_name.family &&
                item.article_original_name.family.is_stock_managed
            );
        },
        getArticleClass(item) {
            if (typeof item.article !== "object") {
                return item.article.internal_company_article &&
                    item.article.internal_company_article.length > 0 &&
                    item.article.internal_company_article[0].stock <
                        item.quantity
                    ? "articleNotInStock"
                    : this.isPendingSupplierOrder(item)
                    ? "articleWaitingForSupplierOrderOrange"
                    : "articleInStock";
            }
            return "";
        },
        isPendingSupplierOrder(item) {
            return (
                item.saved_supplier_order_detail &&
                item.saved_supplier_order_detail.supplier_order &&
                [10091000, 10091001, 10091004].includes(
                    item.saved_supplier_order_detail.supplier_order.status_id
                )
            );
        },
        isSupplierOrderCompleted(item) {
            return (
                item.saved_supplier_order_detail &&
                item.saved_supplier_order_detail.supplier_order &&
                [10091005].includes(
                    item.saved_supplier_order_detail.supplier_order.status_id
                )
            );
        },
        isSupplierOrderAlert(item) {
            return (
                item.saved_supplier_order_detail &&
                item.saved_supplier_order_detail.supplier_order &&
                [10091002].includes(
                    item.saved_supplier_order_detail.supplier_order.status_id
                )
            );
        },
        getSupplierOrderIconColor(item) {
            if (
                item.saved_supplier_order_detail &&
                item.saved_supplier_order_detail.delivery_date &&
                new Date(item.saved_supplier_order_detail.delivery_date) >=
                    new Date()
            ) {
                return "darkorange";
            }
            return "red";
        },
        isStockManaged(item) {
            return (
                item.article_original_name &&
                item.article_original_name.family &&
                item.article_original_name.family.is_stock_managed
            );
        },
        shouldShowStockTooltip(item) {
            return (
                item.article.internal_company_article &&
                item.article.internal_company_article.length > 0 &&
                item.article.internal_company_article[0].stock < item.quantity &&
                this.isStockManaged(item)
            );
        },
    },
    computed: {
        allSuppliers() {
            return this.$store.state.allSuppliers ?? [];
        },
    },
};
</script>
