<template>
    <div></div>
</template>
<script>
import axios from "axios";
import { API_BASE_URL } from "@/config";

export default {
    name: "VerifyEmail",
    mounted() {
        this.verifyEmail();
    },
    methods: {
        verifyEmail() {
            // get url parameters
            const urlParams = new URLSearchParams(window.location.search);

            this.$store.state.isLoading = true;
            axios
                .put(
                    `${API_BASE_URL}/email_verification/${
                        this.$route.params.id
                    }?email=${urlParams.get("email") || 1}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "AccessToken"
                            )}`,
                        },
                    }
                )
                .finally(() => {
                    this.$store.state.isLoading = false;
                })
                .then(({ data }) => {
                    alert(this.$t("email_verification_section.validated"));
                    window.location.href =
                        data.data?.redirect || "https://companyxp.com";
                })
                .catch((e) => {
                    alert(this.$t("email_verification_section.error"));

                    let redirectLink =
                        e.response.data.errors.redirect[0] ?? null;
                    if (redirectLink) {
                        window.location.href = redirectLink;
                    } else {
                        window.location.href = "https://companyxp.com";
                    }
                });
        },
    },
};
</script>
