<template>
  <div>
    <v-row v-if="selectedRole">
      <v-col :md="isset(selectedRole.company_id) ? 7 : 9" class="pb-0">
        <v-tooltip bottom v-if="isset(selectedRole.description)">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-select
                  v-model="selectedRole"
                  :items="formattedRoles"
                  class="to-upper"
                  item-text="text"
                  :label="translate('selected_role')"
                  return-object
              ></v-select>
             </span>
          </template>
          <span>
            {{ selectedRole.description }}
          </span>
        </v-tooltip>
        <v-select
            v-else
            v-model="selectedRole"
            :items="formattedRoles"
            class="to-upper"
            item-text="text"
            :label="translate('selected_role')"
            return-object
        ></v-select>

      </v-col>
      <v-col :md="isset(selectedRole.company_id) ? 5 : 3" class="pb-0 pt-5">
        <v-row>
          <v-col v-if="!isset(selectedRole.company_id)">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                    :disabled="selectedRole.name === 'admin'"
                    @click="roleCustomizationDialog(selectedRole.id)"
                    block
                    class="ma-2"
                    color="primary"
                    v-on="on"
                >
                  {{ translate('enable_customize') }}
                  <v-icon class="ml-1">mdi-book-edit</v-icon>

                </v-btn>
              </template>
              <span>
                {{ translate('customize_explanation') }}.
              </span>
            </v-tooltip>
          </v-col>
          <v-col v-if="isset(selectedRole.company_id)">
            <v-btn
                @click="openDuplicationDialog(selectedRole.id)"
                block
                class="ma-2"
                color="primary"
            >
              {{ $t("user_role.duplicate") }}
              <v-icon class="ml-1">mdi-content-duplicate</v-icon>

            </v-btn>
          </v-col>
          <v-col v-if="isset(selectedRole.company_id)">
            <v-btn
                block
                class="ma-2"
                color="primary"
                @click="() => {
                  editRoleDialog = true
                  roleFormAction = 'edit'
                  this.editRoleModel = {
                    name: this.selectedRole.name,
                    description: this.selectedRole.description
                  }
                }"
            >
              {{ translate('edit_role') }}
              <v-icon class="ml-1">mdi-pencil</v-icon>

            </v-btn>
          </v-col>
          <v-col v-if="isset(selectedRole.company_id)">
            <v-tooltip top>
              <template #activator="{on}">
                <v-btn

                    @click="deleteRoleConfirmationDialog()"
                    block
                    class="ma-2"
                    color="red"
                    style="color: #FFFFFF;"
                    v-on="on"
                >
                  {{ translate('delete_role') }}
                  <v-icon class="ml-1">mdi-delete-outline</v-icon>

                </v-btn>
              </template>
              <span>
                  {{ translate('delete_explanation') }}.
                </span>

            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="12">
        <h3>
          <span class="mr-2"> {{ translate('role_users') }}</span>
          <v-icon v-if="!showRoleUsers" @click="showRoleUsers = true">mdi-eye</v-icon>
          <v-icon v-if="showRoleUsers" @click="showRoleUsers= false">mdi-eye-off</v-icon>
        </h3>
        <v-data-table
            v-if="showRoleUsers"
            :headers="userHeaders"
            :items="formattedUsers"
            class="elevation-1"
            :hide-default-footer="true"
            disable-pagination
        />
      </v-col>
      <v-col md="12">
        <h3>{{ translate('role_permissions') }}</h3>
        <v-data-table
            :headers="moduleHeaders"
            :items="modules"
            class="elevation-1"
            :hide-default-footer="true"
            disable-pagination
            @click:row="(item) => toggleRoleResource(item)"
        >
          <template v-slot:item="props">
            <v-tooltip top :disabled="disableRowTooltip(props.item)">
              <template #activator="{on}">
                <tr :class="{
              'bg-grey': props.item.is_mandatory === 1,
            }"
                    style="cursor: pointer"
                    v-on="on"
                    @click="toggleRoleResource(props.item)"
                >
                  <td>
                    <v-checkbox
                        class="float-right mb-5"
                        v-model="props.item.visibility"
                        color="primary"
                        hide-details
                        :disabled="!isset(selectedRole.company_id) || props.item.is_mandatory === 1"
                        @click="toggleRoleResource(props.item)"
                    ></v-checkbox>
                  </td>
                  <td>{{ props.item.text }}</td>
                  <td>{{ props.item.description }}</td>
                </tr>
              </template>
              <span v-if="selectedRole.name === 'admin'"> {{ $t('user_rights_section.cannot_be_customized',{roleName: selectedRole.text}) }} </span>
              <span v-else-if="!isset(selectedRole.company_id)"> {{translate('enable_customization_info')}} </span>
              <span v-else>{{ translate('mandatory_cannot_customize') }}</span>
            </v-tooltip>
          </template>
          <!--          <template v-slot:item.visibility="{ item }">-->
          <!--            <v-tooltip top v-if="item.is_mandatory === 1">-->
          <!--              <template #activator="{on}">-->
          <!--                <span v-on="on">-->
          <!--                  <v-checkbox-->
          <!--                      class="float-right mb-5"-->
          <!--                      v-model="item.visibility"-->
          <!--                      color="primary"-->
          <!--                      hide-details-->
          <!--                      readonly-->
          <!--                  ></v-checkbox>-->
          <!--                </span>-->
          <!--              </template>-->
          <!--              <span>Mandatory Permissions cannot be Edited</span>-->
          <!--            </v-tooltip>-->
          <!--            <v-checkbox-->
          <!--                v-else-->
          <!--                class="float-right mb-5"-->
          <!--                v-model="item.visibility"-->
          <!--                color="primary"-->
          <!--                hide-details-->
          <!--                :disabled="!isset(selectedRole.company_id) || item.is_mandatory === 1"-->
          <!--                @click="toggleRoleResource(item)"-->
          <!--            ></v-checkbox>-->
          <!--          </template>-->
        </v-data-table>
      </v-col>
    </v-row>
    <confirmation-model
        v-if="confirmationDialog"
        :showDialog.sync="confirmationModelData.dialog"
        :openedForOperation.sync="confirmationModelData.operation"
        :text.sync="confirmationModelData.text"
        :trueText.sync="confirmationModelData.trueText"
        :falseText.sync="confirmationModelData.falseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
    <v-dialog max-width="500" v-model="duplicateRoleDialog" :persistent="duplicating">
      <v-card v-if="selectedRole">
        <v-card-title class="mb-2"> {{
            $t('user_rights_section.duplicate_role_title', {roleName: selectedRole.text ? selectedRole.text : selectedRole.name})
          }}
        </v-card-title>
        <v-card-text>
          <v-text-field
              :label="translate('duplicate_role_name')"
              v-model="duplicateRoleModel.name"
              :error-messages="roleNameError"
              dense
              @keydown="handleInput($event,'alphanumeric', 20)"
          ></v-text-field>
          <v-textarea
              :label="translate('duplicate_role_description')"
              :error-messages="roleDescriptionError"
              v-model="duplicateRoleModel.description"
              dense
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="ash"
              dense
              @click="() => {
                duplicateRoleDialog = false
                this.duplicateRoleModel.name = '';
                this.duplicateRoleModel.description = '';
              }"
              :disabled="duplicating"
          >
            <v-icon class="mr-2">mdi-keyboard-return</v-icon>
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
              color="success"
              @click="createDuplicateRoleConfirmationDialog"
              :loading="duplicating"
          >
            <v-icon>mdi-content-duplicate</v-icon>
            {{ translate('duplicate') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="editRoleDialog" :persistent="duplicating">
      <v-card v-if="selectedRole">
        <v-card-title v-if="roleFormAction === 'edit'"> {{
            $t('user_rights_section.edit_role_title', {roleName: selectedRole.text ? selectedRole.text : selectedRole.name})
          }}
        </v-card-title>
        <v-card-title v-if="roleFormAction === 'new'">
          {{ $t('user_rights_section.new_role_title') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
              :label="translate('role_name')"
              v-model="editRoleModel.name"
              :error-messages="roleNameError"
              dense
              @keydown="handleInput($event,'alphanumeric', 20)"
          ></v-text-field>
          <v-textarea
              :label="translate('role_description')"
              :error-messages="roleDescriptionError"
              v-model="editRoleModel.description"
              dense
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="grey lighten-2"
              dense
              @click="() => {
                editRoleDialog = false

              }"
              :disabled="duplicating"
          >
            {{ $t('cancel') }}
            <v-icon class="ml-1">mdi-keyboard-return</v-icon>

          </v-btn>
          <v-btn
              dense
              color="success"
              @click="editRole"
              :loading="duplicating"
          >
            <span v-if="roleFormAction === 'edit'">
              {{ translate('update') }}
            </span>
            <span v-if="roleFormAction === 'new'">
              {{ translate('save') }}
            </span>
            <v-icon class="ml-1">mdi-content-save</v-icon>

          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <span class="d-none" id="add-new-role" @click="addNewRole()"></span>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel.vue";

export default {
  name: "UserRightsListing",
  components: {
    ConfirmationModel
  },
  data() {
    return {
      description: this.$t("user_role.description"),
      axiosHeaders: {
        'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
      },
      duplicateRoleDialog: false,
      duplicateRoleModel: {
        name: "",
        description: ""
      },
      editRoleDialog: false,
      editRoleModel: {
        name: "",
        description: ""
      },
      roleFormAction: null,
      showRoleUsers: false,
      roleNameError: "",
      roleDescriptionError: "",
      duplicating: false,
      confirmationModelData: {
        dialog: false,
        operation: null,
        text: null,
        trueText: null,
        falseText: null,
      },
      roles: [],
      selectedRole: null,
      userHeaders: [
        {
          text: this.$t('srno'),
          align: 'start',
          value: 'serial',
          width: '50',
          sortable: false,
        },
        {
          text: this.translate('name'),
          value: 'name',
          align: 'start',
          sortable: false,
        }
      ],
      moduleHeaders: [
        {
          text: this.translate('visibility'),
          value: 'visibility',
          sortable: false,
          width: '50',
        },
        {
          text: this.translate('module_name'),
          value: 'text',
          align: 'start',
          sortable: false,
        },
        {
          text: this.translate('module_description'),
          value: 'description',
          align: 'start',
          sortable: false,
        }
      ],
    }
  },
  async mounted() {
    await this.getRoles()
  },
  computed: {
    formattedRoles() {
      if (!this.isset(this.roles)) {
        return [];
      }

      let user = JSON.parse(localStorage.getItem('data')).userDetails

      return this.roles.map(role => {
        role.text = this.isset(role.company_id) ? role.name : this.translate('role_titles.' + role.name)
        return role
      }).filter( r => r.id !== user.role_id)

    },
    formattedUsers() {
      if (!this.selectedRole) return [];
      return this.selectedRole.users.map((user, index) => {
        user.serial = index + 1
        return user
      })
    },
    applicationResources() {
      let dataFromLocalStorage = localStorage.getItem('data')
      if (dataFromLocalStorage) {
        return JSON.parse(dataFromLocalStorage).applicationResources
      }

      return []
    },
    modules() {
      return this.applicationResources.map((resource, index) => {
        let isActive = this.isset(
            this.selectedRole.resources.find(
                (item) => item.application_resource_id === resource.application_resource.id
            )
        )
        return {
          serial: index + 1,
          text: this.translate('application_resource_titles.' + resource.application_resource.key),
          visibility: isActive,
          description: this.translate('application_resource_descriptions.' + resource.application_resource.key),
          ...resource.application_resource
        }
      }).sort((a, b) => {
        // sort based on order, make values with 0 order to be at the end
        if (a.order === 0) {
          return 1
        }

        if (b.order === 0) {
          return -1
        }

        return a.order - b.order

      })
    },
  },
  methods: {
    translate(key) {
      return this.translationHelper('user_rights_section.' + key);
    },
    getRoles() {
      return new Promise((resolve, reject) => {
        
        return axios.get(API_BASE_URL + '/roles', {headers: this.axiosHeaders})
            .finally(() => {
              
            })
            .then((response) => {
              this.roles = Object.values(response.data.data)
              this.selectedRole = this.formattedRoles[0] ? this.formattedRoles[0] : null
              resolve(this.roles);
            }).catch((error) => {
              reject(error)
            })
      })
    },
    toggleRoleResource(applicationResource) {
      if (this.selectedRole.name === 'admin' ||
          applicationResource.is_mandatory === 1 ||
          !this.isset(this.selectedRole.company_id)
      ) {
        return null;
      }

      let applicationResourceId = applicationResource.id
      return new Promise((resolve, reject) => {
        let isActive = this.isset(
            this.selectedRole.resources.find(
                (item) => item.application_resource_id === applicationResourceId
            )
        )
        
        let url = API_BASE_URL + '/roles/' + this.selectedRole.id + '/resources/' + applicationResourceId
        let method = isActive
            ? axios.delete(url, {headers: this.axiosHeaders})
            : axios.put(url, {}, {headers: this.axiosHeaders})
        return method
            .finally(() => {
              
            })
            .then((response) => {
              this.selectedRole.resources = response.data.data.resources

              resolve(this.selectedRole);
            }).catch((error) => {

              reject(error)
            })
      })
    },
    duplicateRole(roleId, name, description, replicate = false) {
      this.roleNameError = "";
      this.roleDescriptionError = ""

      return new Promise((resolve, reject) => {
        let url = API_BASE_URL + '/roles/' + roleId + '/duplicate'
        
        return axios.put(url, {
          name,
          description,
          replicate
        }, {headers: this.axiosHeaders})
            .finally(() => {
              
            })
            .then((response) => {
              this.roles = Object.values(response.data.data.all)
              this.selectedRole = this.formattedRoles.find(r => r.id === response.data.data.new.id)
              this.duplicateRoleDialog = false;

              this.$toast.success(this.translate(replicate ? 'role_duplicated_successfully' : 'customization_enabled'))
              resolve(this.selectedRole);
            }).catch((error) => {
              reject(error)
            })
      })
    },
    confirmationButtonClicked(action) {
      if (!action) {
        if (this.confirmationModelData.operation === 'duplicateRoleFinal') {
          this.duplicateRoleModel.name = "";
          this.duplicateRoleModel.description = "";
        }

        return false;
      }

      if (this.confirmationModelData.operation === 'duplicateRole') {
        this.duplicateRoleDialog = true;
      }

      if (this.confirmationModelData.operation === 'duplicateRoleFinal') {
        this.duplicateRoleDialog = false;
        this.duplicateRole(this.selectedRole.id, this.duplicateRoleModel.name, this.duplicateRoleModel.description)
      }

      if (this.confirmationModelData.operation === 'customizeRole') {
        this.duplicateRole(this.selectedRole.id, this.translate('role_titles.' + this.selectedRole.name), this.selectedRole.description, true)
      }

      if (this.confirmationModelData.operation === 'deleteRole') {
        this.deleteRole(this.selectedRole.id)
      }
    },
    openDuplicationDialog(roleId) {
      this.confirmationModelData.dialog = true
      this.confirmationModelData.operation = 'duplicateRole'
      this.confirmationModelData.text = this.$t('user_rights_section.duplicate_role_confirmation', {roleName: this.selectedRole.text ?? this.selectedRole.name})
      this.confirmationModelData.trueText = this.$t('yes')
      this.confirmationModelData.falseText = this.$t('no')
      this.confirmationModelData.data = roleId
    },
    roleCustomizationDialog(roleId) {
      this.confirmationModelData.dialog = true
      this.confirmationModelData.operation = 'customizeRole'
      this.confirmationModelData.text = this.$t('user_rights_section.customize_role_confirmation', {roleName: this.selectedRole.text ?? this.selectedRole.name})
      this.confirmationModelData.trueText = this.$t('yes')
      this.confirmationModelData.falseText = this.$t('no')
      this.confirmationModelData.data = roleId
    },
    createDuplicateRoleConfirmationDialog() {
      this.roleNameError = "";
      this.roleDescriptionError = ""
      let name = this.duplicateRoleModel.name
      let description = this.duplicateRoleModel.description

      if (!this.isset(name) || !this.isset(description)) {
        if (!this.isset(name)) {
          this.roleNameError = this.translate('role_name_error')
        }

        if (!this.isset(description)) {
          this.roleDescriptionError = this.translate('role_description_error')
        }
        return false;
      }

      this.duplicateRoleDialog = false
      this.confirmationModelData.dialog = true
      this.confirmationModelData.operation = 'duplicateRoleFinal'
      this.confirmationModelData.text = this.translate('duplicate_role_double_confirmation')
      this.confirmationModelData.trueText = this.$t('yes')
      this.confirmationModelData.falseText = this.$t('no')
    },
    deleteRoleConfirmationDialog() {
      this.confirmationModelData.dialog = true
      this.confirmationModelData.operation = 'deleteRole'
      this.confirmationModelData.text = this.$t('user_rights_section.delete_role_confirmation', {roleName: this.selectedRole.text ?? this.selectedRole.name})
      this.confirmationModelData.trueText = this.$t('yes')
      this.confirmationModelData.falseText = this.$t('no')
      this.confirmationModelData.data = this.selectedRole.id
    },
    deleteRole(roleId) {
      return new Promise((resolve, reject) => {
        if (this.isset(this.selectedRole.users)) {
          return this.$toast.error(this.translate('remove_users'));
        }

        let url = API_BASE_URL + '/roles/' + roleId
        
        return axios.delete(url, {headers: this.axiosHeaders})
            .finally(() => {
              
            })
            .then((response) => {
              this.roles = Object.values(response.data.data)
              this.selectedRole = this.formattedRoles[0] ? this.formattedRoles[0] : null
              this.confirmationModelData.dialog = false
              this.$toast.success(this.translate('role_deleted_successfully'))
            });
      })
    },
    editRole() {
      this.roleNameError = "";
      this.roleDescriptionError = ""

      let name = this.editRoleModel.name
      let description = this.editRoleModel.description
      if (!this.isset(name) || !this.isset(description)) {
        if (!this.isset(name)) {
          this.roleNameError = this.translate('role_name_error')
        }

        if (!this.isset(description)) {
          this.roleDescriptionError = this.translate('role_description_error')
        }
        return false;
      }

      return new Promise((resolve, reject) => {
        let data = {
          name: this.editRoleModel.name,
          description: this.editRoleModel.description
        }

        
        let method = this.roleFormAction === 'new'
            ? axios.post(API_BASE_URL + '/roles', data, {headers: this.axiosHeaders})
            : axios.put(API_BASE_URL + '/roles/' + this.selectedRole.id, data, {headers: this.axiosHeaders})
        return method
            .finally(() => {
              
            })
            .then((response) => {
              let keepRowId = this.selectedRole.id
              if (this.roleFormAction === 'edit') {
                this.roles = Object.values(response.data.data)

                setTimeout(() => {
                  this.selectedRole = this.formattedRoles.find(o => o.id === keepRowId)
                }, 100)
              } else {
                this.roles.push(response.data.data)
                this.selectedRole = response.data.data
              }

              this.editRoleDialog = false
              this.$toast.success(this.translate(this.roleFormAction === 'edit' ? 'role_updated_successfully' : 'role_created_successfully'))
              resolve(this.selectedRole);
            }).catch((error) => {
              reject(error)
            })
      })
    },
    addNewRole() {
      this.roleFormAction = 'new';
      this.editRoleDialog = true
      this.editRoleModel.name = "";
      this.editRoleModel.description = "";
      this.roleNameError = "";
      this.roleDescriptionError = ""
    },
    disableRowTooltip(item){
      // (props.item.is_mandatory !== 1 && selectedRole.name !== 'admin') || isset(selectedRole.company_id)
      if(!this.isset(this.selectedRole.company_id)) return true;

      return item.is_mandatory !== 1 && this.selectedRole.name !== 'admin'

    },
  }
}
</script>
<style scoped>
.bg-grey {
  background-color: #f5f5f5;
}
</style>