var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-sheet',[_c('v-tooltip',{attrs:{"location":"top"},scopedSlots:_vm._u([{key:"activator",fn:function({ props }){return [_c('v-calendar',_vm._b({key:_vm.calendarKey,ref:"calendar",style:({'min-height': '80vh'}),attrs:{"weekdays":_vm.weekday,"type":_vm.type,"locale":_vm.locale,"category-show-all":"","events":_vm.events,"event-overlap-mode":_vm.mode,"event-overlap-threshold":30,"interval-minutes":_vm.minimumWorkDuration,"first-interval":_vm.firstInterval,"interval-count":_vm.intervalCount,"interval-format":(interval) => interval.time,"hide-header":false,"weekday-format":() => '',"categories":_vm.categories,"event-ripple":false,"day-format":(day) => _vm.formatDay(day)},on:{"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startTime,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag,"click:event":(e) => _vm.editAppointment(e.event),"click:time":(e) => _vm.addNewAppointment(e),"click:date":(e) => _vm.addNewAppointment(e),"click:day":(e) => _vm.addNewAppointment(e),"mouseenter:event":(e) => _vm.showMore(e.date, e.event),"mouseleave:event":(e) => _vm.hideMore()},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag.apply(null, arguments)}},scopedSlots:_vm._u([{key:"event",fn:function({event}){return [_c('div',{staticStyle:{"padding":"5px","cursor":"pointer"}},[_c('p',{staticClass:"title-section",staticStyle:{"float":"left","font-weight":"bolder"}},[(_vm.appointmentByIndex[event.id].appointment_status.key ==='appointment_is_executed')?_c('v-icon',{staticStyle:{"font-size":"20px"},attrs:{"color":"white"}},[_vm._v(" mdi-account-check ")]):_vm._e(),_vm._v(" "+_vm._s(event.name)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.getTimeFromDate(event.start))+" - "+_vm._s(_vm.getTimeFromDate(event.end))+" ")],1),_c('div',{staticClass:"icons-segment",staticStyle:{"float":"right"}},[(_vm.thirdIconIsVisible(_vm.appointmentByIndex[event.id]))?_c('span',{style:({
                backgroundColor: '#fff',
                borderRadius: '2px',
                float: 'right',
                position: 'absolute',
                right: '0',
                top: '0',
                marginTop: '3px',
                marginBottom: '2px',
                height: '18.5px',
                width: '18.5px',
                textAlign: 'center',
              })},[_c('v-icon',{style:({
                  fontSize: '15px',
                  paddingBottom: '2px',
                }),attrs:{"color":"red"}},[_vm._v("mdi-bell-cancel")])],1):_vm._e(),(
                  _vm.appointmentByIndex[event.id].external_order_status &&
                  _vm.appointmentByIndex[event.id].external_order_status != null
              )?_c('span',{style:({
                float: 'right',
                position: 'absolute',
                right: '0',
                top: '0',
                marginTop: '3px',
                marginBottom: '2px',
                backgroundColor: '#fff',
                borderRadius: '2px',
                height: '18.5px',
                width: '18.5px',
                textAlign: 'center',
              })},[_c('v-icon',{style:({
                  fontSize: '15px',
                  paddingBottom: '2px',
                }),attrs:{"color":_vm.computeExternalOrderStatus(
                    _vm.appointmentByIndex[event.id].external_order_status
                  ).color}},[_vm._v(" "+_vm._s(_vm.computeExternalOrderStatus( _vm.appointmentByIndex[event.id].external_order_status ).icon)+" ")])],1):_vm._e(),(_vm.firstIconIsVisible(_vm.appointmentByIndex[event.id]))?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-code-greater-than ")]):_vm._e(),(_vm.secondIconIsVisible(_vm.appointmentByIndex[event.id]))?_c('v-icon',{style:({
          }),attrs:{"color":"white"}},[_vm._v("mdi-code-less-than ")]):_vm._e()],1)])]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'v-calendar',props,false))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('span',[_vm._v(_vm._s(_vm.dialogContent))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }