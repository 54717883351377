import { render, staticRenderFns } from "./Client.vue?vue&type=template&id=63e61afe&scoped=true"
import script from "./Client.vue?vue&type=script&lang=js"
export * from "./Client.vue?vue&type=script&lang=js"
import style0 from "./Client.vue?vue&type=style&index=0&id=63e61afe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e61afe",
  null
  
)

export default component.exports