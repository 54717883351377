var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1000"},on:{"click:outside":function($event){return _vm.closePopUpModel()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closePopUpModel()}},model:{value:(_vm.popUpModelVisible),callback:function ($$v) {_vm.popUpModelVisible=$$v},expression:"popUpModelVisible"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[(
                        _vm.selectedItemIndexData.article.original_name
                            .length >= 35
                    )?[_vm._v(" "+_vm._s(_vm.selectedItemIndexData.article.original_name.substring( 0, 35 ) + "...")+" ")]:[_vm._v(" "+_vm._s(_vm.selectedItemIndexData.article.original_name)+" ")],_c('span',{staticClass:"pl-10",staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(_vm.$t("receptions.scanned_quantity"))+": "+_vm._s(_vm.selectedItemIndexData.received))])],2)]),_c('v-card-text',[_c('v-row',[(_vm.showDataTable)?_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"width":"100%"},attrs:{"fixed-header":"","footer-props":{
                        'items-per-page-options': _vm.dataTableOptions,
                    },"headers":_vm.headers,"hide-default-header":true,"items":_vm.data,"items-per-page":99999,"options":_vm.options,"sort-by":_vm.sortBy,"loading-text":this.$t('loading'),"item-key":"id","height":'60vh'},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function({ props: { headers } }){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_vm._l((headers),function(header){return [_c('th',{key:header.value,class:header.align == 'start'
                                                ? 'text-left'
                                                : 'text-right',style:(_vm.getHeaderWidth(
                                                header,
                                                header.width
                                            )),attrs:{"scope":"col"}},[_c('v-row',{class:header.align == 'start'
                                                    ? 'justify-left'
                                                    : header.align ==
                                                      'center'
                                                    ? 'justify-center'
                                                    : 'justify-end'},[_vm._v(" "+_vm._s(header.text)+" "),(
                                                    header.value ==
                                                        'is_verified'
                                                )?_c('v-simple-checkbox',{staticClass:"mt-0 ma-0 pa-0 pl-2",attrs:{"hide-details":"auto","ripple":false},on:{"click":function($event){return _vm.verifyAll()}},model:{value:(_vm.isAllSelected),callback:function ($$v) {_vm.isAllSelected=$$v},expression:"isAllSelected"}}):_vm._e()],1)],1)]})],2)])]}},{key:"item",fn:function({ item }){return [_c('tr',{class:_vm.setRowColorClass(item)},[_c('td',[_c('v-tooltip',{attrs:{"bottom":"","z-index":"9999"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                                                _vm.getStatusValue(
                                                    item.status_id
                                                ).key == 'order_registered'
                                            )?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-file-document-multiple ")]):(
                                                _vm.getStatusValue(
                                                    item.status_id
                                                ).key ==
                                                    'successfully_ordered'
                                            )?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-cart-check ")]):(
                                                _vm.getStatusValue(
                                                    item.status_id
                                                ).key ==
                                                    'order_supplier_error'
                                            )?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-network ")]):(
                                                _vm.getStatusValue(
                                                    item.status_id
                                                ).key == 'order_canceled'
                                            )?_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-archivecancel ")]):(
                                                _vm.getStatusValue(
                                                    item.status_id
                                                ).key ==
                                                    'partially_received'
                                            )?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-archive-arrow-down")]):(
                                                _vm.getStatusValue(
                                                    item.status_id
                                                ).key == 'fully_received'
                                            )?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-archive-check ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.translateKeys( _vm.getStatusValue( item.status_id ).key, "key", "receptions.statuses" ))+" ")])])],1),_c('td',[_vm._v(" "+_vm._s(item.ordered_quantity)+" ")]),_c('td',{staticClass:"text-left"},[(item.name || item.first_name)?[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.first_name)+" ")]:[_vm._v(" "+_vm._s(_vm.$t("receptions.internal_stock"))+" ")]],2),_c('td',{staticClass:"text-left"},[(item.id)?_c('span',{style:({
                                        color: 'blue',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }),on:{"click":function($event){return _vm.navigateToOrdersPage(item.order_id)}}},[_c('TextComponentWithExtraIcons',{attrs:{"value":item.identifier}})],1):_vm._e()]),_c('td',{staticClass:"text-left"},[(item.original_start_time)?_c('span',{style:({
                                        color: 'blue',
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                    }),on:{"click":function($event){return _vm.navigateToAppointmentPage(
                                            item.appointment_id
                                        )}}},[_vm._v(" "+_vm._s(item.original_start_time)+" ")]):_vm._e()]),_c('td',[_c('v-row',{staticClass:"justify-end"},[_c('v-checkbox',{staticClass:"mt-n1",attrs:{"color":"green","hide-details":""},on:{"click":_vm.refreshAgainData},model:{value:(item.is_verified),callback:function ($$v) {_vm.$set(item, "is_verified", $$v)},expression:"item.is_verified"}})],1)],1)])]}}],null,false,554007453)}):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey-lighten-2"},on:{"click":function($event){return _vm.closePopUpModel()}}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }