<template>
    <v-dialog persistent v-model="internalShowDialog" @click:outside="cancelDialog('cancel')" @keydown.esc="cancelDialog('cancel')" max-width="1200">
        <v-card>
            <v-card-title class="headline">
                    <template v-if="workpointprop && typeof workpointprop == 'object'">
                        {{ $t('edit') }} {{ $t(label) }}
                    </template>
                    <template v-else>
                        {{ $t(label) }}
                    </template>
            </v-card-title>
            <v-card-text>
            <v-form class="main_form" id="client-form">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="name"
                                name="name"
                                class="to-upper"
                                ref="client_name"
                                v-on:keydown="handleInput($event,'justalphanumeric',50);"
                                :label="$t('company_settings.name')"
                                :error-messages="$store.state.validation && !$v.name ? [this.$t('vehicles.clients_model.text_messages.name_required')] : [] "
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="9">
                            <AddressInput
                                class="mt-n4"
                                v-model="address"
                                :singleLine="false"
                                :showAddressType="false"
                                :defaultAddressType="'billing_address'"
                                :label="$t('billings.address')"
                                :searchWithoutCountry="true"
                                :showLocationName="false"
                                :required="true"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="mt-n6">
                        <v-col cols="12" sm="3">
                            <v-text-field
                                :autocomplete="autocompleteValueLocal"
                                v-model="email"
                                class="to-upper"
                                v-on:keydown="handleInput($event,'email',60);"
                                :label="$t('vehicles.clients_model.email')"
                                :error-messages="$store.state.validation ?  returnErrorMessage(
                                    [!$v.email.required,this.$t('users.text_messages.email_required')],
                                    [!$v.email.email,this.$t('users.text_messages.invalid_email')]
                                ) : []"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                            >
                                <template #label>
                                    <span class="red--text"><strong>* </strong></span> {{ $t('vehicles.clients_model.email') }}
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <vue-tel-input-vuetify :disabledFetchingCountry="true" :preferredCountries="preferredCountry" :filterCountries="onlyCountries"  validCharactersOnly :label="$t('vehicles.clients_model.phone')" :inputOptions="{ showDialCode: true, tabindex: 0 }" :mode="'international'" v-model="phone"></vue-tel-input-vuetify>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <vue-tel-input-vuetify :disabledFetchingCountry="true" :preferredCountries="preferredCountry" :filterCountries="onlyCountries"  validCharactersOnly :label="$t('vehicles.clients_model.gsm')" :inputOptions="{ showDialCode: true, tabindex: 0 }" :mode="'international'" v-model="gsm"></vue-tel-input-vuetify>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <vue-tel-input-vuetify :disabledFetchingCountry="true" :preferredCountries="preferredCountry" :filterCountries="onlyCountries"  validCharactersOnly :label="$t('vehicles.clients_model.fax')" :inputOptions="{ showDialCode: true, tabindex: 0 }" :mode="'international'" v-model="fax"></vue-tel-input-vuetify>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pr-2">
                <v-spacer></v-spacer>
                <v-btn v-if="workpointprop && typeof workpointprop == 'object' && workpointprop.id" id="top-delete" class="mr-1 text-white-color" color="error"
                    @click="deleteMember()">
                    {{ $t("nav_buttons.delete") }}
                    <v-icon dark right> mdi-delete </v-icon>
                </v-btn>
                <v-btn color="grey lighten-2" @click="cancelDialog('cancel')">
                    {{ $t('vehicles.Cancel') }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>
                <v-btn color="success" :disabled="! (address && name) || $store.state.isLoading" @click="saveData()">
                    {{ $t('vehicles.Save') }}
                    <v-icon dark right> mdi-content-save </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import axios from '@/plugins/axios';
    import _ from 'lodash';
    import {API_BASE_URL} from "@/config";
    import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from '@/config';
    import { validationMixin } from 'vuelidate'
    import { required, email, requiredIf, phoneNum, numeric ,minLength, maxLength, alpha, minValue, maxValue } from 'vuelidate/lib/validators'
    import VueTelInputVuetify from "@/components/PhoneNumberInput";
    import AddressInput from "@/components/AddressInput";

export default {
    mixins: [validationMixin],
    name:       "WorkPointModel",
    components: {
        "vue-tel-input-vuetify":VueTelInputVuetify,
        AddressInput
    },
    props: {
        showDialog: [Number, Boolean],
        workpointprop: [Object, Number],
        client: [Object],
        label: {
            type: String,
            default: 'company_settings.working_points.add_work_point'
        },
        addAddress: {
            type: Boolean,
            default: false
        }
    },
    validations: {
        name:{
            required: requiredIf(function () {
                return !this.name || !this.name.length;
            }),
        },
        email:{ 
            required,
            email: (val) => email((val ?? "").toLowerCase()),
        },
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
            internalShowDialog:this.showDialog,
            autocompleteValueLocal : 'off',
            DeleteConfirm: false,
            operation: 'edit',
            token: this.$store.state.AccessToken,
            header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
            workpoint: null,
            name: null,
            phone: null,
            fax: null,
            gsm: null,
            email: null,
            address: null
        }
    },
    mounted(){
        this.$store.commit("setValidation",false); // enable validation
        this.autocompleteValueLocal = this.autocompleteValue();
    },
    methods: {
        addWorkPoint(data) {
            
            axios.post(API_BASE_URL + '/work_points', data, {headers: this.header})
            .then(data => {
                this.$toast.success(this.$t('company_settings.working_points.text_messages.working_point_added_successfully'));
                this.cancelDialog('added',data.data.data);
            })
            .catch((error) =>{

            })
            .finally(() => {
                
            });
        },
        editWorkPoint (data) {
            
            axios.put(API_BASE_URL + '/work_points/' + this.workpointprop.id, data, {headers: this.header})
            .then(data => {
                this.$toast.success(this.$t('company_settings.working_points.text_messages.working_point_updated_successfully'));
                this.cancelDialog('added',data.data.data);
            })
            .catch((error) =>{
            })
            .finally(() => {
                
            });
        },
        getWorkPoint(data) {
            
            axios.get(API_BASE_URL + '/work_points/' + data.id, {headers: this.header})
            .then(response => {
                this.workpoint = response.data.data;
                this.name = this.workpoint.name;
                this.email = this.workpoint.email;
                this.phone = this.workpoint.phone;
                this.gsm = this.workpoint.gsm;
                this.fax = this.workpoint.fax;
                this.address = this.workpoint.address;
            })
            .catch((error) =>{
            })
            .finally(() => {
                
            });
        },
        deleteMember(){
            
            axios
                .delete(API_BASE_URL + "/work_points/" + this.workpoint.id, {
                    headers: this.header,
                })
                .then((data) => {
                    this.$toast.success(
                        this.$t("company_settings.working_points.text_messages.working_point_deleted_successfully")
                    );
                    // this.$router.push("/clients").catch(() => {});
                    this.cancelDialog('added',data);
                })
                .catch((error) => {
                    this.$toast.error(
                        this.$t("company_settings.working_points.text_messages." + error.response.data.errors[0])
                    );
                })
                .finally(() => {
                    
                });
        },
        cancelDialog(operationPerformed, data) {
            this.$emit('update:showDialog', false);
            this.$emit('buttonClicked', operationPerformed == 'added' ? data : false);
            this.internalShowDialog = false;
        },
        saveData() {
            this.$store.commit("setValidation",true); // enable validation
            if(this.$v.$invalid ){
                this.$toast.warning(this.$t('please_fill_form_correctly'));
                return false;
            }
            else{
                if(this.workpoint && typeof this.workpoint == 'object'){
                    this.editWorkPoint(this.makeSubmitData());
                }
                else{
                    this.addWorkPoint(this.makeSubmitData());
                }
            }
        },
        makeSubmitData(){
            return {
                id: this.id ?? null,
                name: this.name,
                phone: this.phone ?? null,
                gsm: this.gsm ?? null,
                fax: this.fax ?? null,
                email: this.email ?? null,
                address: this.address,
                company_id: this.client.representative_company_id,
                add_address: this.addAddress ? 1 : 0
            };
        },
    },
    beforeDestroy(){
        this.$store.commit("setValidation",false);
    },
    watch:   {
        showDialog:{
            handler: function(val){
                this.internalShowDialog = val;
            }
        },
        workpointprop:{
            handler: function(val){
                if(val && typeof val == "object" && val.id){
                    this.getWorkPoint(val);
                }
            },
            immediate: true,
        }
    }
}
</script>

<style scoped>

</style>
