<template>
    <v-text-field
        v-model="model"
        autocomplete="off"
        class="to-upper"
        type="email"
        :disabled="disabled"
        :label="label || $t('clients.email')"
        :error-messages="error_messages"
        @input="checkValidation"
        v-on:keydown="handleInput($event, 'email', 60)"
        role="presentation"
        v-on:keydown.enter.prevent=""
        @keyup="$emit('keyup', model)"
    >
        <template #append>
            <div v-if="!isChecking && showSendVerificationIcon && show_icon_for_verification">
                <v-tooltip bottom z-index="9999">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-on="on"
                            v-bind="attrs"
                            @click="sendValidationEmail"
                            style="color: orangered"
                            >mdi-email-fast-outline</v-icon
                        >
                    </template>
                    {{
                        $t("email_verification_section.send_verification_mail")
                    }}
                </v-tooltip>
            </div>
            <div v-if="!isChecking && model && isVerified && show_icon_for_verification">
                <v-tooltip bottom z-index="9999">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" color="success"
                            >mdi-check</v-icon
                        >
                    </template>
                    {{ $t("email_verification_section.verified") }}
                </v-tooltip>
            </div>
        </template>
    </v-text-field>
</template>
<script>
import axios from "axios";
import _axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
export default {
    emits: ["input", "keyup"],
    props: {
        value: {
            required: true,
        },
        table: {
            default: "clients",
        },
        validation_column: {
            default: "email_verified_at",
        },
        search_column: {
            default: "email",
        },
        disabled: {
            default: false,
        },
        label: {
            default: null,
        },
        identifier_column: {
            default: "id",
        },
        name_column: {
            default: "name",
        },
        client_id: {
            default: null,
        },
        email_position: {
            default: 1,
        },
        error_messages: {
            default: [],
        },
        show_icon_for_verification: {
            default: true
        }
    },
    computed: {
        showSendVerificationIcon() {
            //   return false;
            if (!this.isValidEmail(this.model)) return false;
            // confirm if model is a valid email
            return this.isValidEmail(this.model) && !this.isVerified;
        },
    },
    watch: {
        value() {
            this.model = this.value;
        },
        model() {
            this.$emit("input", this.model);
        },
        client_id() {
            this.checkValidation();
        },
    },
    data() {
        return {
            model: this.value,
            isVerified: false,
            timerId: null,
            isChecking: false,
        };
    },
    methods: {
        isValidEmail(email) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(email);
        },
        sendValidationEmail() {
            _axios
                .post(API_BASE_URL + "/email_verification", {
                    email: this.model,
                    table: this.table,
                    search_column: this.search_column,
                    validation_column: this.validation_column,
                    identifier: this.client_id,
                    identifier_column: this.identifier_column,
                    name_column: this.name_column,
                    email_position: this.email_position,
                })
                .then(() => {
                    this.$toast.success(
                        this.$t("email_verification_section.sent")
                    );
                });
        },
        checkValidation() {
            if (!this.isValidEmail(this.model)) return;

            if (!this.isValidEmail(this.model)) return;
            if (this.timerId) clearTimeout(this.timerId);
            this.timerId = setTimeout(() => {
                this.isChecking = true;
                axios(API_BASE_URL + "/email_verification", {
                    headers: {
                        Authorization:
                            "Bearer " + this.$store.state.AccessToken,
                    },
                    params: {
                        email: this.model,
                        table: this.table,
                        search_column: this.search_column,
                        validation_column: this.validation_column,
                        identifier: this.client_id,
                        identifier_column: this.identifier_column,
                    },
                })
                    .then((response) => {
                        this.isVerified = response.data.data.verified;
                    })
                    .finally(() => {
                        this.isChecking = false;
                    });
            }, 1000);
        },
    },
};
</script>
