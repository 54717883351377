var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},on:{"click:outside":function($event){return _vm.closeUpdater()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeUpdater()}},model:{value:(_vm.show_dialog),callback:function ($$v) {_vm.show_dialog=$$v},expression:"show_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('bulk_updater.title')))])]),_c('v-card-text',[_c('v-container',{staticClass:"pt-0"},[_c('v-row',{staticClass:"mb-n8"},[_c('v-col',[_c('p',{staticClass:"filter"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t('bulk_updater.filter'))+": "),_c('span',[_vm._v(_vm._s(_vm.getAppliedFilters()))])])])])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('p',{staticClass:"orderSubTitle",staticStyle:{"color":"red"}},[_c('strong',[_c('u',[_c('span',{},[_vm._v(_vm._s(_vm.$t('bulk_updater.warning'))+": ")])])]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('bulk_updater.warning_note_1'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('bulk_updater.warning_note_2'))+" ")])])],1),_c('v-row',{},[_c('v-col',[_c('td',{on:{"keydown":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.checkIfMinusPressed($event, 'amount')}}},[_c('custom-money-input',{ref:"tableUnitPrice",staticClass:"pt-0 to-upper right-aligned-input shrink",attrs:{"autocomplete":_vm.autocompleteValueLocal,"hide-details":"auto","label":_vm.$t('bulk_updater.amount'),"dense":"","tabindex":"3","properties":{
                                    prefix: _vm.currencySymbol,
                                    readonly: false,
                                    disabled: false,
                                    outlined: false,
                                    placeholder: _vm.getDecimalNumber() == 2 ? '0.00' : '0.000',
                                    dense: true,
                                    hideDetails:'auto',
                                },"options":{
                                    locale: _vm.userLanguageId,
                                    length: 12,
                                    precision: _vm.getDecimalNumber(),
                                    empty: _vm.getDecimalNumber() == 2 ? 0.00 : 0.000,
                                }},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1)]),_c('v-col',[_c('td',{on:{"keydown":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.checkIfMinusPressed($event, 'percent')}}},[_c('custom-money-input',{staticClass:"pt-0 to-upper right-aligned-input shrink",attrs:{"autocomplete":_vm.autocompleteValueLocal,"label":_vm.$t('bulk_updater.percentage'),"background-color":!_vm.$v.percent.maxValue ? '#ffc2b7' : (!_vm.$v.percent.minValue ? '#ffc2b7' : 'white'),"properties":{
                                    suffix: '%',
                                    readonly: false,
                                    disabled: false,
                                    outlined: false,
                                    clearable: false,
                                    placeholder: _vm.getDecimalNumber() == 2 ? '0.00' : '0.000',
                                    dense: true,
                                    hideDetails:'auto'
                                },"options":{
                                    locale: _vm.userLanguageId,
                                    length: 4,
                                    precision: _vm.getDecimalNumber(),
                                    empty: _vm.getDecimalNumber() == 2 ? 0.00 : 0.000,
                                },"hide-details":"auto","dense":"","tabindex":"4","focus":true},model:{value:(_vm.percent),callback:function ($$v) {_vm.percent=$$v},expression:"percent"}})],1)])],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pr-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey lighten-2"},on:{"click":function($event){return _vm.closeUpdater()}}},[_vm._v(" "+_vm._s(_vm.$t('bulk_updater.cancel'))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-keyboard-return ")])],1),_c('v-btn',{attrs:{"color":"success","disabled":(_vm.amount == 0 && _vm.percent == 0) || _vm.getAppliedFilters().length == 0},on:{"click":function($event){return _vm.saveUpdater()}}},[_vm._v(" "+_vm._s(_vm.$t('bulk_updater.save'))+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-content-save ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }