<template>
  <v-dialog max-width="800px" :persistent="sending" v-model="dialog">
    <template v-slot:activator="{ props, on  }">
      <span v-bind="props" v-on="on" id="activate-vehicle-edit-modal"> <slot></slot> </span>
    </template>

    <template v-slot:default="{  }">
      <v-card>
        <v-form @submit.prevent="updateHandler">
          <v-card-title> {{ $t('router_titles.edit_vehicle')  }}
          <span style="font-weight: bolder; color: #0A47A7; margin-left: 5px">  ({{ vehicle.registration_number ? (vehicle.registration_number.includes("#UNKNOWN") ? $t("unknown_reg_no") : vehicle.registration_number) : '' }})</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-autocomplete
                    v-model="vehicle.brand_id"
                    name="brand"
                    :items="brands"
                    item-text="name"
                    class="to-upper"
                    item-value="id"
                    :label="$t('vehicles.brand')"
                    append-icon="mdi-close"
                    hide-no-data
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-text-field class="to-upper" :label="$t('vehicles.model')" required v-model="vehicle.model"/>
              </v-col>
              <v-col cols="2">
                <v-text-field class="to-upper" :label="$t('vehicles.cylinder_capacity')" v-on:keydown="handleInput($event,'float',9);" v-model="vehicle.cylinder_capacity"/>
              </v-col>
              <v-col cols="2">
                <v-text-field class="to-upper" :label="$t('vehicles.power')" v-model="vehicle.power"/>
              </v-col>
              <v-col cols="2">
                <v-text-field class="to-upper" :label="$t('vehicles.km')" v-on:keydown="handleInput($event,'float',9);" v-model="vehicle.km"/>
              </v-col>

              <v-col cols="3">
                <v-select
                    class="to-upper"
                    :label="$t('vehicles.fuel')"
                    v-model="vehicle.fuel_id"
                    :items="translatedFuelTypes"
                    item-text="label"
                    item-value="id"
                />
              </v-col>
              <v-col cols="3">
                <v-select
                    :items="vehicleTypes"
                    item-text="text"
                    item-value="id"
                    class="to-upper"
                    :label="$t('supplier.type')"
                    v-model="vehicle.vehicle_type_id"
                >

                </v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                    class="to-upper"
                    :label="$t('vehicles.transmission')"
                    v-model="vehicle.transmission_id"
                    :items="translatedTransmissions"
                    item-text="label"
                    item-value="id"
                >

                </v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                    class="to-upper"
                    :label="$t('vehicles.gearbox')"
                    :items="translatedGearBoxes"
                    item-text="label"
                    item-value="id"
                    v-model="vehicle.gearbox_id"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field class="to-upper" :label="$t('vehicles.vin')" v-model="vehicle.vin"/>
              </v-col>

              <v-col cols="3">
                <v-menu
                    v-model="regisrationDateDialog"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="vehicle.registration_date"
                        :label="$t('vehicles.registration_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:append="vehicle.registration_date = null"
                        append-icon="mdi-close"
                        class="mr-2 to-upper"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      :first-day-of-week="1"
                      :weekday-format="getDay"
                      v-model="vehicle.registration_date"
                      @input="regisrationDateDialog = false"
                      no-title
                      scrollable
                      :locale="userLanguageId"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                    :items="creditors"
                    :label="$t('vehicles.creditor')"
                    v-model="vehicle.creditor_id"
                    item-text="name"
                    item-value="id"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field class="to-upper" :label="$t('vehicles.contract_number')" v-on:keydown="handleInput($event,'float',15);"  v-model="vehicle.contract_number"/>
              </v-col>
              <v-col cols="3">
                <v-menu
                    v-model="contractEndDateDialog"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="vehicle.contract_end_date"
                        :label="$t('vehicles.contract_end_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:append="vehicle.contract_end_date = null"
                        append-icon="mdi-close"
                        class="mr-2 to-upper"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      :first-day-of-week="1"
                      :weekday-format="getDay"
                      v-model="vehicle.contract_end_date"
                      @input="contractEndDateDialog = false"
                      no-title
                      scrollable
                      :locale="userLanguageId"
                      :min="vehicle.registration_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>

            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pr-2">
            <v-spacer></v-spacer>
            <v-btn color="grey-lighten-2" @click="dialog = false" :disabled="sending">
              {{ $t("cancel") }}
              <v-icon dark right> mdi-keyboard-return</v-icon>
            </v-btn>
            <v-btn color="green" :dark="!sending" type="submit" :disabled="sending" :loading="sending">
              {{ $t("save") }}
              <v-icon dark right> mdi-content-save</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import {API_BASE_URL, FAMILY_ARTICLE_CARS} from "@/config";
import axios from "@/plugins/axios";

export default {
  name: "EditVehicleModal",
  emits: ['updated', 'saved'],
  props: {
    value: {
      type: Object,
      required: true,
    },
    creditors:{
      type: Array,
    },
    brands:{
     type: Array,
    },
    vehicleTypes:{
      type: Array,
    }
  },
  data() {
    return {
      dialog: false,
      sending: false,
      vehicle: {},
      gearBoxes:[],
      transmissions:[],
      fuelTypes:[],
      header:{ Authorization: "Bearer " + this.$store.state.AccessToken },
      contractEndDateDialog: false,
      regisrationDateDialog: false,
    }
  },
  async mounted() {
    await this.getGearBoxes()
    await this.getVehicleTransmissions()
    await this.getVehicleFuelTypes()
  this.setVehicleData(this.value)
  },
  watch: {
    value: {
      handler: function (val) {
        this.setVehicleData(val)
      },
      deep: true
    },
    vehicle: {
      handler: function (val) {
        this.$emit('updated', val)
      },
      deep: true
    },
    editVehicleModalKey(){
      if(!this._inactive){
        this.dialog = true;
      }
    },
  },
  computed: {
    editVehicleModalKey(){
      return this.$store.state.editVehicleModalKey;
    },
    translatedGearBoxes(){
      return this.gearBoxes.map(g => {
        return {
          ...g,
          label: (this.$t('vehicles.gearboxes.' + g.key)).toUpperCase()
        }
      })
    },
    translatedTransmissions(){
      return this.transmissions.map(t => {
        return {
          ...t,
          label: (this.$t('vehicles.transmissions.' + t.key)).toUpperCase()
        }
      })
    },
    translatedFuelTypes(){
      return this.fuelTypes.map( fuel => {
        return {
          ...fuel,
          label: (this.$t('vehicles.fuels.' + fuel.key)).toUpperCase()
        }
      })
    }
  },
  methods: {
    setVehicleData(val){
      let completeData = {...val.completeData};
      let vehicleData = {...val}
      if(this.isset(completeData)){
        vehicleData.power = completeData.power
        vehicleData.contract_number = completeData.contract_number
        vehicleData.fuel_id = this.vehicle.fuel_id ?? completeData.fuel_id
        vehicleData.transmission_id = completeData.transmission_id
        vehicleData.gearbox_id = completeData.gearbox_id
        vehicleData.cylinder_capacity = completeData.cylinder_capacity
        vehicleData.is_leasing = completeData.is_leasing == true ? 1 : 0;
        vehicleData.driver_id= completeData.driver_id
        vehicleData.second_driver_id = completeData.second_driver_id
        vehicleData.client_id = completeData.client_id
        vehicleData.registration_date = completeData.registration_date
        vehicleData.contract_end_date = completeData.contract_end_date
        vehicleData.note = completeData.note
        vehicleData.is_out_of_circulation = completeData.is_out_of_circulation == true ? 1 : 0;
      }

      this.vehicle = vehicleData
    },
    updateHandler() {
      return new Promise((resolve, reject) => {
        this.$store.state.loading = true
        axios.put(API_BASE_URL + "/client_vehicles/"+ this.vehicle.id, this.vehicle,{
          headers: this.header
        }).then(({data}) => {
          this.$toast.success(this.$t('vehicles.clientVehicle_updated'));
          this.$emit('saved', data);
          this.dialog = false;
          resolve(data.data)
        }).catch((err) => {
          console.error(err)
          this.$toast.error(this.$t('unknown_error'));
          reject(err)
        })
      })
    },
    getGearBoxes(){
      return new Promise((resolve, reject) =>{
        this.getCodes('vehicle_ger_box', 'vehicles.gearboxes')
              .then((data) => {
                this.gearBoxes = data
                resolve(this.gearBoxes)
              });
      })
    },
    getVehicleTransmissions(){
      return new Promise((resolve, reject) => {
        this.getCodes('vehicle_transmission', 'vehicles.transmissions')
              .then((data) => {
                this.transmissions = data
                resolve(this.transmissions)
              });
      })
    },
    getVehicleFuelTypes(){
      return new Promise((resolve, reject) => {
        this.getCodes('vehicle_fuel', 'vehicles.fuels')
              .then((data) => {
                this.fuelTypes = data
                resolve(this.fuelTypes)
              });
      })
    }
  }
}
</script>