<template>
  <v-card>
    <h1 align="center">Dynamic View Test for DEMO COMPANY</h1>
    <div v-if="isLoading">{{ $t("loading") }}</div>
    <div v-else>
      <v-data-table
        height="86vh"
        fixed-header
        :headers="headers"
        :items="roles"
        :options="options"
        :server-items-length="totalItems"
        :loading-text="this.$t('loading')"
        class="elevation-1"
        :items-per-page="itemsPerPage"
        item-key="identifier"
        @update:page="updatePagination"
        @update:items-per-page="updateItemsPerPage"
        @click:row="gotoDetails"
        style="width: 100%"
      >
        <!-- :headers="headers"
      :items-per-page="itemsPerPage"
      @click:row="gotoDetails" -->
        <template v-slot:item.actions="{ item }">
          <!-- <v-icon class="mr-2" @click="deleteItem(item)" color="primary"
          >mdi-pencil
        </v-icon> -->
          <v-icon color="error" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
import axios from '@/plugins/axios';
import { API_BASE_URL } from "@/config";

export default {
  data () {
    return {
      roles: [],
      isLoading: true,
      totalItems: 0,
      page: 1,
      lastPage: 1,
      itemsPerPage: 10,
      clients: [],
      options: {},
      sort: 'asc'
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      axios.get(API_BASE_URL + '/roles-listing?page=' + this.page + '&items_per_page=' + this.itemsPerPage + '&sorting=' + this.sort).then(res => {
        this.roles = res.data.result.data
        this.totalItems = res.data.result.total;
        this.itemsPerPage = parseInt(res.data.result.per_page);
      })
        .catch((err) => {
          console.log(err)
        });

      this.isLoading = false
    },
    deleteItem (item) {
      console.log(item);
    },
    gotoDetails (item) {
      this.$router.push('/user-role/' + item.id).catch(() => {});
    },
    updatePagination(page) {
      this.page = page;
      this.fetchData();
    },
    updateItemsPerPage (itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      // todo add items per page to API query
      this.fetchData();
    },
  },
  computed:{
    headers(){
      return [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'Role', value: 'name', sortable: true},
        { text: 'Description', value: 'description' },
        { text: 'Action', value: 'actions' }
      ];
    }
  }
}
</script>
<style scoped>
</style>
