<template>
    <v-row class="col-12 col-md-12" style="margin: 0px !important;">
        <confirmation-model
            v-if="confirmationDialog"
            :showDialog.sync="confirmationDialog"
            :openedForOperation.sync="confirmationDialogOperation"
            :text.sync="confirmationDialogConfirmationText"
            :trueText.sync="confirmationDialogTrueText"
            :falseText.sync="confirmationDialogFalseText"
            @buttonClicked="confirmationButtonClicked"
        ></confirmation-model>
        <v-card style="max-width: fit-content;" class="elevation-0">
            <div id="scrollableDayView" style="">
                <table class="table" cellspacing="0">
                    <thead>
                        <tr class="headerRow">
                            <th
                                xs4
                                pa-3
                                class="row-v time-sticky-column-0"
                            ></th>
                            <th
                                v-for="colindex in noofcolumns"
                                colspan="4"
                                :key="colindex"
                                xs4
                                pa-3
                                class="row-v timeslotheaderrow tablethtd"
                                :class="colindex == 1 ? '' : ''"
                            >
                                <template v-if="workresources[colindex - 1]">
                                    {{ workresources[colindex - 1].name }}
                                </template>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="row in rows"
                            :key="row.index"
                            class="sortable"
                            xs12
                            my-2
                        >
                            <template v-if="timeslotrow[row.index]">
                                <td xs4 pa-3 class="row-v time-sticky-column">
                                    <strong>{{
                                        timeslotrow[row.index]
                                    }}</strong>
                                </td>
                                <td
                                    v-for="colindex in noofcolumns"
                                    :row="row.index"
                                    :column="colindex - 1"
                                    colspan="4"
                                    :key="colindex"
                                    xs4
                                    pa-3
                                    class="row-v timecolumn tablethtd"
                                >
                                    <template
                                        v-if="
                                            row.items[colindex - 1] &&
                                                row.items[colindex - 1].title
                                        "
                                    >
                                        <template
                                            v-for="(appointment,
                                            index) in checkSameTimeAppointments(
                                                row.items[colindex - 1]
                                            )[0]"
                                        >
                                            <draggable
                                                :key="index"
                                                v-model="row.items"
                                                :group="{
                                                    name: 'row',
                                                    pull: 'true',
                                                    put: true,
                                                }"
                                                @sort="onSort"
                                                @end="onEnd"
                                                :sort="false"
                                                :move="checkDropMove"
                                                class="justify-space-around"
                                            >
                                                <appointment-box
                                                    :noofcolumns="noofcolumns"
                                                    :appointment="appointment"
                                                    :appointmentIndexInSameSlot="
                                                        index
                                                    "
                                                    :otherAppointmentCounts="
                                                        checkSameTimeAppointments(
                                                            row.items[
                                                                colindex - 1
                                                            ]
                                                        )[1]
                                                    "
                                                    :rows="rows"
                                                    :row="row"
                                                    :colindex="colindex"
                                                ></appointment-box>
                                            </draggable>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <draggable
                                            v-model="row.items"
                                            :group="{
                                                name: 'row',
                                                pull: 'true',
                                                put: true,
                                            }"
                                            @sort="onSort"
                                            @end="onEnd"
                                            :sort="false"
                                            :move="checkDropMove"
                                            class="justify-space-around"
                                        >
                                            <v-list-item
                                                :class="
                                                    isPastDate() ||
                                                    readonlygrids
                                                        ? 'spe'
                                                        : ''
                                                "
                                                class="addNewAppointment pa-0"
                                                @click="
                                                    addNewAppointment(
                                                        row.index,
                                                        colindex - 1
                                                    )
                                                "
                                                :row="row.index"
                                                :column="colindex - 1"
                                                @mouseover="
                                                    onMouseOver(
                                                        row.index,
                                                        colindex - 1
                                                    )
                                                "
                                                @mouseleave="onMouseLeave"
                                            >
                                                <div
                                                    @click.stop="
                                                        onPasteAppointment(
                                                            row.index,
                                                            colindex - 1
                                                        )
                                                    "
                                                    style="display: block; height: 100%; width: 100%;"
                                                    v-if="
                                                        appointmentToPaste &&
                                                            hoveredRowIndex ===
                                                                row.index &&
                                                            hoveredColumnIndex ==
                                                                colindex - 1
                                                    "
                                                >
                                                    <v-icon>
                                                        mdi-content-paste
                                                    </v-icon>
                                                </div>
                                            </v-list-item>
                                        </draggable>
                                    </template>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-card>
    </v-row>
</template>
<script>
import moment from "moment-timezone";
import draggable from "vuedraggable";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";
import AppointmentBox from "@/components/AppointmentBox";
export default {
    name: "dayview",
    display: "Two Lists",
    order: 1,
    components: {
        draggable,
        "confirmation-model": ConfirmationModel,
        "appointment-box": AppointmentBox,
    },
    props: {
        timeslots: [Array],
        workpoints: [Array],
        worktype: [Number, Object],
        workresources: [Array],
        appointments: [Array],
        selectedDate: [String],
        minimumTimeTask: [Number],
        readonlygrids: [Boolean],
        resourceType: [Object],
    },
    data() {
        return {
            data: [],
            noofrows: 0,
            noofcolumns: 0,
            rows: [],
            timeslotrow: [],
            processedWorkPointSlot: null,
            rowHeight: 48,
            header: {},
            token: this.$store.state.AccessToken,
            confirmationDialog: false,
            confirmationDialogConfirmationText: null,
            confirmationDialogFalseText: null,
            confirmationDialogTrueText: null,
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,

            // confirmation data
            rowindex: null,
            columnindex: null,
            hoveredRowIndex: null,
            hoveredColumnIndex: null,
        };
    },
    mounted() {
        this.header = { Authorization: "Bearer " + this.token };
        this.processPassedData();
        this.$nextTick(() => {
            let width = this.getDayViewWidth() / this.workresources.length;
            this.setTableThTdWidth(width);
        });
    },
    computed: {
        appointmentToPaste() {
            return this.$store.state.copiedAppointment;
        },
    },
    watch: {
        appointments(val) {
            this.processPassedData();
        },
        confirmationDialogButtonClickedIs(newVal) {
            if (newVal == true && this.confirmationDialogOperation != null) {
                if (
                    this.confirmationDialogOperation == "delete" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    this.onConfirmationDeleteAppointment();
                }
                this.confirmationDialogButtonClickedIs = null;
            }
        },
        workresources(val) {
            this.noofcolumns = val.length;
        },
    },
    beforeDestroy() {
        // Clear arrays
        this.rows = null;
        this.timeslotrow = null;
        this.data = null;
        
        // Clear any remaining references
        this.processedWorkPointSlot = null;
    },
    methods: {
        async onPasteAppointment(rowIndex, columnIndex) {
            if (this.appointmentToPaste) {
                this.$toast.clear();

                let selectedAppointmentData = this.appointmentToPaste;
                // Calculate the difference in milliseconds
                const startTime = new Date(
                    selectedAppointmentData.data.start_time
                );
                const endTime = new Date(selectedAppointmentData.data.end_time);
                const duration = endTime - startTime; // Duration in milliseconds

                // Add minutes
                let newStartTime =
                    this.selectedDate +
                    " " +
                    this.getTimeSlotByRowNumber(rowIndex)[0];
                let newEndTime = this.addMinutesToDateTime(
                    newStartTime,
                    this.millisToMinutesAndSeconds(duration)[0]
                );
                let data = {
                    start_time: newStartTime,
                    end_time: newEndTime,
                    work_resource_id: this.workresources[columnIndex].id,
                    resourceType: this.resourceType,
                };
                await axios
                    .put(
                        API_BASE_URL +
                            "/appointments/" +
                            selectedAppointmentData.data.id,
                        data,
                        { headers: this.header }
                    )
                    .then((response) => {
                        this.$parent.getAppointments();
                        this.$store.commit("setCopiedAppointment", null);
                    })
                    .catch(function(error) {});
            }
        },
        onMouseOver(rowIndex, columnIndex) {
            this.hoveredRowIndex = rowIndex;
            this.hoveredColumnIndex = columnIndex;
        },
        onMouseLeave() {
            this.hoveredRowIndex = null;
            this.hoveredColumnIndex = null;
        },
        confirmationButtonClicked(buttonClicked) {
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog = false;
        },
        deletePopUp() {
            this.confirmationDialogConfirmationText = this.$t(
                "scheduler.delete_confirmation_for_appointment"
            );
            this.confirmationDialogFalseText = this.$t("scheduler.cancel");
            this.confirmationDialogTrueText = this.$t("scheduler.delete");
            this.confirmationDialogOperation = "delete";
            this.confirmationDialog = true;
        },
        isPastDate() {
            if (this.isAdmin) {
                return false;
            }
            return (
                new Date().setHours(0, 0, 0, 0) >
                new Date(this.selectedDate).setHours(0, 0, 0, 0)
            );
        },
        addNewAppointment(rowindex, columnindex) {
            this.$store.commit("setAppointmentRedirectData", {
                new_start_date_time:
                    this.selectedDate +
                    " " +
                    this.getTimeSlotByRowNumber(rowindex)[0],
                work_resource_id: this.workresources[columnindex].id,
                resourceType: this.resourceType,
            });
            this.$router.push("/scheduler/add").catch(() => {});
        },
        editAppointment(rowindex, columnindex) {
            this.$store.commit("setAppointmentRedirectData", {
                id: this.rows[rowindex].items[columnindex].data.id,
            });
            this.$router.push("/scheduler/edit").catch(() => {});
        },
        onConfirmationDeleteAppointment() {
            if (this.rows[this.rowindex].items[this.columnindex].data.id) {
                this.rows.push({});

                axios
                    .delete(
                        API_BASE_URL +
                            "/appointments/" +
                            this.rows[this.rowindex].items[this.columnindex]
                                .data.id,
                        { headers: this.header }
                    )
                    .then(({ data }) => {
                        this.rows.push({}); // just to reflect the changes in array
                        this.$toast.success(
                            this.$t(
                                "scheduler.appointment.deleted_successfully"
                            )
                        );
                        this.rows[this.rowindex].items[this.columnindex] = {};
                        this.rows.splice(-1); // just to reflect the changes in array
                    })
                    .catch((error) => {
                        this.$toast.error(
                            this.$t("scheduler.appointment.delete_failed")
                        );
                    })
                    .finally(() => {
                        this.rows.splice(-1);
                        this.rowindex = null;
                        this.columnindex = null;
                        this.confirmationdialog = false;
                    });
            }
        },
        millisToMinutesAndSeconds(millis) {
            let finalminutes = parseInt(Math.floor(millis / 60000));
            let finalseconds = ((finalminutes % 60000) / 1000).toFixed(0);
            return [finalminutes, finalseconds < 10 ? "0" : ""];
        },
        calculateMinimumTimeTask() {
            if (this.appointments.length > 0) {
                this.appointments.forEach((element) => {
                    let milliseconds = Math.abs(
                        new Date(element.end_time) -
                            new Date(element.start_time)
                    );
                    let finalminutes = this.millisToMinutesAndSeconds(
                        milliseconds
                    )[0]; // Please note we have to manage the final minutes if equal to zero, otherwise it will make page unresponsive
                    // // meanwhile also set the task time to prevent calculation repeatedly
                    element.taskminutes = finalminutes <= 0 ? 2 : finalminutes;
                });
            }

            // based on minimumtimetask calculate height
            this.appointments.forEach((element) => {
                if (element.is_full_day) {
                    // 5 added to row height is in consideration of padding and border
                    return (element.height =
                        (this.rowHeight + 4.5) * this.intervalCount());
                }

                element.height =
                    (parseInt(this.rowHeight) /
                        parseInt(this.minimumTimeTask)) *
                        parseInt(element.taskminutes) +
                    3 * (element.taskminutes / this.minimumTimeTask); // Here We have multiplied by 3 because every cell has 3 px border so in height calculation we have not taken that into consideration so we have multipled 3 with number of cells required
            });
            return this.minimumTimeTask;
        },
        intervalCount() {
            let minInterval = this.processedWorkPointSlot.mintime;
            let maxInterval = this.processedWorkPointSlot.maxtime;
            let minHour = minInterval.split(":")[0];
            let maxHour = maxInterval.split(":")[0];
            let maxMinute = maxInterval.split(":")[1];
            maxMinute = parseInt(maxMinute);
            minHour = parseInt(minHour);
            maxHour = parseInt(maxHour);

            if (maxMinute > 0) {
                maxHour += 1;
            }

            const hoursInAMinute = 60;

            return Math.ceil(
                ((maxHour - minHour + 1) * hoursInAMinute) /
                    this.minimumTimeTask
            );
        },
        processPassedData() {
            this.rows = [];
            this.processedWorkPointSlot = this.processSlots();
            let minimumTimeTask = this.calculateMinimumTimeTask();

            this.createTimeSlots(
                this.processedWorkPointSlot.mintime,
                this.processedWorkPointSlot.maxtime,
                [],
                minimumTimeTask
            );
            this.noofcolumns = this.workresources.length;
            let index = 0;
            for (let i = 0; i < this.noofrows; i++) {
                this.rows.push({
                    index: i,
                    items: [],
                });
                for (let j = 0; j < this.noofcolumns; j++) {
                    this.rows[i].items.push({
                        index: index,
                        title: null,
                        data: null,
                        height: 0,
                    });
                }
            }
            this.appointments.forEach((element, index) => {
                let rowindex =
                    this.getRowIndex(element.start_time, element.end_time) ?? 0;
                let columnindex =
                    this.getColumnIndex(element.work_resource_id) ?? 0;
                let data = {
                    index: index,
                    title: element.title,
                    data: element,
                    height: element.height,
                    sameTimeAppointments: null,
                };
                if (
                    this.rows[rowindex].items[columnindex] &&
                    this.rows[rowindex].items[columnindex].data
                ) {
                    data.sameTimeAppointments = this.rows[rowindex].items[
                        columnindex
                    ];
                }
                this.rows[rowindex].items[columnindex] = data;
            });
            // this.rows[2].items[1].title = "hello job 12";
        },
        checkSameTimeAppointments(data) {
            let appointmentsOnSameTime = [];
            appointmentsOnSameTime.push(data);
            if (data.sameTimeAppointments) {
                while (data.sameTimeAppointments) {
                    appointmentsOnSameTime.push(data.sameTimeAppointments);
                    data = data.sameTimeAppointments;
                }
            }
            return [appointmentsOnSameTime, appointmentsOnSameTime.length];
        },
        getRowIndex(starttime, endtime) {
            return this.getRowNumberByTimeSlot(starttime, endtime);
        },
        getColumnIndex(work_resource_id) {
            let foundIndex = null;
            this.workresources.forEach((resource, index) => {
                if (work_resource_id == resource.id) {
                    foundIndex = index;
                }
            });
            return foundIndex;
        },
        checkDropMove(evt, originalEvent) {
            return true;
        },
        onSort(evt) {},
        getTimeSlotByRowNumber(rownumber) {
            return [
                this.timeslotrow[rownumber], // starting time
                this.timeslotrow[rownumber + 1], // end time
            ];
        },
        getRowNumberByTimeSlot(starttime = null, endtime = null) {
            starttime = starttime.substring(11, 16);
            endtime = starttime.substring(11, 16);
            let indexFound = null;
            if (starttime) {
                for (let i = 1; i < this.timeslotrow.length; i++) {
                    if (
                        new Date("1970/01/01 " + starttime) >=
                            new Date("1970/01/01 " + this.timeslotrow[i - 1]) &&
                        new Date("1970/01/01 " + starttime) <
                            new Date("1970/01/01 " + this.timeslotrow[i])
                    ) {
                        indexFound = i - 1;
                        break;
                    }
                }
            } else if (endtime) {
                for (let i = 1; i < this.timeslotrow.length; i++) {
                    if (
                        new Date("1970/01/01 " + endtime) >=
                            new Date("1970/01/01 " + this.timeslotrow[i - 1]) &&
                        new Date("1970/01/01 " + endtime) <=
                            new Date("1970/01/01 " + this.timeslotrow[i])
                    ) {
                        indexFound = i - 1;
                        break;
                    }
                }
            }
            return indexFound;
        },
        onEnd(evt) {
            this.rows.push({}); // just to reflect the changes in array
            let oldRowIndex = parseInt(
                evt.from.children[0].attributes.row.value
            );
            let oldColumnIndex = parseInt(
                evt.from.children[0].attributes.column.value
            );
            let newRowIndex = parseInt(
                evt.originalEvent.target.attributes.row.value
            );
            let newColumnIndex = parseInt(
                evt.originalEvent.target.attributes.column.value
            );
            let appointmentId = parseInt(
                evt.from.children[0].attributes.appointmentid.value
            );

            let pickedValue = this.rows[oldRowIndex].items[oldColumnIndex];
            let toBeSwappedValue = this.rows[newRowIndex].items[newColumnIndex];

            let temp = this.rows[newRowIndex].items[newColumnIndex];
            let selectedAppointmentData = this.selectAppointmentDraggedFromCellData(
                this.rows[oldRowIndex].items[oldColumnIndex],
                appointmentId
            );
            this.rows[newRowIndex].items[newColumnIndex] =
                selectedAppointmentData[0];
            if (selectedAppointmentData[1].length > 0) {
                // this means if their are some other appointments in the same slot then replace them
                this.rows[oldRowIndex].items[
                    oldColumnIndex
                ] = this.convertTreeDataToSameSlotAppointments(
                    selectedAppointmentData[1]
                );
            } else {
                this.rows[oldRowIndex].items[oldColumnIndex] = temp;
            }
            let newstarttime, newendtime;
            if (selectedAppointmentData[0].data.is_full_day) {
                newstarttime =
                    this.selectedDate +
                    " " +
                    this.processedWorkPointSlot.mintime;
                newendtime =
                    this.selectedDate +
                    " " +
                    this.processedWorkPointSlot.maxtime;
            } else {
                newstarttime =
                    this.selectedDate +
                    " " +
                    this.getTimeSlotByRowNumber(newRowIndex)[0];
                newendtime = this.addMinutesToDateTime(
                    newstarttime,
                    selectedAppointmentData[0].data.taskminutes
                ); // need to add time
            }

            let data = {
                start_time: newstarttime,
                end_time: newendtime,
                work_resource_id: this.workresources[newColumnIndex].id,
            };
            axios
                .put(
                    API_BASE_URL +
                        "/appointments/" +
                        selectedAppointmentData[0].data.id,
                    data,
                    { headers: this.header }
                )
                .then((response) => {
                    this.rows[newRowIndex].items[
                        newColumnIndex
                    ].data.start_time = response.data.data.start_time;
                })
                .catch(function(error) {});
            this.rows.splice(-1); // just to reflect the changes in array
        },
        convertTreeDataToSameSlotAppointments(appointmentTreeData) {
            if (appointmentTreeData) {
                let treeData = null;
                appointmentTreeData.forEach((appointment) => {
                    if (treeData) {
                        treeData = this.addToTreeChain(treeData, appointment);
                    } else {
                        treeData = appointment;
                    }
                });
                return treeData;
            }
        },
        addToTreeChain(treeData, appointment) {
            if (treeData.sameTimeAppointments) {
                this.addToTreeChain(treeData.sameTimeAppointments, appointment);
            } else {
                treeData.sameTimeAppointments = appointment;
            }
            return treeData;
        },
        selectAppointmentDraggedFromCellData(rowData, draggedAppointmentId) {
            let selectedData = null;
            let treeDataInArray = [];
            while (rowData) {
                // make tree data
                let currentData = rowData;
                rowData = rowData.sameTimeAppointments;
                if (currentData.data.id == draggedAppointmentId) {
                    selectedData = currentData;
                    continue;
                }
                currentData.sameTimeAppointments = null;
                treeDataInArray.push(currentData);
            }
            selectedData.sameTimeAppointments = null;
            return [selectedData, treeDataInArray];
        },
        addMinutesToDateTime(datetime, minutes) {
            datetime = new Date(datetime);
            let newdatetime = new Date(datetime.getTime() + minutes * 60000);
            return (
                newdatetime.toISOString().substr(0, 10) +
                " " +
                ("0" + newdatetime.getHours()).slice(-2) +
                ":" +
                ("0" + newdatetime.getMinutes()).slice(-2)
            );
        },
        checkIfCellAllowed(workpointid, columnnumber) {
            let isAllowed = false;
            this.processedWorkPointSlot.workpointslots.forEach((element) => {
                if (element.workpointid == workpointid) {
                    element.starttimeslots.forEach((startime, index) => {
                        if (
                            new Date(
                                "1970/01/01 " + this.timeslotrow[columnnumber]
                            ) >= new Date("1970/01/01 " + startime) &&
                            new Date(
                                "1970/01/01 " + this.timeslotrow[columnnumber]
                            ) <=
                                new Date(
                                    "1970/01/01 " + element.endtimeslots[index]
                                )
                        ) {
                            isAllowed = true;
                        }
                    });
                }
            });
            return isAllowed;
        },
        add() {
            // this.list.push({ name: "Juan" });
        },
        replace() {
            // this.list = [{ name: "Edgard" }];
        },
        clone(el) {
            // return {
            //     name: el.name + " cloned"
            // };
        },
        log(evt) {},
        createTimeSlots(starttime, endtime, breaktime, minimumTimeGap) {
            let startTime = moment(starttime, "HH:mm");
            let endTime = moment(endtime, "HH:mm");

            if (endTime.isBefore(startTime)) {
                endTime.add(1, "day");
            }

            var timeStops = [];

            while (startTime <= endTime) {
                timeStops.push(new moment(startTime).format("HH:mm"));
                startTime.add(minimumTimeGap, "minutes");
            }
            this.timeslotrow = timeStops;
            this.noofrows = timeStops.length;
        },
        processSlots() {
            let workpointslots = [];
            let arrayposition = -1;
            let lasttimeslot = null;

            this.timeslots.forEach((element) => {
                // as timeslots is already sorted so on change of work point id will jump to next array positions
                if (
                    !lasttimeslot ||
                    lasttimeslot.work_point_id != element.work_point_id
                ) {
                    arrayposition++;
                    workpointslots.push({
                        workpointid: element.work_point_id,
                        starttimeslots: [],
                        endtimeslots: [],
                    });
                }
                // push to the array and then sort the time array in ascending order, so that it will help in finding out min and max time slot
                workpointslots[arrayposition].starttimeslots.push(
                    element.start_time
                );
                workpointslots[arrayposition].starttimeslots.sort(function(
                    a,
                    b
                ) {
                    return (
                        new Date("1970/01/01 " + a) -
                        new Date("1970/01/01 " + b)
                    );
                });
                workpointslots[arrayposition].endtimeslots.push(
                    element.end_time
                );
                workpointslots[arrayposition].endtimeslots.sort(function(a, b) {
                    return (
                        new Date("1970/01/01 " + a) -
                        new Date("1970/01/01 " + b)
                    );
                });
                lasttimeslot = element;
            });

            let mintime = "23:59";
            let maxtime = "00:00";

            workpointslots.forEach((element) => {
                if (
                    element.starttimeslots.length > 0 &&
                    new Date("1970/01/01 " + element.starttimeslots[0]) <
                        new Date("1970/01/01 " + mintime)
                ) {
                    mintime = element.starttimeslots[0];
                }
                if (
                    element.endtimeslots.length > 0 &&
                    new Date(
                        "1970/01/01 " +
                            element.endtimeslots[
                                element.endtimeslots.length - 1
                            ]
                    ) > new Date("1970/01/01 " + maxtime)
                ) {
                    maxtime =
                        element.endtimeslots[element.endtimeslots.length - 1];
                }
            });

            return {
                mintime: mintime,
                maxtime: maxtime,
                workpointslots: workpointslots,
            };
        },
    },
};
</script>
<style scoped>
.first-sticky-column {
    position: sticky !important;
    left: 0px !important;
    z-index: 1 !important;
    background-color: white;
}
.time-sticky-column {
    position: sticky !important;
    left: 0px !important;
    z-index: 10 !important;
    background-color: white;
    width: 70px !important;
}
.time-sticky-column-0 {
    position: sticky !important;
    left: 0px !important;
    z-index: 10 !important;
    background-color: white;
    width: 70px !important;
}
.timecolumn {
    border: 1px solid rgba(242, 242, 242, 0.66) !important;
    height: 48px;
}
.cellwidth {
    width: 100px;
    height: 50px;
}
table {
    table-layout: fixed;
    border-collapse: collapse;
}
/* .tablethtd{
            min-width: 200px !important;
        } */
/* td{
            height: 46px;
        }
        td:hover{
            background-color: rgba(233, 233, 233, 0.17);
        } */
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}
.disabled-cell {
    background: repeating-linear-gradient(
        144deg,
        rgba(50, 103, 226, 0.1),
        rgba(55, 55, 55, 0.1) 10px,
        transparent 10px,
        transparent 20px
    ) !important;
}
.timeslotheaderrow {
    width: 50px;
    height: 20px;
    z-index: 8 !important;
}
.timeslotheaderrow span {
    float: right;
}
.sortable-chosen {
    background-color: rgba(25, 202, 99, 0.548);
}
.sortable-ghost {
    /* display: none !important; */
    background-color: rgba(92, 11, 11, 0.815);
}

.addNewAppointment:hover {
    background-color: rgba(223, 214, 237, 0.19);
    cursor: cell;
}
.spe {
    pointer-events: none;
    background-color: #f4f4f4ad;
}
.scrollableDayView {
    max-width: 100em;
    max-height: 80%;
    overflow: scroll;
    position: relative;
}

table {
    position: relative;
    border-collapse: collapse;
}

td,
th {
    padding: 0.25em;
}

thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    color: black;
    background-color: white;
}

thead th:first-child {
    left: 0;
    z-index: 1;
}

tbody th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    left: 0;
    background: #fff;
    border-right: 1px solid #ccc;
}
</style>
