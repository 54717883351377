<template>
    <div class="pa-4 border-box">
        <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
                <div class="row">
                    <div class="col">
                        <h2 class="card-title mb-0 pb-4">{{ card.title }}</h2>
                        <!-- <v-card> -->
                            <v-card-text class="px-0 py-0">
                                <v-row>
                                    <v-col class="col-md-12 col-sm-12 pl-0 pb-0">
                                        <v-card height="100%" width="100%" class="mx-auto" :elevation="0">
                                            <span ref="messageCard"></span>
                                            <v-card-title class="mb-2 py-0">
                                                <span style="line-height: 1.5" class="mb-1">
                                                        {{ card.title }}
                                                    <br />
                                                </span>
                                                <v-spacer></v-spacer>

                                                <!-- List name of Participants in a Thread -->
                                            </v-card-title>
                                            <!-- Send Text Message Field in a chat room fixed to the bottom of the Card -->
                                            <div ref="messagesList" id="messages-list">
                                                <v-card-text :key="messagesKey"
                                                    class="flex-grow-1 overflow-y-auto messages-area"
                                                    v-if="!threadFormVisible">

                                                    <v-card v-for="message in messages" :key="message.time" flat>
                                                        <v-list-item :key="message.id">
                                                            <v-list-item-avatar class="align-self-start mr-2">
                                                                <span style="cursor: pointer">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-avatar size="30" class="mr-1" color="primary"
                                                                                v-bind="attrs" v-on="on">
                                                                                <span class="white--text">{{
                                                                                    'SYS'
                                                                                }}</span>
                                                                            </v-avatar>
                                                                        </template>
                                                                        <span>{{ message.user.name }}</span>
                                                                    </v-tooltip>
                                                                </span>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content class="received-message">
                                                                <v-card color="success" class="flex-none">
                                                                    <v-card-text
                                                                        class="white--text pa-2 d-flex flex-column">
                                                                        <!--                              <span class="text-caption">{{message.from}} </span>-->
                                                                        <span
                                                                            class="align-self-start text-subtitle-1 message-text">{{
                                                                                message.body }}</span>
                                                                        <span
                                                                            class="text-caption font-italic align-self-end message-date">{{
                                                                                formatMessageDate(message.created_at)
                                                                            }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-card>

                                                    <span ref="messageEndsHere"></span>
                                                </v-card-text>
                                                <!-- <v-card-text class="flex-grow-1 overflow-y-auto messages-area" v-else>
                                                    <v-form @submit.prevent="updateChannel()">
                                                        <v-text-field :label="$t('messaging_section.subject')"
                                                            v-model="form.subject" class="to-upper" ref="threadSubject"
                                                            id="threadSubject"></v-text-field>

                                                        <v-list-item-group multiple>
                                                            <v-list-item
                                                                v-for="user in company.users.filter(u => u.id !== authUserId)"
                                                                :key="user.id" :value="user.id"
                                                                @click="toggleUserCheckBox(user)">
                                                                <v-list-item-avatar>
                                                                    <v-avatar size="30" color="primary">
                                                                        <span class="white--text">{{ user.short_name
                                                                        }}</span>
                                                                    </v-avatar>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                                                                </v-list-item-content>
                                                                <v-list-item-action>
                                                                    <v-checkbox v-model="form.participants[user.id]"
                                                                        :ref="'user_checkbox_' + user.id"
                                                                        :id="'user_checkbox_' + user.id"
                                                                        style="pointer-events: none"></v-checkbox>
                                                                </v-list-item-action>
                                                            </v-list-item>
                                                        </v-list-item-group>

                                                    </v-form>
                                                </v-card-text> -->
                                                <!-- <v-card-actions class="pb-0">
                                                    <div style="width: 100%" class="d-flex justify-end mt-2"
                                                        v-if="threadFormVisible">
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="grey lighten-2" class="mr-2"
                                                            @click="threadFormVisible = false">
                                                            <v-icon>mdi-keyboard-return</v-icon>
                                                            Cancel
                                                        </v-btn>

                                                        <v-btn type="submit" color="primary" @click="updateChannel()"
                                                            @keyup.enter="updateChannel()">Update
                                                        </v-btn>

                                                    </div>
                                                    <div class="d-flex justify-content-between" style="width: 100%" v-else>
                                                        <v-text-field :label="$t('messaging_section.send_message')"
                                                            ref="messageInput" rows="1" class="my-0 py-0" auto-grow
                                                            clearable v-model="message" hide-details
                                                            @keyup.enter="sendMessage"
                                                            :disabled="!activeThread || messageSending"
                                                            :loading="messageSending">
                                                            <template v-slot:prepend>
                                                                <v-btn icon small class=" ml-1 mb-0 pb-0">
                                                                    <EmojiPickerModal
                                                                        @emojiSelected="(emoji) => handleEmojiSelected(emoji)" />
                                                                </v-btn>
                                                            </template>
                                                        </v-text-field>

                                                        <v-btn icon small class=" ml-1 mt-2 mb-0 pb-0 mr-4"
                                                            @click="sendMessage" :disabled="!activeThread">
                                                            <v-icon>mdi-send</v-icon>
                                                        </v-btn>
                                                    </div>
                                                </v-card-actions> -->
                                            </div>
                                        </v-card>

                                    </v-col>
                                </v-row>
                            </v-card-text>
                        <!-- </v-card> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EmojiPickerModal from "@/components/MessagesThread/EmojiPickerModal.vue";
import axios from 'axios'
import { API_BASE_URL } from '@/config'
import moment from 'moment'
import ConfirmationModel from "@/components/ConfirmationModel.vue";

export default {
    refs: ['messageCard', "messageInput", "messageThreadForm", "messageEndsHere", "messagesList", "threadSubject"],
    components: {
        // ConfirmationModel,
        // EmojiPickerModal
    },
    props:{
        carddata: [String],
        cardfields: [Array],
        card: [Object]
    },
    data() {
        return {
            dialog: false,
            threadName: '',
            threadLoading: false,
            threadFormVisible: false,
            searchThreads: '',
            company: null,
            message: '',
            messageSending: false,
            navKey: 0,
            messagesKey: 0,
            form: {
                subject: '',
                participants: [],
            },
            threads: [],
            notifications: false,
            selectedThread: null,
            confirmationDialog: false,
            confirmationDialogOperation: "",
            confirmationDialogConfirmationText: "",
            confirmationDialogTrueText: "",
            confirmationDialogFalseText: "",
            threadToBeDeleted: null,
            selectedVehicleHistory: null,
            activeThread: null,
            threadMessageKey: 0,
            getMessageInterval: null,
            items: [
                { text: 'Real-Time', icon: 'mdi-clock' },
                { text: 'Audience', icon: 'mdi-account' },
                { text: 'Conversions', icon: 'mdi-flag' },
            ],
            selectedItem: 0,
            messages: [],
            formConfig: {
                headers: {
                    Authorization: "Bearer " + this.$store.state.AccessToken
                }
            },
            carddataProcessed: []
        }
    },
    computed: {
        totalUnreadMessages() {
            if (!this.threads) {
                return 0;
            }

            return this.threads.reduce((acc, thread) => {
                return acc + thread.unread_messages_count;
            }, 0)

        },
        companyUsers() {
            if (!this.company) {
                return;
            }

            return this.company.users.filter(user => user.id !== parseInt(localStorage.getItem('loginUserId')))
        },
        authUserId() {
            return parseInt(localStorage.getItem('loginUserId'))
        }
    },
    watch: {
        dialog(val) {
            this.navKey++;
            if (this.activeThread) {
                this.getThreadMessages(this.activeThread.id).then(() => {
                    this.scrollToEndOfMessages();
                });

            }

            clearInterval(this.getMessageInterval)

            if (val) {
                //Set 5 seconds Interval
                this.getMessageInterval = setInterval(() => this.getUserThreadUpdates(), 5000)
                this.storeInterval(this.getMessageInterval);
            } else {
                // Set 1 Minute Interval
                this.getMessageInterval = setInterval(() => this.getUserThreadUpdates(), 60000)
                this.storeInterval(this.getMessageInterval);
            }
        },
        activeThread(val) {
            if (!val || !this.dialog) return;

            this.getThreadMessages(val.id)
                .then(() => this.scrollToEndOfMessages());
        },
        totalUnreadMessages(val) {
            this.$store.state.totalUnreadMessagesCount = val
        }
    },
    mounted() {
        // Process Card Data
        let indexOfMessage = null;
        let indexOfTime = null;
        if(this.carddata){
            let dataRows = this.carddata.replace(/"/g, '').split("\n");
            let finalData  = [];
            let count = -1;
            let rownumber = 1;
            dataRows.forEach((record) => {
                count++;
                if(count == 0){
                    // return because first row will contain headers
                    return
                }
                let dataInColumns = record.split(";");
                let rowObject = {};
                for (let index = 0; index < this.cardfields.length; index++) {
                    rowObject[index] = dataInColumns[index]
                    if(this.cardfields[index].translation.toLowerCase() == "Message".toLowerCase()){
                        indexOfMessage = index;
                    }
                    if(this.cardfields[index].translation.toLowerCase() == "Time".toLowerCase()){
                        indexOfTime = index;
                    }
                }
                finalData.push(rowObject);
                rownumber++;
            })

            this.carddataProcessed = finalData;
            // Push to Messages
            this.carddataProcessed.forEach((element) => {
                this.messages.push({
                    time: element[indexOfTime],
                    body: element[indexOfMessage],
                    user: {
                        name: "SYS"
                    }
                });
            })
        }

    },
    methods: {
        formatMessageDate(date) {
            // Format date to be like 2021-01-01 HH:MM AM/PM
            // return moment(date).format('YYYY-MM-DD hh:mm A')
            return moment(date).format('hh:mm')
        },
    }
}
</script>

<style scoped>
.messages-area {
    overflow-y: scroll;
    /* height: 20vh; */
    min-height: 200px;
    width: 100%;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.hide-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.messages-area::-webkit-scrollbar {
    display: none;
}

.chat-message {
    display: unset !important;
    white-space: break-spaces;
}

.chat-screen {
    max-height: 320px;
    overflow-y: auto;
}

.flex-none {
    flex: unset;
}

.received-message::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 54px;
    right: auto;
    top: 12px;
    bottom: auto;
    border: 12px solid;
    border-color: #4caf50 transparent transparent transparent;
}

.sent-message::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 54px;
    top: 12px;
    bottom: auto;
    border: 12px solid;
    border-color: #1976d2 transparent transparent transparent;
}

.message-text {
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    line-height: 1 !important;
}

.v-list-item {
    padding-right: 2px !important;
}
</style>
<style>
h2,
h5,
.h2,
.h5 {
    font-family: inherit;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: .5rem;
    color: #32325d;
}

h5,
.h5 {
    font-size: .8125rem;
}

.border-box {
    border: 1px solid #eeeeee;
}

.bg-danger {
    background-color: #f5365c !important;
}



.font-weight-bold {
    font-weight: 600 !important;
    font-size: 30px;
}


a.text-success:hover,
a.text-success:focus {
    color: #24a46d !important;
}

.text-warning {
    color: #fb6340 !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #fa3a0e !important;
}

.text-danger {
    color: #f5365c !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #ec0c38 !important;
}

.text-white {
    color: #fff !important;
}

a.text-white:hover,
a.text-white:focus {
    color: #e6e6e6 !important;
}

.text-muted {
    color: #8898aa !important;
}


figcaption,
main {
    display: block;
}

main {
    overflow: hidden;
}

.bg-yellow {
    background-color: #ffd600 !important;
}

.icon {
    width: 3rem;
    height: 3rem;
}

.icon i {
    font-size: 2.25rem;
}

.icon-shape {
    display: inline-flex;
    padding: 12px;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}
</style>