import { render, staticRenderFns } from "./Vehicle.vue?vue&type=template&id=104514ba&scoped=true"
import script from "./Vehicle.vue?vue&type=script&lang=js"
export * from "./Vehicle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104514ba",
  null
  
)

export default component.exports