var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.computedSchema,"items-per-page":10},on:{"click:row":(item) =>{
      _vm.selectedSchema = item;
      _vm.launchForm();
      }}}),_c('div',{staticClass:"mt-2 text-muted"},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v(" "+_vm._s(_vm.$t('counter_reminder_help'))+" ")],1),_c('span',{attrs:{"id":"new-schema"},on:{"click":() => {
    _vm.selectedSchema = null;
    _vm.launchForm();
  }}},[_c('order-reminder-schema-form',{attrs:{"order-reminder-schema":_vm.selectedSchema},on:{"created":_vm.getOrderReminderSchemas,"updated":_vm.getOrderReminderSchemas},model:{value:(_vm.formModal),callback:function ($$v) {_vm.formModal=$$v},expression:"formModal"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }