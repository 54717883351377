
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {

    },
    watch:{
        parent_company_client_name:{
            handler: function(val){
                if(val && val.id && val.id == 28093){ // Client 28093 = POINT S BELUX \ ALD AUTOMOTIVE AYVENS
                    this.familyArticles.forEach(element => {
                        if(element.id == 247){ // Family Article ID = ALD
                            this.selectedFamilyArticle = element;
                            // Trigger Fetch Article Suggested List
                            this.$nextTick(() => {
                                if(this.$refs["searchArticleComp"]){
                                    this.$refs["searchArticleComp"].fetchArticlesSuggesList(true)
                                }
                            });
                        }
                    });
                }
            },
            immediate: true
        }
    }
}