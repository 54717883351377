<template>
    <div
        :row="row.index"
        :appointmentid="appointment.data.id"
        :column="colindex - 1"
        class="draggableObject"
        :class="addClass(row, colindex)"
        :style="addTaskStyle(row.items[colindex - 1])"
        v-if="!hideForSomeTime"
    >
        <div class="draggableObjectData">
            <div @click="editAppointment(row.index, colindex - 1)">
                <span class="text-sm-left">
                    <template v-if="appointment.data">
                        <template
                            v-if="
                                appointment.data.client &&
                                    appointment.data.client.name
                            "
                        >
                            <v-icon
                                style="font-size:20px"
                                color="white"
                                v-if="
                                    appointment &&
                                        appointment.data &&
                                        appointment.data.appointment_status &&
                                        appointment.data.appointment_status
                                            .key == 'appointment_is_executed'
                                "
                                >mdi-account-check</v-icon
                            >

                            {{ appointment.data.client.name }}
                            {{
                                appointment.data.client.first_name
                                    ? appointment.data.client.first_name
                                    : ""
                            }}
                            <span
                                v-if="thirdIconIsVisible(appointment)"
                                :style="{
                                    backgroundColor: '#fff',
                                    borderRadius: '2px',
                                    float: 'right',
                                    position: 'absolute',
                                    right: '0',
                                    top: '0',
                                    marginTop: '3px',
                                    marginBottom: '2px',
                                    marginRight: calculateIconRightMargin(
                                        'third',
                                        appointment
                                    ),
                                    height: '18.5px',
                                    width: '18.5px',
                                    textAlign: 'center',
                                }"
                            >
                                <v-icon
                                    :style="{
                                        fontSize: '15px',
                                        paddingBottom: '2px',
                                    }"
                                    color="red"
                                    >mdi-bell-cancel</v-icon
                                >
                            </span>
                            <!-- Icon for External Order Status -->
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-on="on"
                                        v-bind="attrs"
                                        v-if="
                                            appointment &&
                                                appointment.data &&
                                                appointment.data.order_id
                                        "
                                        :style="{
                                            float: 'right',
                                            position: 'absolute',
                                            right: '0',
                                            top: '0',
                                            marginRight: calculateIconRightMargin(
                                                'fourth',
                                                appointment
                                            ),
                                            marginTop: '3px',
                                            marginBottom: '2px',
                                            backgroundColor: '#fff',
                                            borderRadius: '2px',
                                            height: '18.5px',
                                            width: '18.5px',
                                            textAlign: 'center',
                                        }"
                                    >
                                        <template
                                            v-if="
                                                appointment &&
                                                    appointment.data &&
                                                    appointment.data
                                                        .external_order_status &&
                                                    appointment.data
                                                        .external_order_status !==
                                                        null
                                            "
                                        >
                                            <v-icon
                                                :color="
                                                    computeExternalOrderStatus(
                                                        appointment.data
                                                            .external_order_status
                                                    ).color
                                                "
                                                :style="{
                                                    fontSize: '15px',
                                                    paddingBottom: '2px',
                                                }"
                                            >
                                                {{
                                                    computeExternalOrderStatus(
                                                        appointment.data
                                                            .external_order_status
                                                    ).icon
                                                }}
                                            </v-icon>
                                        </template>
                                        <!-- Show icon for all other if order is their -->
                                        <template v-else>
                                            <v-icon
                                                :color="
                                                    computeExternalOrderStatus(
                                                        'success'
                                                    ).color
                                                "
                                                :style="{
                                                    fontSize: '15px',
                                                    paddingBottom: '2px',
                                                }"
                                            >
                                                {{
                                                    computeExternalOrderStatus(
                                                        "success"
                                                    ).icon
                                                }}
                                            </v-icon>
                                        </template>
                                    </span>
                                </template>
                                <span v-if="suppliersPresent">
                                    {{ suppliersPresent }}
                                </span>
                                <span v-else>
                                    {{
                                        $t(
                                            "scheduler.text_messages.all_articles_from_internal_or_received"
                                        )
                                    }}
                                </span>
                            </v-tooltip>
                            <!-- Icon for External Order Status Ends Here -->

                            <!-- Icon for Client Tyre Hotel Ended -->
                            <span
                                v-if="
                                    appointment &&
                                        appointment.data &&
                                        appointment.data
                                            .is_end_of_client_tyre_hotel_article
                                "
                                :style="{
                                    float: 'right',
                                    position: 'absolute',
                                    right: '0',
                                    top: '0',
                                    marginRight: calculateIconRightMargin(
                                        'sixth',
                                        appointment
                                    ),
                                    marginTop: '3px',
                                    marginBottom: '2px',
                                    backgroundColor: '#fff',
                                    borderRadius: '2px',
                                    height: '18.5px',
                                    width: '18.5px',
                                    textAlign: 'center',
                                }"
                            >
                                <v-icon
                                    color="red"
                                    :style="{
                                        fontSize: '15px',
                                        paddingBottom: '2px',
                                    }"
                                >
                                    mdi-clock-end
                                </v-icon>
                            </span>

                            <!-- Icon for More Details of Appointment -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-on="on"
                                        v-bind="attrs"
                                        @click.stop="
                                            showAppointmentDetail(appointment)
                                        "
                                        v-if="
                                            appointment &&
                                                appointment.data &&
                                                appointment.data.order_id
                                        "
                                        :style="{
                                            float: 'right',
                                            position: 'absolute',
                                            right: '0',
                                            bottom: '0',

                                            marginRight: '2px',
                                            marginBottom: '2px',
                                            backgroundColor: 'transparent',
                                            color: '#fff',
                                            borderRadius: '2px',
                                            textAlign: 'center',
                                        }"
                                    >
                                        <appointment-details
                                            v-if="showAppointmentDetails"
                                            :showDialog.sync="
                                                showAppointmentDetails
                                            "
                                            :data="appointment"
                                        ></appointment-details>
                                        <v-icon
                                            color="white"
                                            :style="{
                                                fontSize: '18.5px',
                                                paddingBottom: '2px',
                                            }"
                                        >
                                            mdi-information
                                        </v-icon>
                                    </span>
                                </template>
                                <template
                                    v-if="
                                        appointment &&
                                            appointment.data &&
                                            appointment.data.order_id
                                    "
                                >
                                    <template
                                        v-if="
                                            appointment.data.order_type_id ==
                                                10241000
                                        "
                                    >
                                        {{ $t("billings.types.estimation") }}
                                    </template>
                                    <template
                                        v-if="
                                            appointment.data.order_type_id ==
                                                10241001
                                        "
                                    >
                                        {{
                                            $t("billings.types.purchase_order")
                                        }}
                                    </template>
                                    <template
                                        v-if="
                                            appointment.data.order_type_id ==
                                                10241002
                                        "
                                    >
                                        {{ $t("billings.types.bill") }}
                                    </template>
                                    <template
                                        v-if="
                                            appointment.data.order_type_id ==
                                                10241003
                                        "
                                    >
                                        {{ $t("billings.types.credit_note") }}
                                    </template>
                                </template>
                                <template v-else>
                                    {{
                                        $t(
                                            "scheduler.text_messages.appointment_detail"
                                        )
                                    }}
                                </template>
                            </v-tooltip>

                            <!-- Icon for Copy Appointment -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-on="on"
                                        v-bind="attrs"
                                        class="appointmentCut"
                                        @click.stop="
                                            appointmentToPaste &&
                                            appointmentToPaste == appointment
                                                ? cancelAppointment()
                                                : copyAppointment(appointment)
                                        "
                                        v-if="appointment && appointment.data"
                                        :style="{
                                            float: 'right',
                                            position: 'absolute',
                                            right: '0',
                                            bottom: '0',

                                            marginRight: '24px',
                                            marginBottom: '2px',
                                            backgroundColor: 'transparent',
                                            color: '#fff',
                                            borderRadius: '2px',
                                            textAlign: 'center',
                                        }"
                                    >
                                        <v-icon
                                            color="white"
                                            :style="{
                                                fontSize: '18.5px',
                                                paddingBottom: '2px',
                                            }"
                                        >
                                            {{
                                                appointmentToPaste &&
                                                appointmentToPaste ==
                                                    appointment
                                                    ? "mdi-arrow-all"
                                                    : "mdi-content-cut"
                                            }}
                                        </v-icon>
                                    </span>
                                </template>
                                {{
                                    appointmentToPaste &&
                                    appointmentToPaste == appointment
                                        ? $t(
                                              "scheduler.text_messages.cancel_appointment_move"
                                          )
                                        : $t(
                                              "scheduler.text_messages.move_appointment"
                                          )
                                }}
                            </v-tooltip>

                            <!-- Icon for Order Payed -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-on="on"
                                        v-bind="attrs"
                                        v-if="
                                            appointment &&
                                                appointment.data &&
                                                appointment.data
                                                    .order_status_id == 10251003 // Payed
                                        "
                                        :style="{
                                            float: 'right',
                                            position: 'absolute',
                                            right: '0',
                                            top: '0',
                                            marginRight: calculateIconRightMargin(
                                                'seventh',
                                                appointment
                                            ),
                                            marginTop: '3px',
                                            marginBottom: '2px',
                                            backgroundColor: '#fff',
                                            borderRadius: '2px',
                                            height: '18.5px',
                                            width: '18.5px',
                                            textAlign: 'center',
                                        }"
                                    >
                                        <v-icon
                                            color="green"
                                            :style="{
                                                fontSize: '15px',
                                                paddingBottom: '2px',
                                            }"
                                        >
                                            mdi-cash-check
                                        </v-icon>
                                    </span>
                                </template>
                                {{ $t("scheduler.text_messages.order_payed") }}
                                <template
                                    v-if="
                                        appointment &&
                                            appointment.data &&
                                            appointment.data.payments
                                    "
                                >
                                    {{
                                        generatePaymentText(
                                            appointment.data.payments
                                        )
                                    }}
                                </template>
                            </v-tooltip>

                            <!-- Icon for Email Sent -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        @click.stop="
                                            openEmailHistoryDialog(appointment)
                                        "
                                        v-on="on"
                                        v-bind="attrs"
                                        v-if="
                                            appointment &&
                                                appointment.data &&
                                                appointment.data.email_to
                                        "
                                        :style="{
                                            float: 'right',
                                            position: 'absolute',
                                            right: '0',
                                            top: '0',
                                            marginRight: calculateIconRightMargin(
                                                'eighth',
                                                appointment
                                            ),
                                            marginTop: '3px',
                                            marginBottom: '2px',
                                            backgroundColor: '#fff',
                                            borderRadius: '2px',
                                            height: '18.5px',
                                            width: '18.5px',
                                            textAlign: 'center',
                                        }"
                                    >
                                        <v-icon
                                            color="green"
                                            :style="{
                                                fontSize: '15px',
                                                paddingBottom: '2px',
                                            }"
                                        >
                                            mdi-email-arrow-right-outline
                                        </v-icon>
                                    </span>
                                </template>
                                {{ $t("scheduler.text_messages.email_sent") }}
                            </v-tooltip>
                        </template>

                        <template v-if="appointment.data.vehicle">
                            <template
                                v-if="
                                    appointment.data.client.name &&
                                        appointment.data.client.name.length <=
                                            22
                                "
                            >
                                <br />
                            </template>
                            <template v-else>
                                -
                            </template>

                            <template
                                v-if="
                                    appointment.data.vehicle.brand_id &&
                                        appointment.data.vehicle.brand
                                "
                            >
                                {{ appointment.data.vehicle.brand.name }}
                            </template>
                            <template v-if="appointment.data.vehicle.model">
                                {{ appointment.data.vehicle.model }} /
                            </template>
                            {{
                                appointment.data.vehicle.registration_number.includes(
                                    "#UNKNOWN"
                                )
                                    ? $t("unknown_reg_no")
                                    : appointment.data.vehicle
                                          .registration_number
                            }}
                        </template>
                    </template>
                </span>
                <span class="text-sm-left" v-if="appointment.data.note">
                    <br />
                    <strong>{{ $t("scheduler.appointment.note") }}</strong>
                    {{ appointment.data.note }}
                </span>
                <span
                    class="original_st"
                    v-if="
                        appointment.data &&
                            appointment.data.original_start_time &&
                            appointment.data.original_start_time !=
                                appointment.data.start_time
                    "
                >
                    <v-icon color="white" v-if="firstIconIsVisible(appointment)"
                        >mdi-code-greater-than</v-icon
                    >
                    <v-icon
                        color="white"
                        :style="{
                            marginRight: calculateIconRightMargin(
                                'second',
                                appointment
                            ),
                        }"
                        v-if="secondIconIsVisible(appointment)"
                        >mdi-code-less-than</v-icon
                    >
                </span>
                <span
                    :style="{
                        marginRight: calculateIconRightMargin(
                            'fifth',
                            appointment
                        ),
                        backgroundColor: '#fff',
                        borderRadius: '2px',
                        height: '18.5px',
                        width: '18.5px',
                        textAlign: 'center',
                    }"
                    style="margin-top: 2px"
                    class="original_st"
                    v-if="
                        appointment.data &&
                            appointment.data.depot_out_status_key
                    "
                >
                    <DepotStatus
                        size="15px"
                        :status-key="appointment.data.depot_out_status_key"
                    />
                </span>
            </div>
            <!-- <div class="bottom-box">
                <template v-if="isBill(row, colindex)">
                    <v-btn icon color="rgb(18, 170, 31)" @click="editAppointment(row.index, colindex-1)">
                        <v-icon>mdi-check-bold</v-icon>
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn v-if="! isPastDate()" icon color="white" @click="deleteAppointment(row.index, colindex-1, row)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn icon color="white" @click="editAppointment(row.index, colindex-1)">
                        <v-icon>mdi-calendar-edit</v-icon>
                    </v-btn>
                </template>
                
            </div> -->
        </div>
        <span id="cancel-move" class="d-none" @click="cancelAppointment"></span>

        <OrderEmailHistory
            :order="selectedAppointmentForDialog || {}"
            v-model="emailHistoryDialog"
        />
    </div>
</template>
<script>
import DepotStatus from "@/components/TyreHotel/DepotStatus.vue";
import AppointmentDetails from "./AppointmentDetails.vue";
import ToastSchedulerMoveConfirmation from "@/components/ToastSchedulerMoveConfirmation.vue";
import OrderEmailHistory from "@/components/Emails/OrderEmailHistory.vue";

export default {
    components: { DepotStatus, AppointmentDetails, OrderEmailHistory },
    props: {
        rows: [Array],
        otherAppointmentCounts: [Number],
        appointmentIndexInSameSlot: [Number],
        appointment: [Object],
        row: [Object],
        colindex: [Number],
        noofcolumns: [Number],
    },
    data() {
        return {
            maxWidth: 176,
            showAppointmentDetails: false,
            emailHistoryDialog: false,
            hideForSomeTime: false,
            selectedAppointmentForDialog: null,
        };
    },
    mounted() {
        // this.maxWidth = this.getDayViewWidth() / this.noofcolumns;
    },
    computed: {
        allSuppliers() {
            return this.$store.state.allSuppliers ?? [];
        },
        appointmentToPaste() {
            return this.$store.state.copiedAppointment;
        },
        suppliersPresent() {
            let suppliersPresentInTheArticles = "";
            this.appointment.data.supplier_orders.forEach((element) => {
                let supplier_identifier = "";
                if (
                    element.supplier_id &&
                    this.allSuppliers &&
                    this.allSuppliers.length > 0
                ) {
                    this.allSuppliers.forEach((supplier) => {
                        if (supplier.id == element.supplier_id) {
                            supplier_identifier = supplier.name;
                        }
                    });
                }
                suppliersPresentInTheArticles += supplier_identifier + ", ";
            });
            if (suppliersPresentInTheArticles.length > 1) {
                suppliersPresentInTheArticles = suppliersPresentInTheArticles.substring(
                    0,
                    suppliersPresentInTheArticles.length - 2
                );
            }
            return suppliersPresentInTheArticles;
        },
    },
    methods: {
        generatePaymentText(payments) {
            let textString = " ";
            if (payments && payments.length > 0) {
                payments.forEach((element) => {
                    textString +=
                        this.getPaymentMethodById(element.payed_by_id) +
                        "(" +
                        element.amount +
                        "), ";
                });
            }
            if (textString.length > 2) {
                textString = textString.substring(0, textString.length - 2);
            }
            return textString;
        },
        openEmailHistoryDialog(appointment) {
            this.selectedAppointmentForDialog = appointment.data.order_id;
            this.emailHistoryDialog = false;
            setTimeout(() => {
                this.emailHistoryDialog = true;
                clearTimeout(this.timerId);
            }, 20);
        },
        cancelAppointment() {
            this.$toast.clear();
            // Set Appointment to null
            this.$store.commit("setCopiedAppointment", null);
        },
        copyAppointment(appointment) {
            this.$toast.clear();
            this.$store.commit("setCopiedAppointment", appointment);
            this.$toast(
                {
                    component: ToastSchedulerMoveConfirmation,
                    props: {
                        message: this.$t(
                            "scheduler.text_messages.appointment_ready_to_move"
                        ),
                        buttonText: this.$t(
                            "scheduler.text_messages.cancel_move"
                        ),
                    },
                    on: {
                        "close-toast": () => {
                            this.$toast.goAway(0); // Close the toast immediately
                        },
                    },
                },
                {
                    timeout: 500000,
                }
            );
        },
        showAppointmentDetail() {
            this.showAppointmentDetails = true;
        },
        firstIconIsVisible(appointment) {
            return (
                appointment.data.original_start_time <
                appointment.data.start_time
            );
        },
        secondIconIsVisible(appointment) {
            return (
                appointment.data.original_start_time >
                appointment.data.start_time
            );
        },
        thirdIconIsVisible(appointment) {
            return (
                appointment &&
                appointment.data &&
                appointment.data.appointment_status &&
                appointment.data.appointment_status.key ==
                    "appointment_canceled"
            );
        },
        fourthIconIsVisible(appointment) {
            // return (
            //     appointment &&
            //     appointment.data &&
            //     appointment.data.external_order_status &&
            //     appointment.data.external_order_status !== null
            // );
            return appointment && appointment.data && appointment.data.order_id;
        },

        fifthIconIsVisible(appointment) {
            return (
                appointment &&
                appointment.data &&
                appointment.data.depot_out_status_key
            );
        },

        sixthIconIsVisible(appointment) {
            return (
                appointment &&
                appointment.data &&
                appointment.data.is_end_of_client_tyre_hotel_article
            );
        },

        seventhIconIsVisible(appointment) {
            return (
                appointment &&
                appointment.data &&
                appointment.data.order_status_id == 10251003 // Payed
            );
        },

        calculateIconRightMargin(position, appointment) {
            let increment = 25;
            let initial = 2;
            if (position == "second") {
                return this.firstIconIsVisible(appointment)
                    ? "22px"
                    : initial + "px";
            }

            if (position == "third") {
                let rightMargin = initial;
                if (this.firstIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                if (this.secondIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                return rightMargin + "px";
            }

            if (position == "fourth") {
                let rightMargin = 2;
                if (this.firstIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                if (this.secondIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                if (this.thirdIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                return rightMargin + "px";
            }

            if (position === "fifth") {
                let rightMargin = 2;
                if (this.firstIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                if (this.secondIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                if (this.thirdIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                if (this.fourthIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                return rightMargin + "px";
            }

            // sixth position
            if (position === "sixth") {
                let rightMargin = 2;
                if (this.firstIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                if (this.secondIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                if (this.thirdIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                if (this.fourthIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                if (this.fifthIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                return rightMargin + "px";
            }

            // seventh position
            if (position === "seventh") {
                let rightMargin = 2;
                if (this.firstIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                if (this.secondIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                if (this.thirdIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                if (this.fourthIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                if (this.fifthIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                if (this.sixthIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                return rightMargin + "px";
            }

            // eighth position
            if (position === "eighth") {
                let rightMargin = 2;
                if (this.firstIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                if (this.secondIconIsVisible(appointment)) {
                    rightMargin += increment;
                }
                if (this.thirdIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                if (this.fourthIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                if (this.fifthIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                if (this.sixthIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                if (this.seventhIconIsVisible(appointment)) {
                    rightMargin += increment;
                }

                return rightMargin + "px";
            }

            return initial + "px";
        },
        computeExternalOrderStatus(status) {
            if (status == "success") {
                return {
                    icon: "mdi-check-bold",
                    color: "green",
                };
            } else if (status == "in_progress") {
                return {
                    icon: "mdi-timer-sand",
                    color: "orange",
                };
            } else if (status == "error") {
                return {
                    icon: "mdi-information",
                    color: "red",
                };
            } else {
                return {
                    icon: "mdi-timer-sand",
                    color: "red",
                };
            }
        },
        addClass(row, colindex) {
            let classToBeAdded = this.isBill(row, colindex) ? "bill" : "";
            if (
                this.appointment &&
                this.appointment.data &&
                this.appointment.data.appointment_status &&
                this.appointment.data.appointment_status.key ==
                    "appointment_not_executed"
            ) {
                classToBeAdded += " not-executed";
            }
            return classToBeAdded;
        },
        isBill(row, colindex) {
            return row.items[colindex - 1].data && row.items[colindex - 1].data
                ? row.items[colindex - 1].data.order_type_id == 10241002
                    ? true
                    : false
                : false;
        },
        addTaskStyle(task) {
            let unitAppointmentMaxWidth = this.maxWidth;
            if (this.otherAppointmentCounts > 0) {
                unitAppointmentMaxWidth =
                    this.maxWidth / this.otherAppointmentCounts;
            }

            let style = {
                height: `${this.appointment.height}px`,
                width: `${unitAppointmentMaxWidth}px`,
                "margin-left": `${unitAppointmentMaxWidth *
                    this.appointmentIndexInSameSlot}px`,
                "background-color": `#${this.appointment.data.color}`,
            };

            return style;
        },
        editAppointment(rowindex, columnindex) {
            this.$store.commit("setAppointmentRedirectData", {
                id: this.appointment.data.id,
                status: this.appointment.data.appointment_status,
            });
            this.$router.push("/scheduler/edit").catch(() => {});
        },
    },
    watch: {
        appointment: {
            handler: function(val) {
                this.hideForSomeTime = true;
                this.$nextTick(() => {
                    this.hideForSomeTime = false;
                });
            },
        },
    },
};
</script>
<style scoped>
.draggableObject {
    position: absolute;
    /* background-color: rgba(67, 180, 52, 0.733); */
    z-index: 1;
    min-height: 20px;
    margin-top: -25px;
    border: 2px solid white;
    /* width: 200px !important; */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    cursor: move !important;
    overflow: hidden;
}

.draggableObject:hover {
    box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.41);
    transform: scale(1.01, 1.01);
    z-index: 9;
    /* overflow: auto !important; */
}
.appointmentCut {
    display: none;
}
.draggableObject:hover .appointmentCut {
    display: block;
}

.draggableObject:hover > .draggableObjectData > .bottom-box {
    z-index: 9;
    transition: all 0.7s ease;
    background-color: rgba(12, 0, 0, 0.021);
    opacity: 1;
    bottom: 0px;
    right: 0px;
}

.draggableObjectData {
    color: white;
    margin: 10px;
    font-weight: 400;
    line-height: 18px;
    font-size: 14px;
    margin: 5px;
}

.draggableObjectData:hover {
    cursor: pointer;
}

.bill {
    border: 4px solid rgb(18, 170, 31);
}

.bottom-box {
    opacity: 0;
    position: absolute;
    bottom: -10px;
    right: -10px;
}

.not-executed {
    background-color: #ea9191 !important;
    border: 3px solid red;
}

.original_st {
    position: absolute;
    top: 0px;
    float: right;
    right: 0px;
    color: white;
}
</style>
