<template>
    <div class="text-start" v-if="value">
        {{ value }}
        <v-icon :color="'green'" :size="14" @click.stop="copyText(value)">
            mdi-content-copy
        </v-icon>
    </div>
</template>
<script>
export default {
    props: {
        value: [String],
    },
    data: () => ({}),
};
</script>
<style scoped>
.menu-content-comp{
    display: flex;
    padding: 5px;
    padding-left: 8px;
}
</style>
