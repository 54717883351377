<template>
  <div>
  <v-data-table
      :headers="headers"
      :items="vehicleHistories"
      :server-items-length="totalItems"
      :items-per-page="perPage"
      @update:page="(page) => this.page = page"
      class="elevation-1"
      @click:row="handleRowClick"
      >
    <template v-slot:item.serial="{ item, index }">
      <span>{{ index + 1 }}</span>
    </template>

    <template v-slot:item.date_time="{ item }">
      <span>{{ formatDateWithLocale(item.date_time) }}</span>
    </template>


    <template v-slot:item.action="{ item}">
      <v-icon color="red" @click="deleteVehicleHistoryPopup(item)">mdi-delete</v-icon>
    </template>
  </v-data-table>
    <confirmation-model
        v-if="confirmationDialog"
        :showDialog.sync="confirmationDialog"
        :openedForOperation.sync="confirmationDialogOperation"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="(status) => status ? deleteVehicleHistory() : ''"
    ></confirmation-model>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";
import ConfirmationModel from "@/components/ConfirmationModel";

export default {
  name: "VehicleHistory",
  components: {
    ConfirmationModel,
  },
  props: {
    vehicle: [Object],
    search: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      headers: [{
        text: this.$t("vehicles.vehicle_tab.s_n"),
        align: "start",
        value: "serial",
        width: "5px"
      },
        {
          text: this.$t("vehicles.vehicle_tab.title"),
          value: "title",
          align: "start"
        },
        {
          text: this.$t("vehicles.vehicle_tab.km"),
          value: "km",
          align: "start"
        },
        {
          text: this.$t("vehicles.vehicle_tab.description"),
          value: "description",
          align: "start",
          width: "60%"
        },
        {
          text: this.$t("vehicles.vehicle_tab.date"),
          value: "date_time",
          align: "start"
        },
        {
          text: this.$t("vehicles.vehicle_tab.action"),
          align: "end",
          value: "action",
        }
        ],
      vehicleHistories: [],
      formConfig: {
        headers: {
          Authorization: "Bearer " + this.$store.state.AccessToken,
        },
      },
      page: 1,
      confirmationDialog: false,
      confirmationDialogOperation: "",
      confirmationDialogConfirmationText: "",
      confirmationDialogTrueText: "",
      confirmationDialogFalseText: "",
      selectedVehicleHistory: null,
      totalItems: 0,
      perPage:30

    };
  },
  computed: {},
  mounted() {
    this.getVehicleHistories();
  },
  watch: {
    search() {
      this.getVehicleHistories();
    },
    page() {
      this.getVehicleHistories();
    },
  },
  methods: {
    handleRowClick(item){
      this.editVehicleHistory(item)
    },
    getVehicleHistories(){
      let formConfig = this.formConfig;
      formConfig.signal = this.abortController()
      let url = `${API_BASE_URL}/vehicle_history?page=${this.page}&vehicle_id=${this.vehicle.id}&search=${this.search}&per_page=${this.perPage}`
      axios.get(url, formConfig).then((response) => {
        this.vehicleHistories = response.data.data;
        this.totalItems = response.data.meta.total;
      });
    },
    editVehicleHistory(vehicle){
      if(this.confirmationDialog){
        return;
      }

      this.$emit("editVehicleHistory", vehicle);
  },
    deleteVehicleHistory(){
      

      axios.delete(`${API_BASE_URL}/vehicle_history/${this.selectedVehicleHistory?.id}`, this.formConfig)
          .finally(() => {
        
          })
          .then((response) => {
        this.$toast.success(this.$t("vehicles.vehicle_tab.delete_success"));
        this.getVehicleHistories();
      });
    },
    deleteVehicleHistoryPopup(vehicleHistory){
      this.selectedVehicleHistory = vehicleHistory;
      this.confirmationDialogConfirmationText = this.$t("vehicles.vehicle_tab.delete_confirmation");
      this.confirmationDialogFalseText = this.$t("navigation.no");
      this.confirmationDialogTrueText = this.$t("navigation.yes");
      this.confirmationDialogOperation = "deleteVehicleHistory";
      this.confirmationDialog = true;
    },
  },
};
</script>