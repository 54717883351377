<template>
  <div v-if="trackingDevice">
    <v-simple-table>
      <template v-slot:default>
        <tbody v-for="info in vehicleInformation" :key="info.label">
        <tr>
          <td>{{ info.label }}</td>
          <td>{{ info.value }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import axios from "axios"
import {API_BASE_URL} from "@/config";
import moment from "moment";

export default {
  name: "VehicleLiveInformation",
  props: {
    vehicle: {
      type: Object,
      required: true
    },
    parentTrackingDevice: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      trackingDevice: null,
      axiosOptions: {
        headers: {
          Authorization: "Bearer " + this.$store.state.AccessToken
        }
      }
    }
  },
  computed: {
    vehicleInformation() {
      if (!this.trackingDevice) {
        return [];
      }

      return [
        {
          label: this.$t('vehicles.live_location.status'),
          value: this.trackingDevice.status
        },
        {
          label: this.$t('vehicles.live_location.car_in_motion'),
          value: this.trackingDevice.position.attributes.motion ? "Yes" : "No"
        },
        {
          label: this.$t('vehicles.live_location.last_updated'),
          value: moment(this.trackingDevice.lastUpdate).format("DD/MM/YYYY hh:mm:ss")
        },
        {
          label: this.$t('vehicles.live_location.total_distance_travelled'),
          value: (this.trackingDevice.position.attributes.totalDistance / 1000).toFixed(2) + ' KM'
        },
        {
          label: this.$t('vehicles.live_location.recent_distance_travelled'),
          value: (this.trackingDevice.position.attributes.distance / 1000).toFixed(2) + ' KM'
        },
        {
          label: this.$t('vehicles.live_location.device_battery_level'),
          value: this.trackingDevice.position.attributes.batteryLevel
        },
        {
          label: this.$t('vehicles.live_location.car_speed'),
          value: Math.round(this.trackingDevice.position.speed)+ ' KM/H'
        },
        {
          label: this.$t('vehicles.live_location.car_address'),
          value: this.trackingDevice.address
        },
        {
          label: this.$t('vehicles.live_location.car_coordinates'),
          value: 'LAT ' + this.trackingDevice.position.latitude + ', LNG ' + this.trackingDevice.position.longitude
        }
      ]
    }
  },
  watch: {
    // trackingDevice() {
    //   this.$emit('trackingDeviceUpdated', this.trackingDevice);
    // },
    parentTrackingDevice(val) {
      if (val) {
        this.trackingDevice = val;
      }
    }
  },
  // async mounted() {
  //   if (this.parentTrackingDevice) {
  //     this.trackingDevice = this.parentTrackingDevice;
  //   } else {
  //     this.trackingDevice = await this.getDevice(this.vehicle.tracking_device.serial);
  //     this.$emit('trackingDeviceUpdated', this.trackingDevice);
  //   }
  // },
  methods: {
    getDevice(deviceId) {
      return new Promise((resolve, reject) => {
        
        return axios.get(API_BASE_URL + '/tracking-devices/' + deviceId, this.axiosOptions)
            .finally(() => {
              
            }).then(({data}) => {
              resolve(data)
            }).catch((error) => {
              console.log(error.response)
            })

      });
    }
  }
}
</script>