<template>                       
    <v-dialog class="dialogPopUp" v-model="modify_stock_dialog" @click:outside="closeTyreDialog()" @keydown.esc="closeTyreDialog()" persistent max-width="1024px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ modify_title }}</span>
            </v-card-title>
            <v-card-text @click="modify_data = true">
                <!-- Localisation, Wheel, Note -->
                <v-row>
                    <!-- Localisation -->
                    <v-text-field  
                        flat
                        :autocomplete="autocompleteValueLocal"
                        v-model="localisation"
                        class="mr-2 to-upper"
                        :label="this.$t('tyre_hotel.add_modify_tyre.localisation')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-text-field>
                    <!-- Wheel -->
                    <v-autocomplete
                        :autocomplete="autocompleteValueLocal"
                        flat
                        v-model="wheel_type"
                        :items="wheel_types"
                        :label="$t('tyre_hotel.add_modify_tyre.wheel')"
                        class="mr-2 to-upper"
                        item-value="id"
                        item-text="text"
                        @click:append="wheel_type = null"
                        append-icon="mdi-close"
                        return-object
                        :outlined="is_boxes"
                        :dense="is_boxes"
                        hide-no-data
                    ></v-autocomplete>
                    <!-- Note -->
                    <v-text-field  
                        flat
                        :autocomplete="autocompleteValueLocal"
                        v-model="note"
                        class="mr-2 to-upper"
                        :label="this.$t('tyre_hotel.add_modify_tyre.note')"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-text-field>
                </v-row>
                <!-- Front Tyre Article -->
                <v-row class="mt-6">
                    <!-- Text : Front Tyre -->
                    <v-col cols="12" sm="1" class="ma-0 pa-0 mt-4 pl-3">
                        <v-row  class=" ">
                            <span style="font-size:18px">
                                    {{ $t('tyre_hotel.add_modify_tyre.front') }} 
                            </span>
                        </v-row>
                    </v-col>
                    <!-- Font Tyre Search Component -->
                    <v-col cols="12" sm="10" class="ma-0 pa-0 mt-n4">
                        <tyre-hotel-search-article
                                :class="front_selected_article ? 'articleset': 'articlenotset'"
                                @changeDialog="changeDialog()" 
                                :compSelectedArticleId.sync="frontParentSearchSelectedArticleId"
                                :internalStockOnly.sync="parentInternalStockOnly"
                                ref="frontSearchArticle"
                        ></tyre-hotel-search-article>
                        <div class="v-messages theme--light error--text mt-n8"> 
                            {{ $store.state.validation && !$v.frontParentSearchSelectedArticleId.required ? this.$t('tyre_hotel.add_modify_tyre.front_article_required') : '' }}
                        </div>
                    </v-col>
                    <v-col cools="12" sm="1" class="ma-0 pa-0" v-if="frontParentSearchSelectedArticleId && !front_2_tyres">
                        <v-tooltip  bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" color="primary" @click="addAnotherTyre(true, false)">
                                    <v-icon dark>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                {{ $t('add_another_tyre') }}
                            </span> 
                        </v-tooltip>
                    </v-col>
                    <v-col cools="12" sm="1" class="ma-0 pa-0" v-if="frontParentSearchSelectedArticleId && front_2_tyres" >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" color="error" @click="removeTyres(true, false)">
                                    <v-icon dark>mdi-minus</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                {{ $t('remove_another_tyre') }}
                            </span> 
                        </v-tooltip>
                    </v-col>
                </v-row>
                <!-- Tyre Properties and Image -->
                <v-row class="mt-4 mb-4">
                    <v-col cols="12" sm="4" :class="back_2_tyres ? 'ml-4' : 'ml-8'" align="right">       
                        <!-- First Column : Front Left Tyres -->                 
                        <v-row class="mt-4">
                            <v-col>
                                <!-- Font Left Tyre Active_&_mm -->
                                <v-row>
                                    <v-col class="pa-0">
                                        <v-btn-toggle style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;" v-model="front_left.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="front_left.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.front_left') }} </span>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                    <v-col class="pa-0" cols="12" sm="3">
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: 0px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_left.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 mr-2 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation ?  returnErrorMessage(
                                                [!$v.front_left.mm.maxValue,this.$t('tyre_hotel.add_modify_tyre.mm_max_value')],
                                                [!$v.atLeastOneShouldBeActive.required, this.$t('tyre_hotel.add_modify_tyre.at_least_one_should_be_active')]
                                            ) : []"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <!-- Front Left Tyre (2) Active_&_mm -->
                                <v-row v-if="front_2_tyres">
                                    <v-col class="pa-0">
                                        <v-btn-toggle style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;" v-model="front_left_2.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="front_left_2.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.front_left_2') }} </span>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                    <v-col class="pa-0" cols="12" sm="3">
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: 0px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_left_2.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 mr-2 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation ?  returnErrorMessage(
                                                [!$v.front_left_2.mm.maxValue,this.$t('tyre_hotel.add_modify_tyre.mm_max_value')],
                                            ) : []"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <!-- With Enjo -->
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="5" class="mt-4 mr-n4" align="right">
                                        <span style="font-size:initial"> {{ $t('tyre_hotel.add_modify_tyre.with_enjo') }} </span>
                                    </v-col>
                                    <v-col cols="12" sm="2" align="right">
                                        <v-checkbox
                                            class="ma-0 pa-0 pt-4"
                                            v-model="front_left.with_enjo"
                                            label=" "
                                            hide-details="auto"
                                        >
                                        </v-checkbox>
                                    </v-col>
                                    
                                </v-row>
                                <!-- Tyre Pressure & Couple -->
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_left.pressure"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.pressure')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_left.couple"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.couple')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <!-- Tyre Status -->
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="8" align="right">
                                        <v-autocomplete
                                            :autocomplete="autocompleteValueLocal"
                                            flat
                                            v-model="front_left.status"
                                            :items="depotDetailStatuses"
                                            :label="$t('tyre_hotel.add_modify_tyre.status')"
                                            class="pa-0 mr-2 to-upper"
                                            item-value="id"
                                            item-text="text"
                                            append-icon="mdi-close"
                                            @click:append="front_left.status = null"
                                            return-object
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                            hide-no-data
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <!-- First Column : Back Left Tyres -->
                        <v-row class="mt-10">
                            <v-col>
                                <!-- Back Left Tyre Active_&_mm -->
                                <v-row>
                                    <v-col class="pa-0">
                                        <v-btn-toggle style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;" v-model="back_left.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="back_left.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.rear_left') }} </span>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                    <v-col class="pa-0" cols="12" sm="3">
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: 0px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_left.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 mr-2 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation && !$v.back_left.mm.maxValue ? [this.$t('tyre_hotel.add_modify_tyre.mm_max_value')] : [] "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <!-- Back Left Tyre (2) Active_&_mm -->
                                <v-row v-if="back_2_tyres">
                                    <v-col class="pa-0">
                                        <v-btn-toggle style="border-top-right-radius: 0px;border-bottom-right-radius: 0px;" v-model="back_left_2.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="back_left_2.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.rear_left_2') }} </span>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                    <v-col class="pa-0" cols="12" sm="3">
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: 0px;border-top-left-radius: 0px;border-bottom-left-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_left_2.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 mr-2 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation && !$v.back_left_2.mm.maxValue ? [this.$t('tyre_hotel.add_modify_tyre.mm_max_value')] : [] "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <!-- With Enjo -->
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="5" class="mt-4 mr-n4" align="right">
                                        <span style="font-size:initial"> {{ $t('tyre_hotel.add_modify_tyre.with_enjo') }} </span>
                                    </v-col>
                                    <v-col cols="12" sm="2" align="right">
                                        <v-checkbox
                                            class="ma-0 pa-0 pt-4"
                                            v-model="back_left.with_enjo"
                                            hide-details="auto"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <!-- Tyre Pressure & Couple -->
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_left.pressure"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.pressure')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_left.couple"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.couple')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <!-- Tyre Status -->
                                <v-row>
                                    <v-spacer>
                                    </v-spacer>
                                    <v-col cols="12" sm="8" align="right">
                                        <v-autocomplete
                                            :autocomplete="autocompleteValueLocal"
                                            flat
                                            v-model="back_left.status"
                                            :items="depotDetailStatuses"
                                            :label="$t('tyre_hotel.add_modify_tyre.status')"
                                            class="pa-0 mr-2 to-upper"
                                            item-value="id"
                                            item-text="text"
                                            append-icon="mdi-close"
                                            @click:append="back_left.status = null"
                                            return-object
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                            hide-no-data
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Second Column : Car Image -->
                    <v-col cols="12" sm="3">
                        <v-img
                            height="450"
                            width="230"
                            src="/images/carimage.jpg"
                        ></v-img>
                    </v-col>
                    <!-- Third Column : Right Tyres -->
                    <v-col cols="12" sm="4">
                        <!-- Third Column : Front Right Tyres -->
                        <v-row class="mt-4">
                            <v-col>
                                <!-- Front Right Tyre Active_&_mm -->
                                <v-row>
                                    <v-col class=" pa-0" cols="12" sm="12">
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: -4px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_right.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 pt-n2 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation && !$v.front_right.mm.maxValue ? [this.$t('tyre_hotel.add_modify_tyre.mm_max_value')] : [] "
                                        ></v-text-field>
                                        <v-btn-toggle style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;" v-model="front_right.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="front_right.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.front_right') }}</span>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                                <!-- Front Right Tyre (2) Active_&_mm -->
                                <v-row v-if="front_2_tyres">
                                    <v-col class=" pa-0" cols="12" sm="12">
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: -4px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_right_2.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 pt-n2 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation && !$v.front_right_2.mm.maxValue ? [this.$t('tyre_hotel.add_modify_tyre.mm_max_value')] : [] "
                                        ></v-text-field>
                                        <v-btn-toggle style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;" v-model="front_right_2.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="front_right_2.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.front_right_2') }}</span>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                                <!-- With Enjo -->
                                <v-row>
                                    <v-col cols="12" sm="6" align="right">
                                        <v-checkbox
                                            class="ma-0 pa-0 pt-2"
                                            v-model="front_right.with_enjo"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.with_enjo')"
                                            hide-details="auto"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <!-- Pressure -->
                                <v-row>
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_right.pressure"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.pressure')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                
                                    <v-col cols="12" sm="4" align="right">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="front_right.couple"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.couple')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <!-- Status -->
                                <v-row>
                                    <v-col cols="12" sm="8" align="right">
                                        <v-autocomplete
                                            :autocomplete="autocompleteValueLocal"
                                            flat
                                            v-model="front_right.status"
                                            :items="depotDetailStatuses"
                                            :label="$t('tyre_hotel.add_modify_tyre.status')"
                                            class="pa-0 mr-2 to-upper"
                                            item-value="id"
                                            item-text="text"
                                            append-icon="mdi-close"
                                            @click:append="front_right.status = null"
                                            return-object
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                            hide-no-data
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <!-- Third Column : Back Right Tyres -->
                        <v-row class="mt-10">
                            <v-col>
                                <!-- Back Right Tyre Active_&_mm -->
                                <v-row>
                                    <v-col class=" pa-0" cols="12" sm="12">
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: -3px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_right.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation && !$v.back_right.mm.maxValue ? [this.$t('tyre_hotel.add_modify_tyre.mm_max_value')] : [] "
                                        ></v-text-field>
                                        <v-btn-toggle style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;" v-model="back_right.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="back_right.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.rear_right') }} </span>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                                <!-- Back Right Tyre (2)  Active_&_mm -->
                                <v-row v-if="back_2_tyres">
                                    <v-col class=" pa-0" cols="12" sm="12">
                                        <v-text-field  
                                            style="width: 80px;display: inline-block;top: -3px;border-top-right-radius: 0px;border-bottom-right-radius: 0px;"
                                            outlined
                                            dense
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_right_2.mm"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',3);"
                                            class="pa-0 to-upper"
                                            :placeholder="this.$t('tyre_hotel.add_modify_tyre.mm')"
                                            hide-details="auto"
                                            :error-messages="$store.state.validation && !$v.back_right_2.mm.maxValue ? [this.$t('tyre_hotel.add_modify_tyre.mm_max_value')] : [] "
                                        ></v-text-field>
                                        <v-btn-toggle style="border-top-left-radius: 0px;border-bottom-left-radius: 0px;" v-model="back_right_2.active">
                                            <v-btn active-class="light-green accent-3" style="height:40px !important">
                                                <v-icon v-if="back_right_2.active == 0" dark color="green darken-4">mdi-check</v-icon>
                                                <v-icon v-else dark color="red darken-4">mdi-close</v-icon>
                                                <span> {{ $t('tyre_hotel.add_modify_tyre.rear_right_2') }} </span>
                                            </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                                <!-- With Enjo -->
                                <v-row>
                                    <v-col cols="12" sm="6" align="left">
                                        <v-checkbox
                                            class="ma-0 pa-0 pt-3"
                                            v-model="back_right.with_enjo"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.with_enjo')"
                                            hide-details="auto"
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <!-- Pressure -->
                                <v-row class="pt-2">
                                    <v-col cols="12" sm="4" align="left">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_right.pressure"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.pressure')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                
                                    <v-col cols="12" sm="4" align="lfet">
                                        <v-text-field  
                                            small
                                            :autocomplete="autocompleteValueLocal"
                                            v-model="back_right.couple"
                                            oncontextmenu="return false;"
                                            v-on:keydown="handleInput($event,'float',8);"
                                            class="pa-0 mr-2 to-upper"
                                            :label="this.$t('tyre_hotel.add_modify_tyre.couple')"
                                            hide-details="auto"
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <!-- Status -->
                                <v-row>
                                    <v-col cols="12" sm="8" align="left">
                                        <v-autocomplete
                                            :autocomplete="autocompleteValueLocal"
                                            flat
                                            v-model="back_right.status"
                                            :items="depotDetailStatuses"
                                            :label="$t('tyre_hotel.add_modify_tyre.status')"
                                            class="pa-0 mr-2 to-upper"
                                            item-value="id"
                                            item-text="text"
                                            append-icon="mdi-close"
                                            @click:append="back_right.status = null"
                                            return-object
                                            :outlined="is_boxes"
                                            :dense="is_boxes"
                                            hide-no-data
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <!-- Back Tyre Article -->
                <v-row class="mt-6">
                    <v-col cols="12" sm="1" class="ma-0 pa-0 mt-4 pl-3">
                        <v-row  class=" ">
                            <span style="font-size:18px">
                                    {{ $t('tyre_hotel.add_modify_tyre.back') }} 
                            </span>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="10" class="ma-0 pa-0 mt-n4">
                        <tyre-hotel-search-article
                                :class="front_selected_article ? 'articleset': 'articlenotset'"
                                @changeDialog="changeDialog()" 
                                :compSelectedArticleId.sync="backParentSearchSelectedArticleId"
                                :internalStockOnly.sync="parentInternalStockOnly"
                                ref="frontSearchArticle"
                        ></tyre-hotel-search-article>
                        <div class="v-messages theme--light error--text mt-n8"> 
                            {{ $store.state.validation && !$v.backParentSearchSelectedArticleId.required ? this.$t('tyre_hotel.add_modify_tyre.back_article_required') : '' }}
                        </div>
                    </v-col>
                    <v-col cools="12" sm="1" class="ma-0 pa-0" v-if="backParentSearchSelectedArticleId && !back_2_tyres">
                        <v-tooltip  bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" color="primary" @click="addAnotherTyre(false, true)">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                {{ $t('add_another_tyre') }}
                            </span> 
                        </v-tooltip>
                    </v-col>
                    <v-col cools="12" sm="1" class="ma-0 pa-0" v-if="backParentSearchSelectedArticleId && back_2_tyres">
                        <v-tooltip  bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" color="error" @click="removeTyres(false, true)">
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                            </template>
                            <span>
                                {{ $t('remove_another_tyre') }}
                            </span> 
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pr-2">
                <v-spacer></v-spacer>
                <v-btn class="px-4" color="grey lighten-2"  @click="closeTyreDialog(false)">
                    {{ $t('tyre_hotel.add_modify_tyre.cancel') }}
                    <v-icon dark right> mdi-keyboard-return </v-icon>
                </v-btn>
                <v-btn class="px-4" color="success" @click="saveTyreDialog()">
                    {{ $t('tyre_hotel.add_modify_tyre.save') }}
                    <v-icon dark right> mdi-content-save </v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<style scoped>
    .right-aligned-input >>> input {
        text-align: right
    }
    .text-red >>> input{
        color: red !important;
    }
    .border-error{
        border: 1px solid red;
    }
</style>
<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import {API_BASE_URL} from "@/config";
import TyreHotelSearchArticle from "@/components/TyreHotel/SearchArticle";
import { validationMixin } from 'vuelidate'
import { required, email, requiredIf, maxValue, phoneNum, numeric ,minLength, maxLength, alpha } from 'vuelidate/lib/validators'

export default {
    name: "DepoAddModifyTyre",
    mixins: [validationMixin],
    validations: {
        // wheel_type: { 
        //     required
        // },
        front_left:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        front_right:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        back_left:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        back_right:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        front_left_2:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        front_right_2:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        back_left_2:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        back_right_2:{
            mm:{
                maxValue: maxValue(100)
            },
        },
        frontParentSearchSelectedArticleId: {
            required: requiredIf(function () {
                return this.front_left.active == 0 || this.front_right.active == 0;
            }),
        },
        backParentSearchSelectedArticleId: {
            required: requiredIf(function () {
                return this.back_left.active == 0 || this.back_right.active == 0;
            }),
        },
        atLeastOneShouldBeActive: {
            required: requiredIf(function () {
                return this.front_left.active == undefined && this.front_right.active == undefined && this.back_left.active == undefined && this.back_right.active == undefined;
            }),
        },
    },
    components:{
        "tyre-hotel-search-article":TyreHotelSearchArticle
    },
    props: {
        depotDetails:   [ Object ],
        vehicleDriverDetails:  [ Object ]
    },
    data() {
        return {
            note: null,
            is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
            modify_stock_dialog :   true,
            autocompleteValueLocal : 'off',
            localisation:   null,
            depotDetailStatuses: [],
            wheel_types:[],
            wheel_type: null,
            front_selected_article: null,
            frontParentSearchSelectedArticleId: null,
            backParentSearchSelectedArticleId: null,
            parentInternalStockOnly: false,
            front_2_tyres: false,
            back_2_tyres: false,
            front_left:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            front_right:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            back_left:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            back_right:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            front_left_2:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            front_right_2:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            back_left_2:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            back_right_2:{
                active: undefined,
                mm: null,
                with_enjo: false,
                pressure: null,
                couple: null,
                status: null
            },
            token: this.$store.state.AccessToken,
            header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
            tempDepotDetails: {},
            modify_data: false,
            modify_title: this.$t('tyre_hotel.add_modify_tyre.title'),
        };
    },
    mounted() {
        this.resetPageData();
        this.autocompleteValueLocal = this.autocompleteValue();
        this.header = { Authorization: 'Bearer ' + this.token };
        this.getDepotDetailStatuses();
        this.getWheelMaterial();
        this.getVehicleTyreDetailsFromScan().finally(() => {
            this.updateValues();
        });
        this.$store.commit("setValidation", false); // enable validation
    },
    activated(){
        this.$store.commit("setValidation", false); // enable validation
    },
    watch:{
        depotDetails:{
            handler: function(){
                this.updateValues();
            }
        },
        frontParentSearchSelectedArticleId(val){
            this.backParentSearchSelectedArticleId = val;
        },
        'front_left.mm':_.debounce(function (e) {
            this.front_left.active = 0;
            if(this.modify_data){
                this.front_right.mm = this.front_left.mm;
                if(this.front_2_tyres){
                    this.front_left_2.mm = this.front_left.mm;
                    this.front_right_2.mm = this.front_left.mm;
                }
                this.back_left.mm = this.front_left.mm;
                this.back_right.mm = this.front_left.mm;
                if(this.back_2_tyres){
                    this.back_left_2.mm = this.front_left.mm;
                    this.back_right_2.mm = this.front_left.mm;
                }
            }
        }, 200),
        'front_left_2.mm':_.debounce(function (e) {
            this.front_left_2.active = 0;
            if(this.modify_data){
                this.front_right_2.mm = this.front_left_2.mm;
            }
        }, 200),
        'front_left.with_enjo':_.debounce(function (e) {
            if(this.modify_data){
                this.front_right.with_enjo = this.front_left.with_enjo;
                this.back_left.with_enjo = this.front_left.with_enjo;
                this.back_right.with_enjo = this.front_left.with_enjo;
            }
        }, 200),
        'front_left.pressure':_.debounce(function (e) {
            if(this.modify_data){
                this.front_right.pressure = this.front_left.pressure;
                this.back_left.pressure = this.front_left.pressure;
                this.back_right.pressure = this.front_left.pressure;
            }
        }, 200),
        'front_left.couple':_.debounce(function (e) {
            if(this.modify_data){
                this.front_right.couple = this.front_left.couple;
                this.back_left.couple = this.front_left.couple;
                this.back_right.couple = this.front_left.couple;
            }
        }, 200),
        'front_right.mm':_.debounce(function (e) {
            if(this.modify_data){
                this.front_right.active = 0;
            }
        }, 200),
        'front_right_2.mm':_.debounce(function (e) {
            if(this.modify_data){
                this.front_right_2.active = 0;
            }
        }, 200),
        'back_left.mm':_.debounce(function (e) {
            this.back_left.active = 0;
            if(this.modify_data){
                this.back_right.mm = this.back_left.mm;
            }
        }, 200),
        'back_left_2.mm':_.debounce(function (e) {
            this.back_left_2.active = 0;
            if(this.modify_data){
                this.back_right_2.mm = this.back_left_2.mm;
            }
        }, 200),
        'back_left.with_enjo':_.debounce(function (e) {
            if(this.modify_data){
                this.back_right.with_enjo = this.back_left.with_enjo;
            }
        }, 200),
        'back_left.pressure':_.debounce(function (e) {
            if(this.modify_data){
                this.back_right.pressure = this.back_left.pressure;
            }
        }, 200),
        'back_left.couple':_.debounce(function (e) {
            if(this.modify_data){
                this.back_right.couple = this.back_left.couple;
            }
        }, 200),
        'back_right.mm':_.debounce(function (e) {
            this.back_right.active = 0;
        }, 200),
        'back_right_2.mm':_.debounce(function (e) {
            this.back_right_2.active = 0;
        }, 200),
    },
    methods: {
        resetSecondTyres(front = true, back = true){
            // Reset Front Tyres
            if(front){
                this.front_left_2 = {
                    active: undefined,
                    mm: null,
                    with_enjo: false,
                    pressure: null,
                    couple: null,
                    status: null
                },
                this.front_right_2 = {
                    active: undefined,
                    mm: null,
                    with_enjo: false,
                    pressure: null,
                    couple: null,
                    status: null
                }
            }
            // Reset Back Tyres
            if(back){
                this.back_left_2 = {
                    active: undefined,
                    mm: null,
                    with_enjo: false,
                    pressure: null,
                    couple: null,
                    status: null
                },
                this.back_right_2 ={
                    active: undefined,
                    mm: null,
                    with_enjo: false,
                    pressure: null,
                    couple: null,
                    status: null
                }
            }
        },
        removeTyres(front, back){
            if(front){
                this.front_2_tyres = false;
                this.resetSecondTyres(true, false);
            }
            if(back){
                this.back_2_tyres = false;
                this.resetSecondTyres(false, true);
            }
        },
        addAnotherTyre(front, back){
            if(front){
                this.front_2_tyres = true;
                this.copyFrontTyresValueToSecond();
            }
            if(back){
                this.back_2_tyres = true;
                this.copyBackTyresValueToSecond();
            }
        },
        copyFrontTyresValueToSecond(){
            this.front_left_2.active = this.front_left.active;
            this.front_left_2.mm = this.front_left.mm;
            this.front_left_2.with_enjo = this.front_left.with_enjo,
            this.front_left_2.pressure = this.front_left.pressure,
            this.front_left_2.couple = this.front_left.couple,
            this.front_left_2.status = this.front_left.status;
        },
        copyBackTyresValueToSecond(){
            this.back_left_2.active = this.back_left.active,
            this.back_left_2.mm = this.back_left.mm,
            this.back_left_2.with_enjo = this.back_left.with_enjo,
            this.back_left_2.pressure = this.back_left.pressure,
            this.back_left_2.couple = this.back_left.couple,
            this.back_left_2.status = this.back_left.status
        },
        resetPageData(){
            this.note           = null;
            this.localisation   = null;
            this.wheel_type     = null;

            this.front_selected_article = null;
            this.frontParentSearchSelectedArticleId = null;
            this.backParentSearchSelectedArticleId  = null;

            this.front_left.active = undefined;
            this.front_left.mm = null;
            this.front_left.with_enjo = false,
            this.front_left.pressure = null,
            this.front_left.couple = null,
            this.front_left.status = null

            this.front_left_2.active = undefined;
            this.front_left_2.mm = null;
            this.front_left_2.with_enjo = false,
            this.front_left_2.pressure = null,
            this.front_left_2.couple = null,
            this.front_left_2.status = null
            
            this.front_right.active = undefined;
            this.front_right.mm = null;
            this.front_right.with_enjo = false;
            this.front_right.pressure = null;
            this.front_right.couple = null;
            this.front_right.status = null;

            this.front_right_2.active = undefined;
            this.front_right_2.mm = null;
            this.front_right_2.with_enjo = false;
            this.front_right_2.pressure = null;
            this.front_right_2.couple = null;
            this.front_right_2.status = null;
            
            this.back_left.active = undefined,
            this.back_left.mm = null,
            this.back_left.with_enjo = false,
            this.back_left.pressure = null,
            this.back_left.couple = null,
            this.back_left.status = null

            this.back_left_2.active = undefined,
            this.back_left_2.mm = null,
            this.back_left_2.with_enjo = false,
            this.back_left_2.pressure = null,
            this.back_left_2.couple = null,
            this.back_left_2.status = null

            
            this.back_right.active = undefined,
            this.back_right.mm = null,
            this.back_right.with_enjo = false,
            this.back_right.pressure = null,
            this.back_right.couple = null,
            this.back_right.status = null

            this.back_right_2.active = undefined,
            this.back_right_2.mm = null,
            this.back_right_2.with_enjo = false,
            this.back_right_2.pressure = null,
            this.back_right_2.couple = null,
            this.back_right_2.status = null
        },
        getVehicleTyreDetailsFromScan(){
            return new Promise((resolve, reject) => {
                if(this.vehicleDriverDetails && this.vehicleDriverDetails?.vehicle?.registration_no){
                    

                    axios.get(API_BASE_URL + '/depots/scan/' +  this.vehicleDriverDetails?.vehicle?.registration_no, {headers: this.header})
                    .then(({data}) => {
                        if(data && data.data && (data.data.front_left || data.data.back_left)){
                            this.front_right.mm = data.data.front_right;
                            this.front_left.mm = data.data.front_left;
                            this.back_right.mm = data.data.back_right;
                            this.back_left.mm = data.data.back_left;
                            
                            this.front_right_2.mm = data.data?.front_right_2;
                            this.front_left_2.mm = data.data?.front_left_2;
                            this.back_right_2.mm = data.data?.back_right_2;
                            this.back_left_2.mm = data.data?.back_left_2;

                            this.$nextTick(() => {
                                this.front_right.active  = 0;
                                this.front_left.active  = 0;
                                this.back_right.active  = 0;
                                this.back_left.active  = 0;
                                
                                this.front_right_2.active  = 0;
                                this.front_left_2.active  = 0;
                                this.back_right_2.active  = 0;
                                this.back_left_2.active  = 0;
                            })
                        }
                    })
                    .catch(function (error) {
                        console.log('an error occured ' + error);
                    })
                    .finally(() => {
                        
                        resolve();
                    });
                }
                else{
                    resolve();
                }
            })
        },
        updateValues(){
            if(this.depotDetails && Object.keys(this.depotDetails).length > 0){
                this.modify_title = this.$t('tyre_hotel.add_modify_tyre.edit_title');
                this.tempDepotDetails = Object.assign({}, this.depotDetails);
                this.localisation = this.tempDepotDetails.localisation;
                this.note = this.tempDepotDetails.note;
                if(this.tempDepotDetails.details.length > 0){
                    // assign wheel type
                    this.wheel_type  = this.tempDepotDetails.details[0].rimtype;
                    // assign article and positions
                    this.tempDepotDetails.details.forEach(positionArticle => {
                        if(positionArticle.position_id == 10281002){ // front left
                            if(positionArticle.article_id && positionArticle.article){
                                positionArticle.article.description = positionArticle.article.title_description[0].description;
                            }
                            else{
                                positionArticle.article = positionArticle.article_name;
                            }
                            this.frontParentSearchSelectedArticleId = positionArticle.article;
                            this.front_left.active = 0;
                            this.front_left.mm = positionArticle.mm;
                            this.front_left.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.front_left.pressure =  positionArticle.pressure;
                            this.front_left.couple =  positionArticle.couple;
                            this.front_left.status =  positionArticle.article_status;
                        }
                        if(positionArticle.position_id == 10281001){ // front right
                            if(positionArticle.article_id && positionArticle.article){
                                positionArticle.article.description = positionArticle.article.title_description[0].description;
                            }
                            else{
                                positionArticle.article = positionArticle.article_name;
                            }
                            this.frontParentSearchSelectedArticleId = positionArticle.article;

                            this.front_right.active = 0;
                            this.front_right.mm = positionArticle.mm;
                            this.front_right.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.front_right.pressure =  positionArticle.pressure;
                            this.front_right.couple =  positionArticle.couple;
                            this.front_right.status =  positionArticle.article_status;
                        }
                        if(positionArticle.position_id == 10281003){ // back right
                            if(positionArticle.article_id && positionArticle.article){
                                positionArticle.article.description = positionArticle.article.title_description[0].description;
                            }
                            else{
                                positionArticle.article = positionArticle.article_name;
                            }
                            this.$nextTick(() => {
                                this.backParentSearchSelectedArticleId = positionArticle.article;
                            });
                            
                            this.back_right.active = 0;
                            this.back_right.mm = positionArticle.mm;
                            this.back_right.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.back_right.pressure =  positionArticle.pressure;
                            this.back_right.couple =  positionArticle.couple;
                            this.back_right.status =  positionArticle.article_status;
                        }
                        if(positionArticle.position_id == 10281004){ // back left
                            if(positionArticle.article_id && positionArticle.article){
                                positionArticle.article.description = positionArticle.article.title_description[0].description;
                            }
                            else{
                                positionArticle.article = positionArticle.article_name;
                            }
                            this.$nextTick(() => {
                                this.backParentSearchSelectedArticleId = positionArticle.article;
                            });
                            

                            this.back_left.active = 0;
                            this.back_left.mm = positionArticle.mm;
                            this.back_left.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.back_left.pressure =  positionArticle.pressure;
                            this.back_left.couple =  positionArticle.couple;
                            this.back_left.status =  positionArticle.article_status;
                        }

                        if(positionArticle.position_id == 10281006){ // front left 2
                            this.front_2_tyres = true;
                            if(positionArticle.article_id && positionArticle.article){
                                positionArticle.article.description = positionArticle.article.title_description[0].description;
                            }
                            else{
                                positionArticle.article = positionArticle.article_name;
                            }
                            this.frontParentSearchSelectedArticleId = positionArticle.article;
                            this.front_left_2.active = 0;
                            this.front_left_2.mm = positionArticle.mm;
                            this.front_left_2.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.front_left_2.pressure =  positionArticle.pressure;
                            this.front_left_2.couple =  positionArticle.couple;
                            this.front_left_2.status =  positionArticle.article_status;
                        }

                        if(positionArticle.position_id == 10281007){ // front right 2
                            this.front_2_tyres = true;
                            if(positionArticle.article_id && positionArticle.article){
                                positionArticle.article.description = positionArticle.article.title_description[0].description;
                            }
                            else{
                                positionArticle.article = positionArticle.article_name;
                            }
                            this.frontParentSearchSelectedArticleId = positionArticle.article;

                            this.front_right_2.active = 0;
                            this.front_right_2.mm = positionArticle.mm;
                            this.front_right_2.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.front_right_2.pressure =  positionArticle.pressure;
                            this.front_right_2.couple =  positionArticle.couple;
                            this.front_right_2.status =  positionArticle.article_status;
                        }
                        if(positionArticle.position_id == 10281008){ // back right
                            this.back_2_tyres = true;
                            if(positionArticle.article_id && positionArticle.article){
                                positionArticle.article.description = positionArticle.article.title_description[0].description;
                            }
                            else{
                                positionArticle.article = positionArticle.article_name;
                            }
                            this.$nextTick(() => {
                                this.backParentSearchSelectedArticleId = positionArticle.article;
                            });
                            
                            this.back_right_2.active = 0;
                            this.back_right_2.mm = positionArticle.mm;
                            this.back_right_2.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.back_right_2.pressure =  positionArticle.pressure;
                            this.back_right_2.couple =  positionArticle.couple;
                            this.back_right_2.status =  positionArticle.article_status;
                        }
                        if(positionArticle.position_id == 10281009){ // back left
                            this.back_2_tyres = true;
                            if(positionArticle.article_id && positionArticle.article){
                                positionArticle.article.description = positionArticle.article.title_description[0].description;
                            }
                            else{
                                positionArticle.article = positionArticle.article_name;
                            }
                            this.$nextTick(() => {
                                this.backParentSearchSelectedArticleId = positionArticle.article;
                            });
                            

                            this.back_left_2.active = 0;
                            this.back_left_2.mm = positionArticle.mm;
                            this.back_left_2.with_enjo = positionArticle.is_with_hubcaps == 1 ? true : false;
                            this.back_left_2.pressure =  positionArticle.pressure;
                            this.back_left_2.couple =  positionArticle.couple;
                            this.back_left_2.status =  positionArticle.article_status;
                        }
                        
                    });
                }
            }
        },
        closeTyreDialog(data) {
            this.modify_stock_dialog = false;
            this.$emit('modifyClose', false, data);
            
        },
        saveTyreDialog(){
            this.$store.commit("setValidation",true);
            const payload = {
                vehicle_id:     this.vehicleDriverDetails.id || this.vehicleDriverDetails.vehicle.vehicle_id,
                client_id:      this.vehicleDriverDetails.client_id || this.vehicleDriverDetails.client.client_id,
                localisation:   this.localisation,
                note:           this.note,
                wheel_type:     this.wheel_type ? this.wheel_type.id : null,
                front_left:{
                    active:     this.front_left.active,
                    mm:         this.front_left.mm,
                    with_enjo:  this.front_left.with_enjo,
                    pressure:   this.front_left.pressure,
                    couple:     this.front_left.couple,
                    status:     this.front_left.status ? this.front_left.status.id : null,
                    article_id: typeof this.frontParentSearchSelectedArticleId == 'object' ? this.frontParentSearchSelectedArticleId.id : null,
                    article_name: typeof this.frontParentSearchSelectedArticleId == 'string' ? this.frontParentSearchSelectedArticleId : null
                },
                front_right:{
                    active:     this.front_right.active,
                    mm:         this.front_right.mm,
                    with_enjo:  this.front_right.with_enjo,
                    pressure:   this.front_right.pressure,
                    couple:     this.front_right.couple,
                    status:     this.front_right.status ? this.front_right.status.id : null,
                    article_id: typeof this.frontParentSearchSelectedArticleId == 'object' ? this.frontParentSearchSelectedArticleId.id : null,
                    article_name: typeof this.frontParentSearchSelectedArticleId == 'string' ? this.frontParentSearchSelectedArticleId : null
                },
                back_left:{
                    active:     this.back_left.active,
                    mm:         this.back_left.mm,
                    with_enjo:  this.back_left.with_enjo,
                    pressure:   this.back_left.pressure,
                    couple:     this.back_left.couple,
                    status:     this.back_left.status ? this.back_left.status.id : null,
                    article_id: typeof this.backParentSearchSelectedArticleId == 'object' ? this.backParentSearchSelectedArticleId.id : null,
                    article_name: typeof this.backParentSearchSelectedArticleId == 'string' ? this.backParentSearchSelectedArticleId : null
                },
                back_right:{
                    active:     this.back_right.active,
                    mm:         this.back_right.mm,
                    with_enjo:  this.back_right.with_enjo,
                    pressure:   this.back_right.pressure,
                    couple:     this.back_right.couple,
                    status:     this.back_right.status ? this.back_right.status.id : null,
                    article_id: typeof this.backParentSearchSelectedArticleId == 'object' ? this.backParentSearchSelectedArticleId.id : null,
                    article_name: typeof this.backParentSearchSelectedArticleId == 'string' ? this.backParentSearchSelectedArticleId : null
                },
                front_left_2: this.front_2_tyres ? {
                    active:     this.front_left_2.active,
                    mm:         this.front_left_2.mm,
                    with_enjo:  this.front_left_2.with_enjo,
                    pressure:   this.front_left_2.pressure,
                    couple:     this.front_left_2.couple,
                    status:     this.front_left_2.status ? this.front_left_2.status.id : null,
                    article_id: typeof this.frontParentSearchSelectedArticleId == 'object' ? this.frontParentSearchSelectedArticleId.id : null,
                    article_name: typeof this.frontParentSearchSelectedArticleId == 'string' ? this.frontParentSearchSelectedArticleId : null
                } : null,
                front_right_2: this.front_2_tyres ? {
                    active:     this.front_right_2.active,
                    mm:         this.front_right_2.mm,
                    with_enjo:  this.front_right_2.with_enjo,
                    pressure:   this.front_right_2.pressure,
                    couple:     this.front_right_2.couple,
                    status:     this.front_right_2.status ? this.front_right_2.status.id : null,
                    article_id: typeof this.frontParentSearchSelectedArticleId == 'object' ? this.frontParentSearchSelectedArticleId.id : null,
                    article_name: typeof this.frontParentSearchSelectedArticleId == 'string' ? this.frontParentSearchSelectedArticleId : null
                } : null,
                back_left_2: this.back_2_tyres ? {
                    active:     this.back_left_2.active,
                    mm:         this.back_left_2.mm,
                    with_enjo:  this.back_left_2.with_enjo,
                    pressure:   this.back_left_2.pressure,
                    couple:     this.back_left_2.couple,
                    status:     this.back_left_2.status ? this.back_left_2.status.id : null,
                    article_id: typeof this.backParentSearchSelectedArticleId == 'object' ? this.backParentSearchSelectedArticleId.id : null,
                    article_name: typeof this.backParentSearchSelectedArticleId == 'string' ? this.backParentSearchSelectedArticleId : null
                } : null,
                back_right_2: this.back_2_tyres ? {
                    active:     this.back_right_2.active,
                    mm:         this.back_right_2.mm,
                    with_enjo:  this.back_right_2.with_enjo,
                    pressure:   this.back_right_2.pressure,
                    couple:     this.back_right_2.couple,
                    status:     this.back_right_2.status ? this.back_right_2.status.id : null,
                    article_id: typeof this.backParentSearchSelectedArticleId == 'object' ? this.backParentSearchSelectedArticleId.id : null,
                    article_name: typeof this.backParentSearchSelectedArticleId == 'string' ? this.backParentSearchSelectedArticleId : null
                } : null,
            };
            if (this.$v.$invalid ){
                this.$toast.warning(this.$t('please_fill_form_correctly'));
            }
            else{
                if(this.depotDetails && this.depotDetails.id){
                    this.updateDepot(payload,true);
                }
                else{
                    this.addDepot(payload, true);
                }
            }
        },
        addDepot(data, showMessage= true){
            return new Promise((resolve, reject) => {
                
                axios.post(API_BASE_URL + '/depots', data, {headers: this.header})
                .then(response => {
                    this.tempDepotDetails = response.data.data;
                    this.depotDetails = this.tempDepotDetails;

                    if(showMessage){
                        this.$toast.success( this.$t('tyre_hotel.add_modify_tyre.depot_added'));
                    }
                    resolve();
                })
                .catch((error) => {
                    reject();
                })
                .finally(()=>{
                    
                    this.$store.commit("setValidation",false);
                    this.closeTyreDialog(this.depotDetails);
                });
            })
        },
        updateDepot(data, showMessage = true){
            return new Promise((resolve, reject) => {
                
                axios.put(API_BASE_URL + '/depots/' + this.depotDetails.id, data, {headers: this.header})
                .then(response => {
                    this.tempDepotDetails = response.data.data;
                    this.depotDetails = this.tempDepotDetails;
                    if(showMessage){
                        this.$toast.success( this.$t('tyre_hotel.add_modify_tyre.depot_updated'));
                    }
                    resolve();
                })
                .catch((error) => {
                    reject();
                })
                .finally(()=>{
                    
                    this.$store.commit("setValidation",false);
                    this.closeTyreDialog(this.depotDetails);
                });
            })
        },
        async getDepotDetailStatuses() {
            await this.getCodes('depot_details_statuses', 'tyre_hotel.statuses')
            .then((data) => {
                this.depotDetailStatuses = data;
            })
        },
        async getWheelMaterial() {
            await this.getCodes('wheel_material', 'tyre_hotel.wheel_materials')
            .then((data) => {
                this.wheel_types = data;
            })
        },
    },
}
</script>