import Vue from "vue";
import Vuex from "vuex";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { API_BASE_URL } from "./config";
import i18n from "./i18n";
import Helper from "./helpers.js";
import GlobalComputed from "./GlobalComputed.js";
import wysiwyg from "vue-wysiwyg";
import "vue-wysiwyg/dist/vueWysiwyg.css";
import "./assets/CSS/print.min.css";
import "./assets/CSS/extra-ui.css";
import dateHelper from "./dateHelper.js";


import "./plugins/vuetify-mask.js";
import 'vue-tel-input-vuetify/lib/sprite.css'
import VueLazyload from 'vue-lazyload'
import VueApexCharts from 'vue-apexcharts'
import TextComponentWithExtraIcons from "@/components/TextComponentWithExtraIcons";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

function loadMixins() {
    const mixins = [];
    
    let data = JSON.parse(localStorage.getItem("data"));
    let companyIdentifier = data?.userDetails?.company?.identifier ?? "";

    const mixinFiles = require.context('@/mixins/', true, /\.js$/);

    mixinFiles.keys().filter(
        file => file.includes(companyIdentifier.toLowerCase()) || file.includes(companyIdentifier.toUpperCase())
    )
    .forEach((fileName) => {
        if (fileName.includes(`/${companyIdentifier}/`)) {
            const mixinConfig = mixinFiles(fileName);
            mixins.push(mixinConfig.default || mixinConfig);
        }
    });

    return mixins;
}

export { loadMixins };

Vue.use(VueApexCharts)

Vue.use(VueLazyload)

Vue.mixin(Helper);
Vue.mixin(GlobalComputed);
Vue.mixin(dateHelper);

Vue.component('apexchart', VueApexCharts);
Vue.component('TextComponentWithExtraIcons', TextComponentWithExtraIcons);
const toastOptions = {
    // toast options
    position: 'bottom-left',
    transition: "Vue-Toastification__bounce",
    maxToasts: 28,
    newestOnTop: true,
    shareAppContext: true
};
Vue.use(Toast, toastOptions);

Vue.use(VueCookies);
Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    link: true,
    code: true,
    table: true,
    removeFormat: true,
  },
    maxHeight: '300px'
});

// Vue-Cookies
import VueCookies from "vue-cookies";

Vue.config.productionTip = false;
Vue.config.baseUrl = API_BASE_URL;
// Need to make this value to false later, used for testing purpose
// Vue.config.devtools = true;

export var app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  Vuex,
  data() {
    return {};
  },
  render: (h) => h(App),
}).$mount("#app");
