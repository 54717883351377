var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.hideForSomeTime)?_c('div',{staticClass:"draggableObject",class:_vm.addClass(_vm.row, _vm.colindex),style:(_vm.addTaskStyle(_vm.row.items[_vm.colindex - 1])),attrs:{"row":_vm.row.index,"appointmentid":_vm.appointment.data.id,"column":_vm.colindex - 1}},[_c('div',{staticClass:"draggableObjectData"},[_c('div',{on:{"click":function($event){return _vm.editAppointment(_vm.row.index, _vm.colindex - 1)}}},[_c('span',{staticClass:"text-sm-left"},[(_vm.appointment.data)?[(
                            _vm.appointment.data.client &&
                                _vm.appointment.data.client.name
                        )?[(
                                _vm.appointment &&
                                    _vm.appointment.data &&
                                    _vm.appointment.data.appointment_status &&
                                    _vm.appointment.data.appointment_status
                                        .key == 'appointment_is_executed'
                            )?_c('v-icon',{staticStyle:{"font-size":"20px"},attrs:{"color":"white"}},[_vm._v("mdi-account-check")]):_vm._e(),_vm._v(" "+_vm._s(_vm.appointment.data.client.name)+" "+_vm._s(_vm.appointment.data.client.first_name ? _vm.appointment.data.client.first_name : "")+" "),(_vm.thirdIconIsVisible(_vm.appointment))?_c('span',{style:({
                                backgroundColor: '#fff',
                                borderRadius: '2px',
                                float: 'right',
                                position: 'absolute',
                                right: '0',
                                top: '0',
                                marginTop: '3px',
                                marginBottom: '2px',
                                marginRight: _vm.calculateIconRightMargin(
                                    'third',
                                    _vm.appointment
                                ),
                                height: '18.5px',
                                width: '18.5px',
                                textAlign: 'center',
                            })},[_c('v-icon',{style:({
                                    fontSize: '15px',
                                    paddingBottom: '2px',
                                }),attrs:{"color":"red"}},[_vm._v("mdi-bell-cancel")])],1):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                                        _vm.appointment &&
                                            _vm.appointment.data &&
                                            _vm.appointment.data.order_id
                                    )?_c('span',_vm._g(_vm._b({style:({
                                        float: 'right',
                                        position: 'absolute',
                                        right: '0',
                                        top: '0',
                                        marginRight: _vm.calculateIconRightMargin(
                                            'fourth',
                                            _vm.appointment
                                        ),
                                        marginTop: '3px',
                                        marginBottom: '2px',
                                        backgroundColor: '#fff',
                                        borderRadius: '2px',
                                        height: '18.5px',
                                        width: '18.5px',
                                        textAlign: 'center',
                                    })},'span',attrs,false),on),[(
                                            _vm.appointment &&
                                                _vm.appointment.data &&
                                                _vm.appointment.data
                                                    .external_order_status &&
                                                _vm.appointment.data
                                                    .external_order_status !==
                                                    null
                                        )?[_c('v-icon',{style:({
                                                fontSize: '15px',
                                                paddingBottom: '2px',
                                            }),attrs:{"color":_vm.computeExternalOrderStatus(
                                                    _vm.appointment.data
                                                        .external_order_status
                                                ).color}},[_vm._v(" "+_vm._s(_vm.computeExternalOrderStatus( _vm.appointment.data .external_order_status ).icon)+" ")])]:[_c('v-icon',{style:({
                                                fontSize: '15px',
                                                paddingBottom: '2px',
                                            }),attrs:{"color":_vm.computeExternalOrderStatus(
                                                    'success'
                                                ).color}},[_vm._v(" "+_vm._s(_vm.computeExternalOrderStatus( "success" ).icon)+" ")])]],2):_vm._e()]}}],null,false,2274364510)},[(_vm.suppliersPresent)?_c('span',[_vm._v(" "+_vm._s(_vm.suppliersPresent)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t( "scheduler.text_messages.all_articles_from_internal_or_received" ))+" ")])]),(
                                _vm.appointment &&
                                    _vm.appointment.data &&
                                    _vm.appointment.data
                                        .is_end_of_client_tyre_hotel_article
                            )?_c('span',{style:({
                                float: 'right',
                                position: 'absolute',
                                right: '0',
                                top: '0',
                                marginRight: _vm.calculateIconRightMargin(
                                    'sixth',
                                    _vm.appointment
                                ),
                                marginTop: '3px',
                                marginBottom: '2px',
                                backgroundColor: '#fff',
                                borderRadius: '2px',
                                height: '18.5px',
                                width: '18.5px',
                                textAlign: 'center',
                            })},[_c('v-icon',{style:({
                                    fontSize: '15px',
                                    paddingBottom: '2px',
                                }),attrs:{"color":"red"}},[_vm._v(" mdi-clock-end ")])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                                        _vm.appointment &&
                                            _vm.appointment.data &&
                                            _vm.appointment.data.order_id
                                    )?_c('span',_vm._g(_vm._b({style:({
                                        float: 'right',
                                        position: 'absolute',
                                        right: '0',
                                        bottom: '0',

                                        marginRight: '2px',
                                        marginBottom: '2px',
                                        backgroundColor: 'transparent',
                                        color: '#fff',
                                        borderRadius: '2px',
                                        textAlign: 'center',
                                    }),on:{"click":function($event){$event.stopPropagation();return _vm.showAppointmentDetail(_vm.appointment)}}},'span',attrs,false),on),[(_vm.showAppointmentDetails)?_c('appointment-details',{attrs:{"showDialog":_vm.showAppointmentDetails,"data":_vm.appointment},on:{"update:showDialog":function($event){_vm.showAppointmentDetails=$event},"update:show-dialog":function($event){_vm.showAppointmentDetails=$event}}}):_vm._e(),_c('v-icon',{style:({
                                            fontSize: '18.5px',
                                            paddingBottom: '2px',
                                        }),attrs:{"color":"white"}},[_vm._v(" mdi-information ")])],1):_vm._e()]}}],null,false,3919290939)},[(
                                    _vm.appointment &&
                                        _vm.appointment.data &&
                                        _vm.appointment.data.order_id
                                )?[(
                                        _vm.appointment.data.order_type_id ==
                                            10241000
                                    )?[_vm._v(" "+_vm._s(_vm.$t("billings.types.estimation"))+" ")]:_vm._e(),(
                                        _vm.appointment.data.order_type_id ==
                                            10241001
                                    )?[_vm._v(" "+_vm._s(_vm.$t("billings.types.purchase_order"))+" ")]:_vm._e(),(
                                        _vm.appointment.data.order_type_id ==
                                            10241002
                                    )?[_vm._v(" "+_vm._s(_vm.$t("billings.types.bill"))+" ")]:_vm._e(),(
                                        _vm.appointment.data.order_type_id ==
                                            10241003
                                    )?[_vm._v(" "+_vm._s(_vm.$t("billings.types.credit_note"))+" ")]:_vm._e()]:[_vm._v(" "+_vm._s(_vm.$t( "scheduler.text_messages.appointment_detail" ))+" ")]],2),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.appointment && _vm.appointment.data)?_c('span',_vm._g(_vm._b({staticClass:"appointmentCut",style:({
                                        float: 'right',
                                        position: 'absolute',
                                        right: '0',
                                        bottom: '0',

                                        marginRight: '24px',
                                        marginBottom: '2px',
                                        backgroundColor: 'transparent',
                                        color: '#fff',
                                        borderRadius: '2px',
                                        textAlign: 'center',
                                    }),on:{"click":function($event){$event.stopPropagation();_vm.appointmentToPaste &&
                                        _vm.appointmentToPaste == _vm.appointment
                                            ? _vm.cancelAppointment()
                                            : _vm.copyAppointment(_vm.appointment)}}},'span',attrs,false),on),[_c('v-icon',{style:({
                                            fontSize: '18.5px',
                                            paddingBottom: '2px',
                                        }),attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.appointmentToPaste && _vm.appointmentToPaste == _vm.appointment ? "mdi-arrow-all" : "mdi-content-cut")+" ")])],1):_vm._e()]}}],null,false,146365681)},[_vm._v(" "+_vm._s(_vm.appointmentToPaste && _vm.appointmentToPaste == _vm.appointment ? _vm.$t( "scheduler.text_messages.cancel_appointment_move" ) : _vm.$t( "scheduler.text_messages.move_appointment" ))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                                        _vm.appointment &&
                                            _vm.appointment.data &&
                                            _vm.appointment.data
                                                .order_status_id == 10251003 // Payed
                                    )?_c('span',_vm._g(_vm._b({style:({
                                        float: 'right',
                                        position: 'absolute',
                                        right: '0',
                                        top: '0',
                                        marginRight: _vm.calculateIconRightMargin(
                                            'seventh',
                                            _vm.appointment
                                        ),
                                        marginTop: '3px',
                                        marginBottom: '2px',
                                        backgroundColor: '#fff',
                                        borderRadius: '2px',
                                        height: '18.5px',
                                        width: '18.5px',
                                        textAlign: 'center',
                                    })},'span',attrs,false),on),[_c('v-icon',{style:({
                                            fontSize: '15px',
                                            paddingBottom: '2px',
                                        }),attrs:{"color":"green"}},[_vm._v(" mdi-cash-check ")])],1):_vm._e()]}}],null,false,3143567190)},[_vm._v(" "+_vm._s(_vm.$t("scheduler.text_messages.order_payed"))+" "),(
                                    _vm.appointment &&
                                        _vm.appointment.data &&
                                        _vm.appointment.data.payments
                                )?[_vm._v(" "+_vm._s(_vm.generatePaymentText( _vm.appointment.data.payments ))+" ")]:_vm._e()],2),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                                        _vm.appointment &&
                                            _vm.appointment.data &&
                                            _vm.appointment.data.email_to
                                    )?_c('span',_vm._g(_vm._b({style:({
                                        float: 'right',
                                        position: 'absolute',
                                        right: '0',
                                        top: '0',
                                        marginRight: _vm.calculateIconRightMargin(
                                            'eighth',
                                            _vm.appointment
                                        ),
                                        marginTop: '3px',
                                        marginBottom: '2px',
                                        backgroundColor: '#fff',
                                        borderRadius: '2px',
                                        height: '18.5px',
                                        width: '18.5px',
                                        textAlign: 'center',
                                    }),on:{"click":function($event){$event.stopPropagation();return _vm.openEmailHistoryDialog(_vm.appointment)}}},'span',attrs,false),on),[_c('v-icon',{style:({
                                            fontSize: '15px',
                                            paddingBottom: '2px',
                                        }),attrs:{"color":"green"}},[_vm._v(" mdi-email-arrow-right-outline ")])],1):_vm._e()]}}],null,false,3527672912)},[_vm._v(" "+_vm._s(_vm.$t("scheduler.text_messages.email_sent"))+" ")])]:_vm._e(),(_vm.appointment.data.vehicle)?[(
                                _vm.appointment.data.client.name &&
                                    _vm.appointment.data.client.name.length <=
                                        22
                            )?[_c('br')]:[_vm._v(" - ")],(
                                _vm.appointment.data.vehicle.brand_id &&
                                    _vm.appointment.data.vehicle.brand
                            )?[_vm._v(" "+_vm._s(_vm.appointment.data.vehicle.brand.name)+" ")]:_vm._e(),(_vm.appointment.data.vehicle.model)?[_vm._v(" "+_vm._s(_vm.appointment.data.vehicle.model)+" / ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.appointment.data.vehicle.registration_number.includes( "#UNKNOWN" ) ? _vm.$t("unknown_reg_no") : _vm.appointment.data.vehicle .registration_number)+" ")]:_vm._e()]:_vm._e()],2),(_vm.appointment.data.note)?_c('span',{staticClass:"text-sm-left"},[_c('br'),_c('strong',[_vm._v(_vm._s(_vm.$t("scheduler.appointment.note")))]),_vm._v(" "+_vm._s(_vm.appointment.data.note)+" ")]):_vm._e(),(
                    _vm.appointment.data &&
                        _vm.appointment.data.original_start_time &&
                        _vm.appointment.data.original_start_time !=
                            _vm.appointment.data.start_time
                )?_c('span',{staticClass:"original_st"},[(_vm.firstIconIsVisible(_vm.appointment))?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-code-greater-than")]):_vm._e(),(_vm.secondIconIsVisible(_vm.appointment))?_c('v-icon',{style:({
                        marginRight: _vm.calculateIconRightMargin(
                            'second',
                            _vm.appointment
                        ),
                    }),attrs:{"color":"white"}},[_vm._v("mdi-code-less-than")]):_vm._e()],1):_vm._e(),(
                    _vm.appointment.data &&
                        _vm.appointment.data.depot_out_status_key
                )?_c('span',{staticClass:"original_st",staticStyle:{"margin-top":"2px"},style:({
                    marginRight: _vm.calculateIconRightMargin(
                        'fifth',
                        _vm.appointment
                    ),
                    backgroundColor: '#fff',
                    borderRadius: '2px',
                    height: '18.5px',
                    width: '18.5px',
                    textAlign: 'center',
                })},[_c('DepotStatus',{attrs:{"size":"15px","status-key":_vm.appointment.data.depot_out_status_key}})],1):_vm._e()])]),_c('span',{staticClass:"d-none",attrs:{"id":"cancel-move"},on:{"click":_vm.cancelAppointment}}),_c('OrderEmailHistory',{attrs:{"order":_vm.selectedAppointmentForDialog || {}},model:{value:(_vm.emailHistoryDialog),callback:function ($$v) {_vm.emailHistoryDialog=$$v},expression:"emailHistoryDialog"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }