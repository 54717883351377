var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"mouseenter":function($event){_vm.showDeleteButton = 'visible'},"mouseleave":function($event){_vm.showDeleteButton = 'hidden'}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.time),expression:"time"}],staticClass:"edit-time",style:({
        cursor: 'text',
        width: '75px'
      }),attrs:{"type":"time","id":_vm.elementId,"readonly":_vm.disabled},domProps:{"value":(_vm.time)},on:{"input":function($event){if($event.target.composing)return;_vm.time=$event.target.value}}}),(_vm.action === 'update' && _vm.target==='check_in' && !_vm.clockingIsForFirstColumn(_vm.clocking))?_c('v-btn',{staticClass:"mr-0",style:({
        visibility: _vm.showDeleteButton,
        cursor: 'pointer',
      }),attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.confirmDeleteAction(_vm.clocking, _vm.day)}}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-close")])],1):_vm._e(),(_vm.confirmationDialog)?_c('confirmation-model',{attrs:{"showDialog":_vm.confirmationDialog,"openedForOperation":_vm.confirmationDialogOperation,"text":_vm.confirmationDialogConfirmationText,"trueText":_vm.confirmationDialogTrueText,"falseText":_vm.confirmationDialogFalseText},on:{"update:showDialog":function($event){_vm.confirmationDialog=$event},"update:show-dialog":function($event){_vm.confirmationDialog=$event},"update:openedForOperation":function($event){_vm.confirmationDialogOperation=$event},"update:opened-for-operation":function($event){_vm.confirmationDialogOperation=$event},"update:text":function($event){_vm.confirmationDialogConfirmationText=$event},"update:trueText":function($event){_vm.confirmationDialogTrueText=$event},"update:true-text":function($event){_vm.confirmationDialogTrueText=$event},"update:falseText":function($event){_vm.confirmationDialogFalseText=$event},"update:false-text":function($event){_vm.confirmationDialogFalseText=$event},"buttonClicked":_vm.confirmationButtonClicked}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }