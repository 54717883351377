<template>
    <v-row>
        <v-col>
            <v-row>
                <v-spacer></v-spacer>
                <v-btn
                    id="top-create"
                    class="ma-8"
                    color="primary"
                    @click="addEditAddressModel = true;"
                >
                    {{ $t("nav_buttons.add") }}
                    <v-icon dark right> mdi-plus </v-icon>
                </v-btn>
                <!-- <v-col class="pa-0 pt-4 ma-0" cols="12" sm="3">
                    <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        class="mr-3 to-upper"
                        :label="$t('addresses.search')"
                        @input="debounceInput"
                        prepend-icon="mdi-magnify"
                        v-bind:clearable="true"
                        clear-icon="mdi-close"
                        v-model="search"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-text-field>
                </v-col> -->
            </v-row>
            <v-row>
                <v-data-table
                    :footer-props="{
                        'items-per-page-options': dataTableOptions,
                    }"
                    fixed-header
                    :headers="headers"
                    :options="options"
                    :server-items-length="totalItems"
                    :loading="isLoading"
                    :loading-text="this.$t('loading')"
                    class="elevation-0"
                    :height="'calc(-600px + 100vh)'"
                    style="width: 100%"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :items-per-page="itemsPerPage"
                    item-key="identifier"
                    @update:sort-by="updateSort"
                    @update:sort-desc="updateSortOrder"
                    @update:page="updatePagination"
                    @update:items-per-page="updateItemsPerPage"
                    :items="addresses"
                >
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <template v-if="item.address.building_id">
                                    {{ item.address.building_id }}, 
                                </template>
                                {{ item.address.street }}
                            </td>
                            <td>
                                {{ item.address.zip }}
                            </td>
                            <td>
                                <template v-if="item.address.city">
                                    {{ item.address.city.name }}
                                </template>
                            </td>
                            <td>
                                {{ item.address.county }}
                            </td>
                            <td>
                                <template v-if="item.address.city">
                                    {{ item.address.city.country.key }}
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </td>
                            <td>
                                <template v-if="item.address_type">
                                    {{ $t('address_types.' +item.address_type.key) }}
                                </template>
                            </td>
                            <td>
                                <v-icon
                                    v-if="item.is_default"
                                    class="mr-1"
                                    color="success"
                                >
                                    mdi-check
                                </v-icon>
                            </td>
                            <td>
                                <v-btn
                                    class="ma-2 text-white-color"
                                    color="primary"
                                    small
                                    @click="editAddress(item)"
                                >
                                    {{ $t("edit") }}
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-row>
        </v-col>
        <v-dialog
            persistent
            @click:outside="cancelAddress()"
            @keydown.esc="cancelAddress()"
            v-model="addEditAddressModel"
            v-if="addEditAddressModel"
            min-width="600"
            max-width="1024"
        >
            <v-card>
                <v-card-title class="text-title">
                    {{
                        selectedAddressForEdit
                            ? $t("addresses.edit_title")
                            : $t("addresses.add_title")
                    }}
                </v-card-title>

                <v-card-text class="dialog-text text-content" color="black">
                    <v-row>
                        <v-col cols="12" sm="12" class="pt-0 pb-0">
                            <AddressInput
                                v-model="address"
                                :showAddressType="true"
                                :currentAddressType.sync="selected_address_type"
                                :label="$t('billings.address')"
                                :in_two_lines="true"
                            />
                        </v-col>
                        <v-col cols="12" sm="2" class="pt-0 pb-0" style="margin-top: -100px; position: relative; left: 82%;">
                            <v-checkbox
                                class="ma-0 pa-0 pt-8"
                                v-model="is_default"
                                :label="$t('addresses.is_default')"
                                hide-details="auto"
                            >
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="pr-2">
                    <v-spacer></v-spacer>
                    <v-btn class="px-4" v-if="selectedAddressForEdit && selectedAddressForEdit.address_type && (selectedAddressForEdit.address_type.key != 'main_address'  ||  (selectedAddressForEdit.address_type.key == 'main_address' && selectedAddressForEdit.is_default == 0))" color="error" @click="deleteAddress()">
                        {{ $t("nav_buttons.delete") }}
                        <v-icon right>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                    <v-btn
                        class="px-4"
                        color="grey lighten-2"
                        @click="cancelAddress()"
                    >
                        {{ $t("addresses.cancel_btn_text") }}
                        <v-icon right>
                            mdi-keyboard-return
                        </v-icon>
                    </v-btn>
                    <v-btn
                        color="success"
                        dark
                        class="px-4"
                        @click="addEditAddress()"
                    >
                        {{ $t("save") }}
                        <v-icon dark right> mdi-content-save </v-icon>
                    </v-btn>
                </v-card-actions>

                <confirmation-model
                    v-if="confirmationDialog"
                    :showDialog.sync="confirmationDialog"
                    :openedForOperation.sync="confirmationDialogOperation"
                    :text.sync="confirmationDialogConfirmationText"
                    :trueText.sync="confirmationDialogTrueText"
                    :falseText.sync="confirmationDialogFalseText"
                    @buttonClicked="confirmationButtonClicked"
                ></confirmation-model>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import axios from "@/plugins/axios";
import _ from "lodash";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import ConfirmationModel from "@/components/ConfirmationModel";
import { validationMixin } from "vuelidate";
import AddressInput from "@/components/AddressInput";

export default {
    mixins: [validationMixin],
    name: "AddressTab",
    props: {
        client: [Object],
    },
    components: {
        ConfirmationModel,
        AddressInput
    },
    validations: {
        address: {
            required,
        },
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            addresses: [],
            alerts: [],
            search: "",
            isLoading: true,
            totalItems: 0,
            page: 1,
            lastPage: 1,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            options: {},
            sortBy: "",
            sortDesc: false,
            sortOrd: "ASC",
            sort: {},
            showAlert: false,
            token: this.$store.state.AccessToken,
            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
            autocompleteValueLocal: "off",
            isDebounceCancelled: false,
            addEditAddressModel: false,
            fetchedAddresses: [],
            isLoadingSearchAddress: false,
            isLoadingSearchCity: false,
            isLoadingSearchCounty: false,
            isLoadingSearchCountry: false,
            address: null,
            search_address: null,
            selectedAddressForEdit: null,
            is_default: false,

            confirmationDialog: false,
            confirmationDialogConfirmationText: null,
            confirmationDialogFalseText: null,
            confirmationDialogTrueText: null,
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,

            selected_address_type: null
        };
    },

    created() {
        (this.header = { Authorization: "Bearer " + this.token }),
        this.autocompleteValueLocal = this.autocompleteValue();
    },
    mounted() {
        this.fetchData();
    },
    activated(){

    },
    methods: {
        callDeleteAddress() {
            
            let data = {
                is_private : this.client.representative_company_id ? false : true
            };
            axios
                .delete(API_BASE_URL + "/client_addresses/" + this.client.id + "/" + this.selectedAddressForEdit.address_id, {
                    data: data,
                    headers: this.header,
                })
                .then((data) => {
                    this.$toast.success(
                        this.$t("addresses.text_messages.deleted_successfully")
                    );
                    this.fetchData();
                })
                .catch((error) => {
                    this.$toast.error(error);
                })
                .finally(() => {
                    
                    this.cancelAddress();
                });
        },
        deleteAddress() {
            this.confirmationDialogConfirmationText = this.$t(
                "addresses.delete_address_confirmation"
            );
            this.confirmationDialogFalseText = this.$t("addresses.cancel");
            this.confirmationDialogTrueText = this.$t(
                "addresses.delete_address"
            );
            this.confirmationDialogOperation = "delete_address";
            this.confirmationDialog = true;
        },
        confirmationButtonClicked(buttonClicked) {
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog = false;
        },
        cancelAddress() {
            this.selectedAddressForEdit = null;
            this.addEditAddressModel = false;

            this.address = null;
            this.is_default = false;
        },
        editAddress(item) {
            this.selectedAddressForEdit = item;
            this.addEditAddressModel = true;
        },
        addEditAddress() {
            this.$store.commit("setValidation", true); // enable validation
            if (this.$v.$invalid) {
                this.$toast.warning(this.$t("please_fill_form_correctly"));
                return false;
            } else {
                if (this.selectedAddressForEdit) {
                    this.updateAddress(this.makeNewClientAddress());
                } else {
                    this.addAddress(this.makeNewClientAddress());
                }
            }
        },
        addAddress(data) {
            
            axios
                .post(
                    API_BASE_URL + "/client_addresses/" + this.client.id,
                    data,
                    { headers: this.header }
                )
                .then((response) => {
                    this.$toast.success(
                        this.$t(
                            "addresses.text_messages.address_added_successfully"
                        )
                    );
                    this.fetchData();
                })
                .catch((error) => {
                    this.$toast.error(
                        this.$t(
                            "clients.text_messages." +
                                error.response.data.errors[0]
                        )
                    );
                })
                .finally(() => {
                    
                    this.cancelAddress();
                });
        },
        updateAddress(data) {
            delete data['address']['id']; // Remove id from address object so that it can be processed independently
            axios
                .put(
                    API_BASE_URL +
                        "/client_addresses/" +
                        this.client.id +
                        "/" +
                        this.selectedAddressForEdit.address_id,
                    data,
                    {
                        headers: this.header,
                    }
                )
                .then((response) => {
                    this.$toast.success(
                        this.$t(
                            "addresses.text_messages.address_updated_successfully"
                        )
                    );
                    this.fetchData();
                })
                .catch((error) => {
                    this.$toast.error(
                        this.$t(
                            "clients.text_messages." +
                                error.response.data.errors[0]
                        )
                    );
                })
                .finally(() => {
                    
                    this.cancelAddress();
                });
        },
        makeNewClientAddress() {
            return {
                address: this.address,
                is_default: this.is_default ? true : false,
                address_type: this.selected_address_type && typeof this.selected_address_type == 'object' ? this.selected_address_type.id : null
            };
        },
        debounceInput: _.debounce(function(e) {
            if (this.isDebounceCancelled) {
                this.isDebounceCancelled = false;
                return false;
            }
            this.fetchData();
        }, 500),
        fetchData() {
            this.isLoading = true;
            if(this.client && typeof this.client == 'object'){
                axios({
                    method: "get",
                    url: API_BASE_URL + "/client_addresses/" + this.client.id,
                    headers: this.header,
                })
                    .then((resAddresses) => {
                        this.addresses = resAddresses.data.data;
                        this.totalItems = resAddresses.data.data.length;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.$toast.success(
                            this.$t(
                                "schedulestab.something_went_wrong_while_fetching_data"
                            )
                        );
                    });
            }
        },
        updatePagination(page) {
            this.page = page;
            this.fetchData();
        },
        updateItemsPerPage(itemsPerPage) {
            itemsPerPage == -1
                ? (this.itemsPerPage = this.totalItems)
                : (this.itemsPerPage = itemsPerPage);
            this.fetchData();
        },
        updateSort(sortBy) {
            if (typeof sortBy == "object") {
                this.sortBy = sortBy[0];
            } else {
                this.sortBy = sortBy;
            }
            this.fetchData();
        },
        updateSortOrder(sortDesc) {
            if (typeof sortDesc == "object") {
                !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            } else {
                !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            }
            this.fetchData();
        },
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t("addresses.street"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.zipcode"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.city"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.county"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.country"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("address_types.title"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.is_default"),
                    value: "address",
                    sortable: false,
                },
                {
                    text: this.$t("addresses.action"),
                    value: "address",
                    sortable: false,
                },
            ];
        },
    },
    watch: {
        confirmationDialogButtonClickedIs(newVal) {
            if (newVal == true && this.confirmationDialogOperation != null) {
                // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
                if (this.confirmationDialogOperation == "delete_address") {
                    if (this.confirmationDialogButtonClickedIs == true) {
                        this.callDeleteAddress();
                    }
                }
                this.confirmationDialogButtonClickedIs = null;
            }
        },
        selectedAddressForEdit(val) {
            if (val) {
                this.address = val.address;
                this.$nextTick(() => {
                    this.selected_address_type = val.address_type;
                })
                this.is_default = val.is_default;
            } else {
                this.address = null;
                this.is_default = false;
            }
        },
    },
};
</script>
<style scoped></style>
