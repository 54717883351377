<template>
                <v-row class="pa-0 mt-n3">
                  <!-- <v-col class="d-flex" cols="2" sm="2">
                    <v-select
                        :autocomplete="autocompleteValueLocal"
                        v-model="familyArticle"
                        :items="familyArticles"
                        :placeholder="$t('articles.family')"
                        item-value="id"
                        item-text="name"
                        @change="updateLocalStorageValues(true)"
                        v-on:keydown.enter.prevent="focusOnFamilyArticle();"
                        class="mr-0"
                        ref="familyArticle"
                        return-object
                        :prepend-icon="normalSearch ? 'mdi-grid' : 'mdi-rectangle-outline'"
                        @click:prepend="normalSearch = !normalSearch"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                    ></v-select>
                    </v-col> -->
                    <!-- <v-col class="d-flex" cols="12" sm="9">
                        <v-text-field
                          :autocomplete="autocompleteValueLocal"
                          v-if="familyArticle.id != 1 &&  familyArticle.id != 2 "
                          class="mr-3 to-upper"
                          label="Search"
                          v-bind:clearable="true"
                          clear-icon="mdi-close"  
                          v-model="search"
                          v-on:keydown.enter.prevent="focusOnFamilyArticle();"
                          @input="debounceInput"
                          :outlined="is_boxes"
                          :dense="is_boxes"
                        ></v-text-field>
                        <v-select
                            :autocomplete="autocompleteValueLocal"
                            v-if="familyArticle.id == 1"
                            :items="seasons"
                            @change="updateLocalStorageValues()"
                            v-model="season"
                            class="d-flex ma-0 pa-0 pt-4 mr-2 to-upper shrink"
                            style="width:80px"
                            item-value="value"
                            item-text="value"
                            hide-details="auto"
                            v-on:keydown.enter.prevent="focusOnFamilyArticle();"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        >
                          <template v-slot:selection="{ item }">
                            <span>
                                <v-icon :color="item.color">{{ item.icon }}</v-icon>
                            </span>
                          </template>
                          <template slot="item" slot-scope="{ item }">
                              <v-list-item-content class="pa-0 ma-0">
                                <span>
                                    <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                </span>
                              </v-list-item-content>
                          </template>
                        </v-select>
                        <div v-for="(filter,index) in customFilters" :key="index">
                          <v-text-field  
                              :autocomplete="autocompleteValueLocal"
                              flat
                              v-model="filter.value"
                              :placeholder="filter.text"
                              class="mr-2 to-upper"
                              :ref="filter.property_name"
                              v-on:keydown.enter.prevent="focusOnFamilyArticle();"
                              @focus="filter.value='';resetFurtherBoxesValues(filter.property_name); updateLocalStorageValues();"
                              @input="debounceInput"
                              @keypress="handleInput($event,filter.keytype,filter.search_length);"
                              @keyup="handleAutoFocus($event,filter.search_length,filter.property_name,filter.keytype);updateLocalStorageValues();"
                              :outlined="is_boxes"
                              :dense="is_boxes"
                          ></v-text-field>
                        </div>
                        <v-autocomplete
                            :autocomplete="autocompleteValueLocal"
                            v-on:keydown.enter.prevent="focusOnFamilyArticle();"
                            v-if="familyArticle.id == 1 || familyArticle.id == 2 || familyArticle.id == 5"
                            flat
                            ref="brand"
                            :items="brands"
                            v-model="brand"
                            :placeholder="$t('articles.properties.brand')"
                            class="mr-2 to-upper"
                            item-value="id"
                            item-text="name"
                            @focus="brand='';"
                            return-object
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            hide-no-data
                            auto-select-first
                            hide-no-data
                        ></v-autocomplete>

                    <v-select
                      v-if="articleBooleanProperties.length >= 1"
                      v-model="booleanSelectedValues"
                      :label="$t('articles.properties.more')"
                      :items="articleBooleanProperties"
                      v-on:keydown.enter.prevent="focusOnFamilyArticle();"
                      item-value="id"
                      item-text="name"
                      multiple
                      :outlined="is_boxes"
                      :dense="is_boxes"
                      return-object
                    >
                      <template v-slot:selection="{ item, index }">
                        <span>
                          <template v-if="index === 0 && booleanSelectedValues.length == 1">
                            {{ item.name.substr(0, booleanSelectedValues.length > 1 ? 3 : 5) }}
                          </template>
                          <template
                            v-if="index === 1"
                            class="grey--text caption"
                          >
                            (+{{ booleanSelectedValues.length - 1 }})
                          </template>
                        </span>
                      </template>
                    </v-select>
                    </v-col> -->
                    <v-col class="d-flex" cols="12" sm="9">
                      <v-text-field
                        :autocomplete="autocompleteValueLocal"
                        class="mr-3 to-upper"
                        label="Search"
                        v-bind:clearable="true"
                        clear-icon="mdi-close"  
                        v-model="searchEAN"
                        ref="searchEAN"
                        v-on:keydown.enter.prevent="fireShowDialogBasedUponSearchLength()"
                        @keyup="updateLocalStorageValues();"
                        @change="updateLocalStorageValues();"
                        :outlined="is_boxes"
                        :dense="is_boxes"
                      ></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="1">
                      <v-btn color="primary" dark class="mt-0 ml-n5" @click="dialog = true">
                        <v-icon dark>mdi-database-search</v-icon>
                      </v-btn>
                    </v-col>
                  <search-article-model 
                    @changeDialog="changeDialog()" 
                    :showFamilyArticle="true"
                    :normalSearch.sync="normalSearch"
                    :familyArticleId.sync="familyArticle"
                    :compSelectedArticleId.sync="parentSearchSelectedArticleId"
                    :internalStockOnly.sync="internalStockOnly"
                    :class="dialog == true ? 'articleModelOpened' : 'articleModelNotOpened'" 
                    :showDialog.sync="dialog"
                  ></search-article-model>   

                <v-data-table
                      style="display:none"
                      fixed-header
                      :footer-props="{
                        'items-per-page-options': dataTableOptions
                      }"
                      :headers="customHeader"
                      :items="articles"
                      :items-per-page="itemsPerPage"
                      :options.sync="options"
                      :sort-by="sortBy"
                      @update:page="updatePagination"
                      @update:items-per-page="updateItemsPerPage"
                      class="elevation-1"
                      item-key="identifier"
                      :height="articles.length <= 1 ? '0px' :'0px'"
                  >
                  </v-data-table>
                  
                </v-row>
</template>

<script>
import axios from '@/plugins/axios';
import _ from 'lodash';
import {API_BASE_URL} from "@/config";
import {VIEW_MODE} from "@/config";
import {EDIT_MODE} from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from '@/config';
import SearchArticleModel from '@/components/SearchArticleModel.vue';

export default {
    name:       "Articles",
    components: {
      "search-article-model" : SearchArticleModel
    },
    props: {
      compSelectedArticleId: [String, Number, Object ],
      internalStockOnly : [Boolean, Number],
      compSearchTextBox: [String, Number]
    },
    async mounted() {
        this.autocompleteValueLocal = this.autocompleteValue();
        this.header = { Authorization: 'Bearer ' + this.token },
        this.$store.commit('setAddButtonRoute', 'AddArticle','EditArticle');
        this.setDefaultFamilyArticle();
    },
    data() {
        return {
            booleanSelectedValues: [],
            is_boxes: this.getCompanyPropertyFromLocalStorage('boxes_on_fields', 1),
            normalSearch:                         true,
            seasons: [
              {"icon":"mdi-check-all",                  "color":"grey darken-1",         "text":"no_season",         "value":"0"         },
              {"icon":"mdi-weather-sunny",              "color":"amber darken-4",        "text":"summer",  "value":"10111002"  },
              {"icon":"mdi-weather-snowy-heavy",        "color":"blue darken-2",         "text":"winter",  "value":"10111001"  },
              {"icon":"mdi-weather-partly-snowy-rainy", "color":"green darken-1",        "text":"all_season",  "value":"10111003"  },
            ],
            parentSearchSelectedArticleId : null,
            
            autocompleteValueLocal : 'off',
            dialog: false,
            selectedArticleId :  this.compSelectedArticleId,
            search:'',
            isFirstTimeLoaded : 0,
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            isLoading:        false,
            articles:         [],
            article:          '',
            page:             1,
            lastPage:         null,
            totalItems:       null,
            itemsPerPage:     DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            sortBy:          'description',
            sortDesc:         false,
            options:          {},
            pagination:       {},
            season:           0,
            widths:           ['205', '255', '245', '145', '225'],
            width:            '',
            heights:          ['45', '55', '60', '65', '75', '85', '95'],
            height:           '',
            rims:             ['11', '12', '13', '14', '15', '16', '17', '18', '19'],
            rim:              '',
            lis:              ['90', '91', '100', '120', '130', '140', '150', '160', '170', '180', '190'],
            li:               '',
            sis:              ['H', 'V', 'W'],
            si:               '',
            brands:           [],
            brand:            null,
            approval:         {id: 10011001},
            status:           {id: 10001001},
            articleBooleanProperties: [],
            familyArticle:    {},
            loadingFirstTime: 0,
            ifCurrentlyPaused : false,
            changingArticlePropertiesInProgress : false,
            customHeader: [
                { text: this.$t('articles.id'), value:'id', align:'start', sortable: false, width: 5},
                { text: this.$t('articles.image'), value:'image', align: 'start',sortable: false,width:    30},
                { text: this.$t('articles.ean'), value: 'identifier', align: 'start', sortable: true,width:    140},
                { text: this.$t('articles.description'), align: 'start', sortable: true, value: 'description' },
                { text: this.$t('articles.tariff_price'), align: 'end', sortable: false, value: 'tariff_price' }
            ],
            customFilters : [],
            customFiltersExceptions : [1,2], // these numbers represents property_id value example : property_id = 1 
            token: this.$store.state.AccessToken,
            identifier: null,
            header: { Authorization: 'Bearer ' + this.$store.state.AccessToken },
            searchEAN: null,
            isDebounceCancelled: false
        }
    },
    methods: {
        debounceInput: _.debounce(function (e) {
          if(this.isDebounceCancelled){
            this.isDebounceCancelled = false;
            return false;
          }
          this.fetchDataWithEAN();
        }, 500),
        changeDialog(){
          this.applyPreviousFilters();
          this.dialog=false;
        },
        fireShowDialogBasedUponSearchLength(){
          if( ! (this.searchEAN.length == 13)){
            this.search = this.searchEAN;
            this.updateLocalStorageValues();
            this.showDialog();
          }
          else{
            this.fetchDataWithEAN();
          }
        },
        showDialog() {
          this.parentSearchSelectedArticleId = null;
          this.dialog = true;
        },
        /**
         * This Function is responsible to autofocus on next property field, if current's max length reached
         */
        handleAutoFocus(event,search_length,propertyName,dataType, forceToJumpOnProperty){
          let currentFieldLength = 0;
          if(dataType == 'int' || dataType == 'float'){
            currentFieldLength = (event.target.value + '').replace('.', '').length; 
          }
          else{
            currentFieldLength = (event.target.value + '').length; 
          }
          if(forceToJumpOnProperty && search_length != null && currentFieldLength == search_length){
            this.$refs[forceToJumpOnProperty][0].focus();
          }
          else if(propertyName.search("property") == 0 && search_length != null && currentFieldLength == search_length){
            let underscorePos = propertyName.search("_") + 1;
            let propertyNo = Number(propertyName.substr(underscorePos)) + 1;
            let nextPropertyName = "property_" + (propertyNo < 10 ? '0' : '') + propertyNo;
            this.$refs[nextPropertyName][0].focus();
          }
        },
        focusOnFamilyArticle(){
          this.$refs['familyArticle'].focus();
          this.$nextTick(() => {
            this.dialog = true;
          });
        },
        getFamilyArticleById(id){
          let familyArticle = 0;
          this.familyArticles.forEach((element) => {
            if(element.id == id){
              familyArticle = element;
            }
          });
          
          return familyArticle;
        },
        resetFurtherBoxesValues(focussedPropertyName){
          var foundProperty = false;
          this.customFilters.forEach((filter,index) => {
            if(filter.property_name == focussedPropertyName){
              foundProperty = true;
            }
            if(foundProperty){
              this.customFilters[index].value = null;
            }
          });
        },
        updateLocalStorageValues(familyUpdated){
          var filters = {
            "normalSearch": this.normalSearch,
            "page" : this.page,
            "items_per_page" : this.itemsPerPage,
            "order_by" : this.sortBy,
            "order_direction" : this.sortDesc,
            // "approval" : this.approval.id,
            // "status" : this.status.id,
            "family" : this.familyArticle.id,
            // "season" : this.season,
            // "brand" : familyUpdated ? null : (this.brand != null ? this.brand.id : ""),
            "search" : this.search ? this.search : (this.searchEAN ? this.searchEAN : null),
            // "appliedFilters" : familyUpdated ? [] : this.getQueryStringFromDynamicallyCreatedFilters()[1],
            // "id" : this.article ? this.article.id : null,
            "identifier": this.searchEAN ? this.searchEAN : null
          };
          localStorage.setItem("ARTICLE_MODEL_FILTERS",JSON.stringify(filters));
        },
        applyPreviousFilters(){
          if(this.customFilters.length > 0){
            this.resetFurtherBoxesValues(this.customFilters[0].property_name);
            this.search = null;
            this.brands = [];
            this.brand = null;
          }
          let applyOnRoutes = ['Receptions','AddReceptions','EditReceptions'];
          if(applyOnRoutes.includes(localStorage.getItem("NT_ROUTE_KEY")) ){
            var articleFilters = JSON.parse(localStorage.getItem("ARTICLE_MODEL_FILTERS"));
            if(articleFilters != null){
              this.normalSearch = articleFilters.normalSearch;
              this.page = articleFilters.page;
              this.itemsPerPage = articleFilters.items_per_page;
              this.sortBy = articleFilters.order_by ? articleFilters.order_by : 'id';
              this.sortDesc = articleFilters.order_direction;
              this.approval.id = articleFilters.approval;
              this.status.id = articleFilters.status;
              this.familyArticle = articleFilters.family ? this.getFamilyArticleById(articleFilters.family) : this.familyArticles[0];
              this.season = articleFilters.season;
              
              this.search = articleFilters.search;

              this.customFilters.forEach((element) => {
                let keyName = element.property_name; 
                this.customFilters.find((el,index) => {
                  if(el.property_name == keyName){
                    this.customFilters[index].value =  null;
                    el.value = element[keyName];
                    return el;
                  }
                });
              });
              if(articleFilters.appliedFilters){
                this.booleanSelectedValues = [];
                articleFilters.appliedFilters.forEach((element) =>{
                  let keyName = Object.keys(element)[0];
                  // now map from custom filters and update their value
                  this.customFilters.find((el,index) => {
                    if(el.property_name == keyName){
                      this.customFilters[index].value =  element[keyName];
                      el.value = element[keyName];
                      return el;
                    }
                  });
                  
                  this.articleBooleanProperties.find((el,index) => {
                    if(el.property_name == keyName){
                      let foundBoolean = false;
                      this.booleanSelectedValues.forEach((selectedBoolean) => {
                        if(selectedBoolean.property_id == el.property_id){
                          foundBoolean = true;
                        }
                      });
                      if(! foundBoolean){
                        this.booleanSelectedValues.push(el);
                      }
                      return el;
                    }
                  });
                });
              }

              // set brand
              this.brands = articleFilters.brand ? [articleFilters.brand] : []; 
              this.brand = articleFilters.brand;

              
              this.fetchData();
            }
          }
          
        },
        checkIfFetchingPossible(){
          let isPossible = false;
          this.ifCurrentlyPaused = false;

          let valueAssigned = 0;
          this.customFilters.forEach((element) => {
            if(element.value != null){
              valueAssigned++;
            }
          });
          if(valueAssigned!=0){
            isPossible = true;
          }
          else{
            isPossible = false;
          }
          
          if( ! isPossible){
            this.ifCurrentlyPaused = true;
          }

          // if(this.familyArticle.id == 0 || this.familyArticle.id == 3 ||  this.familyArticle.id == 4){
          //   isPossible = true;
          // }
          
          if(this.changingArticlePropertiesInProgress){
            isPossible = false;
          }
          return isPossible;
        },
        fetchData() {
            this.articles = []; // remove the data from the table
            if( this.checkIfFetchingPossible() ){
              this.isLoading = true;
              const {sortBy, sortDesc, itemsPerPage, page} = this.options;

              this.$emit('update:compSelectedArticleId', null ); // remove selected article
              var filters = {
                "page" : this.page,
                "items_per_page" : this.itemsPerPage,
                "order_by" : this.sortBy,
                "order_direction" : this.sortDesc,
                // "approval" : this.approval.id,
                // "status" : this.status.id,
                // "family" : this.familyArticle.id,
                // "season" : this.season,
                // "brand" : this.brand != null ? this.brand.id : "",
                // "search" : this.search,
                // "appliedFilters" : this.getQueryStringFromDynamicallyCreatedFilters()[1],
                // "id" : this.article ? this.article.id : null,
                "identifier": this.searchEAN ? this.searchEAN : null
              };
              localStorage.setItem("ARTICLE_MODEL_FILTERS",JSON.stringify(filters));
              
            }
            
        },
        fetchDataWithEAN() {
            this.$emit('update:compSearchTextBox', this.searchEAN);
        },
        setDefaultFamilyArticle(){
            let defaultFamilyArticle = null;
            this.familyArticles.forEach((element) => {
                if(element.is_default){
                defaultFamilyArticle = element;
                }
            })
            if(! defaultFamilyArticle){
                this.familyArticle = this.familyArticles[0];
            }
            else{
                this.familyArticle = defaultFamilyArticle;
            }
        },
        selectItem(item) {
          this.$emit('update:compSelectedArticleId', item.id );
          this.showDialog = false;
        },
        showImage(item) {
            alert('THE IMAGE');
        },
        deleteItem(item) {

        },
        changeArticleProperties(){
          var currentFamilyArticle = this.familyArticles.find((element) => {
            if(element.id == this.familyArticle.id)
              return element;
          });
          this.search = null;
          // hard coding for family : todo later make it dynamic
          if(currentFamilyArticle.id === 2){
            this.season = "";
          }
          
          this.articleBooleanProperties = currentFamilyArticle.properties.filter((property) => {
              if(property.cast == 'boolean' && property.in_filter == 1) {
                property.name = property.translated ? property.name : this.$t('articles.properties.'+property.name);
                property.property_name = 'property_'+ (property.property_id < 10 ? 0 + property.property_id.toString() : property.property_id);
                property.translated = true;
                return property;
              }
              return false;
          });
        },
        setCustomDataTableHeaders(){
          var currentFamilyArticle = this.familyArticles.find((element) => {
            if(element.id == this.familyArticle.id)
              return element;
          });
          if(this.familyArticle.id == 1 || this.familyArticle.id == 2 ){
            this.customHeader = [
                  { text: this.$t('articles.id'), value:'id', align:'start', sortable: false, width: 5},
                  { text: this.$t('articles.image'), value:'image', align: 'start',sortable: false,width:    30},
                  { text: this.$t('articles.ean'), value: 'identifier', align: 'start', sortable: true,width:    140},
                  { text: this.$t('articles.description'), align: 'start', sortable: true, value: 'description' },
                  { text: this.$t('articles.tariff_price'), align: 'end', sortable: false, value: 'tariff_price' },
                  { text: this.$t('articles.stock'), align: 'end', sortable: false, value: 'stock' },
                  { text: this.$t('articles.reserve'), align: 'end', sortable: false, value: 'reserve' },
                  { text: this.$t('articles.stock_reserve'), align: 'end', sortable: false, value: 'stock-reserve' }
            ];
          }
          
          else if(this.familyArticle.id == 3){
            this.customHeader = [
                  { text: this.$t('articles.id'), value:'id', align:'start', sortable: false, width: 5},
                  { text: this.$t('articles.ean'), value: 'identifier', align: 'start', sortable: true,width:    180},
            ];
          }
          else if(this.familyArticle.id == 4){
            this.customHeader = [
                  { text: this.$t('articles.id'), value:'id', align:'start', sortable: false, width: 5},
                  { text: this.$t('articles.ean'), value: 'identifier', align: 'start', sortable: true,width:    180},
            ];
          }
          else if(this.familyArticle.id == 0){
            this.customHeader = [
                  { text: this.$t('articles.id'), value:'id', align:'start', sortable: false, width: 5},
                  { text: this.$t('articles.ean'), value: 'identifier', align: 'start', sortable: true,width:    180},
                  { text: this.$t('articles.original_name'), align: 'start', sortable: true, value: 'original_name' },
            ];
          }
          else{
            this.customHeader = [
                  { text: this.$t('articles.id'), value:'id', align:'start', sortable: false, width: 5},
                  { text: this.$t('articles.ean'), value: 'identifier', align: 'start', sortable: true,width:    140},
            ];
          }

          this.renderedFilters = [];
          currentFamilyArticle.properties.filter((property) => {
              if( property.in_grid == 1) {
                this.customHeader.push({
                  text:this.$t('articles.properties.' + property.name),
                  value: 'property_'+ (property.property_id < 10 ? 0 + property.property_id.toString() : property.property_id) ,
                  align:'start',
                  sortable:true,
                });
                return true;
              }
            });
          this.modifyOrderingOfCustomHeaders();
        },
        /**
         * This function is used to modify the ordering of Headers in Listing
         * Note : For ordering you must know the column name 
         */
        modifyOrderingOfCustomHeaders(){
          var sortedArray = [];
          var nonSortedArray = [];
          var sortingArray = [];
          if(this.familyArticle.id == 1 || this.familyArticle.id == 2){
              sortingArray = [this.$t('articles.id'),this.$t('articles.image'),this.$t('articles.properties.type'),this.$t('articles.properties.season'),this.$t('articles.properties.name'),this.$t('articles.ean'),this.$t('articles.properties.width'),this.$t('articles.properties.height'),this.$t('articles.properties.rim'),this.$t('articles.properties.li'),this.$t('articles.properties.si'),this.$t('articles.tariff_price')];
          }
          else{
              sortingArray = [this.$t('articles.id'),this.$t('articles.image'),this.$t('articles.ean'),this.$t('articles.properties.name')];
          }
          
          var processedArray = [];
          sortingArray.forEach((element, indexNo) => {
            for (const [key, value] of Object.entries(this.customHeader)) {
              let added = false;
              
              if(element == value.text  ){
                sortedArray.push(value);
                processedArray.push(value.text);
                added = true;
              }
              if(added == false && processedArray.find(element => element == value.text) == undefined && sortingArray.length == indexNo + 1){
                nonSortedArray.push(value);
              }
            }
          });

          this.customHeader = sortedArray.concat(nonSortedArray);
        },
        setDynamicFilterBoxes(){
          var currentFamilyArticle = this.familyArticles.find((element) => {
            if(element.id == this.familyArticle.id)
              return element;
          });
          this.customFilters = [];
          let filterPropertyCount = 0;
          currentFamilyArticle.properties.filter((property) => {
              if( property.in_filter == 1 && ! this.customFiltersExceptions.includes(property.property_id))  {
                
                if(property.cast != 'boolean'){
                  this.customFilters.push({
                    text:this.$t('articles.properties.' + property.name),
                    align:'start',
                    sortable:true,
                    property_name: 'property_'+ (property.property_id < 10 ? 0 + property.property_id.toString() : property.property_id) ,
                    property_id: property.property_id,
                    column_name: property.name,
                    value:null,
                    property_type: property.cast,
                    mapping : property.mapping ?? null, 
                    keytype : property.cast,
                    search_length : property.search_length,
                  });
                }
                return true;
              }
              return false;
            });
            if(this.isFirstTimeLoaded == 0){
              this.applyPreviousFilters();
              this.isFirstTimeLoaded++;
            }
            this.changingArticlePropertiesInProgress = false;
        },
        getQueryStringFromDynamicallyCreatedFilters(){
          var queryString = "";
          var appliedFilters = [];
          this.customFilters.forEach(filter => {
            if(filter.value){
              queryString += '&'+filter.property_name+"="+filter.value
              appliedFilters.push({
                  [filter.property_name]  : filter.value
              });
            }
          });
          this.booleanSelectedValues.forEach(filter => {
              queryString += '&'+filter.property_name+"=" + 1 // 1 means true
              appliedFilters.push({
                  [filter.property_name]  : 1 // 1 means true
              });
          });
          return [queryString,appliedFilters];
        },
        updatePagination (page) {
          this.page = page;
          this.fetchData();
        },
        updateItemsPerPage (itemsPerPage) {
          this.itemsPerPage = (itemsPerPage == -1 ? this.totalItems : itemsPerPage)
          this.fetchData();
          this.itemsPerPage = (this.itemsPerPage == -1 ? -1 : itemsPerPage);
        },
    },
    computed: {

    },
    watch:   {
        dialog(val){
          this.applyPreviousFilters();
        },
        parentSearchSelectedArticleId(val){
          if(val){
            this.$emit('update:compSelectedArticleId', val );
          }
        },
        compSelectedArticleId(val){
          this.searchEAN = val;
          this.parentSearchSelectedArticleId = val;
        },
        compSearchTextBox(val){
          this.searchEAN = val;
          this.updateLocalStorageValues();
        },
        familyArticle:  {
          handler: function(){
            
            this.changingArticlePropertiesInProgress = true;

            this.brand = null;
            this.brands = [];

            if(this.loadingFirstTime != 0 ){
              this.fetchData();
            }
            if(this.familyArticle ){
              
              if(this.familyArticle && (this.familyArticle.id == 1 || this.familyArticle.id == 2)){
                this.season = this.seasons[0].value;
              }

              
              this.changeArticleProperties();
              this.setCustomDataTableHeaders();
              this.setDynamicFilterBoxes();
              

              if(this.familyArticle == 1){
                this.$nextTick(() => 
                  this.season = 0
                )
              }
            }
            
          },
        },
        search: {
          handler: function(){
            _.debounce(function (e) {
              this.fetchData();
            }, 500)
          },
        },
        brand:  {
          handler: function(){
            this.fetchData();
          },
        },
        season:  {
          handler: function(){
            if(this.loadingFirstTime != 0 ){
              this.fetchData();
            }
            this.loadingFirstTime++;
          },
        },
        status:  {
          handler: function(){
            this.fetchData();
          },
        },
        approval: {
          handler: function(){
            this.fetchData();
          },
        },
    }
}
</script>

<style scoped>
    .to-upper input {
        text-transform: uppercase !important;
    }
    .articleModelOpened{
      display: flex !important;
    }
    .articleModelNotOpened{
      display: none !important;
    }
</style>
