<template>
  <v-dialog :value="value" max-width="700">
  <v-card>
    <v-card-title>
      <span class="headline">{{ translate('duplicate_title', {month: monthLabel}) }}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-menu
                v-model="monthPickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="formattedSelectedMonth"
                    :label="translate('select_new_month')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  type="month"
                  v-model="selectedMonth"
                  @input="monthPickerMenu = false"
                  :min="nextMonth"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <span @click="close" class="mr-2"> <CloseButton/> </span>
      <span @click="toggleDuplicateConfirmation"> <SaveButton :label="translate('duplicate')" /> </span>
    </v-card-actions>
  </v-card>
    <confirmation-model
        v-if="confirmationDialog"
        :showDialog.sync="confirmationDialog"
        :text.sync="confirmationDialogConfirmationText"
        :trueText.sync="confirmationDialogTrueText"
        :falseText.sync="confirmationDialogFalseText"
        @buttonClicked="confirmationButtonClicked"
    ></confirmation-model>
  </v-dialog>
</template>
<script>
import clockingMixin from "@/mixins/clockingMixin";
import moment from "moment";
import CloseButton from "@/components/Buttons/CloseButton.vue";
import SaveButton from "@/components/Buttons/SaveButton.vue";
import ConfirmationModel from "@/components/ConfirmationModel.vue";
import _axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";
export default {
  name: 'DuplicateEmployeeWorkingShiftsModal',
  components: {SaveButton, CloseButton,  ConfirmationModel},
  props: ['value', 'currentMonth', 'monthLabel'],
  emits: ['input', 'duplicated'],
  mixins: [clockingMixin],
  data() {
    return {
      month: null,
      monthPickerMenu: false,
      selectedMonth: null,
      confirmationDialog: false,
      confirmationDialogConfirmationText: '',
      confirmationDialogTrueText: '',
      confirmationDialogFalseText: ''
    }
  },
  computed: {
    formattedSelectedMonth() {
      if (!this.selectedMonth) return '';
      return moment(this.selectedMonth).locale(this.$store.state.languageId).format('MMMM YYYY')
    },
    nextMonth(){
      return moment(this.currentMonth).add(1, 'month').format('YYYY-MM')
    }
  },
  methods: {
    close(){
      this.$emit('input', false)
    },
    toggleDuplicateConfirmation(employeeWorkingShiftId) {
      this.confirmationDialog = true;
      this.confirmationDialogConfirmationText = this.translate('duplicate_confirmation_text');
      this.confirmationDialogFalseText = this.translate('no');
      this.confirmationDialogTrueText = this.translate('yes');
      this.confirmationDialogButtonClickedIs = 'duplicate';
      this.confirmationDialogOperationId = employeeWorkingShiftId;
    },
    confirmationButtonClicked(action) {
      if (action === true && this.confirmationDialogButtonClickedIs === 'duplicate') {
        this.handleDuplicate(this.confirmationDialogOperationId)
      }
    },
    handleDuplicate(){
      return new Promise((resolve) => {
        return _axios.put(API_BASE_URL + '/employees/employees_working_shifts/duplicate', {
          from_month: this.currentMonth,
          to_month: this.selectedMonth
        }).then(() => {
          this.$emit('duplicated', this.selectedMonth)
          this.close()
        })
      })
    }
  }
}
</script>