<template>
    <v-card flat>
        <v-form
            ref="form"
            class="main_form"
            id="billing-form"
            @submit.prevent="submit"
        >
            <input type="hidden" name="id" />
            <v-container fluid>
                <v-overlay :value="!isPageLoaded">
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>
                <v-row class="ma-0 pa-0 align-self-center">
                    <!-- Selected Bill -->
                    <v-col class="d-flex pa-0 ma-0">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-autocomplete
                                    v-if="!changeSelectedBillIdentifierForTemp"
                                    :autocomplete="autocompleteValueLocal"
                                    v-model="selectedBill"
                                    class="mr-2 to-upper"
                                    :items="bills"
                                    :search-input.sync="search_bill"
                                    item-text="bill_number"
                                    item-value="id"
                                    :loading="isLoadingSearchRegiClient"
                                    :label="$t('billings.search_bill')"
                                    :placeholder="$t('billings.start_typing')"
                                    return-object
                                    :append-icon="
                                        isEditingAllowedOfIdentifier &&
                                        selectedBill &&
                                        billType &&
                                        billType.key == 'bill'
                                            ? 'mdi-pencil'
                                            : ''
                                    "
                                    :clearable="selectedBill ? true : false"
                                    clear-icon="mdi-close"
                                    v-on:keydown="
                                        handleInput($event, 'alphanumeric', 20)
                                    "
                                    v-on:keydown.enter.prevent=""
                                    ref="billDropdown"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click:clear="
                                        onResetPageData();
                                        search_bill = null;
                                        looseFocusFromBillBox();
                                    "
                                    @click:append="
                                        changeSelectedBillIdentifier()
                                    "
                                    :outlined="is_boxes"
                                    :hide-no-data="true"
                                    hide-selected
                                    dense
                                    hide-details
                                    :style="{
                                        maxWidth: '350px',
                                        minWidth: '350px',
                                        fontSize: '16px',
                                    }"
                                >
                                    <template #append-outer v-if="selectedBill">
                                        <span
                                            v-if="
                                                selectedBill &&
                                                    (isset(
                                                        selectedBill.child_order_id
                                                    ) ||
                                                        isset(
                                                            selectedBill.parent_order_id
                                                        ))
                                            "
                                            class="mt-n2"
                                        >
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon
                                                        class="mt-0"
                                                    >
                                                        <v-icon
                                                            color="warning"
                                                            @click="
                                                                openOrderHistoryModal
                                                            "
                                                            >mdi-history</v-icon
                                                        >
                                                    </v-btn>
                                                </template>
                                                {{
                                                    $t(
                                                        "billings.linked_document_tooltip_text"
                                                    )
                                                }}
                                            </v-tooltip>
                                        </span>
                                        <span v-if="selectedBill" class="mt-n2">
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="
                                                            openHistoryTableDialog
                                                        "
                                                        icon
                                                        class="mt-0"
                                                    >
                                                        <v-icon
                                                            style="color:royalblue"
                                                            >mdi-clipboard-text-clock</v-icon
                                                        >
                                                    </v-btn>
                                                </template>
                                                {{
                                                    $t(
                                                        "tyre_hotel.interventions"
                                                    )
                                                }}
                                            </v-tooltip>
                                        </span>
                                        <span v-if="selectedBill" class="mt-n2">
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-btn
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click="
                                                            showOrderContributorsModel = true
                                                        "
                                                        icon
                                                    >
                                                        <v-icon
                                                            class=""
                                                            style="color:orange"
                                                            >mdi-account-multiple-plus</v-icon
                                                        >
                                                    </v-btn>
                                                </template>
                                                {{
                                                    $t(
                                                        "billings.order_contributors_tooltip_text"
                                                    )
                                                }}
                                            </v-tooltip>
                                        </span>
                                    </template>
                                    <template slot="item" slot-scope="{ item }">
                                        <v-list-item-content>
                                            <span
                                                class="pl-7 bill_type"
                                                v-if="item.type.key == 'bill'"
                                            >
                                                Bill
                                            </span>
                                            <span
                                                class="pl-7 bill_type"
                                                v-if="
                                                    item.type.key ==
                                                        'purchase_order'
                                                "
                                            >
                                                Purchase Order
                                            </span>
                                            <span
                                                class="pl-7 bill_type"
                                                v-if="
                                                    item.type.key ==
                                                        'estimation'
                                                "
                                            >
                                                Estimation
                                            </span>
                                            <span
                                                class="pl-7 bill_type"
                                                v-if="
                                                    item.type.key ==
                                                        'credit_note'
                                                "
                                            >
                                                Credit Note
                                            </span>
                                            <span>
                                                <v-icon
                                                    class="ma-0"
                                                    v-if="
                                                        item.type.key == 'bill'
                                                    "
                                                    color="red"
                                                    right
                                                >
                                                    mdi-script-text
                                                </v-icon>
                                                <v-icon
                                                    class="ma-0"
                                                    v-if="
                                                        item.type.key ==
                                                            'purchase_order'
                                                    "
                                                    color="green"
                                                    right
                                                >
                                                    mdi-tag-text
                                                </v-icon>
                                                <v-icon
                                                    class="ma-0"
                                                    v-if="
                                                        item.type.key ==
                                                            'estimation'
                                                    "
                                                    color="black"
                                                    right
                                                >
                                                    mdi-book-open
                                                </v-icon>
                                                <v-icon
                                                    class="ma-0"
                                                    v-if="
                                                        item.type.key ==
                                                            'credit_note'
                                                    "
                                                    color="black"
                                                    right
                                                >
                                                    mdi-note-multiple
                                                </v-icon>
                                                {{ item.bill_number }}
                                            </span>
                                            <br />
                                            <span
                                                class="pl-7 client_name"
                                                v-if="item.client"
                                            >
                                                <template
                                                    v-if="item.client.company"
                                                >
                                                    Company -
                                                </template>
                                                <template v-else>
                                                    Private -
                                                </template>
                                                {{ item.client.name }}
                                                {{
                                                    item.client.first_name
                                                        ? item.client.first_name
                                                        : ""
                                                }}
                                            </span>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </template>
                            <span
                                v-if="
                                    selectedBill &&
                                        typeof selectedBill == 'object' &&
                                        selectedBill.bill_number
                                "
                            >
                                {{ selectedBill.bill_number }}
                            </span>
                        </v-tooltip>
                        <v-text-field
                            class="to-upper"
                            v-if="changeSelectedBillIdentifierForTemp"
                            :label="$t('billings.search_bill')"
                            :placeholder="$t('billings.start_typing')"
                            v-model="editIdentifier"
                            :hide-no-data="true"
                            append-icon="mdi-check"
                            @click:append="saveBillIdentifier()"
                            @click:clear="disallowEditIdentifier()"
                            clearable
                            dense
                            :style="{ maxWidth: '250px', minWidth: '250px' }"
                        ></v-text-field>
                    </v-col>
                    <v-col class="d-flex pa-0 ma-0 align-self-center">
                        <span
                            v-if="
                                checkIfSelectedBillHasArticleNotDeliveredYet()
                            "
                            style="color:red"
                        >
                            {{
                                $t(
                                    "billings.text_messages.supplier_stock_not_delivered_yet"
                                )
                            }}
                        </span>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col
                        class="d-flex pa-0 ma-0  justify-end"
                        cols="12"
                        sm="7"
                    >
                        <NoteEditorModal
                            v-if="showBillNote"
                            :dialog.sync="showBillNote"
                            v-model="note"
                            :translationTarget="'billing'"
                        />
                        <v-col class="d-flex pa-0 ma-0" cols="12" sm="2">
                            <v-text-field
                                v-if="
                                    parent_company_client_name &&
                                        parent_company_client_name &&
                                        parent_company_client_name.credit_limit >=
                                            0
                                "
                                v-model="customer_balance"
                                :label="$t('billings.customer_balance')"
                                readonly
                                class="mr-2 to-upper"
                                :class="
                                    customer_balance > 0
                                        ? 'customerbalance'
                                        : ''
                                "
                                v-on:keydown.enter.prevent=""
                                disabled
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                hide-details
                            >
                                <template #append>
                                    <OverdueOrdersList
                                        v-model="overdueOrdersModal"
                                        :client="parent_company_client_name"
                                    />
                                    <v-icon
                                        v-if="customer_balance > 0"
                                        color="red"
                                        class="cursor-poifnter"
                                        @click="activateOverdueListModal"
                                        >mdi-information</v-icon
                                    >

                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                v-on="on"
                                                v-if="parentClientIsBlocked"
                                                class="ml-1"
                                                color="red"
                                                >mdi-account-alert
                                            </v-icon>
                                        </template>
                                        {{ $t("clients.client_blocked_full") }}
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-tooltip bottom v-if="ispayment_method">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    v-if="ispayment_method"
                                    :disabled="isRecalculatingTableData"
                                    class="mt-3 ml-1 mr-3"
                                    return-object
                                    :style="{ maxWidth: '200px' }"
                                    color="warning"
                                    dark
                                    dense
                                    :append-icon="''"
                                    @click="showPaymentModel = true"
                                >
                                    {{
                                        $t("billings.balance") +
                                            ": " +
                                            paymentBalance
                                    }}
                                    <v-icon small class="pl-2">
                                        mdi-open-in-new
                                    </v-icon>
                                </v-btn>
                            </template>
                            {{ $t("billings.click_to_make_payments") }}
                        </v-tooltip>
                        <PaymentMethod
                            v-if="showPaymentModel"
                            :articleTotal.sync="articleTotal"
                            :articlePayments.sync="articlePayments"
                            :showDialog.sync="showPaymentModel"
                            :payment_methods="payment_methods"
                            :currencySymbol="currencySymbol"
                        ></PaymentMethod>
                        <v-select
                            @click="filterOutStatuses"
                            v-if="isVisibleOrderStatus"
                            :disabled="
                                disableOrderOrExpenseStatus(selectedBill)
                            "
                            @change="
                                () => {
                                    showPaymentModelWhenOrderStatusIsPayedOrPartial(
                                        order_status
                                    );
                                    showCancelReasonWhenOrderStatusIsCanceled(
                                        order_status
                                    );
                                }
                            "
                            v-model="order_status"
                            :items="billstatuses"
                            :label="$t('billings.status')"
                            item-text="text"
                            item-value="id"
                            return-object
                            v-on:keydown.enter.prevent=""
                            style="max-width: 130px"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            hide-details
                        >
                        </v-select>
                        <NoteEditorModal
                            :dialog.sync="cancelNoteEditorModal"
                            v-model="cancelReason"
                            :label="$t('billings.cancel_reason')"
                            @save="
                                (reason) => {
                                    if (isset(reason)) {
                                        note =
                                            note +
                                            '<br> ' +
                                            $t('billings.cancel_reason') +
                                            ' <br>' +
                                            reason;
                                    }
                                }
                            "
                            @close="onCancelAskFroConfirmationForRefund()"
                        />

                        <PeppolStatusField
                            :status="currentBillPeppolStatus"
                            v-if="$route.path.includes('edit')"
                        />

                        <div class="d-flex pa-0 ma-0 justify-end">
                            <span class="mx-1">
                                <DateInput
                                    v-model="created_date"
                                    :label="$t('billings.created_date')"
                                    :disabled="true"
                                />
                            </span>

                            <span class="mx-1 ml-5">
                                <DateInput
                                    v-model="due_date"
                                    :label="$t('billings.due_date')"
                                />
                            </span>

                            <OrderReminders
                                v-if="showPaymentReminder"
                                :order_reminders="order_reminders"
                                :order="selectedBill"
                                :showDialog.sync="showPaymentReminder"
                                :parent_email="parent_company_client_email"
                            ></OrderReminders>
                            <v-select
                                v-if="
                                    billType &&
                                        (billType.key == 'credit_note' ||
                                            billType.key == 'bill')
                                "
                                v-model="order_reminder_type"
                                :items="orderReminderTypes"
                                :label="$t('billings.order_reminder_type')"
                                item-text="text"
                                item-value="id"
                                return-object
                                v-on:keydown.enter.prevent=""
                                style="max-width: 180px; min-width: 160px"
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                hide-details
                                @click:append-outer="showPaymentReminder = true"
                            >
                                <template v-slot:append-outer>
                                    <div @click="showPaymentReminder = true">
                                        <v-tooltip bottom>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-btn
                                                    class="mx-1"
                                                    fab
                                                    dark
                                                    x-small
                                                    color="error"
                                                    @click="
                                                        showPaymentReminder = true
                                                    "
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    <span
                                                        style="font-size: 16px"
                                                    >
                                                        {{
                                                            order_reminder_type_counts
                                                        }}
                                                    </span>
                                                </v-btn>
                                            </template>
                                            <span>{{
                                                $t("billings.order_reminders")
                                            }}</span>
                                        </v-tooltip>
                                    </div>
                                </template>
                            </v-select>
                        </div>
                    </v-col>
                </v-row>

                <v-row class="group_label mt-n3" v-if="checkTag('clients')">
                    <v-col class=" pb-0">
                        <span style="color: #2196F3">
                            {{ $t("billings.client") }}
                            <ClientReclamation
                                v-if="
                                    checkIfReclamationNeedToShow(
                                        parent_company_client_name
                                    ) &&
                                        checkIfReclamationNeedToShowForMainClient(
                                            parent_company_client_name
                                        )
                                "
                                :note.sync="parent_company_client_name.note"
                            ></ClientReclamation>
                        </span>
                        <create-edit-client
                            v-if="checkTag('clients')"
                            ref="clientComp"
                            :comp_entityType.sync="parent_entityType"
                            :comp_client_id.sync="parent_client_id"
                            :comp_company.sync="parent_company"
                            :comp_company_client_name.sync="
                                parent_company_client_name
                            "
                            :comp_company_client_first_name.sync="
                                parent_company_client_first_name
                            "
                            :comp_company_client_names.sync="
                                parent_company_client_names
                            "
                            :comp_search_client.sync="parent_search_client"
                            :comp_company_client_phone.sync="
                                parent_company_client_phone
                            "
                            :comp_company_client_contact_name_2.sync="
                                parent_company_client_contact_name_2
                            "
                            :comp_company_client_contact_name_3.sync="
                                parent_company_client_contact_name_3
                            "
                            :comp_company_client_phone_2.sync="
                                parent_company_client_phone_2
                            "
                            :comp_company_client_phone_3.sync="
                                parent_company_client_phone_3
                            "
                            :comp_company_client_gsm.sync="
                                parent_company_client_gsm
                            "
                            :comp_company_client_gsm_2.sync="
                                parent_company_client_gsm_2
                            "
                            :comp_company_client_gsm_3.sync="
                                parent_company_client_gsm_3
                            "
                            :comp_company_client_fax.sync="
                                parent_company_client_fax
                            "
                            :comp_company_client_email.sync="
                                parent_company_client_email
                            "
                            :comp_company_client_email_2.sync="
                                parent_company_client_email_2
                            "
                            :comp_company_client_email_3.sync="
                                parent_company_client_email_3
                            "
                            :comp_company_client_language.sync="
                                parent_company_client_language
                            "
                            :comp_web.sync="parent_web"
                            :comp_currency.sync="parent_currency"
                            :comp_representative_company_id.sync="
                                parent_representative_company_id
                            "
                            :comp_user.sync="parent_user"
                            :comp_note.sync="parent_note"
                            :comp_familyClient.sync="parent_familyClient"
                            :comp_denomination.sync="parent_denomination"
                            :comp_tva.sync="parent_tva"
                            :comp_address.sync="parent_address"
                            :comp_shipping_address.sync="
                                parent_shipping_address
                            "
                            :comp_same_as.sync="parent_address_same_as"
                            :comp_is_shipping_visible="true"
                            :comp_is_billing_visible="true"
                            :comp_order_counts="
                                selectedBill ? selectedBill.orders_count : null
                            "
                            :comp_taxation_reason_id.sync="
                                parent_taxation_reason_id
                            "
                            :hideDenomination="true"
                            @entitytypeChangedToCompany="
                                entitytypeChangedToCompany
                            "
                        />
                    </v-col>
                </v-row>

                <v-row class="group_label pt-4" v-if="checkTag('projects')">
                    <v-col cols="12" sm="12" class="mt-0 pt-0">
                        <create-edit-project
                            v-if="checkTag('projects')"
                            ref="clientProjectComp"
                            :project_client="parent_company_client_name"
                            :project.sync="parent_project"
                            :project_name.sync="parent_project_name"
                            :project_note.sync="parent_project_note"
                            :project_status_id.sync="parent_project_status"
                        />
                    </v-col>
                </v-row>

                <v-row
                    class="group_label"
                    :class="checkTag('projects') ? 'mt-3' : 'mt-7'"
                    v-if="checkTag('vehicles') && isVehicleModuleEnabled"
                >
                    <v-col cols="12" sm="12" class="pt-0">
                        <create-edit-vehicle
                            v-if="checkTag('vehicles')"
                            ref="vehicleComp"
                            :comp_selected_bill.sync="selectedBill"
                            :comp_vehicle_type.sync="parent_vehicle_type"
                            :comp_registration_no.sync="parent_registration_no"
                            :comp_brand.sync="parent_brand"
                            :comp_model.sync="parent_model"
                            :comp_km.sync="parent_km"
                            :comp_reg_date.sync="parent_reg_date"
                            :comp_creditor.sync="parent_creditor"
                            :comp_creditors.sync="creditors"
                            :comp_vin.sync="parent_vin"
                            :comp_is_leasing.sync="parent_is_leasing"
                            :comp_client_detail.sync="
                                parent_company_client_name
                            "
                            :comp_driver.sync="parent_driver"
                            :comp_second_driver.sync="parent_second_driver"
                            :comp_addedRegistrationNumber.sync="
                                parent_addedRegistrationNumber
                            "
                            :comp_available_depots="availableDepots"
                        />
                    </v-col>
                </v-row>

                <v-row
                    class=""
                    :class="checkTag('vehicles') ? 'pt-2 mt-n5' : 'pt-6'"
                >
                    <v-col class="pt-0 pb-0">
                        <v-text-field
                            class="to-upper"
                            :label="$t('billings.reference_client')"
                            v-model="reference_client"
                            :error-messages="
                                $store.state.validation &&
                                !$v.reference_client.maxLength
                                    ? [
                                          this.$t(
                                              'billings.text_messages.reference_client_max_length'
                                          ),
                                      ]
                                    : []
                            "
                            v-on:keydown.enter.prevent=""
                            v-on:keydown="
                                handleInput($event, 'alphanumeric', 50)
                            "
                            :outlined="is_boxes"
                            :dense="is_boxes"
                        ></v-text-field>
                    </v-col>
                    <template v-if="checkTag('appointments')">
                        <v-col
                            class="pt-0 pb-0"
                            :style="{ textAlign: 'right' }"
                        >
                            <t-date-time-field
                                :label="$t('billings.rdv_date')"
                                v-model="rdv_date"
                                append-icon="mdi-calendar"
                                :disabled="true"
                                hide-details
                            ></t-date-time-field>
                        </v-col>
                    </template>
                    <v-col @click="addBillNote()" class="pt-0 pb-0">
                        <v-autocomplete
                            v-model="note"
                            :items="[note]"
                            :label="$t('expeditions.note')"
                            :append-icon="'mdi-open-in-new'"
                            rows="1"
                            readonly
                            disabled
                            class="to-upper mt-1"
                            hide-details
                        >
                            <template v-slot:selection="{ item }">
                                <span v-html="makeSingleLineText(item)"></span>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <!-- <v-row class="ma-0 pa-0">
            <v-col sm="11" md="10" class="ma-0 pa-0">
                <template v-if="selectedBill">
                    <span class="pl-4" v-if="parentOrder">
                      {{ $t("parent") }}:
                      <a @click="editBill(parentOrder)">{{
                          parentOrder.bill_number
                          }}</a>
                    </span>
                    <span class="pl-4" v-if="childOrder">
                      {{ $t("child") }}:
                      <a @click="editBill(childOrder)">{{
                          childOrder.bill_number
                          }}</a>
                    </span>
                </template>
            </v-col>
            <v-col
                    sm="12"
                    md="2"
                    v-if="(selectedArticles && selectedArticles.length > 0) || true"
                    align="right"
                    class="pa-0 ma-0"
            >
                <NoteEditorModal
                        v-model="note"
                />
            </v-col>
        </v-row> -->

                <v-row class="pt-0" v-if="checkTag('billing')">
                    <v-data-table
                        v-if="resetted_data_table"
                        :footer-props="{
                            'items-per-page-options': [10000],
                        }"
                        :headers="dataHeader"
                        :hide-default-footer="true"
                        :hide-default-header="true"
                        :items="selectedArticles"
                        v-sortable-data-table
                        :item-class="row_classes"
                        :items-per-page="10000"
                        class="elevation-0 mb-10"
                        :group-by="showGroupText ? 'group' : ''"
                        :show-group-by="showGroupText ? true : false"
                        style="width: 100%"
                        disable
                    >
                        <template v-slot:group.header="{ group }">
                            <template
                                v-if="
                                    showGroupText &&
                                        group &&
                                        group.trim() !== ''
                                "
                            >
                                <td
                                    :colspan="dataHeader.length"
                                    class="group-header"
                                    @click="toggleGroup(group)"
                                    @mouseover="
                                        toggleElementById(group, 'visible')
                                    "
                                    @mouseleave="
                                        toggleElementById(group, 'hidden')
                                    "
                                >
                                    <v-icon>{{
                                        isGroupExpanded(group)
                                            ? "mdi-chevron-down"
                                            : "mdi-chevron-right"
                                    }}</v-icon>
                                    <strong>{{ group }}</strong>
                                    <!-- <v-icon
                            @click="editGroupText"
                            :id="generateElementId(group)"
                            class="mdi mdi-drag"
                            :style="{
                                        visibility: 'hidden',
                                        cursor: 'move',
                                        marginLeft: 0,
                                        paddingLeft: 0
                                    }"
                        ></v-icon> -->
                                </td>
                            </template>
                        </template>
                        <template #header="{ props: { headers } }">
                            <thead class="v-data-table-header">
                                <tr>
                                    <template v-for="header in headers">
                                        <th
                                            :key="header.value"
                                            scope="col"
                                            class="text-right"
                                            :style="
                                                getHeaderWidth(
                                                    header,
                                                    header.width
                                                )
                                            "
                                        >
                                            <template
                                                v-if="
                                                    header.value ==
                                                        'article_original_name'
                                                "
                                            >
                                                <v-row justify="space-between">
                                                    <v-col
                                                        class="d-flex"
                                                        cols="12"
                                                        sm="2"
                                                    >
                                                        {{ header.text }}
                                                    </v-col>
                                                    <v-col
                                                        v-if="
                                                            selectedFamilyArticle &&
                                                                selectedFamilyArticle.is_stock_managed ==
                                                                    1
                                                        "
                                                        cols="12"
                                                        sm="3"
                                                        class="ma-1 pa-0"
                                                        style="height:20px; display: flex; justify-content: flex-end"
                                                        v-on:keydown.enter.prevent="
                                                            parentInternalStockOnly = !parentInternalStockOnly
                                                        "
                                                    >
                                                        <!-- <v-checkbox
                                                            class="mt-1 ma-0 pa-0"
                                                            hide-details="auto"
                                                            v-model="
                                                                parentInternalStockOnly
                                                            "
                                                            :label="
                                                                $t(
                                                                    'billings.is_only_internal'
                                                                )
                                                            "
                                                        ></v-checkbox> -->
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template
                                                v-else-if="
                                                    header.value == 'actions'
                                                "
                                            >
                                                <v-row justify="space-between">
                                                    <v-col
                                                        class="d-flex dontbreakline"
                                                        cols="12"
                                                        sm="2"
                                                    >
                                                        {{ header.text }}
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template v-else>
                                                {{ header.text }}
                                            </template>
                                        </th>
                                    </template>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:no-data>
                            <span></span>
                        </template>
                        <template v-slot:body.prepend>
                            <tr
                                class="first_row"
                                v-if="
                                    !(
                                        selectedBill &&
                                        selectedBill.status_id == 10251003
                                    )
                                "
                            >
                                <td
                                    class="pl-4 ma-0 pa-0"
                                    colspan="2"
                                    :class="['occupy_space']"
                                >
                                    <v-row>
                                        <!-- Search Article -->
                                        <v-col
                                            class="ma-0 pa-0"
                                            cols="12"
                                            sm="0"
                                        >
                                            <search-article
                                                :selectedFamilyClient="
                                                    parent_familyClient
                                                "
                                                :class="[
                                                    article
                                                        ? 'articleset'
                                                        : 'articlenotset',
                                                ]"
                                                @changeDialog="changeDialog()"
                                                :compSelectedArticleId.sync="
                                                    parentSearchSelectedArticleId
                                                "
                                                :compSelectedArticleSupplierDetail.sync="
                                                    parentSearchSelectedArticleSupplierDetail
                                                "
                                                :internalStockOnly.sync="
                                                    parentInternalStockOnly
                                                "
                                                :selectedFamilyArticle.sync="
                                                    selectedFamilyArticle
                                                "
                                                :group-text.sync="
                                                    parentGroupText
                                                "
                                                :show-group-text="showGroupText"
                                                :unit-price="unit_price"
                                                @triggerAddArticleToDataTable="
                                                    triggerAddArticleToDataTable()
                                                "
                                                ref="searchArticleComp"
                                            ></search-article>
                                        </v-col>
                                        <!-- Article Group -->
                                        <v-col
                                            md="auto"
                                            v-if="
                                                showGroupText &&
                                                    typeof article == 'object'
                                            "
                                            class="ma-0 pa-0 pr-1 pl-1 ml-0"
                                            cols="12"
                                            sm="2"
                                            align="left"
                                        >
                                            <v-text-field
                                                :autocomplete="
                                                    autocompleteValueLocal
                                                "
                                                class="ma-0 pa-0 selectedArticleTextBox to-upper"
                                                :placeholder="$t('group')"
                                                v-bind:clearable="true"
                                                clear-icon="mdi-close"
                                                v-model="parentGroupText"
                                                v-on:keydown.enter.prevent="
                                                    addArticleToDataTable()
                                                "
                                                outlined
                                                dense
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                        <!-- Article Description -->
                                        <v-col
                                            v-if="typeof article == 'object'"
                                            class="ma-0 pa-0"
                                            align="left"
                                        >
                                            <v-text-field
                                                :autocomplete="
                                                    autocompleteValueLocal
                                                "
                                                class="ma-0 pa-0 to-upper selectedArticleTextBox"
                                                append-icon="mdi-close"
                                                @click:append="
                                                    resetSelectedArticle()
                                                "
                                                v-model="
                                                    article.title_description[0]
                                                        .description
                                                "
                                                hide-details="auto"
                                                v-on:keydown.enter.prevent="
                                                    addArticleToDataTable()
                                                "
                                                v-on:keydown.prevent="
                                                    jumpToField(
                                                        $event,
                                                        'tableQuantity'
                                                    )
                                                "
                                                v-on:keydown.tab.prevent="
                                                    jumpToField(
                                                        $event,
                                                        'tableQuantity'
                                                    )
                                                "
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </v-col>
                                        <!-- Article Supplier -->
                                        <v-col
                                            v-if="typeof article == 'object'"
                                            class="ma-0 pa-0 pl-0 pr-0 ml-1"
                                            align="right"
                                            sm="auto"
                                            @click="isEditModeForDataTableItem ? showSupplierDetailModel() : ''"
                                        >
                                            <v-select
                                                v-if="
                                                    typeof article ==
                                                        'object' &&
                                                        article.supplier &&
                                                        article.supplier
                                                            .length > 0 &&
                                                        article.supplier[0] &&
                                                        article.supplier[0]
                                                            .id >= 0 &&
                                                        article.family &&
                                                        article.family
                                                            .is_stock_managed
                                                "
                                                :readonly="
                                                    isEditModeForDataTableItem
                                                "
                                                v-model="article.supplier[0]"
                                                :items="article.supplier"
                                                item-value="id"
                                                item-text="identifier"
                                                class="selectedSupplierTextBox"
                                                style="max-width: 80px"
                                                outlined
                                                dense
                                                return-object
                                                hide-details="auto"
                                            >
                                                <template
                                                    slot="item"
                                                    slot-scope="{ item }"
                                                >
                                                    <v-list-item-content
                                                        @click="
                                                            updatePricingBasedOnSupplier(
                                                                item
                                                            )
                                                        "
                                                    >
                                                        {{ item.name }}
                                                    </v-list-item-content>
                                                </template>
                                                <v-icon
                                                    slot="append"
                                                    v-text="''"
                                                ></v-icon>
                                            </v-select>
                                            <v-select
                                                v-if="
                                                    !addArticlesAutomatically &&
                                                        article &&
                                                        article.supplier &&
                                                        article.supplier
                                                            .length > 0 &&
                                                        article.supplier[0] ==
                                                            null &&
                                                        article.family &&
                                                        article.family
                                                            .is_stock_managed
                                                "
                                                :value="internalSupplier"
                                                :items="[internalSupplier]"
                                                item-value="id"
                                                item-text="identifier"
                                                class="ma-0 mr-0"
                                                :class="'articleInStock'"
                                                style="max-width: 500px"
                                                outlined
                                                dense
                                                return-object
                                                readonly
                                                hide-details="auto"
                                            >
                                                <v-icon
                                                    slot="append"
                                                    v-text="''"
                                                ></v-icon>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </td>

                                <!-- Acquisition Price -->
                                <td
                                    v-if="
                                        checkTag('acquisition_price') &&
                                            checkUserTag('acquisition_price')
                                    "
                                    align="right"
                                    class="pl-5 pr-0"
                                    style="text-align:right; width:100px"
                                    v-on:keydown.enter="addArticleToDataTable()"
                                    v-on:keydown.exact="
                                        checkIfMinusPressed($event)
                                    "
                                    v-on:keydown.tab.prevent="
                                        jumpToField(
                                            $event,
                                            'tableRedPercentage'
                                        )
                                    "
                                >
                                    <div style="display: flex">
                                        <custom-money-input
                                            :autocomplete="
                                                autocompleteValueLocal
                                            "
                                            :disabled="isRecalculatingTableData"
                                            class=" pr-0 to-upper right-aligned-input shrink"
                                            hide-details="auto"
                                            outlined
                                            dense
                                            tabindex="3"
                                            style="width:100px"
                                            ref="tableAcquisitionPrice"
                                            :class="'orange-input'"
                                            v-model="acquisition_price"
                                            v-bind:properties="{
                                                prefix: currencySymbol,
                                                readonly: false,
                                                disabled: false,
                                                outlined: true,
                                                placeholder:
                                                    getDecimalNumber() == 2
                                                        ? '0.00'
                                                        : '0.000',
                                                dense: true,
                                                hideDetails: 'auto',
                                            }"
                                            v-bind:options="{
                                                locale: userLanguageId,
                                                length: 12,
                                                precision: getDecimalNumber(),
                                                empty:
                                                    getDecimalNumber() == 2
                                                        ? 0.0
                                                        : 0.0,
                                            }"
                                        />
                                    </div>
                                </td>
                                <!-- Quantity -->
                                <td
                                    class="pl-0 pr-0"
                                    style="min-width: 130px; max-width: 160px;"
                                    align="right"
                                    v-on:keydown.tab.prevent="
                                        jumpToField('', 'tableUnitPrice')
                                    "
                                    v-on:keydown.enter.prevent=""
                                    v-on:keydown.enter="addArticleToDataTable()"
                                    v-on:keydown="
                                        handleInput(
                                            $event,
                                            selectedFamilyArticle
                                                ? selectedFamilyArticle.quantity_decimals >
                                                  0
                                                    ? 'float'
                                                    : 'int'
                                                : 'int',
                                            10
                                        )
                                    "
                                >
                                    <v-row no-gutters align="center">
                                        <v-col
                                            class="pa-0 ma-0 mr-n4"
                                            style="width:10px"
                                        >
                                            <v-combobox
                                                style="width: 55px;background-color: gainsboro;z-index:4;"
                                                append-icon=""
                                                class="mt-0 shrink"
                                                :autocomplete="
                                                    autocompleteValueLocal
                                                "
                                                v-model="currentMu"
                                                :items="mus"
                                                item-text="value"
                                                v-on:keydown.enter.prevent=""
                                                outlined
                                                dense
                                                hide-details
                                            ></v-combobox>
                                        </v-col>
                                        <v-col class="pa-0 ma-0 mr-4">
                                            <custom-money-input
                                                :autocomplete="
                                                    autocompleteValueLocal
                                                "
                                                :disabled="
                                                    isRecalculatingTableData
                                                "
                                                class="pt-0 to-upper right-aligned-input shrink"
                                                hide-details="auto"
                                                outlined
                                                dense
                                                tabindex="2"
                                                ref="tableQuantity"
                                                v-model="quantity"
                                                v-bind:properties="{
                                                    readonly: false,
                                                    disabled: false,
                                                    outlined: true,
                                                    placeholder: selectedFamilyArticle
                                                        ? selectedFamilyArticle.quantity_decimals >
                                                          0
                                                            ? makeZeros(
                                                                  selectedFamilyArticle.quantity_decimals
                                                              )
                                                            : '0'
                                                        : '0',
                                                    dense: true,
                                                    hideDetails: 'auto',
                                                }"
                                                v-bind:options="{
                                                    locale: userLanguageId,
                                                    length: 12,
                                                    precision: selectedFamilyArticle
                                                        ? selectedFamilyArticle.quantity_decimals >
                                                          0
                                                            ? selectedFamilyArticle.quantity_decimals
                                                            : 0
                                                        : 0,
                                                    empty: selectedFamilyArticle
                                                        ? selectedFamilyArticle.quantity_decimals >
                                                          0
                                                            ? makeZeros(
                                                                  selectedFamilyArticle.quantity_decimals
                                                              )
                                                            : '0'
                                                        : '0',
                                                }"
                                            />
                                        </v-col>
                                    </v-row>
                                </td>
                                <!-- Unit Price -->
                                <td
                                    align="right"
                                    class="pl-2 pr-0"
                                    style="text-align:right; width:100px"
                                    v-on:keydown.enter="addArticleToDataTable()"
                                    v-on:keydown.exact="
                                        checkIfMinusPressed($event)
                                    "
                                    v-on:keydown.tab.prevent="
                                        jumpToField(
                                            $event,
                                            'tableRedPercentage'
                                        )
                                    "
                                >
                                    <div style="display: flex">
                                        <v-tooltip
                                            color="error"
                                            top
                                            v-model="isInValidUnitPrice"
                                            @click="
                                                unit_price = first_row_minimum_sales_price
                                            "
                                        >
                                            <template v-slot:activator="">
                                                <custom-money-input
                                                    :autocomplete="
                                                        autocompleteValueLocal
                                                    "
                                                    :disabled="
                                                        isRecalculatingTableData
                                                    "
                                                    class="pt-0 to-upper right-aligned-input shrink"
                                                    hide-details="auto"
                                                    outlined
                                                    dense
                                                    tabindex="3"
                                                    style="width:100px"
                                                    ref="tableUnitPrice"
                                                    v-model="unit_price"
                                                    :class="
                                                        isInValidUnitPrice
                                                            ? 'red-input'
                                                            : ''
                                                    "
                                                    v-bind:properties="{
                                                        prefix: currencySymbol,
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: true,
                                                        placeholder:
                                                            getDecimalNumber() ==
                                                            2
                                                                ? '0.00'
                                                                : '0.000',
                                                        dense: true,
                                                        hideDetails: 'auto',
                                                    }"
                                                    v-bind:options="{
                                                        locale: userLanguageId,
                                                        length: 12,
                                                        precision: getDecimalNumber(),
                                                        empty:
                                                            getDecimalNumber() ==
                                                            2
                                                                ? 0.0
                                                                : 0.0,
                                                    }"
                                                />
                                            </template>
                                            <span>{{
                                                $t(
                                                    "billings.text_messages.minimum_sales_price"
                                                ) +
                                                    " : " +
                                                    currencySymbol +
                                                    first_row_minimum_sales_price.toFixed(
                                                        getDecimalNumber()
                                                    )
                                            }}</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                                <td
                                    align="right"
                                    class="pl-2 pr-0"
                                    style="text-align:right; min-width: 100px; max-width: 100px;"
                                    v-on:keydown.enter="addArticleToDataTable()"
                                    v-on:keydown.tab.prevent="
                                        addArticleToDataTable()
                                    "
                                >
                                    <custom-money-input
                                        :autocomplete="autocompleteValueLocal"
                                        :disabled="isRecalculatingTableData"
                                        v-model="red_percentage"
                                        :background-color="
                                            !$v.red_percentage.maxValue
                                                ? '#ffc2b7'
                                                : !$v.red_percentage.minValue
                                                ? '#ffc2b7'
                                                : 'white'
                                        "
                                        v-bind:properties="{
                                            suffix: '%',
                                            readonly: false,
                                            disabled: false,
                                            outlined: true,
                                            clearable: false,
                                            placeholder:
                                                getDecimalNumber() == 2
                                                    ? '0.00'
                                                    : '0.000',
                                            dense: true,
                                            hideDetails: 'auto',
                                        }"
                                        v-bind:options="{
                                            locale: userLanguageId,
                                            length: 3,
                                            precision: getDecimalNumber(),
                                            empty:
                                                getDecimalNumber() == 2
                                                    ? 0.0
                                                    : 0.0,
                                        }"
                                        class="pt-0 to-upper right-aligned-input shrink"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        tabindex="4"
                                        v-bind:focus="true"
                                        ref="tableRedPercentage"
                                    >
                                    </custom-money-input>
                                </td>
                                <!-- <td style="text-align:right">{{ first_row_tariff }}</td> -->
                                <!-- <td style="text-align:right">{{ first_row_tva }}</td> -->
                                <td
                                    align="right"
                                    class="pl-2 pr-0"
                                    style="text-align:right; min-width: 100px; max-width: 100px;"
                                >
                                    <v-text-field
                                        :autocomplete="autocompleteValueLocal"
                                        v-model="first_row_tva"
                                        class="pt-0 to-upper right-aligned-input shrink"
                                        hide-details="auto"
                                        dense
                                        outlined
                                        required
                                        v-on:keydown.enter.prevent=""
                                        suffix="%"
                                    ></v-text-field>
                                </td>
                                <td
                                    class="pl-2 pr-0"
                                    style="text-align:right; min-width: 100px; max-width: 100px;"
                                    v-on:keyup="
                                        reverseCalculate(null, first_row_thtva)
                                    "
                                    v-on:keydown.enter="addArticleToDataTable()"
                                    v-on:keydown.tab.prevent="
                                        addArticleToDataTable()
                                    "
                                >
                                    <custom-money-input
                                        :autocomplete="autocompleteValueLocal"
                                        :disabled="isRecalculatingTableData"
                                        class="pt-0 to-upper right-aligned-input shrink"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        tabindex="3"
                                        style="width:100px"
                                        ref="tableFirstRowThtva"
                                        v-model="first_row_thtva"
                                        v-bind:properties="{
                                            prefix: currencySymbol,
                                            readonly: false,
                                            disabled: false,
                                            outlined: true,
                                            placeholder:
                                                getDecimalNumber() == 2
                                                    ? '0.00'
                                                    : '0.000',
                                            dense: true,
                                            hideDetails: 'auto',
                                        }"
                                        v-bind:options="{
                                            locale: userLanguageId,
                                            length: 12,
                                            precision: getDecimalNumber(),
                                            empty:
                                                getDecimalNumber() == 2
                                                    ? 0.0
                                                    : 0.0,
                                        }"
                                    />
                                </td>
                                <td
                                    class="pl-4 pr-0"
                                    style="text-align:right; min-width: 100px; max-width: 100px;"
                                    v-on:keyup="
                                        reverseCalculate(first_row_total, null)
                                    "
                                    v-on:keydown.enter="addArticleToDataTable()"
                                    v-on:keydown.tab.prevent="
                                        addArticleToDataTable()
                                    "
                                >
                                    <custom-money-input
                                        :autocomplete="autocompleteValueLocal"
                                        :disabled="isRecalculatingTableData"
                                        class="pt-0 to-upper right-aligned-input shrink"
                                        hide-details="auto"
                                        outlined
                                        dense
                                        tabindex="3"
                                        style="width:100px"
                                        ref="tableFirstRowTotal"
                                        v-model="first_row_total"
                                        v-bind:properties="{
                                            prefix: currencySymbol,
                                            readonly: false,
                                            disabled: false,
                                            outlined: true,
                                            placeholder:
                                                getDecimalNumber() == 2
                                                    ? '0.00'
                                                    : '0.000',
                                            dense: true,
                                            hideDetails: 'auto',
                                        }"
                                        v-bind:options="{
                                            locale: userLanguageId,
                                            length: 12,
                                            precision: getDecimalNumber(),
                                            empty:
                                                getDecimalNumber() == 2
                                                    ? 0.0
                                                    : 0.0,
                                        }"
                                    />
                                </td>
                                <td align="right">
                                    <v-btn
                                        color="primary"
                                        dark
                                        class="outlinedbuttonheight"
                                        @click="addArticleToDataTable()"
                                    >
                                        <v-icon
                                            dark
                                            v-if="!isEditModeForDataTableItem"
                                            >mdi-plus</v-icon
                                        >
                                        <v-icon dark v-else>mdi-check</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:item.information="{ item }">
                            <div
                                class="d-flex flex-row information-class"
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <div>
                                    <v-icon
                                        :id="generateElementId(item.srno)"
                                        class="mdi mdi-drag"
                                        :style="{
                                            visibility: 'hidden',
                                            cursor: 'move',
                                            marginLeft: 0,
                                            paddingLeft: 0,
                                        }"
                                    ></v-icon>
                                </div>
                                <SupplierComponent
                                    :item="item"
                                    @update-reception-detail="
                                        showSupplierDetailModel(item)
                                    "
                                ></SupplierComponent>
                            </div>
                        </template>
                        <template v-slot:item.article_original_name="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col
                                    cols="12"
                                    sm="12"
                                    class="d-flex justify-space-between"
                                >
                                    <template
                                        v-if="
                                            item.article_original_name &&
                                                item.article_original_name
                                                    .title_description.length >
                                                    0
                                        "
                                    >
                                        <div class="cursor-pointer-trans">
                                            <div
                                                @click="
                                                    dataTableItemClicked(item)
                                                "
                                            >
                                                <span>
                                                    {{
                                                        item
                                                            .article_original_name
                                                            .title_description[0]
                                                            .description
                                                    }}<br />
                                                    <span
                                                        class="article_identifier"
                                                    >
                                                        <TextComponentWithExtraIcons :value="item.article_original_name.identifier"></TextComponentWithExtraIcons>
                                                    </span>
                                                </span>
                                            </div>
                                            <!-- Item Text -->
                                            <span
                                                class="article_text text-muted"
                                                style="font-size: 12px"
                                                v-html="
                                                    (item.text ? item.text : '')
                                                        .replaceAll('<BR>', ' ')
                                                        .substr(0, 100)
                                                "
                                                @click="
                                                    addTextAndGroupToArticle(
                                                        item
                                                    )
                                                "
                                            ></span>
                                        </div>
                                        <IconGroupForArticle
                                            :item="item"
                                            @addTextAndGroupToArticle="
                                                addTextAndGroupToArticle(item)
                                            "
                                            @editItem="
                                                dataTableItemClicked(item)
                                            "
                                        ></IconGroupForArticle>
                                        <ExpeditionDocumentsList
                                            style="position: relative; top: 30%"
                                            v-if="item.expedition_id"
                                            :resource="'expedition'"
                                            :data="item.expedition_id"
                                            :hideDocumentTypes="['bill']"
                                        ></ExpeditionDocumentsList>
                                    </template>
                                    <template v-else>
                                        <div
                                            class="article_identifier"
                                            v-html="item.text"
                                        ></div>
                                    </template>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.quantity="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <template v-if="item.quantity">
                                        <!-- {{ item.article_original_name }} -->
                                        <span>
                                            {{
                                                fixDecimal(
                                                    item.quantity,
                                                    item
                                                        ? item.article_original_name
                                                            ? item
                                                                  .article_original_name
                                                                  .family
                                                                  .quantity_decimals
                                                            : 0
                                                        : 0
                                                )
                                            }}
                                            {{
                                                item.selectedmu &&
                                                typeof item.selectedmu ==
                                                    "object"
                                                    ? item.selectedmu.value
                                                    : item.selectedmu
                                            }}
                                            <!-- Here we have hardcoded if nothing is their then family mu -->
                                        </span>
                                        <!-- <span> {{ item.quantity }} </span> -->
                                    </template>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.acquisition_price="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        <!-- {{ item.acquisition_price ? (parseFloat(item.acquisition_price) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) : (0).toFixed(getDecimalNumber()) }} -->
                                        {{ currencySymbol }}
                                        {{
                                            parseFloat(
                                                item.acquisition_price
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            item.acquisition_price
                                                ? parseFloat(
                                                      item.acquisition_price
                                                  ).toFixed(getDecimalNumber())
                                                : (0).toFixed(
                                                      getDecimalNumber()
                                                  )
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.rounded_unit_price="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        <!-- {{ item.rounded_unit_price ? (parseFloat(item.rounded_unit_price) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) : (0).toFixed(getDecimalNumber()) }} -->
                                        {{ currencySymbol }}
                                        {{
                                            parseFloat(
                                                item.rounded_unit_price
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            item.rounded_unit_price
                                                ? parseFloat(
                                                      item.rounded_unit_price
                                                  ).toFixed(getDecimalNumber())
                                                : (0).toFixed(
                                                      getDecimalNumber()
                                                  )
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.red_percentage="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <span>
                                        {{
                                            (item.red_percentage
                                                ? item.red_percentage
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.tva="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <span>
                                        {{
                                            (item.tva ? item.tva : 0).toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.thtva="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        <!-- {{ ((item.thtva ? item.thtva : 0) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) }} -->
                                        {{ currencySymbol }}
                                        {{
                                            item.thtva.toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            (item.thtva
                                                ? item.thtva
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.total="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="12" sm="12">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        <!-- {{ ((item.total ? item.total : 0) * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber()) }} -->
                                        {{ currencySymbol }}
                                        {{
                                            item.total.toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            (item.total
                                                ? item.total
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-row
                                @mouseover="
                                    toggleElementById(item.srno, 'visible')
                                "
                                @mouseleave="
                                    toggleElementById(item.srno, 'hidden')
                                "
                            >
                                <v-col cols="9" sm="9">
                                    <template
                                        v-if="
                                            selectedArticles[
                                                item.srno ? item.srno : 0
                                            ] &&
                                                Array.isArray(
                                                    selectedArticles[
                                                        item.srno
                                                            ? item.srno
                                                            : 0
                                                    ].dots
                                                ) &&
                                                selectedArticles[
                                                    item.srno ? item.srno : 0
                                                ].dots.length >= 0
                                        "
                                    >
                                        <v-autocomplete
                                            v-if="
                                                !selectedArticles[
                                                    item.srno ? item.srno : 0
                                                ].supplier_stock_detail &&
                                                    item.article_original_name &&
                                                    item.article_original_name
                                                        .family &&
                                                    (item.article_original_name
                                                        .family.id == 1 ||
                                                        item
                                                            .article_original_name
                                                            .family
                                                            .parent_family_id ==
                                                            1) &&
                                                    selectedArticles[
                                                        item.srno
                                                            ? item.srno
                                                            : 0
                                                    ].depot_out_id == null &&
                                                    selectedArticles[
                                                        item.srno
                                                            ? item.srno
                                                            : 0
                                                    ].depot_in_id == null &&
                                                    !(
                                                        selectedArticles[
                                                            item.srno
                                                                ? item.srno
                                                                : 0
                                                        ].supplier &&
                                                        selectedArticles[
                                                            item.srno
                                                                ? item.srno
                                                                : 0
                                                        ].supplier.length > 0 &&
                                                        selectedArticles[
                                                            item.srno
                                                                ? item.srno
                                                                : 0
                                                        ].supplier[0] &&
                                                        selectedArticles[
                                                            item.srno
                                                                ? item.srno
                                                                : 0
                                                        ].supplier[0]
                                                            .identifier != 'INT'
                                                    )
                                            "
                                            v-model="
                                                selectedArticles[
                                                    item.srno ? item.srno : 0
                                                ].stock_property
                                            "
                                            :items="
                                                selectedArticles[
                                                    item.srno ? item.srno : 0
                                                ].dots
                                                    ? selectedArticles[
                                                          item.srno
                                                              ? item.srno
                                                              : 0
                                                      ].dots
                                                    : []
                                            "
                                            item-text="property"
                                            item-value="property"
                                            hide-details="auto"
                                            single-line
                                            :placeholder="$t('billings.dot')"
                                            class="pt-0 to-upper "
                                            dense
                                            tabindex="2"
                                            style="width:90px"
                                            @focus="$event.target.select()"
                                            ref="dot"
                                            append-icon=""
                                            v-on:keydown.enter.prevent=""
                                            v-on:keydown="
                                                handleInput($event, 'int', 4);
                                                fineDataForSending();
                                            "
                                            :outlined="is_boxes"
                                        >
                                            <template
                                                slot="item"
                                                slot-scope="{ item }"
                                            >
                                                <template>
                                                    {{ item.property }} X
                                                    {{ item.counter }}
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </template>
                                    <template v-else>
                                        <v-tooltip bottom>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-progress-circular
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    indeterminate
                                                    color="primary"
                                                    :size="20"
                                                    :width="3"
                                                ></v-progress-circular>
                                            </template>
                                            <span>
                                                {{
                                                    $t(
                                                        "please_wait_while_we_are_loading_dots"
                                                    )
                                                }}
                                            </span>
                                        </v-tooltip>
                                    </template>
                                </v-col>
                                <v-col
                                    v-if="
                                        !(
                                            selectedBill &&
                                            selectedBill.status_id == 10251003
                                        )
                                    "
                                    cols="3"
                                    sm="3"
                                    class="pa-0 pt-4"
                                >
                                    <v-icon
                                        small
                                        color="red"
                                        @click="deleteItem(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </v-col>
                            </v-row>
                        </template>

                        <template v-slot:body.append>
                            <!-- Total -->
                            <tr
                                class="last_row"
                                v-if="selectedArticles.length > 0"
                                style="background-color:rgba(142, 142, 142, 0.07)"
                            >
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th
                                    class="text-sm-right"
                                    v-if="
                                        checkTag('acquisition_price') &&
                                            checkUserTag('acquisition_price')
                                    "
                                    v-text="''"
                                />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right ma-0 pa-0 mt-1 mb-1">
                                    <custom-money-input
                                        :autocomplete="autocompleteValueLocal"
                                        :disabled="isRecalculatingTableData"
                                        @input="calculateTotals()"
                                        class="pt-0 to-upper right-aligned-input shrink float-right pr-2"
                                        hide-details="auto"
                                        dense
                                        tabindex="3"
                                        style="width:80px"
                                        ref="reduction_on_order"
                                        v-model="reduction_on_order"
                                        v-on:keydown="
                                            handleInput($event, 'float', 5)
                                        "
                                        prefix="%"
                                        v-bind:properties="{
                                            prefix: '%',
                                            readonly: false,
                                            disabled: false,
                                            outlined: false,
                                            placeholder:
                                                getDecimalNumber() == 2
                                                    ? '0.00'
                                                    : '0.000',
                                            dense: true,
                                            hideDetails: 'auto',
                                        }"
                                        v-bind:options="{
                                            locale: 'en',
                                            length: 12,
                                            precision: getDecimalNumber(),
                                            empty:
                                                getDecimalNumber() == 2
                                                    ? 0.0
                                                    : 0.0,
                                        }"
                                    />
                                </th>
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        {{ currencySymbol }}
                                        {{
                                            articleTHTVATotalBeforeReduction.toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            (articleTHTVATotalBeforeReduction
                                                ? articleTHTVATotalBeforeReduction
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </th>
                                <th class="text-sm-right">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        <!-- {{ (articleTotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                                        {{ currencySymbol }}
                                        {{
                                            articleTotalBeforeReduction.toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            (articleTotalBeforeReduction
                                                ? articleTotalBeforeReduction
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </th>
                                <th class="text-sm-right" v-text="''" />
                            </tr>
                            <!-- Total After Reduction -->
                            <tr
                                class="last_row"
                                v-if="
                                    selectedArticles.length > 0 &&
                                        total_reduction > 0
                                "
                                style="background-color:rgba(142, 142, 142, 0.07)"
                            >
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th
                                    class="text-sm-right"
                                    v-if="
                                        checkTag('acquisition_price') &&
                                            checkUserTag('acquisition_price')
                                    "
                                    v-text="''"
                                />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right text-no-wrap">
                                    {{ currencySymbol }}
                                    - {{ total_reduction }}
                                </th>
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right">
                                    <!-- <span v-if="billType && billType.key == 'credit_note'" class="text-no-wrap">
                        {{ currencySymbol }}
                        {{ articleTHTVATotal.toFixed(getDecimalNumber()) }}
                    </span>
                    <span v-else class="text-no-wrap">
                        {{ currencySymbol }}
                        {{
                        (articleTHTVATotal ? articleTHTVATotal : 0).toFixed(
                            getDecimalNumber()
                        )
                        }}
                    </span> -->
                                </th>
                                <th class="text-sm-right">
                                    <span
                                        v-if="
                                            billType &&
                                                billType.key == 'credit_note'
                                        "
                                        class="text-no-wrap"
                                    >
                                        <!-- {{ (articleTotal * parseInt(creditNoteMultiplicationValue.value)).toFixed(getDecimalNumber())}} -->
                                        {{ currencySymbol }}
                                        {{
                                            articleTotal.toFixed(
                                                getDecimalNumber()
                                            )
                                        }}
                                    </span>
                                    <span v-else class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{
                                            (articleTotal
                                                ? articleTotal
                                                : 0
                                            ).toFixed(getDecimalNumber())
                                        }}
                                    </span>
                                </th>
                                <th class="text-sm-right" v-text="''" />
                            </tr>
                            <!-- Total Paid -->
                            <tr
                                class="last_row"
                                v-if="
                                    selectedArticles &&
                                        selectedArticles.length > 0 &&
                                        selectedBillData &&
                                        selectedBillData.type &&
                                        ['bill', 'credit_note'].includes(
                                            selectedBillData.type.key
                                        )
                                "
                                style="background-color:rgba(142, 142, 142, 0.07)"
                            >
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th
                                    class="text-sm-right"
                                    v-if="
                                        checkTag('acquisition_price') &&
                                            checkUserTag('acquisition_price')
                                    "
                                    v-text="''"
                                />
                                <th class="text-sm-right" v-text="''" />
                                <!-- <th class="text-sm-right" v-text="''" /> -->
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right">
                                    <span class="mr-0">
                                        {{ $t("billings.paid") }}
                                    </span>
                                </th>
                                <th
                                    class="text-sm-right text-no-wrap"
                                    align="right"
                                >
                                    <span>
                                        {{ currencySymbol }}
                                        ({{ totalPaid }})
                                    </span>
                                </th>
                                <th class="text-sm-right" v-text="''" />
                            </tr>
                            <!-- Total Balance -->
                            <tr
                                class="last_row"
                                v-if="
                                    selectedArticles &&
                                        selectedArticles.length > 0 &&
                                        selectedBillData &&
                                        selectedBillData.type &&
                                        ['bill', 'credit_note'].includes(
                                            selectedBillData.type.key
                                        )
                                "
                                style="background-color:rgba(142, 142, 142, 0.07)"
                            >
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-left" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <th
                                    class="text-sm-right"
                                    v-if="
                                        checkTag('acquisition_price') &&
                                            checkUserTag('acquisition_price')
                                    "
                                    v-text="''"
                                />
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right" v-text="''" />
                                <!-- <th class="text-sm-right" v-text="''" /> -->
                                <th class="text-sm-right" v-text="''" />
                                <th class="text-sm-right">
                                    {{ $t("billings.balance") }}
                                </th>
                                <th class="text-sm-right">
                                    <span class="text-no-wrap">
                                        {{ currencySymbol }}
                                        {{ paymentBalance }}
                                    </span>
                                </th>
                                <th class="text-sm-right" v-text="''" />
                            </tr>
                        </template>
                    </v-data-table>
                </v-row>
            </v-container>
        </v-form>

        <v-btn @click="submit()" class="d-none" id="submit-billing"></v-btn>
        <v-btn
            @click="askForRefund()"
            class="d-none"
            id="submit-refund"
        ></v-btn>
        <v-btn
            @click="submit(true)"
            class="d-none"
            id="submit-billing-without-close"
        ></v-btn>
        <v-btn @click="onDelete()" class="d-none" id="delete-billing"></v-btn>
        <v-btn
            @click="
                onResetPageData();
                goBackToListing();
            "
            class="d-none"
            id="reset-page-data"
        ></v-btn>
        <v-btn
            @click="confirmPurchaseOrderPopUp()"
            class="d-none"
            id="make-purchase-order"
        ></v-btn>
        <v-btn
            @click="confirmMakeBillPopUp()"
            class="d-none"
            id="make-bill"
        ></v-btn>
        <v-btn
            @click="confirmCreditNotePopUp()"
            class="d-none"
            id="make-credit-note"
        ></v-btn>
        <v-btn @click="printBill()" class="d-none" id="print-bill"></v-btn>
        <v-btn @click="emailBill()" class="d-none" id="email-bill"></v-btn>
        <v-btn @click="depotIn()" class="d-none" id="depot-in"></v-btn>
        <v-btn @click="depotOut()" class="d-none" id="depot-out"></v-btn>
        <v-btn
            @click="worksheetBill()"
            class="d-none"
            id="worksheet-bill"
        ></v-btn>
        <v-btn
            @click="workReportBill()"
            class="d-none"
            id="workreport-bill"
        ></v-btn>
        <v-btn
            @click="deliveryNoteBill()"
            class="d-none"
            id="deliverynote-bill"
        ></v-btn>
        <v-btn @click="addBillNote()" class="d-none" id="add-bill-note"></v-btn>
        <v-btn
            @click="duplicateDocument()"
            class="d-none"
            id="duplicate-document"
        ></v-btn>
        <v-btn
            @click="printReportFromTopButton()"
            class="d-none"
            id="print-report"
        ></v-btn>
        <template>
            <v-row justify="center">
                <v-dialog v-model="showTextDialog" scrollable max-width="650px">
                    <v-card>
                        <v-card-title
                            >{{
                                showGroupText
                                    ? $t("edit_article_text_and_group")
                                    : $t("edit_article_text")
                            }}
                        </v-card-title>
                        <v-card-text style="min-height: 200px;">
                            <v-text-field
                                v-if="showGroupText"
                                v-model="tempItemGroup"
                                :label="$t('group')"
                                v-on:keydown.enter.prevent=""
                                :outlined="is_boxes"
                                :dense="is_boxes"
                                class="to-upper"
                            ></v-text-field>
                            <wysiwyg
                                v-model="tempItemText"
                                :label="$t('text')"
                            />
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="px-2">
                            <v-spacer></v-spacer>
                            <v-btn
                                color="grey lighten-2"
                                @click="cancelTextAndGroupToArticle()"
                            >
                                {{ $t("cancel") }}
                                <v-icon dark right> mdi-keyboard-return</v-icon>
                            </v-btn>
                            <v-btn
                                color="success"
                                @click="saveTextAndGroupToArticle()"
                            >
                                {{ $t("save") }}
                                <v-icon dark right> mdi-content-save</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <send-pdf-via-email
                    v-if="isset(selectedBill) && sendPdfDialog"
                    v-model="sendPdfDialog"
                    target="billing"
                    :model="selectedBill"
                    :depot="availableDepots"
                    @emailSent="() => fetchBillByID($store.state.editId)"
                ></send-pdf-via-email>
            </v-row>
        </template>
        <depot-add-modify-tyre
            v-if="depotInMod == true"
            :depotDetails="null"
            :vehicleDriverDetails.sync="vehicleDriverDetails"
            @modifyClose="modifyDepotInClose"
        ></depot-add-modify-tyre>
        <depot-out
            v-if="depotOutMod == true"
            :vehicleDriverDetails.sync="vehicleDriverDetails"
            :alreadySelectedDepot="selectedArticles"
            @modifyClose="modifyDepotOutClose"
        ></depot-out>
        <loading-model
            :showLoadingModel.sync="showLoadingModel"
        ></loading-model>

        <confirmation-model
            v-if="confirmationDialog"
            :showDialog.sync="confirmationDialog"
            :openedForOperation.sync="confirmationDialogOperation"
            :text.sync="confirmationDialogConfirmationText"
            :checkboxText.sync="confirmationDialogReCheckBoxConfirmationText"
            :trueText.sync="confirmationDialogTrueText"
            :falseText.sync="confirmationDialogFalseText"
            @buttonClicked="confirmationButtonClicked"
        ></confirmation-model>
        <ReceptionDetail
            v-if="showReceptionDetail"
            @change-popup-model-visibility="changeReceptionPopUpModelVisibility"
            :data="receptionDetail"
        ></ReceptionDetail>
        <OrderHistory
            v-if="selectedBill"
            @updateBillData="(billId) => updateBill(billId)"
            v-model="orderHistoryDialog"
            :order="selectedBill"
        />
        <OrderContributors
            v-if="selectedBill"
            :order_id="selectedBill.id"
            :showDialog.sync="showOrderContributorsModel"
        ></OrderContributors>
        <span id="launch-interventions" @click="openHistoryTableDialog"
            ><history-table
                v-model="historyTableDialog"
                :model_id="historyTableModelId"
                :model="historyTableModel"
        /></span>
    </v-card>
</template>
<style scoped>
.occupy_space {
    width: 100%;
}

.first_row:hover:not(.v-table__expanded__content) {
    background: white !important;
}

.supplier_name {
    font-size: 12px;
    margin: 0px;
}

.article_name {
    font-size: 14px;
    margin-bottom: 2px;
}

.bill_number {
    font-size: 14px;
    margin: 0px;
}

.identifier {
    font-size: 12px;
    margin-bottom: 2px;
}

.client_name {
    font-size: 11px;
    margin-bottom: 2px;
}

.bill_type {
    font-size: 11px;
    margin-bottom: 2px;
}
</style>

<script>
import axios from "@/plugins/axios";
import Sortable from "sortablejs";
import _ from "lodash";
import { API_BASE_URL, PEPPOL_BILL_TYPES, VIEW_MODE } from "@/config";
// import { EDIT_MODE } from "@/config";;
import CutsomMoneyInput from "@/components/CustomInputs/MoneyInput";
import { BILLING_MODE } from "@/config";
import CreateEditClient from "@/components/Client/CreateEditClient";
import CreateEditProject from "@/components/Projects/CreateEditProject";
import CreateEditVehicle from "@/components/Vehicles/CreateEditVehicle";

import { validationMixin } from "vuelidate";
import {
    required,
    email,
    requiredIf,
    phoneNum,
    numeric,
    minLength,
    maxLength,
    alpha,
    minValue,
    maxValue,
} from "vuelidate/lib/validators";
import SearchArticle from "@/components/Billing/SearchArticle";
import DepotAddModifyTyre from "@/components/TyreHotel/DepotAddModifyTyre";
import DepotOut from "@/components/TyreHotel/DepotOut";
import LoadingModel from "@/components/LoadingModel";
import ConfirmationModel from "@/components/ConfirmationModel";
import DateTimePicker from "@/components/DateTimePicker";
import NoteEditorModal from "../../components/NoteEditorModal.vue";
import peppolMixin from "@/mixins/peppolMixin";
import PeppolStatusField from "@/components/Billing/PeppolStatusField.vue";
import billMixin from "@/mixins/billMixin";
import { mapState } from "vuex";
import PaymentMethod from "../../components/Billing/PaymentMethod.vue";
import OrderReminders from "../../components/Billing/OrderReminders.vue";
import SendPdfViaEmail from "@/components/SendPdfViaEmail.vue";
import splitPaymentMixin from "@/mixins/splitPaymentMixin";
import ExpeditionDocumentsList from "@/components/Expeditions/ExpeditionDocumentsList.vue";
import OrderHistory from "@/components/Billing/OrderHistory.vue";
import historyTableMixin from "@/mixins/historyTableMixin";
import HistoryTable from "@/components/UserActionHistory/HistoryTable.vue";
import ClientReclamation from "@/components/Reclamation.vue";
import OrderContributors from "@/components/OrderContributors.vue";
import { loadMixins } from "@/main.js";
import ReceptionDetail from "@/components/Reception/ReceptionDetail.vue";
import OverdueOrdersList from "@/components/Billing/OverdueOrdersList.vue";
import IconGroupForArticle from "@/components/IconGroupForArticle.vue";
import DateInput from "@/components/DateInput.vue";
import SupplierComponent from "@/components/SupplierComponent.vue";

export default {
    mixins: [
        validationMixin,
        peppolMixin,
        billMixin,
        splitPaymentMixin,
        historyTableMixin,
        ...loadMixins(),
    ],
    validations: {
        // Client Validation
        // Vehicle Validation
        parent_familyClient: {
            required,
        },
        red_percentage: {
            maxValue: maxValue(100),
            minValue: minValue(0),
        },
        reference_client: {
            maxLength: maxLength(200),
        },
        note: {
            maxLength: maxLength(2000),
        },
    },
    name: "Billing",
    props: {
        iCompany: Boolean,
    },
    components: {
        SupplierComponent,
        DateInput,
        IconGroupForArticle,
        OverdueOrdersList,
        ClientReclamation,
        HistoryTable,
        SendPdfViaEmail,
        PeppolStatusField,
        "create-edit-client": CreateEditClient,
        "create-edit-vehicle": CreateEditVehicle,
        "search-article": SearchArticle,
        "depot-add-modify-tyre": DepotAddModifyTyre,
        "depot-out": DepotOut,
        "loading-model": LoadingModel,
        "confirmation-model": ConfirmationModel,
        "t-date-time-field": DateTimePicker,
        "custom-money-input": CutsomMoneyInput,
        "create-edit-project": CreateEditProject,
        NoteEditorModal,
        PaymentMethod,
        OrderReminders,
        ExpeditionDocumentsList,
        OrderHistory,
        OrderContributors,
        ReceptionDetail,
    },
    data() {
        return {
            showReceptionDetail: false,
            receptionDetail: null,
            showOrderContributorsModel: false,
            isVehicleModuleEnabled: this.getCompanyPropertyFromLocalStorage(
                "LINK_VEHICLE_TO_BILL"
            )
                ? this.getCompanyPropertyFromLocalStorage(
                      "LINK_VEHICLE_TO_BILL",
                      1
                  )
                : true,
            orderHistoryDialog: false,
            clientStatuses: [],
            sendPdfDialog: false,
            order_reminders: [],
            order_reminder_type: null,
            order_reminder_type_counts: 0,
            resetted_data_table: false,
            showBillNote: false,
            parentOrder: null,
            childOrder: null,
            internalSupplier: {
                id: 9,
                name: "INTERNAL",
                contact: "",
                identifier: "INT",
                family_article_id: null,
                parent_supplier_id: null,
                parent_supplier: null,
                status_id: 10181000,
                type_id: 10191001,
                created_at: "2018-12-27",
                updated_at: "2019-06-14",
            },
            selectedItemForGroupAndText: false,
            tempItemText: null,
            tempItemGroup: null,
            showTextDialog: false,
            addArticlesAutomatically: this.getCompanyPropertyFromLocalStorage(
                "ADD_AUTOMATICALLY_ARTICLES"
            )
                ? this.convertToBoolean(
                      this.getCompanyPropertyFromLocalStorage(
                          "ADD_AUTOMATICALLY_ARTICLES"
                      ).value
                  )
                : null,
            showGroupText: this.getCompanyPropertyFromLocalStorage(
                "group_articles",
                1
            )
                ? true
                : false,
            parentGroupText: null,
            selectedFamilyArticle: null,
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            isEditingAllowedOfIdentifier: this.checkTag(
                "allow_edit_bill_indentifier"
            ),
            creditNoteMultiplicationValue: null,
            storedDepotIn: [],
            depotInMod: false,
            depotOutMod: false,
            parentSearchSelectedArticleId: null,
            parentSearchSelectedArticleSupplierDetail: null,
            parentInternalStockOnly: true,
            newValidation: false,
            billstatuses: [],
            notificationData: [],
            dataTableOptions: [1000],
            page: 1,
            lastPage: null,
            totalItems: null,
            itemsPerPage: 1000,
            sortBy: "description",
            sortDesc: false,
            options: {},
            pagination: {},
            DeleteConfirm: false,
            denominations: [],
            allStatuses: [],
            order_status: "",
            isVisibleOrderStatus: false,
            isRecalculatingTableData: false,
            showPaymentModel: false,
            showPaymentReminder: false,
            payment_methods: [],
            payment_method: null,
            ispayment_method: false,
            operation: "edit",
            bill_amount: "",
            creditors: [],
            showLoadingModel: false,

            rdv_date: null,
            due_date: null,
            created_date: this.updateDateTime(),
            menu_rdv_date: false,
            menu_due_date: false,
            menu_created_date: false,

            note: "",
            tvas: [],
            reference_client: "",
            isPageLoaded: false,
            acquisition_price: 0.0,
            unit_price: 0.0,
            isInValidUnitPrice: false,
            quantity: 4,
            first_row_tariff: 0,
            first_row_tva: "",
            cached_first_row_tva: "",
            first_row_thtva: "",
            first_row_total: "",
            first_row_minimum_sales_price: 0,
            red_percentage: 0.0,
            next_event_codes: [],
            next_event_code: "",
            search: "",
            isLoadingArticle: false,
            isLoading: false,
            rowNumber: 0,
            articleTHTVATotal: 0.0,
            articleTHTVATotalBeforeReduction: 0.0,
            articleTotal: 0.0,
            articleTotalBeforeReduction: 0.0,
            total_reduction: 0.0,
            reduction_on_order: 0,
            selectedArticles: [],
            finedSelectedArticles: [],
            articles: [],
            article: "",
            search_article: "",
            search_bill: "",
            allowContinueSearch: true,
            bills: [],
            selectedBill: "",
            isLoadingSearchRegiClient: false,
            familyArticle: "",
            customer_balance: 0,

            // Vehicle Component Sync Values
            parent_vehicle_type: null,
            parent_registration_no: null,
            parent_brand: null,
            parent_model: null,
            parent_km: null,
            parent_reg_date: null,
            parent_creditor: null,
            parent_vin: null,
            parent_is_leasing: false,

            // Client Component Sync Values
            parent_entityType: null,
            parent_client_id: null,
            parent_company: null,
            parent_company_client_name: null,
            parent_second_driver: null,
            parent_addedRegistrationNumber: false,
            parent_driver: null,
            parent_company_client_first_name: null,
            parent_company_client_names: [],
            parent_search_client: null,

            parent_company_client_contact_name_2: null,
            parent_company_client_contact_name_3: null,

            parent_company_client_phone: null,
            parent_company_client_phone_2: null,
            parent_company_client_phone_3: null,

            parent_company_client_gsm: null,
            parent_company_client_gsm_2: null,
            parent_company_client_gsm_3: null,

            parent_company_client_fax: null,

            parent_company_client_email: null,
            parent_company_client_email_2: null,
            parent_company_client_email_3: null,

            parent_company_client_language: null,
            parent_web: null,
            parent_currency: null,
            parent_representative_company_id: null,
            parent_user: null,
            parent_note: null,
            parent_familyClient: null,
            parent_denomination: null,
            parent_taxation_reason_id: null,
            parent_registration_code: null,
            parent_tva: null,

            parent_address: null,
            parent_address_same_as: false,

            parent_shipping_address: null,

            dialog: false,

            isEditModeForDataTableItem: false,
            selectedFamilyArticlBeforeEditMode: null,
            selectedItemIndexInDataTable: null,
            currentMu: "",
            mus: [],

            // Parent Project Name
            parent_project_name: null,
            parent_project: null,
            parent_project_location: null,
            parent_project_locations: null,
            parent_project_note: null,
            parent_project_status: null,

            token: this.$store.state.AccessToken,
            header: {
                Authorization: "Bearer " + this.$store.state.AccessToken,
            },
            autocompleteValueLocal: "off",

            confirmationDialog: false,
            confirmationDialogConfirmationText: null,
            confirmationDialogReCheckBoxConfirmationText: null,
            confirmationDialogFalseText: null,
            confirmationDialogTrueText: null,
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,
            selectedDepot: null,
            pauseWatchForUnitPrice: false,

            stock_module_data_for_order: null,
            lastSupplierOrderCreated: null,

            changeSelectedBillIdentifierForTemp: null,
            editIdentifier: null,
            taxationAllowed: true,
            setDefaultObjectForDirtyChecking: null,
            expandedGroups: [],
            overdueOrdersModal: false,
            cancelNoteEditorModal: false,
            cancelReason: null,
        };
    },
    directives: {
        sortableDataTable: {
            bind(el, binding, vnode) {
                const options = {
                    filter: "", // Selectors that do not lead to dragging (String or Function)
                    animation: 350,
                    onUpdate: function(event) {
                        vnode.child.$emit("sorted", event);

                        // vnode.context.saveOrder().then(() => {
                        //     vnode.context.updateGroupText(event);
                        // });
                    },
                    onMove: function(/**Event*/ evt, /**Event*/ originalEvent) {

                        if (
                            evt.dragged.className.includes("first_row") ||
                            evt.dragged.className.includes("last_row") ||
                            evt.related.className == "last_row" ||
                            evt.related.className == "first_row"
                        ) {
                            return false;
                        }
                        // vnode.context.saveOrder().then(() => {
                        //     vnode.context.updateGroupText(evt);
                        // });
                    },
                };

                Sortable.create(el.getElementsByTagName("tbody")[0], options);
            },
        },
    },
    async mounted() {
        this.clientStatuses = await this.getCodes("client_statuses", false);
        this.$store.commit("setValidation", false); // enable validation
        this.autocompleteValueLocal = this.autocompleteValue();
        (this.header = { Authorization: "Bearer " + this.token }),
            this.$store.commit("setFormMode", BILLING_MODE);
        this.$store.commit("setShowPrintReportInPrintDropDown", false);
        this.$store.commit("setShowPrintDepotOutReportInPrintDropDown", false);
        this.creditNoteMultiplicationValue =
            this.getCompanyPropertyFromLocalStorage(
                "CREDIT_NOTE_MULTIPLICATION_VALUE"
            ) ?? this.creditNoteMultiplicationDefaultValue;
        this.resetted_data_table = true;
        this.setCurrentFormOperation();
        this.getPaymentMethods();
        this.getCreditors();
        this.resetSrNo();
        this.getOrderStatuses();
        this.getMeasurementUnits();
        this.getTvas().then(async () => {
            if (this.$route.name == "EditBilling") {
                let billId = this.$store.state.editId;
                if (billId) {
                    this.setHistoryModalData("Order", billId);
                    await this.fetchBillByID(billId);
                } else {
                    this.$router.push("/billing/add").catch(() => {});
                    this.isPageLoaded = true;
                    this.onResetPageData();
                }
                this.$refs["billDropdown"]?.focus();
                // this.$refs['clientComp'].$refs['clientName'].focus(); // remove focus from client name
            } else {
                this.isPageLoaded = true;
                this.onResetPageData();
                this.$nextTick(() => {
                    this.setExpeditionFromRoute();
                });
            }

            if (this.selectedBill) {
                this.$store.state.currentBillPeppolStatus = this.determineBillPeppolStatus(
                    this.selectedBill
                );
                // this.fetchBillRelatives(this.selectedBill);
            }
        });
    },
    activated() {
        this.overdueOrdersModal = false;
    },
    beforeDestroy() {
        this.resetSrNo();
        this.$store.commit("setBillCancelVisible", false);
        this.$store.commit("setEditingAllowed", false);
        this.$store.commit("setMakeBillVisible", false);
        this.$store.commit("setMakePurchaseOrderVisible", false);
        this.$store.commit("setMakeCreditNoteVisible", false);
        this.resetted_data_table = false;
    },
    methods: {
        showSupplierDetailModel(item = null){
            if(!item){
                this.receptionDetail = this.selectedArticles[this.selectedItemIndexInDataTable];
            }
            else{
                this.receptionDetail = item;
            }
            if(this.receptionDetail && this.receptionDetail.saved_supplier_order_detail){
                this.showReceptionDetail = true;
            }
        },
        async onCancelAskFroConfirmationForRefund() {
            let data = await this.makeSubmitData();
            this.updateBilling(data, false, true).then(() => {
                this.askForRefund();
            });
        },
        checkIfStockManaged(element) {
            if (
                element.article &&
                typeof element.article == "object" &&
                element.article.family &&
                typeof element.article.family == "object" &&
                element.article.family.is_stock_managed == 1
            ) {
                return true;
            }
            if (
                element.article_original_name &&
                typeof element.article_original_name == "object" &&
                element.article_original_name.family &&
                typeof element.article_original_name.family == "object" &&
                element.article_original_name.family.is_stock_managed == 1
            ) {
                return true;
            }
            return false;
        },
        changeReceptionPopUpModelVisibility() {
            this.receptionDetail = null;
            this.showReceptionDetail = !this.showReceptionDetail;
            this.submit(true);
        },
        showPaymentModelWhenOrderStatusIsPayedOrPartial(order_status) {
            if (
                (order_status &&
                    typeof order_status == "object" &&
                    order_status.key == "partial_payed") ||
                order_status.key == "payed"
            ) {
                this.showPaymentModel = true;
            }
        },
        showCancelReasonWhenOrderStatusIsCanceled(order_status) {
            if (order_status?.key === "canceled") {
                this.cancelReason = "";
                this.cancelNoteEditorModal = true;
            }
        },
        getSupplierById(supplier_id) {
            let supplierIdentifier = null;
            if (
                supplier_id &&
                this.allSuppliers &&
                this.allSuppliers.length > 0
            ) {
                this.allSuppliers.forEach((element) => {
                    if (element.id == supplier_id) {
                        supplierIdentifier = element.identifier;
                    }
                });
            }
            return supplierIdentifier;
        },
        editGroupText() {},
        updateGroupText(evt) {
            return new Promise((resolve, reject) => {
                let tempSelectedArticles = this.selectedArticles;
                var trs = document.querySelectorAll("tbody tr"),
                    i;
                let previousGroupText = null;
                let processedSelectedArticles = [];
                this.selectedArticles = [];
                let alreadyAddedIdentifiers = [];
                let alreadyAddedExpeditions = [];
                let count = 0;
                trs.forEach((tr) => {
                    let row = tr;
                    // Identifier
                    let article_identifier_row = row.getElementsByClassName(
                        "article_identifier"
                    );
                    let uiIdentifier =
                        article_identifier_row &&
                        article_identifier_row.length > 0
                            ? article_identifier_row[0].textContent ?? ""
                            : "";
                    // Group
                    let group = row.getElementsByClassName("group-header");
                    // Update group if null
                    group && group.length > 0
                        ? (previousGroupText = group[0].textContent ?? null)
                        : null;
                    
                    if (uiIdentifier) {
                        // Update Selected Articles
                        tempSelectedArticles.forEach((article) => {
                            let selectedArticleIdentifier =
                                article && article.article_original_name
                                    ? article.article_original_name.id == 0
                                        ? article.text
                                        : article.article_original_name
                                              .identifier
                                    : article.text;
                            let identifierText = selectedArticleIdentifier
                                .toLowerCase()
                                .toString()
                                .trim();
                            if (
                                !article.expedition_id &&
                                uiIdentifier
                                    .toLowerCase()
                                    .toString()
                                    .trim() == identifierText &&
                                (!alreadyAddedIdentifiers.includes(
                                    identifierText +
                                        article?.text +
                                        article?.supplier_id
                                ) ||
                                    !processedSelectedArticles.includes(
                                        article
                                    ))
                            ) {
                                article.srno = count++;
                                article.row = count;
                                article.group = previousGroupText;
                                processedSelectedArticles.push(article);
                                alreadyAddedIdentifiers.push(
                                    identifierText +
                                        article?.text +
                                        article?.supplier_id
                                );
                            } else if (
                                article.expedition_id &&
                                uiIdentifier
                                    .toLowerCase()
                                    .toString()
                                    .trim() == identifierText &&
                                !alreadyAddedExpeditions.includes(
                                    article.expedition_id
                                )
                            ) {
                                article.srno = count++;
                                article.row = count;
                                article.group = previousGroupText;
                                processedSelectedArticles.push(article);
                                alreadyAddedExpeditions.push(
                                    article.expedition_id
                                );
                            }
                        });
                    }
                });
                this.selectedArticles = processedSelectedArticles;
                resolve();
            });
        },
        toggleGroup(group) {
            const index = this.expandedGroups.indexOf(group);
            if (index >= 0) {
                this.expandedGroups.splice(index, 1);
            } else {
                this.expandedGroups.push(group);
            }
            this.fixHiddenItemsInGroup();
        },
        fixHiddenItemsInGroup() {
            return new Promise((resolve, reject) => {
                var trs = document.querySelectorAll("tbody tr"),
                    i;
                let previousGroupText = null;
                trs.forEach((tr) => {
                    let row = tr;
                    // Identifier
                    let article_identifier_row = row.getElementsByClassName(
                        "article_identifier"
                    );
                    let uiIdentifier =
                        article_identifier_row &&
                        article_identifier_row.length > 0
                            ? article_identifier_row[0].textContent ?? ""
                            : "";
                    // Group
                    let group = row.getElementsByClassName("group-header");
                    // Update group if null
                    group && group.length > 0
                        ? (previousGroupText = group[0].textContent ?? null)
                        : null;
                    if (group && group.length == 0 && uiIdentifier) {
                        if (
                            previousGroupText &&
                            this.expandedGroups.includes(previousGroupText)
                        ) {
                            tr.style.display = "none";
                        } else {
                            tr.style.display = "";
                        }
                    }
                });
                resolve();
            });
        },
        isGroupExpanded(group) {
            if (this.expandedGroups.length == 0) {
                return true;
            }
            return this.expandedGroups.includes(group);
        },
        async isDirty() {
            if (this.setDefaultObjectForDirtyChecking == null) {
                // In case of add this variable will be null
                return true;
            }
            return !this.areObjectsEqual(
                this.setDefaultObjectForDirtyChecking,
                await this.makeSubmitData(),
                ["stock_property"]
            );
        },
        setDueDate() {
            this.due_date = this.generateDueDate(
                this.current_date,
                this.paymentTermsDays
            );
        },
        entitytypeChangedToCompany(val) {
            if (val) {
                // if company
                this.checkAndFixTaxation();
            } else {
                // If Private
                this.taxationAllowed = true;
            }
        },
        addExpeditionCharging(expedition) {
            let text = "";
            expedition.chargings.forEach((charging) => {
                text +=
                    " " +
                    (charging.reference ? charging.reference + ";" : "") +
                    " " +
                    (expedition.identifier ?? "") +
                    "<br>" +
                    " " +
                    (charging.address
                        ? charging.address.human_readable_address
                        : "") +
                    " " +
                    ("(" + charging.charging_from_date + ")") +
                    " " +
                    "<br>";
            });
            return text.toUpperCase();
        },
        addExpeditionDisCharging(expedition) {
            let text = "";
            expedition.dischargings.forEach((discharging) => {
                text +=
                    " " +
                    (discharging.reference ? discharging.reference + ";" : "") +
                    " " +
                    (discharging.address
                        ? discharging.address.human_readable_address
                        : "") +
                    " " +
                    ("(" + discharging.charging_from_date + ")") +
                    " " +
                    "<br>";
                // RefCharging; ExpeditionNumber
                // ChargingAddress (charging date)
                // DischargingAddress (discharging date)
            });
            return text.toUpperCase();
        },
        async getExpeditionArticle(articleId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        API_BASE_URL +
                            "/billings/articles/search?page=1&items_per_page=10" +
                            "&article_ids=" +
                            articleId,
                        { headers: this.header }
                    )
                    .then(({ data }) => {
                        resolve(data.data[0]); // return first article
                    })
                    .catch((err) => {})
                    .finally();
            });
        },
        getTvaForExpeditior(expeditor) {
            // if(expeditor.company.tva_id)
            return parseFloat(this.first_row_tva ?? 0);
        },

        getExpeditor(expeditorCompany) {
            return new Promise((resolve, reject) => {
                axios
                    .get(
                        API_BASE_URL +
                            "/clients/search?companiesOnly=true" +
                            (expeditorCompany.tva
                                ? "&tva=" + expeditorCompany.tva
                                : "") +
                            (expeditorCompany.name
                                ? "&search=" +
                                  encodeURIComponent(expeditorCompany.name)
                                : ""),
                        { headers: this.header }
                    )
                    .then(({ data }) => {
                        if (data.data.length > 0) {
                            resolve(data.data[0]);
                        } else {
                            reject();
                        }
                    })
                    .catch(function(error) {
                        console.log("an error occured " + error);
                        reject();
                    })
                    .finally(() => {
                        this.isLoading = false;
                        reject();
                    });
            });
        },
        async setExpeditionFromRoute() {
            let rowNumber = 0;
            if (
                this.selectedExpeditionIdsForBilling &&
                this.selectedExpeditionIdsForBilling.length > 0
            ) {
                if (
                    this.selectedExpeditionIdsForBilling[0].expeditor &&
                    this.selectedExpeditionIdsForBilling[0].expeditor
                        .representative_company_id
                ) {
                    this.parent_company_client_names = [
                        this.selectedExpeditionIdsForBilling[0].expeditor,
                    ];
                    this.parent_company_client_name = this.selectedExpeditionIdsForBilling[0].expeditor;
                } else {
                    let expeditorData = await this.getExpeditor(
                        this.selectedExpeditionIdsForBilling[0].expeditor
                    );
                    this.parent_company_client_names = [expeditorData];
                    this.parent_company_client_name = expeditorData;
                }
                this.selectedExpeditionIdsForBilling.forEach(
                    async (expedition) => {
                        let text = "REF: " + expedition.reference + "<br>";
                        let chargingText = this.addExpeditionCharging(
                            expedition
                        );
                        let dischargingText = this.addExpeditionDisCharging(
                            expedition
                        );
                        text = text + chargingText + "" + dischargingText;
                        let expeditionArticle = await this.getExpeditionArticle(
                            this.chargingDischargigArticle.article_id
                        );
                        expeditionArticle.supplier = [];
                        let unit_price = parseFloat(
                            expedition.expeditor_price ?? 0
                        );
                        let acquisition_price = parseFloat(
                            expedition.expeditor_price ?? 0
                        );
                        let quantity = 1;
                        let tva = this.getTvaForExpeditior(
                            expedition.expeditor
                        );
                        if (
                            expedition &&
                            expedition.expeditorBillingWorkingPoint &&
                            expedition.expeditorBillingWorkingPoint.address
                        ) {
                            this.parent_address =
                                expedition.expeditorBillingWorkingPoint.address;
                        }

                        let thtva = parseFloat(unit_price * quantity);
                        let total = parseFloat(
                            parseFloat(thtva) + parseFloat((tva * thtva) / 100)
                        );

                        this.selectedArticles.push({
                            srno: rowNumber++,
                            article: expeditionArticle,
                            article_original_name: expeditionArticle,
                            quantity: quantity,
                            acquisition_price: acquisition_price,
                            unit_price: unit_price,
                            minimum_sales_price: unit_price,
                            rounded_unit_price: unit_price.toFixed(
                                this.getDecimalNumber()
                            ),
                            red_percentage: 0.0,
                            tarrif: 0.0,
                            tva: tva,
                            thtva: thtva,
                            stock_property: null,
                            total: total,
                            text: text,
                            row: 0,
                            text_position: 0,
                            depot_out_id: null,
                            depot_in_id: null,
                            supplier_id: null,
                            parent_supplier_id: null,
                            dots: null,
                            group: null,
                            selectedmu: this.defaultMuToSelect,
                            currency_id: this.$store.state.topCurrencyType
                                ? this.$store.state.topCurrencyType.id
                                : this.defaultBankAccount
                                ? this.defaultBankAccount.currency_id
                                : this.defaultCurrencyId,
                            expedition_id: expedition.id,
                        });
                    }
                );
            }
        },
        updateBill(id) {
            this.fetchBillByID(id);
        },
        openOrderHistoryModal() {
            this.orderHistoryDialog = false;

            setTimeout(() => (this.orderHistoryDialog = true), 20);
        },
        getOrderReminder() {
            if (
                this.selectedBill &&
                this.selectedBill.type &&
                (this.selectedBill.type.key == "credit_note" ||
                    this.selectedBill.type.key == "bill")
            ) {
                axios
                    .get(
                        API_BASE_URL +
                            "/billings/" +
                            this.selectedBill.id +
                            "/reminders",
                        { headers: this.header }
                    )
                    .then(({ data }) => {
                        this.order_reminders = data;
                        this.order_reminder_type_counts = data.length;
                        // this.$toast.success( this.$t('billings.text_messages.identifier_updated_successfully'));
                    })
                    .catch((error) => {
                        // this.$toast.error( this.$t('stocks.something_went_wrong'));
                    })
                    .finally(() => {
                        //
                    });
            }
        },
        saveBillIdentifier() {
            let billingsSaveBillData = {
                identifier: this.editIdentifier,
            };

            axios
                .put(
                    API_BASE_URL +
                        "/billings/" +
                        this.selectedBill.id +
                        "/identifier",
                    billingsSaveBillData,
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.selectedBill.bill_number = this.editIdentifier;
                    this.disallowEditIdentifier();
                    this.$toast.success(
                        this.$t(
                            "billings.text_messages.identifier_updated_successfully"
                        )
                    );
                })
                .catch((error) => {
                    this.$toast.error(this.$t("stocks.something_went_wrong"));
                })
                .finally(() => {});
        },
        changeSelectedBillIdentifier() {
            this.confirmChangeIdentifierPopUp();
        },
        allowEditIdentifier() {
            this.changeSelectedBillIdentifierForTemp = this.selectedBill;
            this.editIdentifier = this.selectedBill.bill_number;
        },
        disallowEditIdentifier() {
            this.changeSelectedBillIdentifierForTemp = null;
            this.editIdentifier = null;
        },
        updatePricingBasedOnSupplier(selectedSupplier) {
            this.parentSearchSelectedArticleSupplierDetail = selectedSupplier;
            this.parentSearchSelectedArticleSupplierDetail = {
                text: "",
                value:
                    this.article.supplier[0].identifier.toLowerCase() +
                    "_stock",
                align: "start",
                width: 300,
                sortable: false,
                field_stock:
                    this.article.supplier[0].identifier.toLowerCase() +
                    "_stock",
                field_delivery_date:
                    this.article.supplier[0].identifier.toLowerCase() +
                    "_delivery_date",
                field_price:
                    this.article.supplier[0].identifier.toLowerCase() +
                    "_price",
                field_client_price:
                    this.article.supplier[0].identifier.toLowerCase() +
                    "_client_price",
                header_text: this.article.supplier[0].name,
                supplier_detail: selectedSupplier,
                order: 2,
                index: 2,
                visible: true,
            };
            this.updatePricingForSelectedArticle(this.article.id);
            this.article.supplier[0].random = Math.random();
        },
        getMeasurementUnits() {
            this.getCodes("measurement_units", "measurement_units").then(
                (data) => {
                    this.mus = data;
                    this.mus = this.mus.sort(function(a, b) {
                        if (a.text < b.text) {
                            return -1;
                        }
                        if (a.text > b.text) {
                            return 1;
                        }
                        return 0;
                    });
                }
            );
        },
        selectGivenFamilyArticle(familyArticle) {
            return this.familyArticles.find((element) => {
                if (
                    element.id == familyArticle.id ||
                    element.parent_family_id == familyArticle.id
                ) {
                    return element;
                }
            });
        },
        dataTableItemClicked(item) {
            // Enable Edit Mode for DataTable Item
            this.isEditModeForDataTableItem = true;
            this.selectedItemIndexInDataTable = item.srno;
            if (item.article_original_name) {
                this.articles = [item.article_original_name];
                this.article = item.article_original_name;
                this.selectedFamilyArticlBeforeEditMode = this.selectedFamilyArticle;
                this.selectedFamilyArticle = this.selectGivenFamilyArticle(
                    item.article_original_name.family
                );
                
                this.$nextTick(() => {
                    let internalSupplier = (
                        this.cachedData["suppliers"][
                            this.selectedFamilyArticle.id
                        ] ?? []
                    ).find((element) => {
                        if (element.id == 0) {
                            return element;
                        }
                    });
                    this.article.supplier = (
                        item.supplier ?? [internalSupplier]
                    ).concat(
                        this.cachedData["suppliers"][
                            this.selectedFamilyArticle.id
                        ] ?? []
                    );
                    this.quantity = parseFloat(item.quantity);
                    this.currentMu = item.selectedmu;
                    this.first_row_tariff = parseFloat(item.tarrif ?? 0);
                    this.acquisition_price = parseFloat(item.acquisition_price);
                    this.unit_price = parseFloat(item.unit_price);
                    this.red_percentage = parseFloat(item.red_percentage ?? 0);
                    // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
                    this.first_row_thtva = parseFloat(item.thtva);
                    this.first_row_tva = parseFloat(item.tva);
                    this.first_row_total = parseFloat(item.total);
                    this.first_row_minimum_sales_price = parseFloat(
                        item.minimum_sales_price
                    );
                    this.parentGroupText = item.group ?? null;

                    // Now call the pricing api again to get minimum sales price and updated price
                    let articleId =
                        typeof item.article == "number"
                            ? item.article
                            : typeof item.article.id == "number"
                            ? item.article.id
                            : false;
                    if (!item.minimum_sales_price && articleId) {
                        if (item.supplier && item.supplier.length > 0) {
                            this.parentSearchSelectedArticleSupplierDetail =
                                item.supplier[0];
                            this.parentSearchSelectedArticleSupplierDetail.supplier_detail =
                                item.supplier[0];
                        }
                        this.updatePricingForSelectedArticle(articleId);
                        return false;
                    }
                });
            }
            // this.article.supplier = [];
            // this.article.supplier_stock_detail = [];
        },
        checkIfSelectedBillHasArticleNotDeliveredYet() {
            let hasNotDelivered = false;
            if (
                this.selectedBill &&
                this.selectedBill.details &&
                this.selectedBill.details.length > 0
            ) {
                this.selectedBill.details.forEach(
                    (selectedBillOrderDetailItem) => {
                        if (
                            selectedBillOrderDetailItem &&
                            selectedBillOrderDetailItem.supplier_order_details_id &&
                            selectedBillOrderDetailItem.supplier_order_detail &&
                            selectedBillOrderDetailItem.supplier_order_detail
                                .supplier_order
                        )
                            if (
                                [10091000, 10091001, 10091004].includes(
                                    selectedBillOrderDetailItem
                                        .supplier_order_detail.supplier_order
                                        .status_id
                                )
                            ) {
                                hasNotDelivered = true;
                            }
                    }
                );
            }
            return hasNotDelivered;
        },
        triggerSelectedBillSupplierOrder() {
            this.selectedArticles.forEach((article, index) => {
                if (
                    this.selectedBill &&
                    this.selectedBill.supplier_order &&
                    this.selectedBill.supplier_order.length > 0
                ) {
                    this.selectedBill.supplier_order.forEach(
                        (supplierOrder) => {
                            if (
                                supplierOrder &&
                                supplierOrder.supplierOrderDetails &&
                                article.article ==
                                    supplierOrder.supplierOrderDetails
                                        .article_id
                            ) {
                                // Update Article Suppliers
                                article.supplier = [];
                                article.supplier[0] = supplierOrder.supplier;
                                article.supplier_stock_detail =
                                    supplierOrder.supplier;

                                // Update Article Suppplier Order Status
                                if (
                                    article.supplier_stock_detail &&
                                    supplierOrder.supplier_id &&
                                    article.supplier_stock_detail.id ==
                                        supplierOrder.supplier_id
                                ) {
                                    article.supplier_order_status = supplierOrder;
                                }
                            }
                        }
                    );
                }
            });
        },
        retryPopUp() {
            this.$toast.error(
                this.$t("stocks.error_message_in_place_confirmation")
            );
            // this.confirmationDialogConfirmationText = this.$t(
            //     "stocks.retry_order_confirmation"
            // );
            // this.confirmationDialogFalseText = this.$t("stocks.cancel");
            // this.confirmationDialogTrueText = this.$t("stocks.keep_trying");
            // this.confirmationDialogOperation = "keep_trying";
            // this.confirmationDialog = true;
        },
        askForRefund() {
            if (
                this.selectedBill.payments &&
                this.selectedBill.payments.length > 0 &&
                this.checkIfPaymentTransactionExist(
                    this.selectedBill.payments
                ) &&
                !this.checkIfPaymentRefunded(this.selectedBill.payments)
            ) {
                this.confirmationDialogConfirmationText = this.$t(
                    "billings.text_messages.order_has_payments_do_you_want_to_refund"
                );
                this.confirmationDialogFalseText = this.$t("navigation.no");
                this.confirmationDialogTrueText = this.$t("navigation.yes");
                this.confirmationDialogOperation = "refund";
                this.confirmationDialog = true;
            }
        },
        makeRefund() {
            if (this.selectedBill.bill_number) {
                axios
                    .post(
                        API_BASE_URL +
                            "/billings/refund/" +
                            this.selectedBill.id,
                        { id: this.selectedBill.id },
                        { headers: this.header }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            // this.onResetPageData();
                            this.selectedBill = response.data.data;
                            this.$toast.success(
                                this.$t(
                                    "billings.text_messages.refund_made_successfully"
                                )
                            );
                        } else {
                            this.$toast.error(
                                this.$t(
                                    "billings.text_messages.refund_made_failed"
                                )
                            );
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(
                            this.$t("billings.text_messages.refund_made_failed")
                        );
                    })
                    .finally(() => {});
            }
        },
        makeOrderToSupplier(supplierOrderId, supplier_stock_detail) {
            if (
                supplier_stock_detail.supplier_id &&
                !parseInt(supplier_stock_detail.supplier_id) == 0
            ) {
                axios
                    .post(
                        API_BASE_URL + "/suppliers/order/" + supplierOrderId,
                        supplier_stock_detail,
                        { headers: this.header }
                    )
                    .then(({ data }) => {
                        if (
                            typeof data.data[Object.keys(data.data)[0]] ==
                            "string"
                        ) {
                            this.$toast.warning(
                                data.data[Object.keys(data.data)[0]]
                            );
                        } else {
                            let responseLength = Object.keys(
                                data.data[Object.keys(data.data)[0]]
                            ).length;
                            
                            for (
                                let index = 0;
                                index < responseLength;
                                index++
                            ) {
                                let arrayItemKey =
                                    Object.keys(
                                        data.data[Object.keys(data.data)[0]]
                                    )[index] + "";
                                let arrayItemValue =
                                    data.data[Object.keys(data.data)[0]][
                                        arrayItemKey
                                    ];
                                if (arrayItemValue) {
                                    let response = arrayItemValue;
                                    if (response.error_code == 0) {
                                        if (response.availability > -1) {
                                            this.$toast.success(
                                                this.$t(
                                                    "stocks.order_placed_successfully"
                                                )
                                            );
                                        } else {
                                            this.retryPopUp();
                                        }
                                    } else {
                                        this.retryPopUp();
                                        this.$toast.error(
                                            response.error_message
                                        );
                                    }
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        this.retryPopUp();
                        //   this.$toast.error( this.$t('stocks.something_went_wrong'));
                    })
                    .finally(() => {
                        this.ordering = false;
                    });
            }
        },
        // submitOrderDetails(supplier_stock_detail) {
        //   if(supplier_stock_detail.supplier_id && (! parseInt(supplier_stock_detail.supplier_id) == 0)){
        //
        //     axios
        //       .post(API_BASE_URL + "/orders", supplier_stock_detail, {
        //         headers: this.header,
        //       })
        //       .then((response) => {
        //         if (response.status === 201) {
        //           if (!this.selectedBill.supplier_order) {
        //             this.selectedBill.supplier_order = [];
        //           }
        //           this.selectedBill.supplier_order.push(response.data.data);
        //           this.triggerSelectedBillSupplierOrder();
        //           this.lastSupplierOrderCreated = response.data.data;
        //           this.makeOrderToSupplier(
        //             response.data.data.id,
        //             supplier_stock_detail
        //           );
        //         } else {
        //           // alert('ERROR on saving. Please try again later');
        //           // this.$toast.error( this.$t('stocks.something_went_wrong'));
        //         }
        //       })
        //       .catch((error) => {
        //         alert(error);
        //         // this.$toast.error( this.$t('stocks.something_went_wrong'));
        //       })
        //       .finally(() => {
        //
        //       });
        //   }
        //   this.resetBilling();
        // },
        resetBilling() {
            let billingId = this.selectedBill.id;
            this.fetchBillByID(billingId);
        },
        updateItemInSelectedArticles(item) {
            this.selectedArticles.forEach((element) => {
                console.log("element", element, item);
            });
        },
        addTextAndGroupToArticle(item) {
            this.tempItemGroup = item.group;
            this.tempItemText = item.text;
            this.selectedItemForGroupAndText = item;
            this.showTextDialog = true;
        },
        saveTextAndGroupToArticle() {
            this.selectedItemForGroupAndText.group = this.tempItemGroup;
            this.selectedItemForGroupAndText.text = this.tempItemText;
            this.updateItemInSelectedArticles(this.selectedItemForGroupAndText);
            this.showTextDialog = false;
            this.selectedItemForGroupAndText = null;
        },
        cancelTextAndGroupToArticle(item) {
            this.tempItemGroup = null;
            this.tempItemGroup = null;
            this.showTextDialog = false;
            this.selectedItemForGroupAndText = null;
        },
        saveOrder(event) {
            return new Promise((resolve, reject) => {
                let tempSelectedArticles = this.selectedArticles;
                let processedSelectedArticles = [];
                // this.selectedArticles = [];
                let count = 0;
                let identifiers = document.getElementsByClassName(
                    "article_identifier"
                );
                let alreadyAddedIdentifiers = [];
                let alreadyAddedExpeditions = [];
                console.log("identifiers", identifiers);
                Array.from(identifiers).forEach((element) => {
                    let uiIdentifier = element.textContent;
                    tempSelectedArticles.forEach((article) => {
                        let selectedArticleIdentifier =
                            article && article.article_original_name
                                ? article.article_original_name.id == 0
                                    ? article.text
                                    : article.article_original_name.identifier
                                : article.text;
                        let identifierText = selectedArticleIdentifier
                            .toLowerCase()
                            .toString()
                            .trim();
                        if (
                            !article.expedition_id &&
                            uiIdentifier
                                .toLowerCase()
                                .toString()
                                .trim() == identifierText &&
                            (!alreadyAddedIdentifiers.includes(
                                identifierText +
                                    article?.text +
                                    article?.supplier_id
                            ) ||
                                !processedSelectedArticles.includes(article))
                        ) {
                            article.srno = count++;
                            article.row = count;
                            processedSelectedArticles.push(article);
                            alreadyAddedIdentifiers.push(
                                identifierText +
                                    article?.text +
                                    article?.supplier_id
                            );
                        } else if (
                            article.expedition_id &&
                            uiIdentifier
                                .toLowerCase()
                                .toString()
                                .trim() == identifierText &&
                            !alreadyAddedExpeditions.includes(
                                article.expedition_id
                            )
                        ) {
                            article.srno = count++;
                            article.row = count;
                            processedSelectedArticles.push(article);
                            alreadyAddedExpeditions.push(article.expedition_id);
                        }
                    });
                });
                // this.selectedArticles = processedSelectedArticles;
                resolve();
            });

            // this.$nextTick(() => {
            //   this.selectedArticles = processedSelectedArticles;
            // })
        },
        row_classes(item) {
            // if(item.)
            return "articles_row_item";
        },
        reverseCalculate(total, thtva) {
            this.pauseWatchForUnitPrice = true;
            if (total) {
                // x = total/quantity(1+ tva/100)
                // (unit price * quantity) * ( 1 -  red_percentage /100)( 1 + tva /100)
                this.unit_price = parseFloat(
                    total /
                        (this.quantity *
                            (1 - this.red_percentage / 100) *
                            (1 + this.first_row_tva / 100))
                );
                this.rounded_unit_price = parseFloat(
                    total / (this.quantity * (1 + this.first_row_tva / 100))
                ).toFixed(this.getDecimalNumber());
                this.calculateFields(true, true, true, false);
            }
            if (thtva) {
                //  thtva = unit_price * quantity (1 - r/100 )
                this.unit_price = parseFloat(
                    thtva / (this.quantity * (1 - this.red_percentage / 100))
                );
                this.rounded_unit_price = parseFloat(
                    thtva / this.quantity
                ).toFixed(this.getDecimalNumber());
                this.calculateFields(true, true, false, true);
            }
        },
        getProposedArticles(
            article_id,
            family_article_id,
            family_client_id,
            quantity
        ) {
            axios
                .get(
                    API_BASE_URL +
                        "/articles/proposed/" +
                        article_id +
                        "?family_article_id=" +
                        family_article_id +
                        (family_client_id
                            ? "&family_client_id=" + family_client_id
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    data.data.forEach((proposedArticle) => {
                        let acquisition_price = proposedArticle.price ?? 0.0;
                        let unit_price = proposedArticle.price ?? 0.0;
                        let rounded_unit_price = proposedArticle.price ?? 0.0;
                        let first_row_tariff = parseFloat(
                            proposedArticle.article.tariff_price ?? 0
                        );
                        let first_row_thtva = 0;
                        let first_row_total = 0;
                        // let first_row_minimum_sales_price = proposedArticle.minimum_sales_price;
                        let first_row_minimum_sales_price = 0; // as this is proposed article and we haven't set the proposed article price
                        let red_percentage = 0;

                        // check if article already exists in the datatable
                        let alreadyExistsProposedArticle = this.selectedArticles.find(
                            (element, index) => {
                                if (
                                    typeof element.article == "object" &&
                                    element.article.id ==
                                        proposedArticle.article.id
                                ) {
                                    this.selectedArticles.splice(index, 1);
                                    return element;
                                }
                                if (
                                    typeof element.article == "number" &&
                                    element.article ==
                                        proposedArticle.article.id
                                ) {
                                    this.selectedArticles.splice(index, 1);
                                    return element;
                                }
                            }
                        );
                        if (alreadyExistsProposedArticle) {
                            // recalculate the row total
                            quantity =
                                parseFloat(quantity) +
                                parseFloat(
                                    alreadyExistsProposedArticle.quantity
                                );
                            unit_price = parseFloat(unit_price);
                            // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
                            first_row_thtva = parseFloat(
                                unit_price * quantity -
                                    (unit_price * quantity * red_percentage) /
                                        100
                            );
                            first_row_total = parseFloat(
                                parseFloat(first_row_thtva) +
                                    parseFloat(
                                        (this.first_row_tva * first_row_thtva) /
                                            100
                                    )
                            );
                        }

                        let isToMultiplyWithCredNoteMulValue = false;
                        if (
                            this.billType &&
                            this.billType.key == "credit_note"
                        ) {
                            isToMultiplyWithCredNoteMulValue = true;
                        }

                        let thtva = parseFloat(
                            unit_price * quantity -
                                (unit_price * quantity * 0.0) / 100
                        );

                        this.resetMeasurementUnit();

                        this.selectedArticles.push({
                            srno: this.rowNumber++,
                            article: proposedArticle.article,
                            article_original_name: proposedArticle.article,
                            quantity: quantity,
                            acquisition_price: parseFloat(
                                isToMultiplyWithCredNoteMulValue
                                    ? parseInt(
                                          this.creditNoteMultiplicationValue
                                              .value
                                      ) * (unit_price ?? 0)
                                    : unit_price ?? 0
                            ),
                            unit_price: parseFloat(
                                isToMultiplyWithCredNoteMulValue
                                    ? parseInt(
                                          this.creditNoteMultiplicationValue
                                              .value
                                      ) * (unit_price ?? 0)
                                    : unit_price ?? 0
                            ),
                            minimum_sales_price: parseFloat(
                                first_row_minimum_sales_price
                            ),
                            rounded_unit_price: parseFloat(
                                isToMultiplyWithCredNoteMulValue
                                    ? parseInt(
                                          this.creditNoteMultiplicationValue
                                              .value
                                      ) * (rounded_unit_price ?? 0)
                                    : rounded_unit_price ?? 0
                            ).toFixed(this.getDecimalNumber()),
                            red_percentage: red_percentage,
                            tarrif: proposedArticle.article.tariff_price ?? 0,
                            tva: parseFloat(this.first_row_tva),
                            thtva: parseFloat(
                                isToMultiplyWithCredNoteMulValue
                                    ? parseInt(
                                          this.creditNoteMultiplicationValue
                                              .value
                                      ) * (thtva ?? 0)
                                    : thtva ?? 0
                            ),
                            stock_property: null,
                            total: parseFloat(
                                isToMultiplyWithCredNoteMulValue
                                    ? parseInt(
                                          this.creditNoteMultiplicationValue
                                              .value
                                      ) *
                                          (parseFloat(
                                              parseFloat(thtva) +
                                                  parseFloat(
                                                      (this.first_row_tva *
                                                          thtva) /
                                                          100
                                                  )
                                          ) ?? 0)
                                    : parseFloat(
                                          parseFloat(thtva) +
                                              parseFloat(
                                                  (this.first_row_tva * thtva) /
                                                      100
                                              )
                                      ) ?? 0
                            ),
                            text: null,
                            row: 0,
                            text_position: 0,
                            depot_out_id: null,
                            depot_in_id: null,
                            supplier_id: null,
                            parent_supplier_id: null,
                            dots: null,
                            group: null,
                            selectedmu: "",
                            currency_id: this.$store.state.topCurrencyType
                                ? this.$store.state.topCurrencyType.id
                                : this.defaultBankAccount
                                ? this.defaultBankAccount.currency_id
                                : this.defaultCurrencyId,
                            expedition_id: null,
                        });
                        this.resetSrNo();
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        showOrHidePrintReport() {
            if (this.selectedBill) {
                let found = false;
                if (
                    this.selectedBill &&
                    this.selectedBill.details &&
                    this.selectedBill.details.length > 0
                ) {
                    this.selectedBill.details.forEach((order_detail) => {
                        // if (order_detail.depot_out_id || order_detail.depot_in_id) {
                        //   found = true;
                        // }
                        if (order_detail.depot_in_id) {
                            found = true;
                        }
                    });
                }
                if (found) {
                    this.$store.commit(
                        "setShowPrintReportInPrintDropDown",
                        true
                    );
                } else {
                    this.$store.commit(
                        "setShowPrintReportInPrintDropDown",
                        false
                    );
                }
            } else {
                this.$store.commit("setShowPrintReportInPrintDropDown", false);
            }
        },
        showOrHidePrintDepotOutReport() {
            if (this.selectedBill) {
                let found = false;
                if (
                    this.selectedBill &&
                    this.selectedBill.details &&
                    this.selectedBill.details.length > 0
                ) {
                    this.selectedBill.details.forEach((order_detail) => {
                        // if (order_detail.depot_out_id || order_detail.depot_in_id) {
                        //   found = true;
                        // }
                        if (order_detail.depot_out_id) {
                            found = true;
                        }
                    });
                }
                if (found) {
                    this.$store.commit(
                        "setShowPrintDepotOutReportInPrintDropDown",
                        true
                    );
                } else {
                    this.$store.commit(
                        "setShowPrintDepotOutReportInPrintDropDown",
                        false
                    );
                }
            } else {
                this.$store.commit(
                    "setShowPrintDepotOutReportInPrintDropDown",
                    false
                );
            }
        },
        confirmChangeIdentifierPopUp() {
            this.confirmationDialogConfirmationText = this.$t(
                "billings.identifier_confirmation_message"
            );
            this.confirmationDialogFalseText = this.$t("no");
            this.confirmationDialogTrueText = this.$t("yes");
            this.confirmationDialogOperation = "identifier_confirmation";
            this.confirmationDialog = true;
        },
        confirmPrintReportPopUp() {
            this.confirmationDialogConfirmationText = this.$t(
                "billings.print_report_confirmation"
            );
            this.confirmationDialogFalseText = this.$t("no");
            this.confirmationDialogTrueText = this.$t("yes");
            this.confirmationDialogOperation = "print_report";
            this.confirmationDialog = true;
        },
        clientEmailErrorPopUp() {
            this.confirmationDialogConfirmationText = this.$t(
                "billings.can_not_send_email_beca_email_not_present"
            );
            this.confirmationDialogFalseText = null;
            this.confirmationDialogTrueText = this.$t("close");
            this.confirmationDialogOperation = "message";
            this.confirmationDialog = true;
        },
        confirmPrintLabelPopUp() {
            this.confirmationDialogConfirmationText = this.$t(
                "billings.print_label_confirmation"
            );
            this.confirmationDialogFalseText = this.$t("no");
            this.confirmationDialogTrueText = this.$t("yes");
            this.confirmationDialogOperation = "print_label";
            this.confirmationDialog = true;
        },
        confirmMakeBillPopUp() {
            if (this.checkIfForCurrentOrderSupplierOrderNotReceived()) {
                this.$nextTick(() => {
                    this.confirmationDialogConfirmationText = this.$t(
                        "billings.convert_to_bill_confirmation_and_supplier_not_received"
                    );
                    this.confirmationDialogFalseText = this.$t("no");
                    this.confirmationDialogTrueText = this.$t("yes");
                    this.confirmationDialogOperation = "make_bill";
                    this.confirmationDialog = true;
                });
            } else {
                this.$nextTick(() => {
                    this.confirmationDialogConfirmationText = this.$t(
                        "billings.convert_to_bill_confirmation"
                    );
                    this.confirmationDialogFalseText = this.$t("no");
                    this.confirmationDialogTrueText = this.$t("yes");
                    this.confirmationDialogOperation = "make_bill";
                    this.confirmationDialog = true;
                });
            }
        },
        confirmPurchaseOrderPopUp() {
            this.confirmationDialogConfirmationText = this.$t(
                "billings.convert_to_purchase_order_confirmation"
            );
            this.confirmationDialogFalseText = this.$t("no");
            this.confirmationDialogTrueText = this.$t("yes");
            this.confirmationDialogOperation = "make_purchase_order";
            this.confirmationDialog = true;
        },
        confirmCreditNotePopUp() {
            this.confirmationDialogConfirmationText = this.$t(
                "billings.convert_to_credit_note_confirmation"
            );
            this.confirmationDialogReCheckBoxConfirmationText = this.$t(
                "billings.convert_to_credit_note_confirmation_reconfirm_text"
            );
            this.confirmationDialogFalseText = this.$t("no");
            this.confirmationDialogTrueText = this.$t("yes");
            this.confirmationDialogOperation = "make_credit_note";
            this.confirmationDialog = true;
        },
        printReport() {
            let url =
                API_BASE_URL +
                "/depot/" +
                this.selectedDepot.md5_id +
                "/print?document=report&lang=" +
                (localStorage.getItem("languageId") || "en") +
                "&user=" +
                localStorage.getItem("loginUserId");
            let fileName = this.selectedBill
                ? this.selectedBill?.bill_number
                : "";
            this.setPreviewData(url, fileName, true, "application/pdf");
        },
        printLabel() {
            let url =
                API_BASE_URL +
                "/depot/" +
                this.selectedDepot.md5_id +
                "/print?document=labels&lang=" +
                (localStorage.getItem("languageId") || "en") +
                "&user=" +
                localStorage.getItem("loginUserId");
            let fileName = this.selectedBill
                ? this.selectedBill?.bill_number
                : "";
            this.setPreviewData(url, fileName, true, "application/pdf");
        },
        confirmationButtonClicked(buttonClicked) {
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog = false;
        },
        modifyDepotOutClose(modifyDialog, data) {
            if (data) {
                this.addDepotArticleToDataTable(data, "out");
            }

            this.depotOutMod = false;
        },
        modifyDepotInClose(modifyDialog, data) {
            this.depotInMod = false;
            if (data) {
                this.selectedDepot = data;
                this.storedDepotIn.push(this.selectedDepot.id);
                this.parent_registration_no.depot.push(data);
                this.confirmPrintLabelPopUp();
                this.addDepotArticleToDataTable(data, "in");
            }
        },

        depotIn() {
            this.selectedDepot = null;
            const clientData = this.makeNewClientData();
            const vehicleData = this.makeNewVehicleData();
            this.addClient(clientData).then(() => {
                this.createVehicle(vehicleData).then(() => {
                    this.depotInMod = true;
                });
            });
        },
        depotOut() {
            const clientData = this.makeNewClientData();
            const vehicleData = this.makeNewVehicleData();
            this.addClient(clientData).then(() => {
                this.createVehicle(vehicleData).then(() => {
                    this.depotOutMod = true;
                });
            });
        },
        getNoteValue() {
            return this.parent_note
                ? this.parent_note
                : this.parent_company_client_name &&
                  typeof this.parent_company_client_name == "object"
                ? this.parent_company_client_name.note
                : null;
        },
        makeNewClientData() {
            return {
                name: this.parent_company_client_name
                    ? typeof this.parent_company_client_name == "object"
                        ? this.parent_company_client_name.name
                        : this.parent_company_client_name
                    : null,
                first_name: this.parent_company_client_first_name
                    ? typeof this.parent_company_client_first_name == "object"
                        ? this.parent_company_client_first_name.first_name
                        : this.parent_company_client_first_name
                    : null,
                family_client_id: this.parent_familyClient
                    ? typeof this.parent_familyClient == "object"
                        ? parseInt(this.parent_familyClient.id)
                        : this.parent_familyClient
                    : null,
                denomination_id: this.parent_denomination
                    ? typeof this.parent_denomination == "object"
                        ? this.parent_denomination.id
                        : this.parent_denomination
                    : null,
                taxation_reason_id: this.parent_taxation_reason_id
                    ? typeof this.parent_taxation_reason_id == "object"
                        ? this.parent_taxation_reason_id.id
                        : this.parent_taxation_reason_id
                    : null,
                email: this.parent_company_client_email ?? null,
                email_2: this.parent_company_client_email_2 ?? null,
                email_3: this.parent_company_client_email_3 ?? null,
                language:
                    this.parent_company_client_language ?? this.userLanguageId,
                language_id:
                    this.parent_company_client_language ?? this.userLanguageId,
                phone: this.parent_company_client_phone ?? null,
                phone_2: this.parent_company_client_phone_2 ?? null,
                phone_3: this.parent_company_client_phone_3 ?? null,
                gsm: this.parent_company_client_gsm ?? null,
                gsm_2: this.parent_company_client_gsm_2 ?? null,
                gsm_3: this.parent_company_client_gsm_3 ?? null,
                contact_name_2:
                    this.parent_company_client_contact_name_2 ?? null,
                contact_name_3:
                    this.parent_company_client_contact_name_3 ?? null,
                fax: this.parent_company_client_fax ?? null,
                web: this.parent_web ?? null,
                tva: this.parent_tva ?? null,
                note: this.getNoteValue(),
            };
        },
        addClient(data) {
            return new Promise((resolve, reject) => {
                if (
                    !(this.parent_company_client_name
                        ? typeof this.parent_company_client_name == "object"
                            ? this.parent_company_client_name.id
                            : null
                        : null)
                ) {
                    this.showLoadingModel = true;
                    axios
                        .post(API_BASE_URL + "/clients", data, {
                            headers: this.header,
                        })
                        .then((response) => {
                            this.parent_company_client_name =
                                response.data.data;
                            resolve();
                        })
                        .catch((error) => {
                            reject();
                        })
                        .finally(() => {
                            this.showLoadingModel = false;
                        });
                } else {
                    resolve();
                }
            });
        },
        makeNewVehicleData() {
            return {
                client_id: this.parent_company_client_name
                    ? typeof this.parent_company_client_name == "object"
                        ? this.parent_company_client_name.id
                        : null
                    : null,
                vehicle_type_id: this.parent_vehicle_type
                    ? typeof this.parent_vehicle_type == "object"
                        ? this.parent_vehicle_type.id
                        : null
                    : null,
                registration_number:
                    this.parent_registration_no &&
                    typeof this.parent_registration_no == "object"
                        ? this.parent_registration_no.registration_number
                        : this.parent_registration_no,
                brand_id:
                    this.parent_brand && typeof this.parent_brand == "object"
                        ? this.parent_brand.id
                        : this.parent_brand ?? null,
                model: this.parent_model ?? null,
                km: this.parent_km ?? null,
                registration_date: this.parent_reg_date ?? null,
                vin: this.parent_vin ?? null,
                is_leasing: this.parent_creditor ? true : false,
                driver: this.parent_driver ? this.parent_driver : null,
                second_driver: this.parent_second_driver
                    ? this.parent_second_driver
                    : null,
                creditor_id: this.parent_creditor
                    ? typeof this.parent_creditor == "object"
                        ? this.parent_creditor.id
                        : this.parent_creditor
                    : null,
            };
        },
        createVehicle(data) {
            data.client_id = this.parent_company_client_name
                ? typeof this.parent_company_client_name == "object"
                    ? this.parent_company_client_name.id
                    : null
                : null;
            return new Promise((resolve, reject) => {
                if (
                    !(this.parent_registration_no &&
                    typeof this.parent_registration_no == "object"
                        ? this.parent_registration_no.id
                        : null)
                ) {
                    this.showLoadingModel = true;
                    axios
                        .post(API_BASE_URL + "/client_vehicles", data, {
                            headers: this.header,
                        })
                        .then((response) => {
                            this.parent_registration_no = response.data;
                            this.parent_addedRegistrationNumber = true;
                            resolve();
                        })
                        .catch((error) => {
                            if (
                                error.response &&
                                error.response.data.errors[0]
                            ) {
                                this.$toast.error(
                                    this.$t(
                                        "vehicles.text_messages." +
                                            error.response.data.errors[0]
                                    )
                                );
                            }
                            reject();
                        })
                        .finally(() => {
                            this.showLoadingModel = false;
                        });
                } else {
                    resolve();
                }
            });
        },
        debounceInput: _.debounce(function(val) {}, 500),
        looseFocusFromBillBox() {
            // 
        },
        setPageTitle() {
            if (this.billType && this.billType.key == "estimation") {
                this.$route.meta.title = this.selectedBill
                    ? "edit_estimation"
                    : "add_estimation";
            } else if (this.billType && this.billType.key == "purchase_order") {
                this.$route.meta.title = this.selectedBill
                    ? "edit_purchase_order"
                    : "add_purchase_order";
            } else if (this.billType && this.billType.key == "bill") {
                this.$route.meta.title = this.selectedBill
                    ? "edit_bill"
                    : "add_bill";
            } else if (this.billType && this.billType.key == "credit_note") {
                this.$route.meta.title = this.selectedBill
                    ? "edit_credit_note"
                    : "add_credit_note";
            }
        },
        goBackToListing() {
            if (this.$store.state.editId) {
                // if the user has came from the listing page and clicks on close button then redirect him to the last page visited
                this.$router.go(-1);
            }
        },
        changeDialog() {
            this.dialog = false;
            this.$nextTick(() => this.$refs["tableQuantity"].focus());
        },
        resetSelectedArticle() {
            this.article = "";
            this.acquisition_price = 0.0;
            this.unit_price = 0.0;
            this.red_percentage = 0.0;
            this.first_row_thtva = 0.0;
            this.first_row_total = 0.0;
            this.first_row_minimum_sales_price = 0.0;
            this.quantity = this.selectedFamilyArticle
                ? this.selectedFamilyArticle.default_quantity
                : 4;
            this.resetMeasurementUnit();
            this.parentGroupText = null;
            this.isEditModeForDataTableItem = false;
            this.selectedItemIndexInDataTable = null;
        },
        showDialog() {
            this.parentSearchSelectedArticleId = null;
            this.parentSearchSelectedArticleSupplierDetail = null;
            this.dialog = true;
        },
        getTvas() {
            return new Promise((resolve, reject) => {
                axios
                    .get(API_BASE_URL + "/tvas?by_auth=1", {
                        headers: this.header,
                    })
                    .then(({ data }) => {
                        // this.tvas.push(parseFloat(0));
                        // data.forEach((item, index)  => {
                        //   this.tvas.push(parseFloat(item.tax));
                        // });

                        // this.tvas = this.tvas.sort(function(a, b) {
                        //   return b - a;
                        // });
                        this.first_row_tva = this.fixDecimal(data.tax);
                        this.cached_first_row_tva = this.fixDecimal(data.tax);
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err);
                        reject();
                    });
            });
        },
        fetchBillByID(id) {
            return new Promise((resolve, reject) => {
                if (id) {
                    axios
                        .get(API_BASE_URL + "/billings/" + id, {
                            headers: this.header,
                        })
                        .then(({ data }) => {
                            this.bills = [data.data];
                            this.selectedBill = data.data;
                            this.isPageLoaded = true;
                            resolve();
                        })
                        .catch((err) => {
                            console.log(err);
                            this.isLoadingArticle = false;
                            this.isPageLoaded = true;
                            this.$router.push("/billing/add").catch(() => {});
                        })
                        .finally(() => {
                            this.isLoadingArticle = false;
                            this.isPageLoaded = true;

                            setTimeout(() => {
                                this.$nextTick(async () => {
                                    this.setDefaultObjectForDirtyChecking = await this.makeSubmitData();
                                });
                            }, 400);
                        });
                } else {
                    this.$router.push("/billing/add").catch(() => {});
                }
            });
        },
        checkIfFamilyClientEmpty(event) {
            if (!this.parent_familyClient) {
                this.$refs["clientComp"].$refs["family_client"].focus();
                this.$refs["clientComp"].$refs["family_client"].$touch;
                event.preventDefault();
            }
        },
        reCalculateSelectedArticlesPricing() {
            if (this.selectedArticles.length > 0) {
                let selectedIds = this.selectedArticles.map((element) => {
                    if (typeof element.article == "number") {
                        // saved bill case, on load
                        return element.article;
                    }
                    return element.article.article_id ?? element.article.id;
                });

                this.isRecalculatingTableData = true;

                axios
                    .get(
                        API_BASE_URL +
                            "/billings/articles/search?page=1&items_per_page=10" +
                            (this.parent_familyClient
                                ? "&family_client_for_pricing=" +
                                  this.parent_familyClient
                                : "") +
                            (selectedIds.length > 0
                                ? "&article_ids=" + selectedIds
                                : ""),
                        { headers: this.header }
                    )
                    .then(({ data }) => {
                        this.rowNumber = 0;
                        var tempSelectedArticles = [];

                        // mapping current selected articles with new articles and then updating the temporaray selected articles with new ... and rest of the things will be done by the watchers
                        this.selectedArticles.map((articleOldData) => {
                            let newMappedArticle = data.data.find(
                                (articleNewData) => {
                                    if (
                                        typeof articleOldData.article ==
                                        "number"
                                    ) {
                                        // saved bill case, on load
                                        return (
                                            articleNewData.id ==
                                            articleOldData.article
                                        );
                                    } else {
                                        return (
                                            articleNewData.id ==
                                            articleOldData.article.article_id
                                        );
                                    }
                                }
                            );

                            if (newMappedArticle) {
                                let tariff = parseFloat(
                                    newMappedArticle.tariff_price ?? 0
                                );
                                // let unit_price = parseFloat(newMappedArticle.internal_company_article ? newMappedArticle.internal_company_article[0].price : 0); // test it again
                                let acquisition_price = parseFloat(
                                    articleOldData.internal_company_article
                                        ? articleOldData.unit_price
                                        : 0
                                ); // test it again
                                let unit_price = parseFloat(
                                    articleOldData.internal_company_article
                                        ? articleOldData.unit_price
                                        : 0
                                ); // test it again
                                let rounded_unit_price = parseFloat(
                                    articleOldData.internal_company_article
                                        ? articleOldData.rounded_unit_price
                                        : 0
                                ); // test it again
                                let tva = parseFloat(
                                    newMappedArticle.internal_company_article
                                        .length > 0
                                        ? newMappedArticle
                                              .internal_company_article[0].tva
                                              .tax
                                        : 17
                                );
                                let thtva = parseFloat(
                                    unit_price * articleOldData.quantity -
                                        (unit_price *
                                            articleOldData.quantity *
                                            articleOldData.red_percentage) /
                                            100
                                );
                                let total = parseFloat(
                                    parseFloat(thtva) +
                                        parseFloat((tva * thtva) / 100)
                                );
                                tempSelectedArticles.push({
                                    srno: this.rowNumber++,
                                    article: newMappedArticle,
                                    article_original_name: newMappedArticle,
                                    quantity: articleOldData.quantity,
                                    acquisition_price: acquisition_price ?? 0,
                                    unit_price: unit_price ?? 0,
                                    minimum_sales_price:
                                        newMappedArticle.minimum_sales_price,
                                    rounded_unit_price: rounded_unit_price ?? 0,
                                    red_percentage:
                                        articleOldData.red_percentage ?? 0,
                                    tarrif: tariff,
                                    tva: tva,
                                    stock_property:
                                        newMappedArticle.stock_property ?? null,
                                    thtva: thtva,
                                    total: total,
                                    family_article: newMappedArticle.family,
                                    text: newMappedArticle.text,
                                    text_position:
                                        newMappedArticle.text_position ?? 0,
                                    depot_out_id: newMappedArticle.depot_out_id,
                                    depot_in_id: newMappedArticle.depot_in_id,
                                    supplier_id: newMappedArticle?.supplier_id,
                                    parent_supplier_id:
                                        newMappedArticle?.parent_supplier_id,
                                    selectedmu: newMappedArticle.mu_id,
                                    currency_id: this.$store.state
                                        .topCurrencyType
                                        ? this.$store.state.topCurrencyType.id
                                        : this.defaultBankAccount
                                        ? this.defaultBankAccount.currency_id
                                        : this.defaultCurrencyId,
                                });
                            }
                        });
                        // this.selectedArticles = tempSelectedArticles;
                    })
                    .catch((err) => {
                        this.isRecalculatingTableData = false;
                    })
                    .finally((this.isRecalculatingTableData = false));
            }
        },
        checkEmptinessOfForm() {
            let isBillFieldsEmpty =
                !this.order_status &&
                !this.payment_method &&
                !this.note &&
                !this.reference_client &&
                !this.red_percentage &&
                !this.next_event_code &&
                this.selectedArticles.length == 0;

            let isClientFieldsEmpty =
                !this.parent_company_client_name &&
                !this.parent_company_client_first_name &&
                !this.parent_search_client &&
                !this.parent_company_client_phone &&
                !this.parent_company_client_phone_2 &&
                !this.parent_company_client_phone_3 &&
                !this.parent_company_client_gsm &&
                !this.parent_company_client_gsm_2 &&
                !this.parent_company_client_gsm_3 &&
                !this.parent_company_client_email &&
                !this.parent_company_client_email_2 &&
                !this.parent_company_client_email_3 &&
                !this.parent_company_client_contact_name_2 &&
                !this.parent_company_client_contact_name_3 &&
                !this.parent_note &&
                !this.parent_tva &&
                !this.parent_address;

            let isVehicleFieldsEmpty =
                !this.parent_vehicle_type &&
                !this.parent_registration_no &&
                !this.parent_brand &&
                !this.parent_model &&
                !this.parent_km &&
                !this.parent_driver &&
                !this.parent_second_driver &&
                !this.parent_reg_date &&
                !this.parent_creditor &&
                !this.parent_vin;

            if (
                isBillFieldsEmpty &&
                isClientFieldsEmpty &&
                isVehicleFieldsEmpty
            ) {
                this.$store.commit("setShowNewButton", true);
            } else {
                if (this.selectedBill) {
                    this.$store.commit("setShowNewButton", true);
                } else {
                    this.$store.commit("setShowNewButton", false);
                }
            }

            if (this.selectedBill) {
                this.$store.commit("setIsBillSelected", true);
                this.$store.commit("setSelectedBillData", this.selectedBill);
            } else {
                this.$store.commit("setIsBillSelected", false);
                this.$store.commit("setSelectedBillData", null);
            }

            // show save button if client is selected and at least one article is selected
            if (this.parent_company_client_name) {
                this.$store.commit("setShowSaveButton", true);
            } else {
                this.$store.commit("setShowSaveButton", false);
            }
        },
        jumpToField: function(event, ref) {
            this.$refs[ref].focus();
        },
        checkIfMinusPressed(event) {
            if (/^[/-]$/i.test(event.key)) {
                this.unit_price =
                    this.unit_price *
                    parseInt(this.creditNoteMultiplicationValue.value);
            }
        },
        makePurchaseOrder() {
            if (this.selectedBill.bill_number) {
                this.$store.commit("setMakePurchaseOrderVisible", false);
                axios
                    .post(
                        API_BASE_URL +
                            "/billings/convert/" +
                            this.selectedBill.id,
                        { id: this.selectedBill.id },
                        { headers: this.header }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            // this.onResetPageData();
                            this.selectedBill = response.data.data;
                            this.$toast.success(
                                this.$t(
                                    "billings.text_messages.converted_to_purchase_order_successfully"
                                )
                            );
                            this.$store.commit("setMakeBillVisible", true);
                            this.$store.commit(
                                "setMakePurchaseOrderVisible",
                                false
                            );
                            this.$store.commit(
                                "setMakeCreditNoteVisible",
                                false
                            );
                        } else {
                            this.$store.commit("setMakeBillVisible", false);
                            this.$store.commit(
                                "setMakePurchaseOrderVisible",
                                true
                            );
                            this.$store.commit(
                                "setMakeCreditNoteVisible",
                                false
                            );
                            this.$toast.error(
                                this.$t(
                                    "billings.text_messages.converted_to_purchase_order_failed"
                                )
                            );
                        }
                    })
                    .catch((error) => {
                        this.$store.commit("setMakeBillVisible", false);
                        this.$store.commit("setMakePurchaseOrderVisible", true);
                        this.$store.commit("setMakeCreditNoteVisible", false);
                        this.$toast.error(
                            this.$t(
                                "billings.text_messages.converted_to_purchase_order_failed"
                            )
                        );
                    })
                    .finally(() => {});
            }
        },
        checkIfForCurrentOrderSupplierOrderNotReceived() {
            let ifAnySupplierOrderNotFullFilled = false;
            if (
                this.selectedBill &&
                this.selectedBill.details &&
                this.selectedBill.details.length > 0
            ) {
                this.selectedBill.details.forEach(
                    (selectedBillOrderDetailItem) => {
                        if (
                            selectedBillOrderDetailItem &&
                            selectedBillOrderDetailItem.supplier_order_details_id &&
                            selectedBillOrderDetailItem.supplier_order_detail &&
                            selectedBillOrderDetailItem.supplier_order_detail
                                .supplier_order
                        ) {
                            if (
                                selectedBillOrderDetailItem.supplier_order_detail &&
                                selectedBillOrderDetailItem
                                    .supplier_order_detail.supplier_order &&
                                typeof selectedBillOrderDetailItem
                                    .supplier_order_detail.supplier_order ==
                                    "object" &&
                                selectedBillOrderDetailItem
                                    .supplier_order_detail.supplier_order
                                    .status_id != 10091005
                            ) {
                                // Fully Received
                                ifAnySupplierOrderNotFullFilled = true;
                            }
                        }
                    }
                );
            }
            return ifAnySupplierOrderNotFullFilled;
        },
        makeBill() {
            this.submit(true).then(() => {
                if (this.selectedBill.bill_number) {
                    this.$store.commit("setMakeBillVisible", false);
                    axios
                        .post(
                            API_BASE_URL +
                                "/billings/convert/" +
                                this.selectedBill.id,
                            { id: this.selectedBill.id },
                            { headers: this.header }
                        )
                        .then((response) => {
                            if (response.status === 200) {
                                // this.onResetPageData();
                                this.selectedBill = response.data.data;
                                this.$toast.success(
                                    this.$t(
                                        "billings.text_messages.converted_to_bill_successfully"
                                    )
                                );
                                this.$store.commit("setMakeBillVisible", false);
                                this.$store.commit(
                                    "setMakePurchaseOrderVisible",
                                    false
                                );
                                this.$store.commit(
                                    "setMakeCreditNoteVisible",
                                    true
                                );
                            } else {
                                this.$store.commit("setMakeBillVisible", true);
                                this.$store.commit(
                                    "setMakePurchaseOrderVisible",
                                    false
                                );
                                this.$store.commit(
                                    "setMakeCreditNoteVisible",
                                    false
                                );
                                this.$toast.error(
                                    this.$t(
                                        "billings.text_messages.converted_to_bill_failed"
                                    )
                                );
                            }
                        })
                        .catch((error) => {
                            this.$store.commit("setMakeBillVisible", true);
                            this.$store.commit(
                                "setMakePurchaseOrderVisible",
                                false
                            );
                            this.$store.commit("setMakeCreditNoteVisible", false);
                            this.$toast.error(
                                this.$t(
                                    "billings.text_messages.converted_to_bill_failed"
                                )
                            );
                        })
                        .finally(() => {});
                }
            });
        },
        makeCreditNote() {
            this.submit(true).then(() => {
                if (this.selectedBill.bill_number) {
                    this.$store.commit("setMakeCreditNoteVisible", false);
                    axios
                        .post(
                            API_BASE_URL +
                                "/billings/convert/" +
                                this.selectedBill.id,
                            { id: this.selectedBill.id },
                            { headers: this.header }
                        )
                        .then((response) => {
                            if (response.status === 200) {
                                // this.onResetPageData();
                                this.selectedBill = response.data.data;
                                this.$toast.success(
                                    this.$t(
                                        "billings.text_messages.converted_to_credit_note_successfully"
                                    )
                                );
                                this.$store.commit("setMakeBillVisible", false);
                                this.$store.commit(
                                    "setMakePurchaseOrderVisible",
                                    false
                                );
                                this.$store.commit(
                                    "setMakeCreditNoteVisible",
                                    false
                                );
                                this.askForRefund();
                            } else {
                                this.$store.commit("setMakeBillVisible", false);
                                this.$store.commit(
                                    "setMakePurchaseOrderVisible",
                                    false
                                );
                                this.$store.commit(
                                    "setMakeCreditNoteVisible",
                                    true
                                );
                                this.$toast.error(
                                    this.$t(
                                        "billings.text_messages.converted_to_credit_note_failed"
                                    )
                                );
                            }
                        })
                        .catch((error) => {
                            this.$store.commit("setMakeBillVisible", false);
                            this.$store.commit(
                                "setMakePurchaseOrderVisible",
                                false
                            );
                            this.$store.commit("setMakeCreditNoteVisible", true);
                            this.$toast.error(
                                this.$t(
                                    "billings.text_messages.converted_to_credit_note_failed"
                                )
                            );
                        })
                        .finally(() => {});
                }
            });
        },
        printBill() {
            this.$store.commit("setValidation", true); // enable validation
            if (this.validateSubmit()) {
                this.saveOrder().then(() => {
                    this.updateGroupText().then(() => {
                        this.fineDataForSending().then(async () => {
                            const data = await this.makeSubmitData();
                            if (!data.bill.order_id) {
                                this.addBilling(data, false).then(() => {
                                    let url =
                                        API_BASE_URL +
                                        "/billings/" +
                                        this.selectedBill.md5_id +
                                        "/print?document=order&lang=" +
                                        (localStorage.getItem("languageId") ||
                                            "en") +
                                        "&user=" +
                                        localStorage.getItem("loginUserId");
                                    let fileName = this.selectedBill
                                        ? this.selectedBill?.bill_number
                                        : "";
                                    this.setPreviewData(
                                        url,
                                        fileName,
                                        true,
                                        "application/pdf"
                                    );
                                });
                            } else {
                                this.updateBilling(data, false, true).then(
                                    () => {
                                        let url =
                                            API_BASE_URL +
                                            "/billings/" +
                                            this.selectedBill.md5_id +
                                            "/print?document=order&lang=" +
                                            (localStorage.getItem(
                                                "languageId"
                                            ) || "en") +
                                            "&user=" +
                                            localStorage.getItem("loginUserId");
                                        let fileName = this.selectedBill
                                            ? this.selectedBill?.bill_number
                                            : "";
                                        this.setPreviewData(
                                            url,
                                            fileName,
                                            true,
                                            "application/pdf"
                                        );
                                    }
                                );
                            }
                        });
                    });
                });
                // this.$toast.success( this.$t('billings.text_messages.credit_note_updated_successfully'));
            }
        },
        emailBill() {
                this.$store.commit("setValidation", true); // enable validation
                if (this.validateSubmit()) {
                    this.saveOrder().then(() => {
                        this.updateGroupText().then(() => {
                            this.fineDataForSending().then(async () => {
                                const data = await this.makeSubmitData();
                                if (!data.bill?.order_id) {
                                    this.addBilling(data, false).then(() => {
                                        return (this.sendPdfDialog = true);
                                        // this.sendEmail();
                                    });
                                } else {
                                    this.updateBilling(data, false, true).then(
                                        () => {
                                            // this.sendEmail();
                                            return (this.sendPdfDialog = true);
                                        }
                                    );
                                }
                            });
                        });
                    });
                }
        },
        duplicateDocument() {
            // Create a new document with all the existing documents
            let tempArticlesForDuplicate = this.selectedArticles;
            this.onResetPageData();
            setTimeout(() => {
                this.selectedArticles = tempArticlesForDuplicate;
            }, 200);
        },
        worksheetBill() {
            this.$store.commit("setValidation", true); // enable validation
            if (this.validateSubmit()) {
                this.saveOrder().then(() => {
                    this.updateGroupText().then(() => {
                        this.fineDataForSending().then(async () => {
                            const data = await this.makeSubmitData();
                            if (!data.bill.order_id) {
                                this.addBilling(data, false).then(() => {
                                    let url =
                                        API_BASE_URL +
                                        "/billings/" +
                                        this.selectedBill.md5_id +
                                        "/print?document=worksheet&lang=" +
                                        (localStorage.getItem("languageId") ||
                                            "en") +
                                        "&user=" +
                                        localStorage.getItem("loginUserId");
                                    let fileName = this.selectedBill
                                        ? this.selectedBill?.bill_number
                                        : "";
                                    this.setPreviewData(
                                        url,
                                        fileName,
                                        true,
                                        "application/pdf"
                                    );
                                });
                            } else {
                                this.updateBilling(data, false, true).then(
                                    () => {
                                        let url =
                                            API_BASE_URL +
                                            "/billings/" +
                                            this.selectedBill.md5_id +
                                            "/print?document=worksheet&lang=" +
                                            (localStorage.getItem(
                                                "languageId"
                                            ) || "en") +
                                            "&user=" +
                                            localStorage.getItem("loginUserId");
                                        let fileName = this.selectedBill
                                            ? this.selectedBill?.bill_number
                                            : "";
                                        this.setPreviewData(
                                            url,
                                            fileName,
                                            true,
                                            "application/pdf"
                                        );
                                    }
                                );
                            }
                        });
                    });
                });
            }
        },
        workReportBill() {
            this.$store.commit("setValidation", true); // enable validation
            if (this.validateSubmit()) {
                this.saveOrder().then(() => {
                    this.updateGroupText().then(() => {
                        this.fineDataForSending().then(async () => {
                            const data = await this.makeSubmitData();
                            if (!data.bill.order_id) {
                                this.addBilling(data, false).then(() => {
                                    let url =
                                        API_BASE_URL +
                                        "/billings/" +
                                        this.selectedBill.md5_id +
                                        "/print?document=workreport&lang=" +
                                        (localStorage.getItem("languageId") ||
                                            "en") +
                                        "&user=" +
                                        localStorage.getItem("loginUserId");
                                    let fileName = this.selectedBill
                                        ? this.selectedBill?.bill_number
                                        : "";
                                    this.setPreviewData(
                                        url,
                                        fileName,
                                        true,
                                        "application/pdf"
                                    );
                                });
                            } else {
                                this.updateBilling(data, false, true).then(
                                    () => {
                                        let url =
                                            API_BASE_URL +
                                            "/billings/" +
                                            this.selectedBill.md5_id +
                                            "/print?document=workreport&lang=" +
                                            (localStorage.getItem(
                                                "languageId"
                                            ) || "en") +
                                            "&user=" +
                                            localStorage.getItem("loginUserId");
                                        let fileName = this.selectedBill
                                            ? this.selectedBill?.bill_number
                                            : "";
                                        this.setPreviewData(
                                            url,
                                            fileName,
                                            true,
                                            "application/pdf"
                                        );
                                    }
                                );
                            }
                        });
                    });
                });
            }
        },
        deliveryNoteBill() {
            this.$store.commit("setValidation", true); // enable validation
            if (this.validateSubmit()) {
                this.saveOrder().then(() => {
                    this.updateGroupText().then(() => {
                        this.fineDataForSending().then(async () => {
                            const data = await this.makeSubmitData();
                            if (!data.bill.order_id) {
                                this.addBilling(data, false).then(() => {
                                    let url =
                                        API_BASE_URL +
                                        "/billings/" +
                                        this.selectedBill.md5_id +
                                        "/print?document=delivery_note&lang=" +
                                        (localStorage.getItem("languageId") ||
                                            "en") +
                                        "&user=" +
                                        localStorage.getItem("loginUserId");
                                    let fileName = this.selectedBill
                                        ? this.selectedBill?.bill_number
                                        : "";
                                    this.setPreviewData(
                                        url,
                                        fileName,
                                        true,
                                        "application/pdf"
                                    );
                                });
                            } else {
                                this.updateBilling(data, false, true).then(
                                    () => {
                                        let url =
                                            API_BASE_URL +
                                            "/billings/" +
                                            this.selectedBill.md5_id +
                                            "/print?document=delivery_note&lang=" +
                                            (localStorage.getItem(
                                                "languageId"
                                            ) || "en") +
                                            "&user=" +
                                            localStorage.getItem("loginUserId");
                                        let fileName = this.selectedBill
                                            ? this.selectedBill?.bill_number
                                            : "";
                                        this.setPreviewData(
                                            url,
                                            fileName,
                                            true,
                                            "application/pdf"
                                        );
                                    }
                                );
                            }
                        });
                    });
                });
            }
        },
        addBillNote() {
            this.showBillNote = true;
        },
        printReportFromTopButton() {
            this.$store.commit("setValidation", true); // enable validation
            if (this.validateSubmit()) {
                this.saveOrder().then(() => {
                    this.updateGroupText().then(() => {
                        this.fineDataForSending().then(async () => {
                            const data = await this.makeSubmitData();
                            if (!data.bill.order_id) {
                                this.addBilling(data, false).then(() => {
                                    let depot_ids = new Set();
                                    this.selectedArticles.forEach((article) => {
                                        if (article.depot_in_id) {
                                            depot_ids.add(article.depot_in_id);
                                        }
                                        // else if (article.depot_out_id) {
                                        //   depot_ids.add(article.depot_out_id);
                                        // }
                                    });
                                    Array.from(depot_ids).forEach((element) => {
                                        if (element) {
                                            let url =
                                                API_BASE_URL +
                                                "/depot/" +
                                                this.encryptToMD5(
                                                    element.toString()
                                                ) +
                                                "/print?document=report&lang=" +
                                                (localStorage.getItem(
                                                    "languageId"
                                                ) || "en") +
                                                "&user=" +
                                                localStorage.getItem(
                                                    "loginUserId"
                                                );
                                            let fileName = this.selectedBill
                                                ? this.selectedBill?.bill_number
                                                : "";
                                            this.setPreviewData(
                                                url,
                                                fileName,
                                                true,
                                                "application/pdf"
                                            );
                                        }
                                    });
                                });
                            } else {
                                this.updateBilling(data, false, true).then(
                                    () => {
                                        let depot_ids = new Set();
                                        this.selectedArticles.forEach(
                                            (article) => {
                                                if (article.depot_in_id) {
                                                    depot_ids.add(
                                                        article.depot_in_id
                                                    );
                                                }
                                                // else if (article.depot_out_id) {
                                                //   depot_ids.add(article.depot_out_id);
                                                // }
                                            }
                                        );
                                        Array.from(depot_ids).forEach(
                                            (element) => {
                                                if (element) {
                                                    let url =
                                                        API_BASE_URL +
                                                        "/depot/" +
                                                        this.encryptToMD5(
                                                            element.toString()
                                                        ) +
                                                        "/print?document=report&lang=" +
                                                        (localStorage.getItem(
                                                            "languageId"
                                                        ) || "en") +
                                                        "&user=" +
                                                        localStorage.getItem(
                                                            "loginUserId"
                                                        );
                                                    let fileName = this
                                                        .selectedBill
                                                        ? this.selectedBill
                                                              ?.bill_number
                                                        : "";
                                                    this.setPreviewData(
                                                        url,
                                                        fileName,
                                                        true,
                                                        "application/pdf"
                                                    );
                                                }
                                            }
                                        );
                                    }
                                );
                            }
                        });
                    });
                });
            }
        },
        sendEmail() {
            if (this.parent_company_client_name.email) {
                axios
                    .get(
                        API_BASE_URL +
                            "/billings/" +
                            this.selectedBill.id +
                            "/email",
                        {
                            headers: this.header,
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.$toast.success(
                                this.$t(
                                    "billings.text_messages.email_sent_successfully"
                                )
                            );
                        } else {
                            this.$toast.error(
                                this.$t(
                                    "billings.text_messages.failed_while_sending"
                                )
                            );
                        }
                    })
                    .catch((error) => {
                        this.$toast.error(
                            this.$t(
                                "billings.text_messages.failed_while_sending"
                            )
                        );
                    })
                    .finally(() => {});
            } else {
                this.clientEmailErrorPopUp();
            }
        },
        onResetPageData() {
            // Reset Expedition Id

            this.resetted_data_table = false;
            this.resetBillType();
            this.$store.commit("setValidation", false);
            this.$refs["clientComp"].$refs["clientName"].focus();
            this.order_status = null;
            this.payment_method = null;
            this.selectedBill = null;
            this.$store.commit("setBillCancelVisible", false);
            this.$store.commit("setEditingAllowed", false);
            this.selectedArticles = [];
            this.bill_amount = "";
            this.bills = [];
            this.rdv_date = null;
            this.due_date = null;
            this.created_date = this.updateDateTime();
            this.parent_reg_date = null;
            this.note = null;
            this.reference_client = null;
            this.acquisition_price = null;
            this.unit_price = null;
            this.quantity = this.selectedFamilyArticle
                ? this.selectedFamilyArticle.default_quantity
                : 4;
            this.first_row_tariff = null;
            this.first_row_thtva = null;
            this.first_row_tva = this.cached_first_row_tva; // Reset TVA to company's Default TVA
            this.first_row_total = null;
            this.first_row_minimum_sales_price = 0.0;
            this.red_percentage = 0.0;
            this.acquisition_price = 0.0;
            this.unit_price = 0.0;
            this.reduction_on_order = 0;
            this.parentGroupText = null;
            this.isInValidUnitPrice = false;
            this.next_event_code = null;
            this.finedSelectedArticles = [];
            this.articles = [];
            this.search_article = null;

            // Vehicle Component Sync Values
            this.parent_vehicle_type = null;
            this.parent_registration_no = null;
            this.parent_brand = null;
            this.parent_driver = null;
            this.parent_second_driver = null;
            this.parent_model = null;
            (this.parent_km = null), (this.parent_reg_date = null);
            this.parent_creditor = null;
            this.parent_vin = null;
            this.parent_is_leasing = null;

            // Client Component Sync Values
            this.parent_client_id = null;
            this.parent_company = null;
            this.parent_client_id = null;
            this.parent_company_client_name = null;
            this.parent_company_client_first_name = null;
            this.parent_company_client_names = [];
            this.parent_search_client = null;
            this.parent_company_client_contact_name_2 = null;
            this.parent_company_client_contact_name_3 = null;
            this.parent_company_client_phone = null;
            this.parent_company_client_phone_2 = null;
            this.parent_company_client_phone_3 = null;
            this.parent_company_client_gsm = null;
            this.parent_company_client_gsm_2 = null;
            this.parent_company_client_gsm_3 = null;
            this.parent_company_client_fax = null;
            this.parent_company_client_email = null;
            this.parent_company_client_email_2 = null;
            this.parent_company_client_email_3 = null;
            this.parent_company_client_language = null;
            this.parent_web = null;
            this.parent_currency = null;
            this.parent_representative_company_id = null;
            this.parent_user = null;
            this.parent_note = null;
            this.parent_familyClient = null;
            this.parent_denomination = null;
            this.parent_taxation_reason_id = null;
            this.parent_registration_code = null;
            this.parent_tva = null;
            this.parent_address = null;
            this.parent_shipping_address = null;
            this.parent_address_same_as = false;

            // Parent Project Name
            this.parent_project_name = null;
            this.parent_project = null;
            this.parent_project_location = null;
            this.parent_project_locations = null;
            this.parent_project_note = null;
            this.parent_project_status = null;

            this.storedDepotIn = [];

            this.$store.commit("setShowNewButton", true);
            this.setPageTitle();
            this.$nextTick(() => {
                this.resetted_data_table = true;
            });
            this.setTopCurrencyBasedOnDefaultBank();
        },
        convertCommaToDecimalSeparator(str) {
            return parseFloat((str + "").replace(",", ".").replace(" ", ""));
        },
        addDepotArticleToDataTable(depot, depotType) {
            depot.details.forEach((depotDetail) => {
                let article_mu = "";
                this.familyArticles.forEach((element) => {
                    if (
                        depotDetail.article_id &&
                        depotDetail.article &&
                        (element.id == depotDetail.article.family_id ||
                            element.parent_family_id ==
                                depotDetail.article.family_id)
                    ) {
                        article_mu = element.mu;
                    }
                });
                if (!article_mu) {
                    article_mu = this.defaultMuToSelect;
                }
                let text = "";
                text +=
                    (depotType == "out"
                        ? this.$t("billings.text.depot_out")
                        : this.$t("billings.text.depot_in")) +
                    ": " +
                    (depotDetail.article_id
                        ? depotDetail.article.title_description[0].description
                        : depotDetail.article_name) +
                    (depotDetail.position
                        ? " - " +
                          this.$t(
                              "billings.position.sh_" + depotDetail.position.key
                          )
                        : "") +
                    (depotDetail.rimtype
                        ? " - " +
                          this.$t(
                              "billings.wheel_materials.sh_" +
                                  depotDetail.rimtype.key
                          )
                        : "") +
                    (depotDetail.is_with_hubcaps == 1
                        ? " - " + this.$t("billings.text.with_hub_cups")
                        : "") +
                    (depotDetail.mm
                        ? " - " +
                          this.$t("billings.text.mm") +
                          ": " +
                          depotDetail.mm
                        : "") +
                    (depotDetail.couple
                        ? " - " +
                          this.$t("billings.text.couple") +
                          ": " +
                          depotDetail.couple
                        : "") +
                    (depotDetail.pressure
                        ? " - " +
                          this.$t("billings.text.pressure") +
                          ": " +
                          depotDetail.pressure
                        : "") +
                    (depotDetail.article_status
                        ? " - " +
                          this.$t("billings.text.status") +
                          ": " +
                          this.$t(
                              "billings.article_status.sh_" +
                                  depotDetail.article_status.key
                          )
                        : "");

                if (depotType == "out" && depotDetail.checked) {
                    this.selectedArticles.push({
                        srno: this.rowNumber++,
                        article: 0,
                        article_original_name: null,
                        quantity: 1,
                        acquisition_price: 0.0,
                        unit_price: 0.0,
                        minimum_sales_price: 0.0,
                        rounded_unit_price: (0).toFixed(
                            this.getDecimalNumber()
                        ),
                        red_percentage: 0.0,
                        tarrif: 0.0,
                        tva: 0.0,
                        thtva: 0.0,
                        stock_property: null,
                        total: 0.0,
                        text: text,
                        row: 0,
                        text_position: 0,
                        depot_out_id: depot.id,
                        depot_in_id: null,
                        supplier_id: null,
                        parent_supplier_id: null,
                        dots: null,
                        group: null,
                        selectedmu: article_mu,
                        currency_id: this.$store.state.topCurrencyType
                            ? this.$store.state.topCurrencyType.id
                            : this.defaultBankAccount
                            ? this.defaultBankAccount.currency_id
                            : this.defaultCurrencyId,
                        expedition_id: null,
                    });
                } else if (depotType == "in") {
                    this.selectedArticles.push({
                        srno: this.rowNumber++,
                        article: 0,
                        article_original_name: null,
                        quantity: 1,
                        acquisition_price: 0.0,
                        unit_price: 0.0,
                        minimum_sales_price: 0.0,
                        rounded_unit_price: (0).toFixed(
                            this.getDecimalNumber()
                        ),
                        red_percentage: 0.0,
                        tarrif: 0.0,
                        tva: 0.0,
                        thtva: 0.0,
                        stock_property: null,
                        total: 0.0,
                        text: text,
                        row: 0,
                        text_position: 0,
                        depot_out_id: null,
                        depot_in_id: depot.id,
                        supplier_id: null,
                        parent_supplier_id: null,
                        dots: null,
                        group: null,
                        selectedmu: article_mu,
                        currency_id: this.$store.state.topCurrencyType
                            ? this.$store.state.topCurrencyType.id
                            : this.defaultBankAccount
                            ? this.defaultBankAccount.currency_id
                            : this.defaultCurrencyId,
                        expedition_id: null,
                    });
                }
            });
        },
        addArticleToDataTable: function(e) {
            if (e) {
                e.preventDefault();
            }

            if (this.addArticlesAutomatically) {
                this.$refs["searchArticleComp"]
                    .saveArticle(true)
                    .then(() => {
                        this.addArticle();
                    })
                    .catch(() => {
                        this.addArticle();
                    });
            } else {
                this.addArticle();
            }
        },
        triggerAddArticleToDataTable() {
            this.$nextTick(() => {
                var counter = setInterval(() => {
                    if (this.article && this.quantity) {
                        this.addArticle();
                    }
                }, 500);
                this.storeInterval(counter);

                setTimeout(() => {
                    clearInterval(counter);
                }, 2000);
            });
        },
        addArticle() {
            if (
                !(this.article && this.quantity && this.red_percentage <= 100)
            ) {
                return false;
            }
            if (this.isInValidUnitPrice) {
                this.$toast.error(
                    this.$t(
                        "billings.text_messages.price_cannot_be_less_then_min_sale_price"
                    )
                );
                return false;
            }
            // resetting the article id in local storage
            var articleFilters = JSON.parse(
                localStorage.getItem("ARTICLE_MODEL_FILTERS")
            );
            if (articleFilters) {
                articleFilters["id"] = null;
            }
            localStorage.setItem(
                "ARTICLE_MODEL_FILTERS",
                JSON.stringify(articleFilters)
            );

            let isToMultiplyWithCredNoteMulValue = false;
            if (this.billType && this.billType.key == "credit_note") {
                isToMultiplyWithCredNoteMulValue = true;
            }

            if (this.isEditModeForDataTableItem) {
                // If article is being edited then modify that particular item quantity and other tdetails
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].quantity = this.quantity;
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].selectedmu = this.currentMu;
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].red_percentage = this.red_percentage;
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].tarrif = this.first_row_tariff;
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].supplier = this.article.supplier;
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].supplier_stock_detail = this.article.supplier_stock_detail;
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].acquisition_price = this.acquisition_price;
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].unit_price = this.unit_price;
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].rounded_unit_price = parseFloat(
                    this.convertCommaToDecimalSeparator(this.unit_price) ?? 0
                ).toFixed(this.getDecimalNumber());
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].tva = parseFloat(this.first_row_tva);
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].thtva = parseFloat(this.first_row_thtva);
                this.selectedArticles[
                    this.selectedItemIndexInDataTable
                ].total = parseFloat(this.first_row_total);
                
                if (
                    this.article &&
                    this.article.internal_company_article &&
                    this.article.internal_company_article.length > 0
                ) {
                    this.first_row_minimum_sales_price = parseFloat(
                        this.article.internal_company_article[0]
                            .minimum_sales_price ?? 0
                    );
                    this.selectedArticles[
                        this.selectedItemIndexInDataTable
                    ].minimum_sales_price = parseFloat(
                        this.first_row_minimum_sales_price
                    );
                } else {
                    this.first_row_minimum_sales_price = 0;
                    this.selectedArticles[
                        this.selectedItemIndexInDataTable
                    ].minimum_sales_price = parseFloat(
                        this.first_row_minimum_sales_price
                    );
                }

                this.selectedArticles[this.selectedItemIndexInDataTable].group =
                    this.parentGroupText ?? null;

                this.calculateTotals();
                // Reset Edit Mode for DataTable
                this.resetSelectedArticle();
                this.fineDataForSending();
            } else {
                // If New Article is added
                this.acquisition_price = parseFloat(
                    this.convertCommaToDecimalSeparator(
                        this.acquisition_price
                    ) ?? 0
                );
                this.unit_price = parseFloat(
                    this.convertCommaToDecimalSeparator(this.unit_price) ?? 0
                );
                this.first_row_minimum_sales_price = parseFloat(
                    this.convertCommaToDecimalSeparator(
                        this.first_row_minimum_sales_price
                    )
                );
                this.rounded_unit_price = parseFloat(
                    this.convertCommaToDecimalSeparator(this.unit_price) ?? 0
                ).toFixed(this.getDecimalNumber());
                this.red_percentage = parseFloat(this.red_percentage);
                // Add Related Articles
                this.$nextTick(() => {
                    this.resetMeasurementUnit();
                });
                this.getProposedArticles(
                    this.article.id,
                    this.article.family_id,
                    this.parent_familyClient,
                    parseFloat(this.quantity)
                );

                // check if article already exists in the datatable
                let alreadyExistsArticle = this.selectedArticles.find(
                    (element, index) => {
                        if (
                            typeof element.article == "object" &&
                            element.article.id == this.article.id &&
                            (element.group ?? "").toUpperCase() ===
                                (this.parentGroupText ?? "").toUpperCase()
                        ) {
                            if (
                                this.checkIfDifferentAcqPriceOrDiffernetSupplier(
                                    element,
                                    this.article
                                )
                            ) {
                                return false;
                            }
                            this.selectedArticles.splice(index, 1);
                            return element;
                        }
                        if (
                            typeof element.article == "number" &&
                            element.article == this.article.id &&
                            (element.group ?? "").toUpperCase() ===
                                (this.parentGroupText ?? "").toUpperCase()
                        ) {
                            if (
                                this.checkIfDifferentAcqPriceOrDiffernetSupplier(
                                    element,
                                    this.article
                                )
                            ) {
                                return false;
                            }
                            this.selectedArticles.splice(index, 1);
                            return element;
                        }
                    }
                );
                if (alreadyExistsArticle) {
                    // recalculate the row total
                    this.quantity =
                        parseFloat(this.quantity) +
                        parseFloat(alreadyExistsArticle.quantity);
                    this.first_row_tariff = parseFloat(
                        this.article.tarrif ?? 0
                    );
                    this.acquisition_price = parseFloat(this.acquisition_price);
                    this.unit_price = parseFloat(this.unit_price);
                    this.first_row_minimum_sales_price = parseFloat(
                        this.first_row_minimum_sales_price
                    );
                    // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
                    this.first_row_thtva = parseFloat(
                        this.unit_price * this.quantity -
                            (this.unit_price *
                                this.quantity *
                                this.red_percentage) /
                                100
                    );
                    this.first_row_total = parseFloat(
                        parseFloat(this.first_row_thtva) +
                            parseFloat(
                                (this.first_row_tva * this.first_row_thtva) /
                                    100
                            )
                    );
                }
                
                if (this.showGroupText) {
                    this.parentGroupText = this.parentGroupText
                        ? this.parentGroupText
                        : this.selectedFamilyArticle
                        ? this.selectedFamilyArticle.name
                        : null;
                }
                this.selectedArticles.push({
                    srno: this.rowNumber++,
                    article: this.article,
                    article_original_name: this.article,
                    quantity: this.quantity,
                    acquisition_price: parseFloat(this.acquisition_price),
                    unit_price: parseFloat(
                        isToMultiplyWithCredNoteMulValue
                            ? parseInt(
                                  this.creditNoteMultiplicationValue.value
                              ) * (this.unit_price ?? 0)
                            : this.unit_price ?? 0
                    ),
                    minimum_sales_price: this.first_row_minimum_sales_price,
                    rounded_unit_price: parseFloat(
                        isToMultiplyWithCredNoteMulValue
                            ? parseInt(
                                  this.creditNoteMultiplicationValue.value
                              ) * (this.unit_price ?? 0)
                            : this.unit_price ?? 0
                    ).toFixed(this.getDecimalNumber()),
                    red_percentage: parseFloat(this.red_percentage ?? 0.0),
                    tarrif: this.first_row_tariff,
                    tva: parseFloat(this.first_row_tva),
                    thtva: parseFloat(
                        isToMultiplyWithCredNoteMulValue
                            ? parseInt(
                                  this.creditNoteMultiplicationValue.value
                              ) * (this.first_row_thtva ?? 0)
                            : this.first_row_thtva ?? 0
                    ),
                    stock_property: null,
                    total: parseFloat(
                        isToMultiplyWithCredNoteMulValue
                            ? parseInt(
                                  this.creditNoteMultiplicationValue.value
                              ) * (this.first_row_total ?? 0)
                            : this.first_row_total ?? 0
                    ),
                    text: null,
                    row: 0,
                    text_position: 0,
                    depot_out_id: null,
                    depot_in_id: null,
                    supplier_id: null,
                    dots: null,
                    supplier: this.article.supplier,
                    parent_supplier_id: null,
                    supplier_stock_detail: this.article.supplier_stock_detail,
                    group: this.parentGroupText ?? null,
                    selectedmu: this.currentMu
                        ? typeof this.currentMu == "object"
                            ? this.currentMu
                            : typeof this.currentMu == "string"
                            ? this.getMuByKey(this.currentMu)
                            : null
                        : null,
                    currency_id: this.$store.state.topCurrencyType
                        ? this.$store.state.topCurrencyType.id
                        : this.defaultBankAccount
                        ? this.defaultBankAccount.currency_id
                        : this.defaultCurrencyId,
                    expedition_id: null,
                });
                this.resetFirstRowValues();
                this.resetSrNo();
            }
        },
        checkIfDifferentAcqPriceOrDiffernetSupplier(
            element,
            articleInSelection
        ) {
            // if(element.acquisition_price != this.acquisition_price){
            //     return true;
            // }

            let oldArticleSupplier = element.supplier_id
                ? element.supplier_id
                : element.supplier && element.supplier.length > 0
                ? element.supplier[0].id
                : 0;
            let newArticleSupplier = articleInSelection.supplier_id
                ? articleInSelection.supplier_id
                : articleInSelection.supplier &&
                  articleInSelection.supplier.length > 0
                ? articleInSelection.supplier[0].id
                : 0;
            

            if (oldArticleSupplier != newArticleSupplier) {
                return true;
            }
            // if(element.acquisition_price && articleInSelection.internal_company_article && articleInSelection.internal_company_article.length > 0 && )
        },
        getMuByKey(muKey) {
            let mu = null;
            if (muKey) {
                this.mus.forEach((element) => {
                    if (element.key == muKey) {
                        mu = element;
                    }
                });
            }
            return mu;
        },
        resetSrNo() {
            let sno = 0;
            this.selectedArticles.forEach((element) => {
                element.srno = sno;
                sno++;
            });
            this.rowNumber = sno;
        },
        resetFirstRowValues() {
            this.article = "";
            this.quantity = this.selectedFamilyArticle
                ? this.selectedFamilyArticle.default_quantity
                : 4;
            this.red_percentage = 0.0;
            this.acquisition_price = 0.0;
            this.unit_price = 0.0;
            this.first_row_tariff = "";
            this.first_row_thtva = "";
            this.first_row_total = "";
            this.first_row_minimum_sales_price = 0.0;
            this.parentGroupText = null;
        },
        deleteItem(item) {
            this.selectedArticles.find((element, index) => {
                if (element.srno == item.srno) {
                    this.selectedArticles.splice(index, 1);
                    this.resetSrNo();
                    return true;
                }
            });
        },
        resetMeasurementUnit() {
            this.currentmu = this.selectedFamilyArticle.mu;
        },
        onDelete() {
            this.DeleteConfirm = true;
        },
        makeSupplierOrderData(item, supplier_stock_detail) {
            
            if (typeof item == "number") {
                return {
                    quantity_to_order: 0,
                    price: this.article.internal_company_article[0].price,
                    unit_client_price: this.article.internal_company_article[0]
                        .price,
                    delivery_date: this.article.internal_company_article[0]
                        .delivery_date,
                    total_price: this.article.internal_company_article[0].price,
                    tva: this.article.internal_company_article[0].tva.tax,
                    price_with_tva:
                        this.article.internal_company_article[0].price +
                        (this.article.internal_company_article[0].price *
                            this.article.internal_company_article[0].tva.tax) /
                            100,
                    client: "",
                    dispo: 0,
                    new_dispo: 0,
                    current_stock_price: 0,
                    new_stock_price: 0,
                    article_id: this.article.id,
                    supplier_id: this.article.supplier[0].id,
                    message_for_order: "",
                    order_reference: "",
                };
            }
            
            return {
                quantity_to_order: 0,
                price: item[`${supplier_stock_detail.field_price}`],
                unit_client_price:
                    item[`${supplier_stock_detail.field_client_price}`],
                delivery_date:
                    item[`${supplier_stock_detail.field_delivery_date}`],
                total_price: 0,
                tva: item.tax,
                price_with_tva: 0,
                client: "",
                dispo: item.stock - item.reserve,
                new_dispo: 0,
                current_stock_price: item.price,
                new_stock_price: item.price,
                article_id: item.article_id,
                supplier_id:
                    supplier_stock_detail.supplier_detail.id == 0
                        ? item.brand_supplier_id
                        : supplier_stock_detail.supplier_detail.id,
                message_for_order: "",
                order_reference: "",
            };
        },
        makeClientData() {
            return {
                client_id: this.parent_company_client_name
                    ? typeof this.parent_company_client_name == "object"
                        ? this.parent_company_client_name.id
                        : null
                    : null,
                entityType: this.parent_entityType
                    ? this.parent_entityType.id
                    : 0, // By Default Private Client
                denomination: this.parent_denomination
                    ? typeof this.parent_denomination == "object"
                        ? this.parent_denomination.id
                        : this.parent_denomination
                    : null,
                taxation_reason_id: this.parent_taxation_reason_id
                    ? typeof this.parent_taxation_reason_id == "object"
                        ? this.parent_taxation_reason_id.id
                        : this.parent_taxation_reason_id
                    : null,
                familyClient: this.parent_familyClient
                    ? typeof this.parent_familyClient == "object"
                        ? parseInt(this.parent_familyClient.id)
                        : this.parent_familyClient
                    : null,

                name: this.parent_company_client_name
                    ? typeof this.parent_company_client_name == "object"
                        ? this.parent_company_client_name.name
                        : this.parent_company_client_name
                    : null,
                first_name: this.parent_company_client_first_name
                    ? typeof this.parent_company_client_first_name == "object"
                        ? this.parent_company_client_first_name.name
                        : this.parent_company_client_first_name
                    : null,
                email: this.parent_company_client_email ?? null,
                email_2: this.parent_company_client_email_2 ?? null,
                email_3: this.parent_company_client_email_3 ?? null,
                language: this.parent_company_client_language
                    ? typeof this.parent_company_client_language == "object"
                        ? this.parent_company_client_language.id
                        : this.parent_company_client_language
                    : null,
                fax: this.parent_company_client_fax ?? null,
                gsm: this.parent_company_client_gsm ?? null,
                gsm_2: this.parent_company_client_gsm_2 ?? null,
                gsm_3: this.parent_company_client_gsm_3 ?? null,
                phone: this.parent_company_client_phone ?? null,
                phone_2: this.parent_company_client_phone_2 ?? null,
                phone_3: this.parent_company_client_phone_3 ?? null,
                contact_name_2:
                    this.parent_company_client_contact_name_2 ?? null,
                contact_name_3:
                    this.parent_company_client_contact_name_3 ?? null,
                note: this.getNoteValue(),
                tva: this.parent_tva ?? null,
                web: this.parent_web ?? null,
            };
        },
        makeVehicleData() {
            return {
                vehicle_id:
                    this.parent_registration_no &&
                    typeof this.parent_registration_no == "object"
                        ? this.parent_registration_no.id
                        : null,
                vehicle_type_id:
                    this.parent_vehicle_type &&
                    typeof this.parent_vehicle_type == "object"
                        ? this.parent_vehicle_type.id
                        : null,
                registration_no:
                    this.parent_registration_no &&
                    typeof this.parent_registration_no == "object"
                        ? this.parent_registration_no.registration_number
                        : this.parent_registration_no,
                brand: this.parent_brand
                    ? typeof this.parent_brand == "number"
                        ? this.parent_brand
                        : typeof this.parent_brand == "object"
                        ? this.parent_brand.id
                        : null
                    : null,
                model: this.parent_model ?? null,
                km: this.parent_km ?? null,
                reg_date: this.parent_reg_date ?? null,
                driver: this.parent_driver ? this.parent_driver : null,
                second_driver: this.parent_second_driver
                    ? this.parent_second_driver
                    : null,
                vin: this.parent_vin ?? null,
                is_leasing: this.parent_creditor ? true : false,
                creditor_id: this.parent_creditor
                    ? typeof this.parent_creditor == "object"
                        ? this.parent_creditor.id
                        : this.parent_creditor
                    : null,
            };
        },
        convertDateTimeToString(datetime) {
            if (datetime) {
                datetime = new Date(datetime);
                return (
                    datetime.toISOString().substring(0, 10) +
                    " " +
                    ("0" + datetime.getHours()).slice(-2) +
                    ":" +
                    ("0" + datetime.getMinutes()).slice(-2)
                );
            } else {
                return null;
            }
        },
        async makeSubmitData() {
            await this.fineDataForSending();
            let topBankAccountSelected = this.$store.state.topBankAccount;
            return {
                type: this.billType ? this.billType.id : null,
                bank_id: topBankAccountSelected
                    ? topBankAccountSelected.id
                    : this.defaultBankAccount
                    ? this.defaultBankAccount.id
                    : null,
                bill: !this.selectedBill
                    ? {}
                    : {
                          order_id: this.selectedBill.bill_number
                              ? this.selectedBill.id
                              : null,
                          client_id: this.selectedBill
                              ? this.selectedBill.client_id
                              : null,
                          company_id: this.selectedBill
                              ? this.selectedBill.company_id
                              : null,
                          vehicle_id: this.selectedBill
                              ? this.selectedBill.vehicle_id
                              : null,
                      },
                reduction_on_order: this.reduction_on_order
                    ? this.reduction_on_order
                    : null,
                next_event_code: this.next_event_code
                    ? this.next_event_code.id
                    : null,
                status: this.order_status ? this.order_status.id : null,
                payment_method: this.payment_method
                    ? this.payment_method.id
                    : null,
                rdv_date: this.convertDateTimeToString(this.rdv_date),
                due_date: this.due_date,
                created_date: this.created_date,
                reference_client: this.reference_client,
                note: this.note,
                client: this.makeClientData(),
                billing_address: this.parent_address,
                shipping_address: this.parent_shipping_address,
                client_projects: {
                    project_id: this.parent_project
                        ? typeof this.parent_project == "object"
                            ? parseInt(this.parent_project.id)
                            : null
                        : null,
                    parent_project_name: this.parent_project_name
                        ? typeof this.parent_project_name == "object"
                            ? parseInt(this.parent_project_name.name)
                            : this.parent_project_name
                        : null,
                    parent_project_location: this.parent_project_location
                        ? typeof this.parent_project_location == "object"
                            ? parseInt(this.parent_project_location.id)
                            : this.parent_project_location
                        : null,
                    parent_project_note: this.parent_project_note
                        ? typeof this.parent_project_note == "object"
                            ? parseInt(this.parent_project_note.id)
                            : this.parent_project_note
                        : null,
                    parent_project_status: this.parent_project_status
                        ? typeof this.parent_project_status == "object"
                            ? this.parent_project_status.id
                            : this.parent_project_status
                        : null,
                },
                order_reminder_type: this.order_reminder_type
                    ? this.order_reminder_type.id
                    : null,
                vehicle: this.makeVehicleData(),
                article: this.finedSelectedArticles,
                depot_ins: this.storedDepotIn,
            };
        },
        validateSubmit() {
            // let quantityValidation = false;
            // this.selectedArticles.forEach((element) => {
            //   if(element.article.stock < element.quantity){
            //       quantityValidation = true;
            //   }
            // })

            if (
                !this.billType ||
                !this.parent_company_client_name ||
                this.selectedArticles.length <= 0
            ) {
                this.selectedArticles.length <= 0
                    ? this.$toast.error(
                          this.$t(
                              "billings.text_messages.please_select_at_least_one_article"
                          )
                      )
                    : "";
                !this.billType
                    ? this.$toast.error(
                          this.$t(
                              "billings.text_messages.please_select_bill_type"
                          )
                      )
                    : "";
                !this.parent_company_client_name
                    ? this.$toast.error(
                          this.$t(
                              "billings.text_messages.please_select_client_name"
                          )
                      )
                    : "";
                return false;
            }

            // if (quantityValidation || this.$v.invalid ||  this.$refs['clientComp'].$v.invalid) {
            //   quantityValidation ? this.$toast.warning("Please verify the stock of the articles selected!")  : '';
            //   this.$v.invalid || this.$refs['clientComp'].$v.invalid ? this.$toast.warning(this.$t('please_fill_form_correctly'))  : '';

            // }
            let anyArticleUnitPriceLessThenMin = false;

            if (anyArticleUnitPriceLessThenMin) {
                return false;
            }
            if (
                this.$v.$invalid ||
                (this.checkTag("clients")
                    ? this.$refs["clientComp"].$v.$invalid
                    : false) ||
                (this.checkTag("vehicles") &&
                this.isVehicleModuleEnabled &&
                this.$refs["vehicleComp"]
                    ? this.$refs["vehicleComp"].$v.$invalid ||
                      (this.$refs["vehicleComp"].$refs["drivercomp"] &&
                          this.$refs["vehicleComp"].$refs["drivercomp"].$v
                              .$invalid)
                    : false)
            ) {
                this.$v.$invalid ||
                (this.checkTag("clients")
                    ? this.$refs["clientComp"].$v.$invalid
                    : false) ||
                (this.checkTag("vehicles") &&
                this.isVehicleModuleEnabled &&
                this.$refs["vehicleComp"]
                    ? this.$refs["vehicleComp"].$v.$invalid ||
                      (this.$refs["vehicleComp"].$refs["drivercomp"] &&
                          this.$refs["vehicleComp"].$refs["drivercomp"].$v
                              .$invalid)
                    : false)
                    ? this.$toast.warning(this.$t("please_fill_form_correctly"))
                    : "";
                return false;
            }

            return true;
        },
        async submit(withoutClose = false) {
            this.$store.commit("setValidation", true); // enable validation
            if (this.validateSubmit()) {
                await this.saveOrder().then(async () => {
                    await this.updateGroupText().then(async () => {
                        await this.fineDataForSending().then(async () => {
                            let data = await this.makeSubmitData();
                            if (!data.bill.order_id) {
                                if (
                                    this.parent_company_client_name &&
                                    typeof this.parent_company_client_name ===
                                        "object"
                                ) {
                                    let clientStatus = this.clientStatuses.find(
                                        (i) =>
                                            i.id ===
                                            this.parent_company_client_name
                                                .status_id
                                    );
                                    if (
                                        clientStatus &&
                                        clientStatus.key === "client_blocked"
                                    ) {
                                        return this.$toast.error(
                                            this.$t(
                                                "billings.text_messages.client_blocked"
                                            )
                                        );
                                    }
                                }
                                await this.addBilling(data, true).then(() => {
                                    if (!withoutClose) {
                                        this.$router
                                            .push("/billing")
                                            .catch(() => {});
                                    }
                                });
                            } else {
                                await this.updateBilling(data, true, false).then(
                                    () => {
                                        if (!withoutClose) {
                                            this.$router
                                                .push("/billing")
                                                .catch(() => {});
                                        }
                                    }
                                );
                            }
                        });
                    });
                }).finally(() => {
                    return Promise.resolve(true);
                });
            }
            else{
                return Promise.resolve(true);
            }
        },
        async getPaymentMethods() {
            await this.getCodes(
                "payment_types",
                "billings.payment_methods"
            ).then((data) => {
                this.payment_methods = data;
            });
        },
        getCreditors() {
            axios
                .get(API_BASE_URL + "/creditors", { headers: this.header })
                .then(({ data }) => {
                    this.creditors = data.data.map((element) => {
                        if (element.creditor_company) {
                            element.creditor_company["is_leaser"] =
                                element.is_leaser;
                            return element.creditor_company;
                        }
                    });
                })
                .catch(function(error) {
                    console.log("an error occured " + error);
                });
        },
        async getOrderStatuses() {
            await this.getCodes("order_status", "billings.statuses").then(
                (data) => {
                    this.allStatuses = data;
                }
            );
        },
        filterOutStatuses() {
            let filteredStatuses = [];
            if (this.billType) {
                switch (this.billType.key) {
                    case "estimation":
                        filteredStatuses = this.filterAndSortStatuses(
                            this.statusFilters.estimation
                        );
                        break;
                    case "purchase_order":
                        filteredStatuses = this.filterAndSortStatuses(
                            this.statusFilters.purchase_order
                        );
                        break;
                    case "bill":
                        filteredStatuses = this.filterAndSortStatuses(
                            this.statusFilters.bill
                        );
                        break;
                    case "credit_note":
                        filteredStatuses = this.filterAndSortStatuses(
                            this.statusFilters.credit_note
                        );
                        break;
                    default:
                        filteredStatuses = this.allStatuses;
                }
            } else {
                filteredStatuses = this.allStatuses;
            }
            this.billstatuses = filteredStatuses;
        },
        filterAndSortStatuses(statusFilter) {
            return this.allStatuses
                .filter((element) => statusFilter.includes(element.key))
                .sort(
                    (a, b) =>
                        statusFilter.indexOf(a.key) -
                        statusFilter.indexOf(b.key)
                );
        },
        setCurrentFormOperation() {
            this.operation =
                this.$route.path.indexOf("/add") > 0 ? "add" : "edit";
        },
        addBilling(data, showMessage = true) {
            return new Promise((resolve, reject) => {
                this.resetted_data_table = false;
                axios
                    .post(API_BASE_URL + "/billings", data, {
                        headers: this.header,
                    })
                    .then((response) => {
                        this.selectedBill = response.data.data;
                        if (response.status === 200) {
                            let alreadySentForSupplier = [];
                            // Make order to Supplier on success of bill creation
                            data.article.forEach(
                                (finedArticlesForMakingStockOrder) => {
                                    
                                    if (
                                        finedArticlesForMakingStockOrder.supplier_stock_detail &&
                                        finedArticlesForMakingStockOrder
                                            .supplier_stock_detail.article_id
                                    ) {
                                        response.data.data.details.forEach(
                                            (detailArticle) => {
                                                if (
                                                    finedArticlesForMakingStockOrder
                                                        .supplier_stock_detail
                                                        .article_id ==
                                                    detailArticle.article_id
                                                ) {
                                                    if (
                                                        detailArticle.supplier_order_detail &&
                                                        detailArticle
                                                            .supplier_order_detail
                                                            .supplier_order &&
                                                        !alreadySentForSupplier.includes(
                                                            detailArticle
                                                                .supplier_order_detail
                                                                .supplier_order
                                                                .id
                                                        )
                                                    ) {
                                                        finedArticlesForMakingStockOrder.supplier_stock_detail.order_id =
                                                            response.data.data.id;
                                                        this.makeOrderToSupplier(
                                                            detailArticle
                                                                .supplier_order_detail
                                                                .supplier_order
                                                                .id,
                                                            finedArticlesForMakingStockOrder.supplier_stock_detail
                                                        );
                                                        alreadySentForSupplier.push(
                                                            detailArticle
                                                                .supplier_order_detail
                                                                .supplier_order
                                                                .id
                                                        );
                                                    }
                                                }
                                            }
                                        );

                                        // this.submitOrderDetails(
                                        //   finedArticlesForMakingStockOrder.supplier_stock_detail
                                        // );
                                    }
                                }
                            );
                            if (showMessage) {
                                if (data.type == 10241000) {
                                    this.$toast.success(
                                        this.$t(
                                            "billings.text_messages.estimation_created_success"
                                        )
                                    );
                                } else if (data.type == 10241001) {
                                    this.$toast.success(
                                        this.$t(
                                            "billings.text_messages.purchase_order_created_successfully"
                                        )
                                    );
                                } else if (data.type == 10241002) {
                                    this.$toast.success(
                                        this.$t(
                                            "billings.text_messages.bill_created_successfully"
                                        )
                                    );
                                } else if (data.type == 10241003) {
                                    this.$toast.success(
                                        this.$t(
                                            "billings.text_messages.credit_note_created_successfully"
                                        )
                                    );
                                }
                            }
                            resolve();
                            // this.onResetPageData();
                        }
                    })
                    .catch((error) => {
                        reject();
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.resetted_data_table = true;
                    });
            });
        },
        updateBilling(data, showMessage = true, checkDirty = false) {
            if (checkDirty && !this.isDirty()) {
                return new Promise((resolve, reject) => {
                    resolve();
                });
            }
            if (data.bill.order_id) {
                return new Promise((resolve, reject) => {
                    this.resetted_data_table = false;
                    axios
                        .put(
                            API_BASE_URL + "/billings/" + data.bill.order_id,
                            data,
                            {
                                headers: this.header,
                            }
                        )
                        .then((response) => {
                            this.selectedBill = response.data.data;
                            if (response.status === 200) {
                                let alreadySentForSupplier = [];
                                // Make order to Supplier on success of bill creation
                                data.article.forEach(
                                    (finedArticlesForMakingStockOrder) => {
                                        if (
                                            finedArticlesForMakingStockOrder.supplier_stock_detail &&
                                            finedArticlesForMakingStockOrder
                                                .supplier_stock_detail
                                                .article_id
                                        ) {
                                            response.data.data.details.forEach(
                                                (detailArticle) => {
                                                    if (
                                                        finedArticlesForMakingStockOrder
                                                            .supplier_stock_detail
                                                            .article_id ==
                                                        detailArticle.article_id
                                                    ) {
                                                        if (
                                                            detailArticle.supplier_order_detail &&
                                                            detailArticle
                                                                .supplier_order_detail
                                                                .supplier_order &&
                                                            !alreadySentForSupplier.includes(
                                                                detailArticle
                                                                    .supplier_order_detail
                                                                    .supplier_order
                                                                    .id
                                                            )
                                                        ) {
                                                            finedArticlesForMakingStockOrder.supplier_stock_detail.order_id =
                                                                response.data.data.id;
                                                            this.makeOrderToSupplier(
                                                                detailArticle
                                                                    .supplier_order_detail
                                                                    .supplier_order
                                                                    .id,
                                                                finedArticlesForMakingStockOrder.supplier_stock_detail
                                                            );
                                                            alreadySentForSupplier.push(
                                                                detailArticle
                                                                    .supplier_order_detail
                                                                    .supplier_order
                                                                    .id
                                                            );
                                                        }
                                                    }
                                                }
                                            );

                                            // this.submitOrderDetails(
                                            //   finedArticlesForMakingStockOrder.supplier_stock_detail
                                            // );
                                        }
                                    }
                                );
                                if (showMessage) {
                                    if (data.type == 10241000) {
                                        this.$toast.success(
                                            this.$t(
                                                "billings.text_messages.estimation_updated_success"
                                            )
                                        );
                                    } else if (data.type == 10241001) {
                                        this.$toast.success(
                                            this.$t(
                                                "billings.text_messages.purchase_order_updated_successfully"
                                            )
                                        );
                                    } else if (data.type == 10241002) {
                                        this.$toast.success(
                                            this.$t(
                                                "billings.text_messages.bill_updated_successfully"
                                            )
                                        );
                                    } else if (data.type == 10241003) {
                                        this.$toast.success(
                                            this.$t(
                                                "billings.text_messages.credit_note_updated_successfully"
                                            )
                                        );
                                    }
                                }
                                resolve();
                                // this.onResetPageData();
                            } else {
                                this.$toast.error(response);
                            }
                        })
                        .catch((error) => {
                            reject();
                            this.$toast.error(error);
                        })
                        .finally(() => {
                            this.resetted_data_table = true;
                        });
                });
            }
        },
        updatePagination(page) {
            // this.page = page;
            // this.fetchData();
        },
        updateItemsPerPage(itemsPerPage) {
            // this.itemsPerPage = (itemsPerPage == -1 ? this.totalItems : itemsPerPage)
            // this.fetchData();
            // this.itemsPerPage = (this.itemsPerPage == -1 ? -1 : itemsPerPage);
        },
        clearArticleList() {
            this.articles = [];
        },
        fetchArticleListDebounced() {
            clearTimeout(this._searchTimerId);
            this._searchTimerId = setTimeout(() => {
                this.fetchArticlesList();
            }, 500); /* 500ms throttle */
        },
        fetchArticlesList() {
            if (!this.search || typeof this.search == "object") {
                return;
            }
            this.clearArticleList();
            axios
                .get(
                    API_BASE_URL +
                        "/billings/articles/search?page=1&items_per_page=10" +
                        (this.parent_familyClient
                            ? "&family_client_for_pricing=" +
                              this.parent_familyClient
                            : "") +
                        (this.search
                            ? "&search=" + encodeURIComponent(this.search)
                            : "") +
                        (this.parentInternalStockOnly
                            ? "&internal_stock=true"
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    if (data.data.length == 0) {
                        this.$toast.error(
                            this.$t(
                                "scheduler.appointment.no_pricing_found_for_this_article"
                            )
                        );
                    }
                    this.articles = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingArticle = false;
                })
                .finally(() => (this.isLoadingArticle = false));
        },
        fetchBillSuggesListDebounced() {
            clearTimeout(this._searchTimerId);
            this._searchTimerId = setTimeout(() => {
                this.fetchBillSuggesList();
            }, 500); /* 500ms throttle */
        },
        fetchBillSuggesList() {
            if (!this.search_bill || typeof this.search_bill == "object") {
                return;
            }
            // this.clearArticleList()
            let status_ids = "";
            this.statusFiltersForSearch.forEach((element) => {
                status_ids += element.id + ",";
            });
            axios
                .get(
                    API_BASE_URL +
                        "/billings/search?page=1&items_per_page=20" +
                        (this.search_bill
                            ? "&search=" + encodeURIComponent(this.search_bill)
                            : "") +
                        (status_ids
                            ? "&status_ids=" + status_ids.slice(0, -1)
                            : ""),
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.bills = data.data;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoadingSearchRegiClient = false;
                })
                .finally(() => (this.isLoadingSearchRegiClient = false));
        },
        calculateFields(
            UpdateQuantity,
            updateUnitPrice,
            updateTHTVA,
            updateTotal
        ) {
            // this.unit_price = this.convertCommaToDecimalSeparator(this.unit_price);
            // this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
            // this.unit_price = "";
            if (UpdateQuantity) {
                // this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
            }
            if (updateUnitPrice) {
                // this.first_row_thtva = parseFloat((this.unit_price * this.quantity) - ( (this.unit_price * this.quantity) * this.red_percentage) /100);
            }
            if (updateTHTVA) {
                this.first_row_thtva = parseFloat(
                    this.unit_price * this.quantity -
                        (this.unit_price *
                            this.quantity *
                            this.red_percentage) /
                            100
                );
            }
            if (updateTotal) {
                this.first_row_total = parseFloat(
                    parseFloat(this.first_row_thtva) +
                        parseFloat(
                            (this.first_row_tva * this.first_row_thtva) / 100
                        )
                );
            }
        },
        calculateColumns(changeUnitPrice) {
            if (this.isEditModeForDataTableItem) {
                // do not update column values if an article is in edit mode
                // ------------ Update Price on Supplier Change ----------
                // if(this.article && this.article.internal_company_article){
                //   this.unit_price = parseFloat(
                //     changeUnitPrice
                //       ? this.unit_price
                //       : this.article.internal_company_article
                //       ? this.article.internal_company_article.length > 0
                //         ? this.article.internal_company_article[0].price
                //         : 0
                //       : 0.0
                //   );
                // }
                // else{

                // }

                // ------------ Updated Price on Supplier Change ----------
                this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
                if (
                    this.article &&
                    this.article.internal_company_article &&
                    this.article.internal_company_article.length > 0
                ) {
                    this.first_row_minimum_sales_price = parseFloat(
                        this.article.internal_company_article[0]
                            .minimum_sales_price ?? 0
                    );
                } else {
                    this.first_row_minimum_sales_price = this.selectedArticles[
                        this.selectedItemIndexInDataTable
                    ]?.minimum_sales_price;
                }
                (this.rounded_unit_price = parseFloat(this.unit_price).toFixed(
                    this.getDecimalNumber()
                )),
                    // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
                    (this.first_row_thtva = parseFloat(
                        this.unit_price * this.quantity -
                            (this.unit_price *
                                this.quantity *
                                this.red_percentage) /
                                100
                    ));
                this.first_row_total = parseFloat(
                    parseFloat(this.first_row_thtva) +
                        parseFloat(
                            (this.first_row_tva * this.first_row_thtva) / 100
                        )
                );
                this.calculateTotals();
                return;
            }
            if (this.article) {
                this.unit_price = this.convertCommaToDecimalSeparator(
                    this.unit_price
                );
                this.first_row_tariff = parseFloat(this.article.tarrif ?? 0);
                if (
                    this.article &&
                    this.article.internal_company_article &&
                    this.article.internal_company_article.length > 0
                ) {
                    this.first_row_minimum_sales_price = parseFloat(
                        this.article.internal_company_article[0]
                            .minimum_sales_price ?? 0
                    );
                } else {
                    this.first_row_minimum_sales_price = this.selectedArticles[
                        this.selectedItemIndexInDataTable
                    ]?.minimum_sales_price;
                }
                this.unit_price = parseFloat(
                    changeUnitPrice
                        ? this.unit_price
                        : this.article.internal_company_article
                        ? this.article.internal_company_article.length > 0
                            ? this.article.internal_company_article[0].price
                            : 0
                        : 0.0
                );
                this.rounded_unit_price = parseFloat(this.unit_price).toFixed(
                    this.getDecimalNumber()
                );
                // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
                this.first_row_thtva = parseFloat(
                    this.unit_price * this.quantity -
                        (this.unit_price *
                            this.quantity *
                            this.red_percentage) /
                            100
                );
                this.first_row_total = parseFloat(
                    parseFloat(this.first_row_thtva) +
                        parseFloat(
                            (this.first_row_tva * this.first_row_thtva) / 100
                        )
                );
            } else {
                this.unit_price = this.convertCommaToDecimalSeparator(
                    this.unit_price
                );
                (this.rounded_unit_price = parseFloat(this.unit_price).toFixed(
                    this.getDecimalNumber()
                )),
                    // this.first_row_tva = parseFloat(this.article.internal_company_article.length > 0 ? this.article.internal_company_article[0].tva.tax : 17);
                    (this.first_row_thtva = parseFloat(
                        this.unit_price * this.quantity -
                            (this.unit_price *
                                this.quantity *
                                this.red_percentage) /
                                100
                    ));
                this.first_row_total = parseFloat(
                    parseFloat(this.first_row_thtva) +
                        parseFloat(
                            (this.first_row_tva * this.first_row_thtva) / 100
                        )
                );
                this.first_row_minimum_sales_price = parseFloat(
                    this.first_row_minimum_sales_price
                );
            }
        },
        calculateTotals() {
            let thtva_total = 0;
            let total_total = 0;
            this.selectedArticles.forEach((element) => {
                thtva_total += parseFloat(element.thtva);
                total_total += parseFloat(element.total);
            });
            this.articleTHTVATotalBeforeReduction = parseFloat(thtva_total);
            this.articleTHTVATotal = this.reduction_on_order
                ? parseFloat(
                      this.articleTHTVATotalBeforeReduction -
                          (this.articleTHTVATotalBeforeReduction *
                              this.reduction_on_order) /
                              100
                  )
                : this.articleTHTVATotalBeforeReduction;
            this.articleTotalBeforeReduction = parseFloat(total_total);
            this.articleTotal = this.reduction_on_order
                ? parseFloat(
                      this.articleTotalBeforeReduction -
                          (this.articleTotalBeforeReduction *
                              this.reduction_on_order) /
                              100
                  )
                : this.articleTotalBeforeReduction;
            this.total_reduction = parseFloat(
                (this.articleTotalBeforeReduction ?? 0) *
                    ((this.reduction_on_order ?? 0) / 100)
            ).toFixed(this.getDecimalNumber());
        },
        fineDataForSending() {
            return new Promise((resolve) => {
                this.finedSelectedArticles = [];
                this.selectedArticles.forEach((element) => {
                    if (
                        element.supplier_stock_detail &&
                        element.supplier_stock_detail.article_id
                    ) {
                        element.supplier_stock_detail.quantity_to_order =
                            element.quantity; // Update Stock Order Quantity with selected article quantity
                        element.supplier_stock_detail.client = {
                            id: this.vehicleDriverDetails.client.client_id,
                            name: this.vehicleDriverDetails.client.name,
                        };
                        if (element.supplier && element.supplier.length > 0) {
                            element.supplier_stock_detail.supplier_id =
                                element.supplier[0].id;
                            element.supplier_id = element.supplier[0].id;
                        }
                    }
                    // else{

                    // }
                    
                    this.finedSelectedArticles.push({
                        article_id:
                            element.depot_in_id || element.depot_out_id
                                ? 0
                                : typeof element.article == "object"
                                ? element.article.id
                                : element.article, // If element has text property then we will send the article id as 0
                        // company_id: element.article.company_id,
                        // supplier_id: element.article.supplier_id,
                        quantity: element.quantity,
                        red_percentage: parseFloat(element.red_percentage ?? 0),
                        tarrif: parseFloat(element.tarrif),
                        thtva: parseFloat(element.thtva),
                        total: parseFloat(element.total),
                        tva: parseFloat(element.tva),
                        stock_property: element.stock_property
                            ? typeof element.stock_property == "object"
                                ? element.stock_property.property
                                : element.stock_property
                            : null,
                        acquisition_price: parseFloat(
                            element.acquisition_price
                        ),
                        unit_price: parseFloat(element.unit_price),
                        minimum_sales_price: parseFloat(
                            element.minimum_sales_price
                        ),
                        rounded_unit_price: parseFloat(
                            element.rounded_unit_price
                        ),
                        text: element.text,
                        row: element.row,
                        text_position: element.text_position ?? 0,
                        depot_out_id: element.depot_out_id,
                        depot_in_id: element.depot_in_id,
                        supplier_id: this.checkIfStockManaged(element)
                            ? element.supplier &&
                              element.supplier.length > 0 &&
                              element.supplier[0] &&
                              element.supplier[0].id
                                ? element.supplier[0].id
                                : null
                            : null,
                        parent_supplier_id: this.checkIfStockManaged(element)
                            ? element.parent_supplier_id
                            : null,
                        supplier_stock_detail: this.checkIfStockManaged(element)
                            ? element.supplier_stock_detail
                            : null,
                        group: element.group,
                        selectedmu:
                            element.selectedmu &&
                            typeof element.selectedmu == "object"
                                ? element.selectedmu.id
                                : element.selectedmu,
                        currency_id: element.currency_id,
                        expedition_id: element.expedition_id,
                    });
                });
                resolve();
            });
        },
        fetchClientBalance(client_id) {
            axios
                .get(
                    API_BASE_URL +
                        "/clients/" +
                        client_id +
                        "/pending/bill?return_total=1",
                    { headers: this.header }
                )
                .then(({ data }) => {
                    this.customer_balance = this.fixDecimal(data.data);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {});
        },
        getArticleDots(selectedArticleIndex, article_id, supplier_id) {
            if (typeof article_id == "object") {
                article_id = article_id.id;
            }
            if (supplier_id == 0) {
                // if Article added is of another supplier then we dont have to check for dot properties as those order will be directly made to the supplier
                axios
                    .get(
                        API_BASE_URL +
                            "/stock_property/" +
                            article_id +
                            "?property_class_id=dot_property" +
                            "&supplier_id=" +
                            supplier_id,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + this.$store.state.AccessToken,
                            },
                        }
                    )
                    .then((response) => {
                        this.selectedArticles[selectedArticleIndex].dots =
                            response.data.data ?? [];
                        if (
                            !this.selectedArticles[selectedArticleIndex]
                                .stock_property
                        ) {
                            this.selectedArticles[
                                selectedArticleIndex
                            ].stock_property = response.data.data
                                ? response.data.data[0]
                                : null;
                        }
                    })
                    .catch((error) => {
                        console.log("an error occured " + error);
                        this.selectedArticles[selectedArticleIndex].dots = [];
                    })
                    .finally(() => {});
            } else {
                this.selectedArticles[selectedArticleIndex].dots = [];
            }
        },
        fixBillCurrencySymbol() {
            if (this.selectedArticles.length > 0) {
                this.selectedArticles.forEach((element) => {
                    element.currency_id = this.$store.state.topCurrencyType
                        ? this.$store.state.topCurrencyType.id
                        : this.defaultCurrencyId;
                });
                this.fineDataForSending();
            }
        },
        deleteSupplierOrder() {
            axios
                .delete(
                    API_BASE_URL +
                        "/suppliers/order/" +
                        this.lastSupplierOrderCreated.id,
                    { headers: this.header }
                )
                .then(({ data }) => {})
                .catch(function(error) {
                    console.log("an error occured " + error);
                })
                .finally(() => {});
        },
        updatePricingForSelectedArticle(val) {
            if (val) {
                let stockItem = val;
                val = typeof val == "object" ? val.id : val;
                let supplier = this.parentSearchSelectedArticleSupplierDetail
                    ? this.parentSearchSelectedArticleSupplierDetail
                          .supplier_detail
                    : null;
                
                let supplier_stock_detail = this
                    .parentSearchSelectedArticleSupplierDetail
                    ? this.parentSearchSelectedArticleSupplierDetail
                    : null;
                this.dialog = false;
                //
                this.showLoadingModel = true;
                axios
                    .get(
                        API_BASE_URL +
                            "/billings/articles/search?page=1&items_per_page=10" +
                            (this.parent_familyClient
                                ? "&family_client_for_pricing=" +
                                  this.parent_familyClient
                                : "") +
                            ("&article_ids=" + val) +
                            (supplier && supplier.id
                                ? "&supplier_id=" + supplier.id
                                : ""),
                        { headers: this.header }
                    )
                    .then(({ data }) => {
                        if (data.data.length == 0) {
                            this.articles = [];
                            this.article = undefined;
                            this.$toast.error(
                                this.$t(
                                    "scheduler.appointment.no_pricing_found_for_this_article"
                                )
                            );
                        }
                        if (
                            !this.isEditModeForDataTableItem &&
                            (!supplier || (supplier && supplier.id == 0)) &&
                            !this.isAdmin &&
                            this.selectedFamilyArticle &&
                            this.selectedFamilyArticle.is_stock_managed &&
                            data &&
                            data.data &&
                            data.data[0] &&
                            data.data[0].internal_company_article &&
                            data.data[0].internal_company_article.length > 0 &&
                            data.data[0].internal_company_article[0].stock -
                                data.data[0].internal_company_article[0]
                                    .reserve <=
                                0
                        ) {
                            this.articles = [];
                            this.article = undefined;
                            this.$toast.error(
                                this.$t(
                                    "scheduler.text_messages.cannot_add_article_because_of_not_in_stock"
                                )
                            );
                        } else {
                            this.articles = [data.data[0]];
                            this.article = data.data[0];
                            if (supplier == null) {
                                supplier = (
                                    this.cachedData["suppliers"][
                                        this.selectedFamilyArticle.id
                                    ] ?? []
                                ).find((element) => {
                                    if (element.id == 0) {
                                        return element;
                                    }
                                });
                            }
                            this.article.supplier = [supplier];
                            this.article.supplier = this.article.supplier.concat(
                                this.cachedData["suppliers"][
                                    this.selectedFamilyArticle.id
                                ] ?? []
                            );
                            this.article.supplier_stock_detail = this.makeSupplierOrderData(
                                stockItem,
                                supplier_stock_detail
                            );
                        }
                        this.isLoadingArticle = false;
                        //

                        var articleFilters = JSON.parse(
                            localStorage.getItem("ARTICLE_MODEL_FILTERS")
                        );
                        articleFilters["id"] = val;
                        localStorage.setItem(
                            "ARTICLE_MODEL_FILTERS",
                            JSON.stringify(articleFilters)
                        );
                        this.calculateColumns(true);
                    })
                    .catch(() => {
                        //
                    })
                    .finally(() => {
                        this.$refs["tableQuantity"].focus();
                        // this.parentSearchSelectedArticleId = null;

                        this.showLoadingModel = false;
                    });
            } else {
                this.articles = [];
                this.article = "";
                this.acquisition_price = 0.0;
                this.unit_price = 0.0;
                this.red_percentage = 0.0;
                this.first_row_minimum_sales_price = 0.0;
                this.parentGroupText = null;
            }
        },
        checkAndFixTaxation() {
            let val = this.parent_taxation_reason_id;
            // No reason
            if (
                (val && typeof val != "object" && val == 10771000) ||
                (typeof val == "object" && val && val.id == 10771000)
            ) {
                this.taxationAllowed = false;
                this.$nextTick(() => {
                    this.taxationAllowed = true;
                });
            }
            // Intercommunity
            if (
                (val && typeof val != "object" && val == 10771001) ||
                (typeof val == "object" && val && val.id == 10771001)
            ) {
                this.taxationAllowed = true;
                this.$nextTick(() => {
                    this.taxationAllowed = false;
                });
            }
        },
        checkAndSetNewTaxValueForRecalculation() {
            let val = this.parent_taxation_reason_id;
            // No reason
            if (
                (val && typeof val != "object" && val == 10771000) ||
                (typeof val == "object" && val && val.id == 10771000)
            ) {
                return true;
            }
            // Intercommunity
            if (
                (val && typeof val != "object" && val == 10771001) ||
                (typeof val == "object" && val && val.id == 10771001)
            ) {
                return false;
            }
            return true;
        },
        fixCalculationAfterTaxation() {
            // Do Not calculate because its payed/partially payed
            if (
                this.selectedBill &&
                this.order_status &&
                (this.order_status.key == "payed" ||
                    this.order_status.key == "partial_payed")
            ) {
                console.log(
                    "Not calculating because its payed/partially payed",
                    this.order_status
                );
                return;
            }
            let tva = parseFloat(this.first_row_tva);
            this.selectedArticles.forEach((element) => {
                if (this.checkAndSetNewTaxValueForRecalculation()) {
                    tva = element.tva;
                }
                let unit_price = element.unit_price;
                let quantity = element.quantity;

                let thtva = parseFloat(
                    unit_price * quantity -
                        (unit_price * quantity * element.red_percentage) / 100
                );
                let total = parseFloat(
                    parseFloat(thtva) + parseFloat((tva * thtva) / 100)
                );
                // Update Element
                element.thtva = thtva;
                element.tva = tva ?? 0;
                element.total = total;
            });
            this.$nextTick(() => {
                this.calculateTotals();
            });
        },
        setQuantityIfLessAvailable(article) {
            if (article) {
                if (
                    this.selectedFamilyArticle &&
                    this.selectedFamilyArticle.is_stock_managed &&
                    this.selectedFamilyArticle.default_quantity &&
                    article.supplier &&
                    article.supplier.length > 0 &&
                    article.supplier[0] &&
                    (article.supplier[0].id == 0 ||
                        article.supplier[0].id == "0" ||
                        article.supplier[0].id != 0)
                ) {
                    if (
                        article.internal_company_article &&
                        article.internal_company_article.length > 0 &&
                        article.internal_company_article[0]
                    ) {
                        if (
                            this.selectedFamilyArticle.default_quantity >
                            article.internal_company_article[0].stock
                        ) {
                            this.quantity =
                                article.internal_company_article[0].stock;
                        }
                    }
                }
            }
        },
        setAcquisitionPrice(article) {
            if (article) {
                if (
                    article.internal_company_article &&
                    article.internal_company_article.length > 0 &&
                    article.internal_company_article[0]
                ) {
                    this.acquisition_price =
                        article.internal_company_article[0].acquisition_price;
                }
            }
        },
        activateOverdueListModal() {
            this.overdueOrdersModal = false;
            this.$nextTick(() => {
                this.overdueOrdersModal = true;
            });
        },
    },
    computed: {
        dataHeader() {
            let data = [
                {
                    text: "",
                    align: "right",
                    width: "10px",
                    sortable: false,
                    value: "information",
                },
                {
                    text: this.$t("scheduler.article"),
                    align: "start",
                    width: 450,
                    sortable: true,
                    value: "article_original_name",
                },
                {
                    text: this.$t("scheduler.quantity"),
                    align: "end",
                    width: 80,
                    sortable: true,
                    value: "quantity",
                },
                // { text: this.$t('scheduler.unit_price'),           align: 'end',    width:80,  sortable: false,         value: 'unit_price' },
                {
                    text: this.$t("billings.unit_price"),
                    align: "end",
                    width: 80,
                    sortable: false,
                    value: "rounded_unit_price",
                },
                {
                    text: this.$t("scheduler.red_percentage"),
                    align: "end",
                    width: 80,
                    sortable: false,
                    value: "red_percentage",
                },
                // { text: this.$t('scheduler.tarrif'),               align: 'end',    width:100,  sortable: false,         value: 'tarrif' },
                {
                    text: this.$t("scheduler.tva"),
                    align: "end",
                    width: 100,
                    sortable: false,
                    value: "tva",
                },
                {
                    text: this.$t("scheduler.thtva"),
                    align: "end",
                    width: 125,
                    sortable: false,
                    value: "thtva",
                },
                {
                    text: this.$t("scheduler.total"),
                    align: "end",
                    width: 125,
                    sortable: false,
                    value: "total",
                },
                {
                    text: this.$t("billings.actions") + "      .",
                    align: "center",
                    width: 200,
                    sortable: false,
                    value: "actions",
                },
            ];
            if (
                this.checkTag("acquisition_price") &&
                this.checkUserTag("acquisition_price")
            ) {
                data.splice(2, 0, {
                    text: this.$t("scheduler.acquisition_price"),
                    align: "end",
                    width: 80,
                    sortable: false,
                    value: "acquisition_price",
                    visibility:
                        this.checkTag("acquisition_price") &&
                        this.checkUserTag("acquisition_price"),
                });
            }
            return data;
        },
        ...mapState(["repeatedArticles", "selectedBillData"]),
        allSuppliers() {
            return this.$store.state.allSuppliers ?? [];
        },
        navigationData() {
            return this.$store.state.navigationData;
        },
        paymentTermsDays() {
            if (
                this.parent_company_client_name &&
                typeof this.parent_company_client_name == "object"
            ) {
                return parseInt(this.parent_company_client_name.payment_terms);
            }
            return parseInt(
                this.getCompanyPropertyFromLocalStorage(
                    "DEFAULT_DOCUMENT_DUE_DAYS"
                )
                    ? this.getCompanyPropertyFromLocalStorage(
                          "DEFAULT_DOCUMENT_DUE_DAYS"
                      ).value
                    : 30
            );
        },
        statusFiltersForSearch() {
            let data = this.allStatuses.filter((element) => {
                if (!this.cancelledStatusFilters.includes(element.key)) {
                    return element;
                }
            });
            return data;
        },
        chargingDischargigArticle() {
            return JSON.parse(localStorage.getItem("expedition_article"));
        },
        selectedExpeditionIdsForBilling() {
            return this.$store.state.selectedExpeditionIdsForBilling;
        },
        availableDepots() {
            let depot_ids = [];
            this.selectedArticles.forEach((article) => {
                if (article.depot_in_id) {
                    depot_ids.push(article.depot_in_id);
                }
            });

            return depot_ids.filter((element) => this.isset(element));
        },
        currencySymbol() {
            let symbol = null;
            this.fixBillCurrencySymbol();
            if (this.$store.state.topCurrencyType) {
                symbol = this.parseSymbol(
                    this.$store.state.topCurrencyType.symbol ?? ""
                );
                return symbol;
            }
            return symbol ?? "";
        },
        selectedSupplier() {
            return this.article &&
                this.article.supplier &&
                this.article.supplier.length > 0
                ? this.article.supplier[0]
                    ? this.article.supplier[0]
                    : null
                : null;
        },
        parentClientIsBlocked() {
            if (!this.parent_company_client_name) {
                return false;
            }

            if (this.parent_company_client_name.status_id == 10041003) {
                return true;
            }

            return false;
        },
        vehicleDriverDetails() {
            let data = {
                client: this.makeClientData(),
                vehicle: this.makeVehicleData(),
                depot: this.parent_registration_no
                    ? typeof this.parent_registration_no == "object"
                        ? this.parent_registration_no.depot
                        : null
                    : null,
            };

            return data;
        },
        companyNameLabel() {
            return this.isCompanyEntitySelected ? "Company Name" : "Full Name";
        },
        articles_list() {
            return this.articles;
        },
        billType() {
            return this.$store.state.billType;
        },
        billTypes() {
            return this.$store.state.billTypes;
        },
        isEditingAllowed() {
            return this.$store.state.isEditingAllowed;
        },
    },
    watch: {
        showPaymentReminder(val) {
            if (val == false) {
                this.getOrderReminder();
            }
        },
        navigationData: {
            handler: function() {
                this.selectDefaultBillTypeFromNavigationData();
            },
        },
        setDefaultObjectForDirtyChecking(val) {
                this.setDefaultObjectForDirtyChecking
        },
        parent_taxation_reason_id: {
            handler: function(val) {
                this.checkAndFixTaxation();
                this.checkEmptinessOfForm();
                this.$nextTick(() => {
                    this.fixCalculationAfterTaxation();
                });
            },
            immediate: true,
        },
        taxationAllowed: {
            handler: function(val) {
                if (val == true) {
                    this.first_row_tva = this.cached_first_row_tva;
                } else if (val == false) {
                    this.first_row_tva = 0;
                }
                this.$nextTick(() => {
                    this.fixCalculationAfterTaxation();
                });
            },
        },
        isEditModeForDataTableItem(val) {
            if (!val) {
                this.selectedFamilyArticle = this.selectedFamilyArticlBeforeEditMode;
            }
        },
        selectedSupplier(val) {
                val;
            // this.updatePricingBasedOnSupplier();
        },
        repeatedArticles(val) {
            this.selectedArticles.push(...val);
        },
        selectedFamilyArticle(val) {
            this.quantity = this.selectedFamilyArticle
                ? this.selectedFamilyArticle.default_quantity
                : 4;
            // set current mu value (measurement value)
            this.currentMu = val ? val.mu.value : "";
            this.red_percentage = 0.0;
            this.acquisition_price = 0.0;
            this.unit_price = 0.0;
            this.first_row_tariff = "";
            this.first_row_thtva = "";
            this.first_row_total = "";
            this.first_row_minimum_sales_price = 0.0;
            this.parentGroupText = null;
        },
        confirmationDialogButtonClickedIs(newVal) {
            if (newVal == true && this.confirmationDialogOperation != null) {
                if (
                    this.confirmationDialogOperation ==
                        "identifier_confirmation" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    this.allowEditIdentifier();
                }
                if (
                    this.confirmationDialogOperation == "print_report" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    this.printReport();
                }
                if (
                    this.confirmationDialogOperation == "print_label" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    this.printLabel();
                }
                if (
                    (this.confirmationDialogOperation == "make_bill" ||
                        this.confirmationDialogOperation ==
                            "make_bill_error") &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    this.makeBill();
                }
                if (
                    this.confirmationDialogOperation == "make_purchase_order" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    this.makePurchaseOrder();
                }
                if (
                    this.confirmationDialogOperation == "make_credit_note" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    this.makeCreditNote();
                }
                if (
                    this.confirmationDialogOperation == "keep_trying" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    if (this.confirmationDialogButtonClickedIs == false) {
                        this.deleteSupplierOrder();
                    }
                }
                if (
                    this.confirmationDialogOperation == "refund" &&
                    this.confirmationDialogButtonClickedIs == true
                ) {
                    if (this.confirmationDialogButtonClickedIs == true) {
                        this.makeRefund();
                    }
                }
                this.confirmationDialog = false;
                this.confirmationDialogButtonClickedIs = null;
            }
        },
        vehicleDriverDetails(val) {
            this.$store.state.currentlySelectedClient = val.client;

            if (val.client.client_id && val.vehicle.vehicle_id) {
                if (
                    typeof this.parent_registration_no == "object" &&
                    this.parent_registration_no.depot &&
                    this.parent_registration_no.depot.length > 0
                ) {
                    let depotOutAlreadyExists = false;
                    this.selectedArticles.forEach((article) => {
                        if (
                            article.depot_out_id ||
                            (article.depot_in_id &&
                                typeof article.depot_in_id == "string" &&
                                article.depot_in_id.includes(
                                    this.storedDepotIn
                                ) &&
                                this.storedDepotIn.length == 1)
                        ) {
                            depotOutAlreadyExists = true;
                        }
                    });
                    if (depotOutAlreadyExists) {
                        this.$store.commit("setShowDepotButtons", 4); // Enable Depot In But Disable Depot Out, as their is already a depot out for the bill
                    } else {
                        this.$store.commit("setShowDepotButtons", 3); // Enable Both Depot In Depot Out
                    }
                } else {
                    this.$store.commit("setShowDepotButtons", 1); // Enable Just Depot In
                }
            } else if (
                (val.client.name || val.client.client_id) &&
                val.vehicle.registration_no &&
                !val.vehicle.vehicle_id
            ) {
                this.$store.commit("setShowDepotButtons", 1); // Enable Just Depot In
            } else {
                this.$store.commit("setShowDepotButtons", 0); // Dont show any button
            }
        },
        parentSearchSelectedArticleId(val) {
            this.updatePricingForSelectedArticle(val);
        },
        payment_method(val) {
            this.checkEmptinessOfForm();
        },
        note(val) {
            this.checkEmptinessOfForm();
        },
        reference_client(val) {
            this.checkEmptinessOfForm();
        },
        first_row_tariff(val) {
            this.checkEmptinessOfForm();
        },
        first_row_tva(val) {
            this.calculateColumns(true);
            this.checkEmptinessOfForm();
        },
        first_row_thtva(val) {
            this.checkEmptinessOfForm();
        },
        first_row_total(val) {
            this.checkEmptinessOfForm();
        },
        next_event_code(val) {
            this.checkEmptinessOfForm();
        },
        parent_search_client(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_phone(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_phone_2(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_phone_3(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_gsm(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_gsm_2(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_gsm_3(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_contact_name_2(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_contact_name_3(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_email(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_email_2(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_email_3(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_language(val) {
            this.checkEmptinessOfForm();
        },
        parent_note(val) {
            this.checkEmptinessOfForm();
        },
        parent_denomination(val) {
            this.checkEmptinessOfForm();
        },
        parent_tva(val) {
            this.checkEmptinessOfForm();
        },
        parent_address(val) {
            this.checkEmptinessOfForm();
        },
        parent_creditor(val) {
            this.checkEmptinessOfForm();
        },
        parent_reg_date(val) {
            this.checkEmptinessOfForm();
        },
        parent_model(val) {
            this.checkEmptinessOfForm();
        },
        parent_km(val) {
            this.checkEmptinessOfForm();
        },
        parent_brand(val) {
            this.checkEmptinessOfForm();
        },
        parent_registration_no(val) {
            this.checkEmptinessOfForm();

            this.$store.state.selectedAppointmentVehicleId = val?.id || null;
        },
        parent_vehicle_type(val) {
            this.checkEmptinessOfForm();
        },
        parent_vin(val) {
            this.checkEmptinessOfForm();
        },

        due_date(val) {
            if (val && val.length > 10) {
                this.due_date = val.substring(0, 10);
            }
        },
        created_date(val) {
            this.created_date = val;
        },

        parent_familyClient(val) {
            if (val) {
                this.reCalculateSelectedArticlesPricing();
            }
            this.checkEmptinessOfForm();
        },
        parent_company_client_first_name(val) {
            this.checkEmptinessOfForm();
        },
        parent_company_client_name(val) {
            if (
                this.parent_company_client_name &&
                typeof this.parent_company_client_name == "object" &&
                this.parent_company_client_name.company &&
                typeof this.parent_company_client_name.company == "object"
            ) {
                this.taxationAllowed = this.findTaxationReasonByCode(
                    this.parent_company_client_name.company.taxation_reason_id
                );
                
                    this.findTaxationReasonByCode(
                        this.parent_company_client_name.company
                            .taxation_reason_id
                    );
            } else {
                this.taxationAllowed = true;
            }
            this.$nextTick(() => {
                this.fixCalculationAfterTaxation();
            });
            this.customer_balance = this.fixDecimal(0);
            this.checkEmptinessOfForm();
            if (this.selectedBill && this.selectedBill.vehicle) {
                let billVehicle = val.vehicles.find((vehicle) => {
                    return vehicle.id == this.selectedBill.vehicle.id;
                });
                if (billVehicle) {
                    val.vehicles.unshift(billVehicle);
                }
                this.parent_company_client_name = val;
            }
            if (val && val.id) {
                this.fetchClientBalance(val.id);
            }
            this.setDueDate();
        },
        billType(val) {
            this.checkEmptinessOfForm();
            // this.statuses = [];
            // this.order_status = null;
            this.filterOutStatuses();
            //   if(val && val.key && val.key == 'purchase_order'){
            //     this.isVisibleOrderStatus = false;
            //   }
            //   else{
            this.isVisibleOrderStatus = true;
            //   }
            this.setPageTitle();
        },
        ispayment_method() {
            // this.payment_method = null;
        },
        order_status(val) {
            this.checkEmptinessOfForm();
            this.ispayment_method = false;
            if (val) {
                if (
                    (typeof val == "object" &&
                        val?.key &&
                        val.key == "payed") ||
                    val.key == "partial_payed"
                ) {
                    this.ispayment_method = true;
                }
            }
        },
        selectedArticles() {
            this.checkEmptinessOfForm();
            this.calculateTotals();
            this.selectedArticles.forEach((article, index) => {
                // update mus for every article
                if (!article.selectedmu) {
                    article.selectedmu =
                        article.article_original_name.family.mu;
                }
            });
            this.fineDataForSending();

            // Enable or disable depot buttons based upon depot out
            let depotOutAlreadyExists = false;
            this.selectedArticles.forEach((article) => {
                if (article.depot_out_id) {
                    depotOutAlreadyExists = true;
                }
            });
            if (
                this.$store.state.showDepotButtons == 3 ||
                this.$store.state.showDepotButtons == 4
            ) {
                if (depotOutAlreadyExists) {
                    this.$store.commit("setShowDepotButtons", 4); // Enable Depot In But Disable Depot Out, as their is already a depot out for the bill
                } else {
                    this.$store.commit("setShowDepotButtons", 3); // Enable Both Depot In Depot Out
                }
            }
            this.selectedArticles.forEach((article, index) => {
                // Fetch Dots only for tyre article
                if (
                    article.article_original_name &&
                    article.article_original_name.family &&
                    (article.article_original_name.family.id == 1 ||
                        article.article_original_name.family.parent_family_id ==
                            1) &&
                    article.depot_out_id == null &&
                    article.depot_in_id == null
                ) {
                    this.getArticleDots(
                        index,
                        article.article,
                        article.supplier && article.supplier[0]
                            ? article.supplier[0].id != 0
                                ? article.supplier[0].id
                                : 0
                            : 0
                    );
                } else {
                    this.selectedArticles[index].dots = [];
                }

                // Attach Supplier Orders to the articles
                if (
                    this.selectedBill &&
                    this.selectedBill.details &&
                    this.selectedBill.details.length > 0
                ) {
                    let currentArticleId =
                        typeof article.article == "object"
                            ? article.article.id
                            : article.article;
                    this.selectedBill.details.forEach((detailItem) => {
                        if (
                            detailItem &&
                            detailItem.supplier_order_details_id &&
                            detailItem.supplier_order_detail &&
                            currentArticleId == detailItem.article_id &&
                            detailItem.row == article.row &&
                            detailItem.supplier_id == article.supplier_id
                        ) {
                            article.supplier = [
                                detailItem.supplier_order_detail.supplier_order
                                    .supplier,
                            ];
                            article.saved_supplier_order_detail =
                                detailItem.supplier_order_detail;
                        }
                    });
                }

                // if (
                //   this.selectedBill &&
                //   this.selectedBill.supplier_order &&
                //   this.selectedBill.supplier_order.length > 0
                // ) {
                //   this.selectedBill.supplier_order.forEach((supplierOrder) => {
                //     if (
                //       article.article == supplierOrder.supplier_order_details.article_id
                //     ) {
                //       // Update Article Suppliers
                //       article.supplier = [];
                //       article.supplier[0] = supplierOrder.supplier;
                //       article.supplier_stock_detail = supplierOrder.supplier;

                //       // Update Article Suppplier Order Status
                //       if (
                //         article.supplier_stock_detail &&
                //         supplierOrder.supplier_id &&
                //         article.supplier_stock_detail.id == supplierOrder.supplier_id
                //       ) {
                //         article.supplier_order_status = supplierOrder;
                //       }
                //     }
                //   });
                // }
            });
        },
        article(val) {
            this.checkEmptinessOfForm();
            this.calculateColumns();
            if (!this.isEditModeForDataTableItem) {
                // If article is being edited then modify that particular item quantity and other tdetails
                this.setQuantityIfLessAvailable(val);
                this.setAcquisitionPrice(val);
                if (val && val.family && val.family.mu_id) {
                    this.mus.forEach((element) => {
                        if (element.id == val.family.mu_id) {
                            this.currentMu = element;
                        }
                    });
                }
                if (this.showGroupText) {
                    this.parentGroupText = this.parentGroupText
                        ? this.parentGroupText
                        : this.selectedFamilyArticle
                        ? this.selectedFamilyArticle.name
                        : null;
                }
            }

            if (this.article) {
                if (
                    this.unit_price < this.first_row_minimum_sales_price &&
                    this.billType &&
                    this.billType.key !== "credit_note"
                ) {
                    this.isInValidUnitPrice = true;
                } else {
                    this.isInValidUnitPrice = false;
                }
            } else {
                this.isInValidUnitPrice = false;
            }
        },
        quantity(val) {
            this.checkEmptinessOfForm();
            this.calculateColumns(true);
        },
        unit_price(val) {
            if (!val) {
                val = 0;
            }

            this.checkEmptinessOfForm();
            this.unit_price = val ?? 0;
            if (this.pauseWatchForUnitPrice) {
                this.pauseWatchForUnitPrice = false;
            } else {
                this.calculateColumns(true);
            }
            if (this.article) {
                if (
                    this.unit_price < this.first_row_minimum_sales_price &&
                    this.billType &&
                    this.billType.key !== "credit_note"
                ) {
                    this.isInValidUnitPrice = true;
                } else {
                    this.isInValidUnitPrice = false;
                }
            } else {
                this.isInValidUnitPrice = false;
            }
        },
        first_row_minimum_sales_price(val) {
            if (!val) {
                val = 0;
            }

            this.first_row_minimum_sales_price = val ?? 0;
        },
        red_percentage(val) {
            this.checkEmptinessOfForm();
            this.calculateColumns(true);
        },
        search(val) {
            this.checkEmptinessOfForm();
            if (
                !val ||
                (this.article &&
                    this.article.title_description[0].description == val)
            ) {
                return;
            }
            this.isLoadingArticle = true;
            this.fetchArticleListDebounced();
        },
        search_bill(val) {
            this.checkEmptinessOfForm();
            if (val && typeof val == "object") {
                this.search_bill = val.bill_number
                    ? val.bill_number
                    : val.category == "vehicles"
                    ? val.registration_number
                    : val.name;
                this.allowContinueSearch = false;
                return;
            }

            if (val && this.allowContinueSearch) {
                if (
                    !val ||
                    (this.selectedBill && this.selectedBill.bill_number == val)
                ) {
                    return;
                }
                this.isLoadingSearchRegiClient = true;
                this.fetchBillSuggesListDebounced();
            }
            this.allowContinueSearch = true;
        },
        async selectedBill(val) {
            this.$store.commit("setCurrentPageData", this.selectedBill);
            this.$store.commit("setSearchClientValue", null);
            // this.fetchBillRelatives(val);

            this.$store.state.currentBillPeppolStatus = this.determineBillPeppolStatus(
                val
            );
            this.checkEmptinessOfForm();
            this.lastSupplierOrderCreated = null;
            if (this.selectedBill) {
                this.$store.commit("setIsBillSelected", true);
                this.$store.commit("setSelectedBillData", this.selectedBill);
                // Set RDV Date if their is an appointment created for the selected bill or with its parent
                if (
                    this.selectedBill.appointment &&
                    this.selectedBill.appointment.id
                ) {
                    this.rdv_date = new Date(
                        this.selectedBill.appointment.start_time
                    ).toISOString();
                } else {
                    this.rdv_date = null;
                }
            } else {
                this.$store.commit("setIsBillSelected", false);
                this.$store.commit("setSelectedBillData", null);
            }
            if (!this.selectedBill) {
                this.$route.meta.title = "add_billing";
                this.onResetPageData();
            }
            if (this.selectedBill) {
                this.bills = [this.selectedBill];
                this.$store.commit("setBillType", this.selectedBill.type);
                this.$route.meta.title = "edit_billing";
                this.selectedArticles = [];
                this.articles = [];
                this.article = "";

                if (this.selectedBill.bill_number) {
                    this.$store.commit("setBillCancelVisible", true);
                    this.$store.commit("setEditingAllowed", true);
                } else {
                    this.$store.commit("setBillCancelVisible", false);
                    this.$store.commit("setEditingAllowed", false);
                }

                if (
                    this.selectedBill.bill_number &&
                    this.billType &&
                    this.billType.key == "estimation" &&
                    this.selectedBill.child_order_id == null
                ) {
                    this.$store.commit("setMakeBillVisible", false);
                    this.$store.commit("setMakePurchaseOrderVisible", true);
                    this.$store.commit("setMakeCreditNoteVisible", false);
                } else if (
                    this.selectedBill.bill_number &&
                    this.billType &&
                    this.billType.key == "purchase_order" &&
                    (this.selectedBill.status_id == 10251000 ||
                        this.selectedBill.status_id == 10251002 ||
                        this.selectedBill.status_id == 10251003 ||
                        this.selectedBill.status_id == 10251008) &&
                    this.selectedBill.child_order_id ==
                        null /* (10251000 = Open) */
                ) {
                    this.$store.commit("setMakeBillVisible", true);
                    this.$store.commit("setMakePurchaseOrderVisible", false);
                    this.$store.commit("setMakeCreditNoteVisible", false);
                } else if (
                    this.selectedBill.bill_number &&
                    this.billType &&
                    this.billType.key == "bill" &&
                    this.selectedBill.child_order_id == null
                ) {
                    this.$store.commit("setMakeBillVisible", false);
                    this.$store.commit("setMakePurchaseOrderVisible", false);
                    this.$store.commit("setMakeCreditNoteVisible", true);
                } else {
                    this.$store.commit("setMakeBillVisible", false);
                    this.$store.commit("setMakePurchaseOrderVisible", false);
                    this.$store.commit("setMakeCreditNoteVisible", false);
                }
                let isCompany = !!this.selectedBill.client.company_id;
                this.parent_entityType = isCompany
                    ? this.entityTypeSelectItems[1]
                    : this.entityTypeSelectItems[0];
                this.parent_company_client_name = this.selectedBill.client;
                this.parent_company_client_names = [this.selectedBill.client];
                this.parent_familyClient = this.selectedBill.client.family_client_id;
                this.parent_project = this.selectedBill.project;
                if (this.selectedBill.project) {
                    this.parent_project_name = this.selectedBill.project.name;
                    this.parent_project_location = this.selectedBill.project.location;
                    this.parent_project_locations = this.selectedBill.project.location;
                    this.parent_project_note = this.selectedBill.project.note;
                    this.parent_project_status = this.selectedBill.project.status_id;
                }

                this.parent_company_client_contact_name_2 = this.selectedBill.client.contact_name_2;
                this.parent_company_client_contact_name_3 = this.selectedBill.client.contact_name_3;
                this.parent_company_client_email = this.selectedBill.client.email;
                this.parent_company_client_email_2 = this.selectedBill.client.email_2;
                this.parent_company_client_email_3 = this.selectedBill.client.email_3;
                this.parent_company_client_language = this.selectedBill.client.language;
                this.parent_company_client_gsm = this.selectedBill.client.gsm;
                this.parent_company_client_gsm_2 = this.selectedBill.client.gsm_2;
                this.parent_company_client_gsm_3 = this.selectedBill.client.gsm_3;
                this.parent_company_client_phone = this.selectedBill.client.phone;
                this.parent_company_client_phone_2 = this.selectedBill.client.phone_2;
                this.parent_company_client_phone_3 = this.selectedBill.client.phone_3;
                this.parent_representative_company_id = this.selectedBill.client.representative_company_id;

                this.reference_client = this.selectedBill.reference;
                this.note = this.selectedBill.message;
                this.next_event_code = this.selectedBill.next_event_id;
                this.$nextTick(() => {
                    // In Next Tick because on client update it will change due date automatically so we have to wait for it
                    this.due_date = this.selectedBill?.due_date;
                    this.created_date = this.selectedBill?.created_date;
                });

                if (
                    this.selectedBill.billing_address_id &&
                    this.selectedBill.billing_address.id
                ) {
                    this.parent_address = this.selectedBill.billing_address;
                }
                if (
                    this.selectedBill.shipping_address_id &&
                    this.selectedBill.shipping_address?.id
                ) {
                    this.parent_shipping_address = this.selectedBill.shipping_address;
                }

                if (this.selectedBill.client.company) {
                    this.parent_denomination = this.selectedBill.client.company.denomination_id;
                    this.parent_taxation_reason_id = this.selectedBill.client.company.taxation_reason;
                    this.parent_tva = this.selectedBill.client.company.tva;
                }
                if (
                    this.selectedBill &&
                    this.selectedBill.details &&
                    this.selectedBill.details.length > 0
                ) {
                    this.rowNumber = 0;
                    this.setTopCurrencyBasedOnDefaultBank(
                        this.selectedBill.bank_id
                    );
                    this.selectedBill.details.forEach((element) => {
                        let isToMultiplyWithCredNoteMulValue = false;
                        let selectedmu = "";
                        this.mus.forEach((elementmu) => {
                            if (elementmu.id == element.mu_id) {
                                selectedmu = elementmu;
                            }
                        });
                        if (
                            this.billType &&
                            this.billType.key == "credit_note"
                        ) {
                            isToMultiplyWithCredNoteMulValue = true;
                        }
                        let thtva =
                            element.price * element.quantity -
                            (element.price *
                                element.quantity *
                                element.reduction) /
                                100;
                        this.selectedArticles.push({
                            srno: this.rowNumber++,
                            article: element.article_id,
                            article_original_name: element.article,
                            quantity: element.quantity,
                            acquisition_price: parseFloat(
                                element.acquisition_price
                            ),
                            unit_price: parseFloat(
                                isToMultiplyWithCredNoteMulValue
                                    ? parseInt(
                                          this.creditNoteMultiplicationValue
                                              .value
                                      ) * (element.price ?? 0)
                                    : element.price ?? 0
                            ),
                            minimum_sales_price: parseFloat(
                                element.minimum_sales_price
                            ),
                            rounded_unit_price: parseFloat(
                                isToMultiplyWithCredNoteMulValue
                                    ? parseInt(
                                          this.creditNoteMultiplicationValue
                                              .value
                                      ) * (element.rounded_unit_price ?? 0)
                                    : element.rounded_unit_price ?? 0
                            ),
                            red_percentage: parseFloat(element.reduction ?? 0),
                            tarrif: parseFloat(element.article.tariff_price),
                            tva: parseFloat(element.tax),
                            thtva: parseFloat(
                                isToMultiplyWithCredNoteMulValue
                                    ? parseInt(
                                          this.creditNoteMultiplicationValue
                                              .value
                                      ) * (thtva ?? 0)
                                    : thtva ?? 0
                            ),
                            stock_property: element.stock_property ?? null,
                            total: parseFloat(
                                isToMultiplyWithCredNoteMulValue
                                    ? parseInt(
                                          this.creditNoteMultiplicationValue
                                              .value
                                      ) * (element.total ?? 0)
                                    : element.total ?? 0
                            ),
                            text: element.text,
                            row: element.row,
                            text_position: element.text_position ?? 0,
                            depot_out_id: element.depot_out_id,
                            depot_in_id: element.depot_in_id,
                            supplier_id: element.supplier_id,
                            parent_supplier_id: element.parent_supplier_id,
                            dots: null,
                            group: element.group ?? null,
                            selectedmu: selectedmu,
                            currency_id: element.currency_id,
                            expedition_id: element.expedition_id,
                        });
                    });
                }
                this.order_status = this.selectedBill.status;
                this.payment_method = this.selectedBill.payed_by_id
                    ? this.selectedBill.payedBy
                    : null;

                this.showOrHidePrintReport();
                this.showOrHidePrintDepotOutReport();
                this.getOrderReminder();
                this.reduction_on_order = this.selectedBill.reduction;

                this.order_reminder_type = this.selectedBill.reminder_type;
            } else {
                this.$store.commit("setMakeBillVisible", false);
                this.$store.commit("setMakePurchaseOrderVisible", false);
                this.$store.commit("setMakeCreditNoteVisible", false);
            }
        },
    },
};
</script>

<style scoped>
.right-aligned-input >>> input {
    text-align: right;
}

.customerbalance >>> .theme--light.v-label--is-disabled {
    color: rgb(255, 10, 10) !important;
}

.customerbalance >>> input {
    text-align: right;
    color: rgb(255, 0, 0) !important;
}

.customerbalance >>> .theme--light.v-icon.v-icon.v-icon--disabled {
    color: rgb(255, 0, 0) !important;
}

.articlenotset {
    display: flex !important;
}

.articleset {
    display: none !important;
}

.selectedArticleTextBox {
    background-color: rgb(220, 255, 220);
}

.selectedArticleTextBox .v-input__slot {
    background-color: rgb(220, 255, 220);
}

.selectedSupplierTextBox {
    background-color: rgb(255, 233, 220);
}

.selectedSupplierTextBox .v-input__slot {
    background-color: rgb(255, 233, 220);
}

.articleNotInStock {
    background-color: transparent;
    color: red;
}

.articleInStock {
    background-color: transparent;
    color: green !important;
}

.articleWaitingForSupplierOrder {
    background-color: transparent !important;
    color: red !important;
}

.articleWaitingForSupplierOrderOrange {
    background-color: transparent !important;
    color: orange !important;
}

.dontbreakline {
    white-space: pre !important;
}

.articles_row_item > .text-right {
    padding: 0px !important;
}

.v-tooltip__content {
    font-size: 14px !important;
    background: rgb(211, 255, 204) !important;
    border: 1px solid black;
    color: black !important;
    z-index: 999999999 !important;
}
</style>
