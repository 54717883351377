<template>
  <main>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="3">
        <v-text-field
            autocomplete="off"
            class="mr-3 to-upper"
            :label="$t('vehicles.search')"
            autofocus
            prepend-icon="mdi-magnify"
            v-bind:clearable="true"
            clear-icon="mdi-close"
            v-model="search"
            @input="searchHandler"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
        :key="$store.state.forceReloadComponentKey"
        :headers="headers"
        :items="formattedItems"
        :options="{
            itemsPerPage: perPage,
            itemsPerPageOptions: perPageOptions,
            page: page,

        }"
        :height="$store.state.defaultTableHeight"
        fixed-header
        :footer-props="{
            'items-per-page-options': perPageOptions,
          }"
        :server-items-length="totalItems"
        @update:page="(newPage) => {
        page = newPage
        getAddressBooks()
      }"
        @update:items-per-page="(itemsPerPage) =>{
        perPage = itemsPerPage
        getAddressBooks()
      }"
        @click:row="openFormDialog"
    >

    </v-data-table>
    <span id="address-book-form" @click="openFormDialog(null)">
      <AddressBookForm
          v-model="formDialog"
          :address-book.sync="selectedAddressBook"
          @updated="(data) => hydrateData(data)"
          @deleted="(data) => {
            addressBooks = addressBooks.filter(ab => ab.id !== data.id)
            totalItems--;
            formatItems()
          }"
      />
    </span>
  </main>
</template>
<script>
import AddressBookForm from "@/components/AddressBook/Form.vue";
import _axios from "@/plugins/axios";
import {API_BASE_URL} from "@/config";
import {DEFAULT_ITEMS_PER_PAGE_OPTIONS} from "@/config";
import addressBookMixin from "@/mixins/addressBookMixin";

export default {
  name: "AddressBookListing",
  components: {AddressBookForm},
  mixins: [addressBookMixin],
  data() {
    return {
      formDialog: false,
      page: 1,
      perPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
      perPageOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      totalItems: 0,
      selectedAddressBook: null,
      headers: [
        {
          text: this.$t('srno'),
          value: 'serial',
          sortable: false,
          width: '30px'
        },
        {
          text: this.translate('name_label'),
          value: 'name',
          sortable: false
        },
        {
          text: this.translate('email_label'),
          value: 'email',
          sortable: false
        },
        {
          text: this.translate('phone_label'),
          value: 'phone',
          sortable: false
        },
        {
          text: this.translate('gsm_label'),
          value: 'gsm',
          sortable: false
        },
      ],
      formattedItems: [],
      timerId: null,
      search: null
    }
  },
  activated() {
    this.formDialog = false
  },
  methods: {
    openFormDialog(addressBook = null) {
      this.formDialog = false
      this.selectedAddressBook = addressBook
      this.$nextTick(() => {
        this.formDialog = true
      })
    },
    formatItems() {
      this.formattedItems = this.addressBooks.map((addressBook, index) => {
        return {
          ...addressBook,
          serial: index + 1
        }
      })
    },
    translate(key) {
      return this.$t('address_book_section.' + key)
    },
    hydrateData(addressBook) {
      let index = this.addressBooks.findIndex(ab => ab.id === addressBook.id)
      if (index !== -1) {
        this.addressBooks[index] = addressBook
      } else {
        this.addressBooks.unshift(addressBook)
        this.totalItems++
      }
      this.formatItems()
    },
    searchHandler() {
      if (this.timerId) {
        clearTimeout(this.timerId)
      }
      this.timerId = setTimeout(() => {
        this.getAddressBooks()
      }, 500)
    }
  }
}
</script>