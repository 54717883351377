<template>
    <div>
        <v-btn @click="openFileOpener()" class="d-none" id="add-document-to-expedition"></v-btn>
        <div class="dialog-text text-content" :style="justUpload ? {maxHeight:'10px'} : {maxHeight:'150px'} ">
            <div class="mt-n8" @drop.prevent="handleDrop" @dragover.prevent="() => true" @dragenter.prevent="() => true" @dragleave.prevent="() => true">
                <div style="background-color:#e9f1e91c;margin-bottom:10px;padding:10px; height: 88px;">
                    <div>
                        <ul class="scroll" v-if="attachments.length && !justUpload">
                            <li class="list-item" v-for="(file, index) in attachments" :key="index">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on">
                                            <v-icon class="attachment_icon_color ml-0" @click="viewAttachment(file)" @mouseover="showDeleteIcon[index] = true" @mouseleave="showDeleteIcon[index] = false" right x-large> {{ findFileIconWithDescription(file) }}  </v-icon>
                                            <v-icon v-if="showDeleteIcon[index]" @click="deleteAttachmentAfterConfirmation(file.id)" right medium class="mt-n6 ml-n6" color="red"> mdi-delete </v-icon>
                                        </div>
                                    </template>
                                    {{ tooltipTitle(file.title) }}
                                </v-tooltip>
                                <div class="mt-0" style="font-size: 9px;">{{ makeFileTitleToShow(file.title) }}</div>
                            </li>
                        </ul>
                    </div>
                </div> 
            </div>
        </div>
        <div class="d-flex d-none ma-0 ml-n0 pa-2" cols="12" sm="6">
            <v-file-input
                show-size
                counter
                id="attachment"
                ref="attachment"
                v-model="attachment"
                type="file"
                :label="$t('attachment.file')"
                class="d-none"
                @change="showAttachmentTypeDialog()"
            ></v-file-input>
        </div>
        <v-dialog
            persistent
            @click:outside="showAttachmentTypeDialogVisible = false"
            @keydown.esc="showAttachmentTypeDialogVisible = false"
            v-model="showAttachmentTypeDialogVisible"
            min-width="500"
            max-width="800"
        >
            <v-card style="min-height: 710px;">
                <v-card-title class="text-title">
                    {{ $t("attachment.type") }}
                </v-card-title>

                <v-card-text class="dialog-text text-content" color="black">
                    <v-row>
                        <v-col  v-for="(loopattachmenttype, index) in attachmentTypes" :key="index" cols="3" class="d-flex" style="flex-direction:column">
                            <v-card @click="selectAttachmentTypeFromDialog(loopattachmenttype)" class="mb-1 flex-grow-1" height="130px" style="background-color: #f4f4f4; text-align: center; ">
                                <v-icon class="pt-3 pb-2" color="rgb(33, 150, 243)" size="60">{{ loopattachmenttype.icon }}</v-icon>
                                <p style="font-size: 16px;" class="pt-1 pb-2">
                                    {{ loopattachmenttype.text }}
                                </p>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <confirmation-model
            v-if="confirmationDialog"
            :showDialog.sync="confirmationDialog"
            :openedForOperation.sync="confirmationDialogOperation"
            :text.sync="confirmationDialogConfirmationText"
            :trueText.sync="confirmationDialogTrueText"
            :falseText.sync="confirmationDialogFalseText"
            @buttonClicked="confirmationButtonClicked"
        ></confirmation-model>
    </div>
</template>

<script>
import _ from "lodash";
import axios from "@/plugins/axios";
import { API_BASE_URL } from "@/config";
import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import ConfirmationModel from "@/components/ConfirmationModel";
import { Tile } from "maplibre-gl";

export default {
    name: "DocumentSelector",
    components: {
        ConfirmationModel,
    },
    mixins: [validationMixin],
    props: {
        showDialog: [Number, Boolean],
        data: [Number, String, Object, Array],
        resource: [String],
        countKey: [Number, String, Object],
        refresh: {type: Boolean, default: false},
        justUpload: { type: Boolean, default: false}
    },
    validations: {
        // Attachment Validation
        // description: {
        //     required: required,
        // },
        title: {
            maxLength: maxLength(200),
        },
    },
    computed: {
        headers() {
            return [
                {
                    text: "",
                    align: "start",
                    sortable: true,
                    value: "date",
                    width: "10px",
                },
                {
                    text: this.$t("attachment.date"),
                    align: "start",
                    sortable: true,
                    value: "date",
                },
                // {
                //     text: this.$t("attachment.group"),
                //     align: "start",
                //     sortable: false,
                //     value: "group",
                // },
                {
                    text: this.$t("attachment.title"),
                    align: "start",
                    sortable: false,
                    value: "title",
                },
                {
                    text: this.$t("attachment.description"),
                    align: "start",
                    sortable: false,
                    value: "description",
                },
                {
                    text: this.$t("attachment.actions"),
                    align: "start",
                    sortable: false,
                    value: "actions",
                },
            ];
        },
    },
    data() {
        return {
            is_boxes: this.getCompanyPropertyFromLocalStorage(
                "boxes_on_fields",
                1
            ),
            sortBy: "description",
            sortDesc: false,
            options: {},
            dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
            isLoading: false,
            totalItems: 0,
            page: 1,
            lastPage: 1,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
            autocompleteValueLocal: "off",
            internalShowDialog: this.showDialog,
            title: null,
            description: null,
            attachment: null,
            attachments: [],
            module_id: null,
            resource_id: null,
            editModeItem: null,
            group: null,
            resourceModule: null,
            attchmentErrors: "",

            confirmationDialog: false,
            confirmationDialogConfirmationText: null,
            confirmationDialogFalseText: null,
            confirmationDialogTrueText: null,
            confirmationDialogButtonClickedIs: null,
            confirmationDialogOperation: null,
            confirmationDialogData: null,
            showAttachmentTypeDialogVisible: false,
            attachmentType: null,
            showDeleteIcon: false,
            uploadingAttachment: false
        };
    },
    mounted() {},
    methods: {
        tooltipTitle(title){
            let expedition = this.data ? (typeof this.data == 'object' ? this.data : null) : null;
            if(expedition && expedition.identifier){
                title = title.replace(expedition.identifier, '');
            }
            return title;
        },
        makeFileTitleToShow(title){
            let expedition = this.data ? (typeof this.data == 'object' ? this.data : null) : null;
            if(expedition && expedition.identifier){
                title = title.replace(expedition.identifier, '');
            }
            return this.getFirstLetters(title);
            // return title.substr(0,);
        },
        getFirstLetters(str) {
            let firstLetterCount = str.split(' ');
            if(firstLetterCount.length > 1){
                let firstLetters = str
                    .split(' ')
                    .map(word => word.replace("#","").charAt(0))
                    .join('.');
                return firstLetters;
            }
            return str;
        },
        openFileOpener(){
            this.attachment = null;
            document.getElementById('attachment').click()
        },
        selectAttachmentTypeFromDialog(item){
            this.attachmentType = item;
            this.showAttachmentTypeDialogVisible = false;
            this.addAttachments();
        },
        showAttachmentTypeDialog(){
            if(this.attachment){
                this.showAttachmentTypeDialogVisible = true;
            }
        },
        viewAttachment(file){
            let url = file.signed_url;
            let fileName = file.title
            this.setPreviewData(url,fileName, true, file.mime_type);
        },
        handleDrop(e) {
            this.attachment = e.dataTransfer.files[0];
            this.showAttachmentTypeDialog();
        },
        deleteAttachmentAfterConfirmation(data) {
            this.confirmationDialogData = data;
            this.confirmationDialogConfirmationText = this.$t(
                "attachment.delete_attachment_confirmation"
            );
            this.confirmationDialogFalseText = this.$t("attachment.cancel");
            this.confirmationDialogTrueText = this.$t(
                "attachment.delete"
            );
            this.confirmationDialogOperation = "delete_attachment";
            this.confirmationDialog = true;
        },
        confirmationButtonClicked(buttonClicked) {
            this.confirmationDialogButtonClickedIs = buttonClicked;
            this.confirmationDialog = false;
        },
        updateResource() {
            let data = JSON.parse(localStorage.getItem("data"));
            let applicationResourceId = null;
            data.applicationResources.forEach((resource) => {
                if (resource.application_resource.key == this.resource) {
                    applicationResourceId = resource.application_resource.id;
                }
            });
            data.attachmentModules.forEach((modules) => {
                if (modules.application_resource_id == applicationResourceId) {
                    this.module_id = modules.id;
                    this.resource_id = modules.application_resource_id;
                    this.resourceModule = modules;
                }
            });
            if(! this.justUpload){
                this.getAttachments();
            }
        },
        openUrl(item) {
            // window.open(
            //     API_BASE_URL +
            //         "/attachments/file/" +
            //         `${item.resource_key_id}/${item.file_name}`,
            //     "_blank"
            // );
            // window.open(item.signed_url);
            let url = item.signed_url;
            let fileName = item.title
            this.setPreviewData(url,fileName, true, item.mime_type);
        },
        getItemIcon(item) {
            if (item.mime_type.includes("video")) {
                return "orange";
            } else if (item.mime_type.includes("image")) {
                return "blue";
            } else if (item.mime_type.includes("audio")) {
                return "blue";
            } else {
                return "black";
            }
        },
        getItemIconColor(item) {
            if (item.mime_type.includes("video")) {
                return "mdi mdi-multimedia";
            } else if (item.mime_type.includes("image")) {
                return "mdi mdi-image";
            } else if (item.mime_type.includes("audio")) {
                return "mdi mdi-volume-high";
            } else {
                return "mdi mdi-file";
            }
        },
        deleteAttachment(id) {
            
            axios
                .delete(API_BASE_URL + "/attachments/" + id, {
                    headers: {
                        Authorization:
                            "Bearer " + this.$store.state.AccessToken,
                    },
                })
                .then(({ data }) => {
                  let attachmentToBeRemoved = this.attachments.find(attachment => attachment.id === id);

                    this.removeFromList(id);
                    this.fixAttachmentForExpedition();

                    if(attachmentToBeRemoved.description === 'BILL'){
                        this.$emit('billRemoved');
                    }

                })
                .catch((error) => {
                    if (error.response.data.errors.includes("not found")) {
                        this.removeFromList(id);
                    }
                })
                .finally(() => {
                    
                });
            this.confirmationDialogData = null;
        },
        removeFromList(id) {
            let tempAttachments = [];
            this.attachments.forEach((attachment, index) => {
                if (attachment.id != id) {
                    tempAttachments.push(attachment);
                }
            });
            this.attachments = tempAttachments;
            this.totalItems = this.attachments.length;
        },
        editItem(item) {
            this.editModeItem = item;
        },
        cancelEdit() {
            this.editModeItem = null;
        },
        falseButtonClicked(item) {
            this.$emit("update:showDialog", false);
            this.$emit("buttonClicked", false);
            this.internalShowDialog = false;
        },
        getAttachments() {
            this.totalItems = 0;
            let dataId = this.data ? (typeof this.data == 'object' ? this.data.id : this.data) : null;
            if (this.module_id && dataId) {
                
                axios
                    .get(
                        API_BASE_URL +
                            `/attachments?module_id=${this.module_id}&resource_key_id=${dataId}`,
                        {
                            headers: {
                                Authorization:
                                    "Bearer " + this.$store.state.AccessToken,
                            },
                        }
                    )
                    .then(({ data }) => {
                        this.attachments = data.data.result;
                        this.totalItems = data.data.result.length;
                    })
                    .catch((error) => {
                        console.log("an error occured " + error);
                    })
                    .finally(() => {
                        
                        this.resetRefresh();
                    });
            }
        },
        resetRefresh(){
            this.$emit("update:refresh", false);
        },
        resetForm() {
            this.attachment = null;
            this.title = null;
            this.group = null;
            this.description = null;
        },
        fixAttachmentForExpedition(){
            let dataId = this.data ? (typeof this.data == 'object' ? this.data.id : this.data) : null;
            if(this.resource == 'expedition' && dataId){
                
                axios
                    .put(API_BASE_URL + "/expeditions/" + dataId + "/attachments", {}, {
                        headers: {
                            Authorization:
                                "Bearer " + this.$store.state.AccessToken,
                        },
                    })
                    .then(({ data }) => {
                    })
                    .catch((error) => {
                    })
                    .finally(() => {
                        
                    });
            }
        },
        addAttachments() {
            let dataId = this.data ? (typeof this.data == 'object' ? this.data.id : this.data) : null;
            let attachmentText = (typeof this.data == 'object' ? this.data.identifier : "") + " " +(this.attachmentType.text ? this.attachmentType.text.replace(/[^\w\s]/gi, '') : "")
            let formData = new FormData();
            formData.append("attachment", this.attachment);
            formData.append("module_id", this.module_id);
            formData.append("title", attachmentText);
            formData.append("description", this.attachmentType.key ?? "");
            formData.append("resource_key_id", dataId);
            
            this.uploadingAttachment = true;
            axios
                .post(API_BASE_URL + "/attachments", formData, {
                    headers: {
                        Authorization:
                            "Bearer " + this.$store.state.AccessToken,
                    },
                })
                .then(({ data }) => {
                    this.$emit('attachmentadded', this.attachmentType);
                    if(this.attachmentType.key == 'cmr_lv' || this.attachmentType.key == 'cmr_bl'){
                        this.$emit('cmradded', true);
                    }
                    if(this.attachmentType.key == 'bill'){
                        this.$emit('billadded', true);
                    }
                    this.fixAttachmentForExpedition();
                    this.attachments.push(data.data);
                    this.totalItems++;
                    this.resetForm();
                })
                .catch((error) => {
                    this.attchmentErrors = error.response.data.errors[0];
                    console.log(
                        "an error occured " + error.response.data.errors
                    );
                })
                .finally(() => {
                    
                    this.uploadingAttachment = false;
                    this.attachment = null;
                });
        },
        updateSort(sortBy) {
            if (typeof sortBy == "object") {
                this.sortBy = sortBy[0];
            } else {
                this.sortBy = sortBy == undefined ? "id" : sortBy;
            }
        },
        updateSortOrder(sortDesc) {
            if (typeof sortDesc == "object") {
                !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            } else {
                !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
            }
            this.fetchData();
        },
        updatePagination(page) {
            this.page = page;
            this.fetchData();
        },
        updateItemsPerPage(itemsPerPage) {
            this.itemsPerPage =
                itemsPerPage == -1 ? this.totalItems : itemsPerPage;
            this.fetchData();
            this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
        },
    },
    watch: {
        refresh(val){
            if(val){
                this.totalItems = 0;
                if(! this.justUpload){
                    this.getAttachments();
                }
            }
        },
        confirmationDialogButtonClickedIs(newVal) {
            if (newVal == true && this.confirmationDialogOperation != null) {
                // Delete Order if user declined to keep trying else keep the same status of the order i.e. supplier error
                if (this.confirmationDialogOperation == "delete_attachment") {
                    if (this.confirmationDialogButtonClickedIs == true) {
                        this.deleteAttachment(this.confirmationDialogData);
                    }
                }
                this.confirmationDialogButtonClickedIs = null;
            }
        },
        showDialog: {
            handler: function(val) {
                this.internalShowDialog = val;
            },
        },
        attachment: {
            handler: function(val) {
                if (!val) {
                    this.attchmentErrors = null;
                }
            },
        },
        attachments: {
            handler: function(val) {
                this.showDeleteIcon = [];
                if(val.length > 0){
                    this.attachments.forEach(element => {
                        this.showDeleteIcon.push(false);
                    });
                }
                this.$emit('attachments', val ?? []);
            },
        },
        resource: {
            handler: function(val) {
                this.totalItems = 0;
                this.attachments = [];
                if (val) {
                    this.updateResource();
                }
            },
            immediate: true,
        },
        totalItems: {
            handler: function(val) {
                let data  = typeof this.$store.state.attachmentCounts == 'number' ? [] : (this.$store.state.attachmentCounts ?? []);
                data[this.countKey ?? 'data'] = val;
                this.$store.commit("setAttachmentCounts", data);
                this.$store.state.attachmentCounts.push({});
                this.$nextTick(() => {
                    this.$store.state.attachmentCounts.pop();
                });
            },
        },
        data: {
            handler: function(val) {
                this.totalItems = 0;
                this.attachments = [];
                if (val) {
                    this.updateResource();
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped>
.list-item {
    width: 50px;
    height: 100%;
    margin-top: 35px;
    margin-left: 0px;
    margin-right: 20px;
    display: inline-block;
    text-align: center;
}
.list-item i {
    margin: 0px;
    padding: 0px;
}

.scroll { 
    height: 99px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: auto;
    padding: 0px;
    margin: 0px;
}
.dialog-text {
    display: block;
    height: 600px !important;
}
.v-dialog > .v-card > .v-card__subtitle,
.v-dialog > .v-card > .v-card__text {
    padding: 0 24px 27px !important;
}
.text-content {
    font-size: 18px !important;
}
.text-title {
    font-size: 24px !important;
}


/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #bdbdbd #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
</style>
