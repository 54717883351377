<template>
    <div>
        <document-selectors
                :data="expeditionToWhichDocumentToBeAdded"
                :countKey="'expedition'"
                :resource="'expedition'"
                @attachments="documentsLoaded"
                @cmradded="cmraddedChangeExpeditionStatus"
                :justUpload="true"
            ></document-selectors>
        <v-row class="mt-1">
            <v-col class="ma-0 pa-0">
                <v-text-field
                class="mr-3 to-upper"
                :label="$t('expeditions.search')"
                prepend-icon="mdi-magnify"
                v-bind:clearable="true"
                @change="updateLocalStorageValues"
                clear-icon="mdi-close"
                v-model="search"
                :outlined="is_boxes"
                :dense="is_boxes"
                ></v-text-field>
            </v-col>
            <v-col class="ma-0 pa-0 mr-2">
                <v-select
                    :label="$t('expeditions.charterer')"
                    :items="companyUsers"
                    @change="updateLocalStorageValues(); fetchData();"
                    item-text="name"
                    item-value="id"
                    v-model="user"
                    clearable
                >
                </v-select>
            </v-col>
            <v-col class="ma-0 pa-0 mr-2">
                <v-select
                    :label="$t('expeditions.second_user')"
                    :items="companyUsers"
                    @change="updateLocalStorageValues(); fetchData()"
                    item-text="name"
                    item-value="id"
                    v-model="second_user"
                    clearable
                >
                </v-select>
            </v-col>
            <v-col class="ma-0 pa-0">
                <v-text-field
                class="mr-3 to-upper"
                :label="$t('expeditions.search_charging_address')"
                prepend-icon="mdi-magnify"
                v-bind:clearable="true"
                @change="updateLocalStorageValues()"
                clear-icon="mdi-close"
                v-model="search_charging_address"
                :outlined="is_boxes"
                :dense="is_boxes"
                ></v-text-field>
            </v-col>
            <v-col class="ma-0 pa-0">
                <v-text-field
                class="mr-3 to-upper"
                :label="$t('expeditions.search_discharging_address')"
                prepend-icon="mdi-magnify"
                v-bind:clearable="true"
                @change="updateLocalStorageValues()"
                clear-icon="mdi-close"
                v-model="search_discharging_address"
                :outlined="is_boxes"
                :dense="is_boxes"
                ></v-text-field>
            </v-col>
            <v-col class="ma-0 pa-0">
                <v-menu
                    v-model="menu_search_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="search_date"
                            :label="$t('expeditions.search_date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click:append="search_date = null;"
                            append-icon="mdi-close"
                            class="mr-2 to-upper"
                            :outlined="is_boxes"
                            :dense="is_boxes"
                            hide-no-data
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        :first-day-of-week="1"
                        :weekday-format="getDay"
                        v-model="search_date"
                        no-title
                        scrollable
                        :locale="userLanguageId"
                        hide-no-data
                        hide-details
                        range
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col class="ma-0 pa-0">
                <v-select
                    :autocomplete="autocompleteValueLocal"
                    v-model="expeditionStatus"
                    :items="expeditionStatuses"
                    @change="updateLocalStorageValues"
                    item-value="id"
                    class="to-upper"
                    item-text="text"
                    clearable
                    :label="$t('expeditions.status')"
                    :outlined="is_boxes"
                    :dense="is_boxes"
                    :menu-props="{
                        closeOnClick: true,
                        closeOnContentClick: true,
                    }"
                    :background-color="setExpeditionRowColorClass(expeditionStatus)"
                >
                    <template slot="item" slot-scope="{ item }">
                        <v-list-item @click="expeditionStatus = item; updateLocalStorageValues()" class="pa-0 ma-0 pl-2" :class="setExpeditionRowColorClass(item)" style="color: black !important; width: 100%;">
                            {{ item.text }}
                        </v-list-item>
                    </template>
                </v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-data-table
                fixed-header
                :footer-props="{
                    'items-per-page-options': dataTableOptions,
                }"
                :hide-default-footer="false"
                :hide-default-header="true"
                :headers="headers"
                :items="expeditions"
                :options="options"
                :server-items-length="totalItems"
                :loading-text="this.$t('loading')"
                class="elevation-0"
                :items-per-page="itemsPerPage"
                item-key="identifier"
                @update:page="updatePagination"
                @update:items-per-page="updateItemsPerPage"
                @click:row="editItem"
                :height="'calc(-211px + 100vh)'"
                style="width: 100%"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @update:sort-by="updateSort"
                @update:sort-desc="updateSortOrder"
                mustSort
            >
                <template #header="{ props: { headers } }">
                    <thead class="v-data-table-header">
                        <tr>
                            <template v-for="header in headers">
                                <th :style="getHeaderStyle(header)" :key="header.value" scope="col" class="text-start pr-0 column" v-bind:class="[header.sortable ? 'sortable' : '', sortBy == header.value ? 'active': '', sortOrd ? 'ASC':'DESC']" @click="header.sortable ? changeSort(header.value) : ''">
                                    <template v-if="header.value == 'registration_number'">
                                        <v-row justify="justify-start">
                                            <v-tooltip bottom z-index="9999">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        <v-checkbox
                                                            class="shrink ma-0 pa-0"
                                                            hide-details="auto"
                                                            v-model="is_paid_expeditor"
                                                            @change="fetchData(); updateLocalStorageValues();"
                                                            :label="$t('expeditions.bill_short_header')  "
                                                        ></v-checkbox> 
                                                    </span>
                                                </template>
                                                {{ $t('billings.statuses.payed') }}
                                            </v-tooltip>
                                            <v-tooltip bottom z-index="9999">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        <v-checkbox
                                                            class="shrink ma-0 pa-0 ml-1"
                                                            hide-details="auto"
                                                            v-model="is_paid_transporter"
                                                            :label=" $t('expeditions.expense_short_header')"
                                                            @change="fetchData(); updateLocalStorageValues();"
                                                        ></v-checkbox>
                                                    </span>
                                                </template>
                                                {{ $t('billings.statuses.payed') }}
                                            </v-tooltip>
                                        </v-row>
                                    </template>
                                    <template v-else-if="header.value == 'ex_trans_name'">
                                        <v-row justify="justify-start">
                                            <v-tooltip bottom z-index="9999">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        <v-checkbox
                                                            class="shrink ma-0 pa-0"
                                                            hide-details="auto"
                                                            v-model="search_in_expeditor"
                                                            @click="markBothSelectedInCaseOfNoneSelected();"
                                                            @change="fetchData(); updateLocalStorageValues()"
                                                            :label="$t('expeditions.client_heading')  "
                                                        ></v-checkbox> 
                                                    </span>
                                                </template>
                                                {{ $t('expeditions.search_in') }}
                                            </v-tooltip>
                                            <v-tooltip bottom z-index="9999">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        <v-checkbox
                                                            class="shrink ma-0 pa-0 ml-1"
                                                            hide-details="auto"
                                                            v-model="search_in_transporter"
                                                            :label=" $t('expeditions.transporter_heading')"
                                                            @click="markBothSelectedInCaseOfNoneSelected();"
                                                            @change="fetchData(); updateLocalStorageValues()"
                                                        ></v-checkbox>
                                                    </span>
                                                </template>
                                                {{ $t('expeditions.search_in') }}
                                            </v-tooltip>
                                        </v-row>
                                    </template>
                                    <template v-else>
                                    {{ header.text }}
                                    <v-icon v-if="header.sortable" class="v-data-table-header__icon" small>
                                        <template v-if="sortOrd">
                                        mdi-arrow-up
                                        </template>
                                        <template v-else>
                                        mdi-arrow-down
                                        </template>
                                    </v-icon>
                                    </template>
                                </th>
                            </template>
                        </tr>
                    </thead>
                </template>
                <template v-slot:footer>
                    <div style="position: absolute" class="pa-0 pl-2 pt-4" v-if="checkIsAllowedToView">
                        <strong class="expeditorline">        {{ $t("expeditions.expeditor_total")}} : {{ currencySymbol }} {{ (expeditor_total ? expeditor_total : 0).toFixed(getDecimalNumber()) }}   </strong>
                        <strong class="transporterline ml-10">{{ $t("expeditions.transport_total")}} : {{ currencySymbol }} {{ (transport_total ? transport_total : 0).toFixed(getDecimalNumber()) }}   </strong>
                        <strong class="marginline ml-10">     {{ $t("expeditions.margin")}}          : {{ currencySymbol }} {{ (total_margin ? total_margin : 0).toFixed(getDecimalNumber()) }}         </strong>
                    </div>
                </template>
                <template v-slot:item="{ item, index }">
                    <tr class="itemrow"  :class="setExpeditionRowColorClass(item.status)">
                        <td class="row_border">
                            <v-row class="ma-0" style="text-align: center;">
                                {{ getRowIndex(index) }}
                            </v-row>
                        </td>
                        <!-- Expeditor Select -->
                        <td class="pr-0 pl-0 row_border">
                            <div :style="getDisplayType(item)">
                                <v-btn @click="editExpedition(item)" class="ma-2" small icon :style="{backgroundColor: 'green', display: 'inline-flex'}">
                                    <v-icon size="small" color="white">mdi mdi-square-edit-outline</v-icon>
                                </v-btn>
                                <template v-if="item.expeditor && item.expeditor.id && (!item.order_id || !item.expense_id)">
                                    <v-simple-checkbox
                                        @click="checkRelatedExpeditor(item, item.is_selected)"
                                        v-model="item.is_selected"
                                        :ripple="false"
                                    ></v-simple-checkbox>
                                </template>
                            </div>
                        </td>
                        <!-- Identifier -->
                        <td class="expeditionitemtd row_border">
                            <v-row class="expeditorline">
                                <TextComponentWithExtraIcons :value="item.identifier"></TextComponentWithExtraIcons>
                            </v-row>
                            <v-row class="transporterline">
                                {{ item.reference }}
                            </v-row>
                        </td>
                        <!-- Expeditor/Transporter -->
                        <td class="expeditionitemtd row_border" style="height: 85px">
                            <v-row class="expeditorline">
                                <template v-if="item.expeditor">
                                    <!-- <v-col cols="12" sm="12" class="ma-0 pa-0">
                                        <template v-if="item.expeditor.name.length > 25">
                                            <v-tooltip bottom z-index="9999">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        {{ item.expeditor.name.substr(0, 25) }}...
                                                    </span>
                                                </template>
                                                {{ item.expeditor.name }}
                                                <span v-if="item.expeditor.tva"><br>{{ $t('vehicles.clients_model.tva') }} - {{ item.expeditor.tva }}</span>
                                                <span v-if="item.expeditor.email"><br>{{ $t('vehicles.clients_model.email') }} - {{ item.expeditor.email }}</span>
                                                <span v-if="item.expeditor.phone"><br>{{ $t('vehicles.clients_model.phone') }} - {{ item.expeditor.phone }}</span>
                                                <span v-if="item.expeditor.fax"><br>{{ $t('vehicles.clients_model.fax') }} - {{ item.expeditor.fax }}</span>
                                                <span v-if="item.expeditor.credit_limit"><br>{{ $t('billings.credit_limit') }} - {{ item.expeditor.credit_limit }}</span>
                                            </v-tooltip>
                                        </template>
                                        <template v-else>
                                            <v-tooltip bottom z-index="9999">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        {{ item.expeditor.name.substr(0, 25) }}
                                                    </span>
                                                </template>
                                                {{ item.expeditor.name }}
                                                <span v-if="item.expeditor.tva"><br>{{ $t('vehicles.clients_model.tva') }} - {{ item.expeditor.tva }}</span>
                                                <span v-if="item.expeditor.email"><br>{{ $t('vehicles.clients_model.email') }} - {{ item.expeditor.email }}</span>
                                                <span v-if="item.expeditor.phone"><br>{{ $t('vehicles.clients_model.phone') }} - {{ item.expeditor.phone }}</span>
                                                <span v-if="item.expeditor.fax"><br>{{ $t('vehicles.clients_model.fax') }} - {{ item.expeditor.fax }}</span>
                                                <span v-if="item.expeditor.credit_limit"><br>{{ $t('billings.credit_limit') }} - {{ item.expeditor.credit_limit }}</span>
                                            </v-tooltip>
                                        </template>
                                        <span v-if="item.expeditor.tva"> ({{ item.expeditor.tva }}) </span>
                                    </v-col> -->
                                    <v-col cols="12" sm="12" class="ma-0 pa-0">
                                        <template v-if="item.expeditor_billing_working_point && item.expeditor_billing_working_point.name">
                                            {{ item.expeditor_billing_working_point.name }}<span v-if="item.expeditor.tva">, {{ $t('vehicles.clients_model.tva') }} - {{ item.expeditor.tva }}<br></span>
                                            {{ computeExpeditionListingAddressLabel(item.expeditor_billing_working_point.address) }}
                                        </template>
                                    </v-col>
                                </template>
                            </v-row>
                            <v-row class="transporterline">
                                <template v-if="item.transporter">
                                    <v-col cols="12" sm="12" class="ma-0 pa-0">
                                        <template v-if="item.transporter.name.length > 25">
                                            <v-tooltip bottom z-index="9999">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        {{ item.transporter.name.substr(0, 25) }}...
                                                    </span>
                                                </template>
                                                {{ item.transporter.name }}
                                                <span v-if="item.transporter.tva"><br>{{ $t('vehicles.clients_model.tva') }} - {{ item.transporter.tva }}</span>
                                                <span v-if="item.transporter.email"><br>{{ $t('vehicles.clients_model.email') }} - {{ item.transporter.email }}</span>
                                                <span v-if="item.transporter.phone"><br>{{ $t('vehicles.clients_model.phone') }} - {{ item.transporter.phone }}</span>
                                                <span v-if="item.transporter.fax"><br>{{ $t('vehicles.clients_model.fax') }} - {{ item.transporter.fax }}</span>
                                                <span v-if="item.transporter.credit_limit"><br>{{ $t('billings.credit_limit') }} - {{ item.transporter.credit_limit }}</span>
                                            </v-tooltip>
                                        </template>
                                        <template v-else>
                                            <v-icon class="pr-1" v-if="item.is_email_sent" size="15" color="green">mdi-email-check</v-icon>
                                            <v-tooltip bottom z-index="9999">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        {{ item.transporter.name }}
                                                    </span>
                                                </template>
                                                {{ item.transporter.name }}
                                                <span v-if="item.transporter.tva"><br>{{ $t('vehicles.clients_model.tva') }} - {{ item.transporter.tva }}</span>
                                                <span v-if="item.transporter.email"><br>{{ $t('vehicles.clients_model.email') }} - {{ item.transporter.email }}</span>
                                                <span v-if="item.transporter.phone"><br>{{ $t('vehicles.clients_model.phone') }} - {{ item.transporter.phone }}</span>
                                                <span v-if="item.transporter.fax"><br>{{ $t('vehicles.clients_model.fax') }} - {{ item.transporter.fax }}</span>
                                                <span v-if="item.transporter.credit_limit"><br>{{ $t('billings.credit_limit') }} - {{ item.transporter.credit_limit }}</span>
                                            </v-tooltip>
                                        </template>
                                        <span v-if="item.transporter.tva"> ({{ item.transporter.tva }}) </span>
                                    </v-col>
                                    <v-col cols="12" sm="12" class="ma-0 pa-0">
                                        <template v-if="item.transporter && item.transporter.company_addresses && item.transporter.company_addresses.length > 0">
                                            {{  getTransporterMainAddress(item.transporter.company_addresses ) }}
                                        </template>
                                    </v-col>
                                </template>
                            </v-row>
                        </td>
                        <!-- Bill/Expense Number -->
                        <td class="expeditionitemtd row_border">
                            <v-row class="expeditorline">
                                    <template v-if="item.order">
                                        <TextComponentWithExtraIcons :value="item.order.identifier"></TextComponentWithExtraIcons>
                                    </template>
                                    <template v-if="item.order && item.order.payments && item.order.payments.length > 0">
                                        <v-icon size="large" color="green">mdi-check</v-icon>
                                    </template>
                            </v-row>
                            <v-row class="transporterline">
                                    <template v-if="item.expense">
                                        {{ item.expense.reference }}
                                    </template>
                                    <template v-if="item.expense && item.expense.payments && item.expense.payments.length > 0">
                                        <v-icon size="large" color="rgb(214, 129, 0)">mdi-check</v-icon>
                                    </template>
                            </v-row>
                        </td>
                        <!-- Registration Number -->
                        <td class="expeditionitemtd row_border">
                            <v-row class="transporterline">
                                    <template>
                                        {{ item.trolly_registration_number }}
                                        <br v-if="item.trolly_registration_number">
                                        {{ item.truck_registration_number }}
                                    </template>
                            </v-row>
                        </td>
                        <!-- Charging/Discharging Address -->
                        <td class="expeditionitemtd row_border">
                            <v-row class="expeditorline">
                                <template v-if="item.chargings && item.chargings.length > 0 && item.chargings[0].address">
                                    <template>
                                        <v-tooltip bottom z-index="9999">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on" class="edit_hover_class">
                                                    {{ computeExpeditionListingAddressLabel(item.chargings[0].address) }}
                                                        <v-icon color="black" v-bind="attrs" v-on="on" size="12" @click="copyText(computeExpeditionListingAddressLabel(item.chargings[0].address))">
                                                            mdi-content-copy
                                                        </v-icon>
                                                </span>
                                            </template>
                                            {{ item.chargings[0].address.human_readable_address }}
                                        </v-tooltip>
                                    </template>
                                </template>
                            </v-row>
                            <v-row class="transporterline">
                                <template v-if="item.dischargings && item.dischargings.length > 0 && item.dischargings[0].address">
                                    <template>
                                        <v-tooltip bottom z-index="9999">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on"  class="edit_hover_class">
                                                    {{ computeExpeditionListingAddressLabel(item.dischargings[0].address) }}
                                                        <v-icon color="black" v-bind="attrs" v-on="on" size="12" @click="copyText(computeExpeditionListingAddressLabel(item.dischargings[0].address))">
                                                            mdi-content-copy
                                                        </v-icon>
                                                </span>
                                            </template>
                                            {{ item.dischargings[0].address.human_readable_address }}
                                        </v-tooltip>
                                    </template>
                                </template>
                            </v-row>
                        </td>
                        <td class="row_border row_border">
                            <v-row class="expeditorline">
                                <template v-if="item.chargings && item.chargings.length > 0 && item.chargings[0]">
                                    {{ item.chargings[0].charging_from_date }}
                                </template>
                            </v-row>
                            <v-row class="transporterline">
                                <template v-if="item.dischargings && item.dischargings.length > 0 && item.dischargings[0]">
                                    {{ item.dischargings[0].charging_from_date }}
                                </template>
                            </v-row>
                        </td>
                        <!-- Product to Charge  -->
                        <td class="expeditionitemtd row_border">
                            <v-row class="ma-1">
                                <template v-if="item.chargings && item.chargings.length > 0">
                                    <!-- <v-chip class="ma-1" small>
                                        <template v-if="item.chargings[0].article.original_name.length > 25">
                                            <v-tooltip bottom z-index="9999">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        {{ item.chargings[0].article.original_name.substr(0, 25) }}...
                                                    </span>
                                                </template>
                                                {{ item.chargings[0].article.original_name }}
                                            </v-tooltip>
                                        </template>
                                        <template v-else>
                                            {{ item.chargings[0].article.original_name }}
                                        </template>
                                        ({{ item.chargings[0].quantity }})
                                    </v-chip> -->
                                    <v-chip class="ma-1" small v-if="item.chargings[0].merchandise_type">
                                        {{ makeMerchandiseTitle($t("merchandise_types." + item.chargings[0].merchandise_type.key)) }}
                                    </v-chip>
                                    <v-chip class="ma-1" small v-if="item.chargings[0].weight && item.chargings[0].weight > 0">
                                        {{ item.chargings[0].weight }} {{ $t('expeditions.chip_unit_tons') }}
                                    </v-chip>
                                    <v-chip class="ma-1" small v-if="item.chargings[0].loading_meters && item.chargings[0].loading_meters > 0">
                                        {{ item.chargings[0].loading_meters }} {{ $t('expeditions.chip_unit_floor_meter') }}
                                    </v-chip>
                                    <v-chip class="ma-1" small v-if="item.chargings[0].volume && item.chargings[0].volume > 0">
                                        {{ item.chargings[0].volume }} {{ $t('expeditions.chip_unit_meter_cubs') }}
                                    </v-chip>
                                    <v-chip class="ma-1" small v-if="item.chargings[0].width && item.chargings[0].width > 0">
                                        {{ item.chargings[0].width }} {{ $t('expeditions.chip_unit_width') }}
                                    </v-chip>
                                    <v-chip class="ma-1" small v-if="item.chargings[0].height && item.chargings[0].height > 0">
                                        {{ item.chargings[0].height }} {{ $t('expeditions.chip_unit_height') }}
                                    </v-chip>
                                    <v-chip class="ma-1" small v-if="item.chargings[0].length && item.chargings[0].length > 0">
                                        {{ item.chargings[0].length }} {{ $t('expeditions.chip_unit_length') }}
                                    </v-chip>
                                </template>
                            </v-row>
                            <v-row>
                            </v-row>
                        </td>
                        <!-- Truck/Trailer Registration Number -->
                        <!-- <td @click="editExpedition(item)" class="expeditionitemtd">
                            <v-row class="expeditorline">
                                {{ item.truck_registration_number }}
                            </v-row>
                            <v-row class="transporterline">
                                {{ item.trolly_registration_number }}
                            </v-row>
                        </td> -->
                        <!-- Total Bill/Bill Due Date -->
                        <td class="expeditionitemtd row_border">
                            <v-row class="expeditorline">
                                <template v-if="item.expeditor_price">
                                    {{ currencySymbol }} {{ (item.expeditor_price ? item.expeditor_price : 0).toFixed(getDecimalNumber()) }}
                                </template>
                            </v-row>
                            <v-row class="transporterline">
                                <template v-if="item.transport_price">
                                    {{ currencySymbol }} {{ (item.transport_price ? item.transport_price : 0).toFixed(getDecimalNumber()) }}
                                </template>
                                <!-- Due Date -->
                                <!-- <template v-if="item.order && item.order.due_date">
                                    {{ item.order.due_date.substr(0, 10) }}
                                </template> -->
                            </v-row>
                            <v-row>
                                <template v-if="item.expeditor_price && item.transport_price">
                                    {{ currencySymbol }} {{ ((item.expeditor_price - item.transport_price) ? (item.expeditor_price - item.transport_price) : 0).toFixed(getDecimalNumber()) }}
                                </template>
                            </v-row>
                        </td>
                        <!-- Margin -->
                        <!-- <td class="expeditionitemtd">
                            <v-row class="expeditorline">
                                <template v-if="item.expeditor_price && item.transport_price">
                                    {{ currencySymbol }} {{ item.expeditor_price - item.transport_price }}
                                </template>
                            </v-row>
                        </td> -->
                        <!-- Email Sent -->
                        <!-- <td class="expeditionitemtd">
                            <v-row class="expeditorline">
                            </v-row>
                        </td> -->
                        <!-- Created By -->
                        <td class="expeditionitemtd row_border">
                            <v-row class="expeditorline">
                                <template v-if="item.user">
                                    <v-tooltip bottom z-index="9999">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on" class="edit_hover_class">
                                                {{ getFirstLetters(item.user.name) }}
                                            </span>
                                        </template>
                                        {{ item.user.name }}
                                    </v-tooltip>
                                </template>
                            </v-row>
                            <v-row class="transporterline">
                                <template v-if="item.seconduser">
                                    <v-tooltip bottom z-index="9999">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on" class="edit_hover_class">
                                                {{ getFirstLetters(item.seconduser.name) }}
                                            </span>
                                        </template>
                                        {{ item.seconduser.name }}
                                    </v-tooltip>
                                </template>
                            </v-row>
                        </td>
                        <!-- Status -->
                        <!-- <td class="expeditionitemtd">
                            <v-row>
                                <template v-if="item.status && item.status.key">
                                    {{ $t('expedition_statuses.' + item.status.key) }}
                                </template>
                            </v-row>
                            <v-row>
                            </v-row>
                        </td> -->
                        <!-- Documents -->
                        <td class="row_border">
                            <div>
                                    <v-row>
                                        <ul class="scroll pl-0">
                                            <template v-if="item.attachments.length">
                                                <li class="list-item" v-for="(file) in item.attachments" :key="file">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div v-bind="attrs" v-on="on">
                                                                <v-icon class="attachment_icon_color" @click="viewAttachment(file)" right medium> {{ findFileIconWithDescription(file) }}  </v-icon>
                                                            </div>
                                                        </template>
                                                        {{ tooltipTitleForDataTableItem(file.title, item) }}
                                                    </v-tooltip>
                                                </li>
                                            </template>
                                        </ul>
                                    </v-row>
                                    <v-row>
                                        <ul class="scroll pl-0">
                                        <template>
                                            <li class="list-item">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <div v-bind="attrs" v-on="on">
                                                            <v-icon class="attachment_icon_color" @click="addAttachmentToExpedition(item)" right medium>mdi-plus</v-icon>
                                                        </div>
                                                    </template>
                                                    {{ $t("add_attachment") }}
                                                </v-tooltip>
                                            </li>
                                        </template>
                                        </ul>
                                    </v-row>
                            </div>
                        </td>  
                        <!-- Note -->
                        <td class="expeditionitemtd row_border">
                            <v-row >
                                <template v-if="item.note">
                                    <v-tooltip right z-index="9999">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="warning" v-bind="attrs" v-on="on">
                                                mdi-note-alert
                                            </v-icon>
                                        </template>
                                        {{ item.note }}
                                    </v-tooltip>
                                </template>
                            </v-row>
                            <v-row>
                            </v-row>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-row>
        <v-btn @click="makeExpeditionBill()" class="d-none" id="make-expedition-bill"></v-btn>
        <v-btn @click="makeExpeditionExpense()" class="d-none" id="make-expedition-expense"></v-btn>
    </div>
</template>
<script>
    import axios from "@/plugins/axios";
    import _ from "lodash";
    import { API_BASE_URL } from "@/config";
    import { EXPEDITION_LISTING_MODE } from "@/config";
    import { EDIT_MODE } from "@/config";
    import { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "@/config";
    import { ADD_MODE } from "@/config";
    import DocumentSelectors from "@/components/CustomInputs/DocumentSelectors";
    
    export default{
        name:"ExpeditionListing",
        components: {
            "document-selectors": DocumentSelectors,
        },
        data(){
            return {
                search_discharging_address: null,
                search_charging_address: null,
                autocompleteValueLocal: "off",
                is_boxes: this.getCompanyPropertyFromLocalStorage("boxes_on_fields", 1),
                isLoading: true,
                totalItems: 0,
                lastPage: 1,
                sortBy: "id",
                sortDesc: true,
                sortOrd: "ASC",
                itemsPerPage: DEFAULT_ITEMS_PER_PAGE_OPTIONS[0],
                expeditions: [],
                search: "",
                options: {},
                dataTableOptions: DEFAULT_ITEMS_PER_PAGE_OPTIONS,
                // header
                token: this.$store.state.AccessToken,
                header: { Authorization: "Bearer " + this.$store.state.AccessToken },
                
                search_date: null,
                menu_search_date: false,
                expeditionStatuses: [],
                expeditionStatus: null,
                isMountingPage: true,

                company: null,
                user: null,
                second_user: null,
                total_margin: 0,
                expeditor_total: 0,
                transport_total: 0,
                expeditionToWhichDocumentToBeAdded: null,
                is_paid_expeditor: null,
                is_paid_transporter: null,
                search_in: null,
                search_in_transporter: true,
                search_in_expeditor: true,
            }
        },
        mounted(){
            this.$store.commit("seteditId", null);
            this.$store.commit("setFormMode", EXPEDITION_LISTING_MODE);
            this.getExpeditionStatuses().then(()=> {
                this.checkAndApplyLocalStorageValues().then(() => {
                    // if(! this.expeditionStatus){
                    //     this.expeditionStatus = this.expeditionStatuses[0].id;
                    // }
                    this.fetchData();
                    this.isMountingPage = false;
                });
            });         
            this.getCompany();   
        },
        watch:{
            search: _.debounce(function (e) {
                if(!this.isMountingPage){
                    this.fetchData();
                }
            }, 500),
            search_charging_address: _.debounce(function (e) {
                if(!this.isMountingPage){
                    this.fetchData();
                }
            }, 500),
            search_discharging_address: _.debounce(function (e) {
                if(!this.isMountingPage){
                    this.fetchData();
                }
            }, 500),
            search_date(val){
                if(!this.isMountingPage){
                    this.updateLocalStorageValues();
                    this.fetchData();
                }
            },
            expeditionStatus(val){
                if(!this.isMountingPage){
                    this.fetchData();
                }
            }
        },
        methods:{
            markBothSelectedInCaseOfNoneSelected(){
                if(this.search_in_expeditor == false && this.search_in_transporter == false){
                    this.$nextTick(() => {
                        this.search_in_expeditor = true;
                        this.search_in_transporter = true;
                    });
                }
            },
            tooltipTitleForDataTableItem(title, item){
                let expedition = item ? (typeof item == 'object' ? item : null) : null;
                if(expedition && expedition.identifier){
                    title = title.replace(expedition.identifier, '');
                }
                return title;
            },
            getTransporterMainAddress(company_addressess){
                let address  = null;
                company_addressess.forEach((company_address) => {
                    if(company_address.address_type_id == 10511001){
                        address = company_address.address;
                    }
                })
                return address ? this.computeExpeditionListingAddressLabel(address) : '';
            },
            documentsLoaded(attachments){
                if(attachments && attachments.length > 0){
                    this.expeditions.forEach((expedition) => {
                        if(this.expeditionToWhichDocumentToBeAdded && expedition.id == this.expeditionToWhichDocumentToBeAdded.id){
                            if(expedition.attachments.length == 0){
                                expedition.attachments = attachments;
                            }
                            else{
                                expedition.attachments.push(attachments[0]);
                            }
                            this.expeditionToWhichDocumentToBeAdded = null;
                        }
                    })
                }
            },
            cmraddedChangeExpeditionStatus(){
                this.expeditionStatuses.forEach((status) => {
                    if(status.key == 'finished'){
                        let partialUpdateData = {
                            partialUpdate: true,
                            status_id: status.id
                        }
                        this.updateExpedition(partialUpdateData);
                    }
                })
            },
            updateExpedition(partialUpdateData){
                if(this.expeditionToWhichDocumentToBeAdded){
                    axios
                    .put(API_BASE_URL + "/expeditions/" + this.expeditionToWhichDocumentToBeAdded.id, partialUpdateData, {
                        headers: {
                            Authorization:
                                "Bearer " + this.$store.state.AccessToken,
                        },
                    })
                    .then(({ data }) => {
                        this.fetchData();
                    })
                    .catch((error) => {
                    })
                    .finally(() => {
                        
                    });
                }
            },
            addAttachmentToExpedition(item){
                this.expeditionToWhichDocumentToBeAdded = item;
                document.getElementById("add-document-to-expedition").click();
            },
            makeMerchandiseTitle(title){
                return this.getFirstLetters(title);
                // return title.substr(0,);
            },
            getFirstLetters(str) {
                let firstLetterCount = str.split(' ');
                if(firstLetterCount.length > 1){
                    let firstLetters = str
                        .split(' ')
                        .map(word => word.replace("#","").charAt(0))
                        .join('.');
                    return firstLetters;
                }
                return str;
            },
            getDisplayType(item){
                return item.expeditor && item.expeditor.id && (item.order_id == null || item.expense_id == null) ? 'display: flex; border: 0px' : '';
            },
            getRowIndex(index){
                if(this.page != 1){
                    return (this.page - 1 ) * this.itemsPerPage  + index + 1; 
                }
                return index + 1;
            },
            toggleElementById(index, state) {
                let node = document.getElementById(this.generateElementId(index));

                if (node) {
                    node.style.visibility = state;
                }

                let editArticleNode = document.getElementById("expedition_" + index);

                if (editArticleNode) {
                    editArticleNode.style.visibility = state;
                }
            },
            generateElementId(index) {
                return "expedition_" + index;
            },
            getCompany() {
                return new Promise((resolve, reject) => {
                    axios.get(API_BASE_URL + '/companies/' + this.$store.state.companyId, {
                            headers: this.header,
                        })
                        .then((response) => {
                            this.company = response.data.data
                            resolve(this.company)
                        })
                        .catch(error => reject(error))
                });
            },
            checkAndApplyLocalStorageValues(){
                return new Promise((resolve) => {
                    let localStorageExpeditionFilterValues = JSON.parse(localStorage.getItem("EXPEDITION_LISTING_FILTERS") ?? "[]");
                    if(localStorageExpeditionFilterValues.status_id){
                        this.expeditionStatus = localStorageExpeditionFilterValues.status_id;
                        this.setExpeditionRowColorClass(this.expeditionStatus);
                    }
                    if(localStorageExpeditionFilterValues.search_date){
                        this.search_date = localStorageExpeditionFilterValues.search_date;
                    }
                    if(localStorageExpeditionFilterValues.search){
                        this.search = localStorageExpeditionFilterValues.search;
                    }
                    if(localStorageExpeditionFilterValues.user){
                        this.user = localStorageExpeditionFilterValues.user;
                    }
                    if(localStorageExpeditionFilterValues.second_user){
                        this.second_user = localStorageExpeditionFilterValues.second_user;
                    }
                    // if(localStorageExpeditionFilterValues.search_in_expeditor){
                        this.search_in_expeditor = localStorageExpeditionFilterValues?.search_in_expeditor ?? true;
                    // }
                    // if(localStorageExpeditionFilterValues.search_in_transporter){
                        this.search_in_transporter = localStorageExpeditionFilterValues?.search_in_transporter ?? true;
                    // }
                    if(localStorageExpeditionFilterValues.search_charging_address){
                        this.search_charging_address = localStorageExpeditionFilterValues.search_charging_address;
                    }
                    if(localStorageExpeditionFilterValues.search_discharging_address){
                        this.search_discharging_address = localStorageExpeditionFilterValues.search_discharging_address;
                    }
                    // if(localStorageExpeditionFilterValues.is_paid_expeditor){
                        this.is_paid_expeditor = localStorageExpeditionFilterValues?.is_paid_expeditor ?? false;
                    // }
                    // if(localStorageExpeditionFilterValues.is_paid_transporter){
                        this.is_paid_transporter = localStorageExpeditionFilterValues?.is_paid_transporter ?? false;
                    // }
                    resolve();
                })
            },
            updateLocalStorageValues(){
                var filters = {
                    "status_id" : this.expeditionStatus ? (typeof this.expeditionStatus == 'object' ? this.expeditionStatus.id : this.expeditionStatus) : null,
                    "search_date": this.search_date ? this.search_date: null,
                    "search": this.search ? this.search : null,
                    "user": this.user ? this.user : null,
                    "second_user": this.second_user ? this.second_user : false,
                    "search_in_transporter": this.search_in_transporter ? this.search_in_transporter : false,
                    "search_in_expeditor" : this.search_in_expeditor ? this.search_in_expeditor : false,
                    "search_charging_address":  this.search_charging_address ? this.search_charging_address : null,
                    "search_discharging_address": this.search_discharging_address ? this.search_discharging_address : null,
                    "is_paid_expeditor": this.is_paid_expeditor ? this.is_paid_expeditor : null,
                    "is_paid_transporter": this.is_paid_transporter ? this.is_paid_transporter : null,
                };
                localStorage.setItem("EXPEDITION_LISTING_FILTERS",JSON.stringify(filters));
            },
            viewAttachment(file){
                let url = file.signed_url;
                let fileName = file.title
                this.setPreviewData(url,fileName, true, file.mime_type);
            },
            makeExpeditionBill(){
                let order_id = null;
                let selectedExpeditionsForBill = this.updateSelectedExpeditionsForBill();
                selectedExpeditionsForBill.forEach((element) => {
                    if(element.order_id){
                        order_id = element.order_id; 
                    }
                })
                if(order_id){
                    // Already Set: setSelectedExpeditionIdsForBilling using computed property
                    this.$store.commit("seteditId", order_id);
                    this.$router.push("/billing/edit");
                }
                else{
                    // Already Set: setSelectedExpeditionIdsForBilling using computed property
                    this.$router.push("/billing/add");
                }
            },
            makeExpeditionExpense(){
                let expense_id = null;
                let selectedExpeditionsForBill = this.updateSelectedExpeditionsForBill();
                selectedExpeditionsForBill.forEach((element) => {
                    if(element.expense_id){
                        expense_id = element.expense_id; 
                    }
                })
                if(expense_id){
                    // Already Set: setSelectedExpeditionIdsForBilling using computed property
                    this.$store.commit("seteditId", expense_id);
                    this.$router.push("/expenses/edit");
                }
                else{
                    // Already Set: setSelectedExpeditionIdsForBilling using computed property
                    this.$router.push("/expenses/add");
                }
            },
            checkRelatedExpeditor(item, is_already_selected){
                if(is_already_selected){
                    // Select the Expeditions
                    this.expeditions.forEach((expedition) => {
                        if(item.id == expedition.id){
                            expedition.is_selected = is_already_selected;
                        }
                    })
                }
                // if(is_already_selected){ // If we are clicking on Non checked item then select releated ones
                //     // First De-Select All the expedition
                //     this.expeditions.forEach((expedition) => {
                //             expedition.is_selected = false;
                //     })
                //     // Select the related Expeditions
                //     this.expeditions.forEach((expedition) => {
                //         if(item.expeditor_id == expedition.expeditor_id && expedition.status.key == 'finished' && expedition.order_id == null){
                //             expedition.is_selected = is_already_selected;
                //         }
                //     })
                // }
                // else{
                //     // Just Unselect the clicked Item
                //     this.expeditions.forEach((expedition) => {
                //         if(item.id == expedition.id){
                //             expedition.is_selected = is_already_selected;
                //         }
                //     })
                // }
                
                this.updateSelectedExpeditionsForBill();
            },
            updateSelectedExpeditionsForBill(){
                let selExp = this.expeditions.filter((element) => {
                    return element.is_selected;
                })
                this.$store.commit("setSelectedExpeditionIdsForBilling", selExp);

                return selExp;
            },
            async getExpeditionStatuses() {
                if(this.expeditionStatuses.length == 0){
                    await this.getCodes('expedition_statuses', 'expedition_statuses')
                    .then((data) => {
                        this.expeditionStatuses = data;
                    })
                }
            },
            editExpedition(item){
                this.$store.commit("seteditId", item.id);
                this.$router.push("/expedition/edit").catch(() => {});
            },
            fetchData(currentPage = null) {
                this.resetDataTable(currentPage);
                this.isLoading = true;
                let defaultSearchIns = 'bill,expense';
                axios
                .get(
                    API_BASE_URL + "/expeditions?page=" +
                        this.page +
                        "&items_per_page=" +
                        this.itemsPerPage +
                        (this.search ? "&search=" + encodeURIComponent(this.search) : "") + 
                        (this.search_date ? "&date=" + this.search_date : "") + 
                        (this.expeditionStatus ? "&status_id=" + (typeof this.expeditionStatus == 'object' ? this.expeditionStatus.id : this.expeditionStatus) : "") + 
                        (this.user ? "&created_by=" + this.user : "") + 
                        (this.second_user ? "&created_by_second_user=" + this.second_user : "" )+
                        (this.search_discharging_address ? "&search_discharging_address=" + this.search_discharging_address : "") + 
                        (this.search_charging_address ? "&search_charging_address=" + this.search_charging_address : "") + 
                        (this.is_paid_expeditor ? "&is_paid_expeditor=" + this.is_paid_expeditor : "") +
                        (this.is_paid_transporter ? "&is_paid_transporter=" + this.is_paid_transporter : "") +
                        (this.search_in_expeditor || this.search_in_transporter ? ("&search_in=" + (this.search_in_expeditor ? 'bill' : '') + "," + (this.search_in_transporter ? 'expense' : '')) : ("&search_in=" + defaultSearchIns)) +
                        "&sortby=" + this.sortBy + "&sorttype=" + this.sortOrd,
                {
                    headers: this.header,
                }
                )
                .then(({ data }) => {
                    data.data.forEach((element) => {
                        element.is_selected = false;
                    })
                    this.expeditions = data.data;
                    this.total_margin = data.meta.total_margin;
                    this.transport_total = data.meta.transport_total;
                    this.expeditor_total = data.meta.expeditor_total;
                    this.lastPage = data.meta.last_page;
                    this.totalItems = data.meta.total;
                    this.page = data.meta.current_page;
                    // this.itemsPerPage = parseInt(data.meta.per_page);
                    // this.sortBy = null;
                    // this.sortDesc = null;
                    this.isLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.isLoading = false;
                });
            },
            updatePagination(page) {
                this.page = page;
                this.fetchData(page);
            },
            updateItemsPerPage(itemsPerPage) {
                this.itemsPerPage = itemsPerPage == -1 ? this.totalItems : itemsPerPage;
                this.fetchData();
                this.itemsPerPage = this.itemsPerPage == -1 ? -1 : itemsPerPage;
            },
            editItem(item) {
                this.$store.commit("seteditId", item.id);
                this.$store.commit("setFormMode", EDIT_MODE);
                this.$router.push("/client/edit").catch(() => {});
            },
            deleteItem(item) {
                // 
            },
            updateSort(sortBy) {
                if (typeof sortBy == "object") {
                    this.sortBy = sortBy[0];
                } else {
                    this.sortBy = sortBy;
                }
                this.fetchData();
            },
            updateSortOrder(sortDesc) {
                if (typeof sortDesc == "object") {
                    !sortDesc[0] ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
                } else {
                    !sortDesc ? (this.sortOrd = "ASC") : (this.sortOrd = "DESC");
                }
                this.fetchData();
            },
        },
        computed: {
            checkIsAllowedToView() {
                if(this.isAdmin){
                    return true;
                }
                if (this.currentUser && this.currentUser.id) {
                    const userId = this.currentUser.id;

                    if ((this.user == null && this.second_user == null) || 
                        (this.user != null && this.user !== userId) || 
                        (this.second_user != null && this.second_user !== userId)) {
                        return false;
                    }

                    return true;
                }
                return false;
            },
            companyUsers() {
                if(!this.company){
                    return [];
                }
                return this.company.users;
            },
            currencySymbol() {
                let symbol = null;
                if(this.$store.state.topCurrencyType){
                    symbol = this.parseSymbol(this.$store.state.topCurrencyType.symbol ?? '')
                    return symbol;
                }
                return symbol ?? ''; 
            },
            headers() {
                return [
                    { text: this.$t(""), value: "checkbox", sortable: false},
                    { text: this.$t(""), value: "clickable", sortable: false},
                    { text: this.$t("expeditions.expedition_number"), value: "expedition_number", sortable: false, width: '1%'},
                    { text: this.$t("expeditions.expeditor/transporter"), value: "ex_trans_name", sortable: false, width: '35%'},
                    { text: this.$t("expeditions.bill_and_expense_number"), value: "registration_number", sortable: false, width: '13%'},
                    { text: this.$t("expeditions.registration_number"), value: "registration", sortable: false, width: '250px'},
                    { text: this.$t("expeditions.address"), value: "address", sortable: false, width: '16%'},
                    { text: this.$t("expeditions.load_unload_date"), value: "load_unload_date", sortable: false, width: '7%'},
                    { text: this.$t("expeditions.products"), value: "products", sortable: false, width: '250px'},
                    // { text: this.$t("expeditions.transport"), value: "transport", sortable: false},
                    { text: this.$t("expeditions.bill_details"), value: "bill_details", sortable: false, width: '6%'},
                    // { text: this.$t("expeditions.margin"), value: "margin", sortable: false},
                    // { text: this.$t("expeditions.is_email_sent"), value: "is_email_sent", sortable: false},
                    { text: this.$t("expeditions.created_by"), value: "created_by", sortable: false, width: '5%'},
                    // { text: this.$t("expeditions.status"), value: "status", sortable: false},
                    { text: this.$t("expeditions.documents"), value: "documents", sortable: false, width: '10%'},
                    { text: this.$t("expeditions.note"), value: "note", sortable: false, width: "2%"},
                ];
            },
        },
    }
</script>
<style>
.greycolor{
    background-color: #e6e6e6 !important;
}
.orangecolor
{
    background-color: #fff0da !important;
}
.greencolor{
    background-color: #d5ffd6 !important;
}
.bluecolor{
    background-color: #649cdb88 !important;
}
.redcolor{
    background-color: #ffdada !important;
}

.row_border{
    border-bottom: 2px solid rgba(40, 40, 40, 0.345) !important;
  }


.list-item {
    /* width: 20px; */
    height: 100%;
    /* margin-top: 35px; */
    /* margin-left: 0px; */
    /* margin-right: 20px;   */
    display: inline-block;
}
.list-item i {
    margin: 0px;
    padding: 0px;
}

.scroll { 
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    width: auto;
    padding: 0px;
    margin: 0px;
}
.expeditionitemtd{
    font-size: 13px !important;
}
.expeditorline{
    color: green;
}
.transporterline{
    color: rgb(214, 129, 0);
}
.edit_hover_class{
    cursor: grab;
}
.edit_hover_class button{
  visibility:hidden;
  cursor: grab;
}
.edit_hover_class:hover button {
  visibility:visible;
  cursor: grab;
}
</style>